import React, { useCallback, useEffect, useState } from 'react'
import * as S from './EmailSlide.style'
import Switch from '@bonliva-components/web/shared/Switch'
import { EmailPreferenceType } from '@bonliva-traits/api/enums'
import {
  useTreaterProfile,
  useUpdateTreaterProfile,
} from '@bonliva-traits/api/treater-profile'
import { useEmailPreferences } from '@bonliva-traits/hooks/useEmailPreferences'

const isCorrectEmail = (email: string | undefined): boolean => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return typeof email !== 'undefined' && re.test(email)
}

type Props = {
  setDisabled: (disabled: boolean) => void
}
const EmailSlide: React.FC<Props> = (props) => {
  const profile = useTreaterProfile()
  const updateProfile = useUpdateTreaterProfile()
  const [emailPreferences, togglePreferenceHandler, preferencesHasLoaded] =
    useEmailPreferences()

  const [email, setEmail] = useState(profile.data.email || '')
  const [disabled, setDisabled] = useState(false)

  const updateTreaterHandler = useCallback(() => {
    updateProfile({ email })
  }, [email])

  const checkEmail = (mail: string) => {
    if (isCorrectEmail(mail)) {
      props.setDisabled(false)
      setDisabled(false)
    } else {
      props.setDisabled(true)
      setDisabled(true)
    }
  }

  const onEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const mail = e.target.value
    setEmail(mail)
    checkEmail(mail)
  }

  useEffect(() => {
    checkEmail(email)
  }, [email])

  return (
    <S.EmailSlide>
      <S.EmailSlideTextWrapper>
        <S.EmailSlideTitle>Lägg till email.</S.EmailSlideTitle>
        <S.EmailSlideBody>
          Din email används enbart för att skicka notiser om bokningar.
        </S.EmailSlideBody>
      </S.EmailSlideTextWrapper>

      <S.EmailSlideTextInput
        placeholder="Skriv in din Email"
        value={email}
        onChange={onEmailChange}
        onBlur={updateTreaterHandler}
      />

      <S.EmailSlideContent $disabled={disabled || !preferencesHasLoaded}>
        {Object.entries(emailPreferences).map(([type, data], index, arr) => (
          <React.Fragment key={type}>
            <S.EmailSlideFlexWrapper>
              <span>
                <S.EmailSlideLabel>{data.label}</S.EmailSlideLabel>
                <S.EmailSlideDescription>
                  {data.description}
                </S.EmailSlideDescription>
              </span>

              <Switch
                label={data.label}
                isChecked={data.isChecked}
                onChange={(bool) =>
                  togglePreferenceHandler(type as EmailPreferenceType, bool)
                }
              />
            </S.EmailSlideFlexWrapper>

            {index < arr.length - 1 && <S.EmailSlideUnderline />}
          </React.Fragment>
        ))}
      </S.EmailSlideContent>
    </S.EmailSlide>
  )
}

export default EmailSlide
