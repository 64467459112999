import React, { useState } from 'react'
import * as S from './PatientAssignmentsCard.style'
import { IAssignment } from '@bonliva-traits/api/types'
import Spinner from '@bonliva-components/web/shared/Spinner'
import MediaModal from '@bonliva-components/web/shared/MediaModal'
import ShareAssignmentModal from '@bonliva-components/web/shared/ShareAssignmentModal'
import SuccessModal from '@bonliva-components/web/shared/SuccessModal'
import {
  usePatient,
  useAssignments,
} from '@bonliva-routes/web/special-routes/PatientRoute'
import ProgressBar from '@bonliva-components/web/shared/ProgressBar'
import { isExercise } from '@bonliva-traits/api/assignments/helpers/isExercise'
import { isExerciseProgram } from '@bonliva-traits/api/assignments/helpers/isExerciseProgram'
import { getAssignmentCompletionRate } from '@bonliva-traits/api/assignments/helpers/getAssignmentCompletionRate'

const PatientAssignmentsCard: React.FC = () => {
  const patient = usePatient()
  const assignments = useAssignments()
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showShareMediaModal, setShowShareMediaModal] = useState(false)
  const [showAssignmentModal, setShowAssignmentModal] = useState(false)
  const [selected, setSelected] = useState<IAssignment>()

  return (
    <S.PatientAssignmentsCard>
      <S.Header>
        <S.Title>Program och övningar</S.Title>

        <S.Add onClick={() => setShowShareMediaModal(true)}>
          Tilldela övning
        </S.Add>
      </S.Header>

      <S.Content>
        {assignments.data?.map((assignment) => {
          const { count, completed, isCompleted } = getAssignmentCompletionRate(
            assignment.data
          )

          const openModal = () => {
            setSelected(assignment)
            setShowAssignmentModal(true)
          }

          return (
            <S.Assignment key={assignment.id} onClick={openModal}>
              {isExerciseProgram(assignment.data) && (
                <React.Fragment>
                  <S.ThumbnailWrapper>
                    <S.Thumbnail
                      src={assignment.data.thumbnail.data?.attributes.url}
                      alt={assignment.data.thumbnail.data?.attributes.name}
                    />

                    <S.ThumbnailOverlay />

                    <S.ThumbnailDetail>
                      <span>Program</span>
                      {' • '}
                      <span>
                        {count} {count > 1 ? 'övningar' : 'övning'}
                      </span>
                      {' • '}
                      <span>{assignment.data.timeEstimation} min</span>
                    </S.ThumbnailDetail>

                    <S.ThumbnailTitle>{assignment.data.title}</S.ThumbnailTitle>
                  </S.ThumbnailWrapper>

                  <S.AssignmentContent>
                    <ProgressBar count={count} completed={completed} />
                    <S.ProgressBarTextWrapper>
                      <S.ProgressBarText>
                        {isCompleted
                          ? 'Avslutad'
                          : completed !== 0
                          ? 'Påbörjad'
                          : 'Tilldelad'}
                      </S.ProgressBarText>

                      <S.ProgressBarText>
                        {Math.round((completed / count) * 100)}%
                      </S.ProgressBarText>
                    </S.ProgressBarTextWrapper>
                  </S.AssignmentContent>
                </React.Fragment>
              )}

              {isExercise(assignment.data) && (
                <React.Fragment>
                  <S.ThumbnailWrapper>
                    <S.Thumbnail
                      src={assignment.data.thumbnail.data?.attributes.url}
                      alt={assignment.data.thumbnail.data?.attributes.name}
                    />

                    <S.ThumbnailOverlay />

                    <S.ThumbnailDetail>
                      <span>Övning</span>
                      {' • '}
                      <span>
                        {count} {count > 1 ? 'delar' : 'del'}
                      </span>
                      {' • '}
                      <span>{assignment.data.timeEstimation} min</span>
                    </S.ThumbnailDetail>

                    <S.ThumbnailTitle>{assignment.data.title}</S.ThumbnailTitle>
                  </S.ThumbnailWrapper>

                  <S.AssignmentContent>
                    <ProgressBar count={count} completed={completed} />
                    <S.ProgressBarTextWrapper>
                      <S.ProgressBarText>
                        {isCompleted
                          ? 'Avslutad'
                          : completed !== 0
                          ? 'Påbörjad'
                          : 'Tilldelad'}
                      </S.ProgressBarText>

                      <S.ProgressBarText>
                        {Math.round((completed / count) * 100)}%
                      </S.ProgressBarText>
                    </S.ProgressBarTextWrapper>
                  </S.AssignmentContent>
                </React.Fragment>
              )}
            </S.Assignment>
          )
        })}

        {assignments.hasLoaded && assignments.data?.length === 0 && (
          <S.NoAssignmentsMessage>
            Du har inte delat några övningar eller program med{' '}
            {patient.data?.name}.
          </S.NoAssignmentsMessage>
        )}

        {!assignments.data && assignments.isLoading && (
          <S.LoadingWrapper>
            <Spinner color="neutral" />
          </S.LoadingWrapper>
        )}
      </S.Content>

      {assignments.numberOfPages > 1 && (
        <S.Footer>
          <S.FooterPagination>
            <S.FooterPaginationButton
              onClick={() => assignments.setPage(assignments.page - 1)}
              disabled={assignments.page <= 0}
            >
              <S.FooterPaginationButtonIcon src="ChevronLeft" />
            </S.FooterPaginationButton>

            <S.FooterPaginationPage>
              {assignments.page + 1} / {assignments.numberOfPages || 1}
            </S.FooterPaginationPage>

            <S.FooterPaginationButton
              onClick={() => assignments.setPage(assignments.page + 1)}
              disabled={assignments.page >= assignments.numberOfPages - 1}
            >
              <S.FooterPaginationButtonIcon src="ChevronRight" />
            </S.FooterPaginationButton>
          </S.FooterPagination>
        </S.Footer>
      )}

      {showAssignmentModal && selected && (
        <MediaModal
          patient={patient.data}
          assignment={selected}
          isOpen={showAssignmentModal}
          setIsOpen={setShowAssignmentModal}
        />
      )}

      {showShareMediaModal && patient.data && (
        <ShareAssignmentModal
          patient={patient.data}
          isOpen={showShareMediaModal}
          setIsOpen={setShowShareMediaModal}
          setSuccessModalOpen={setShowSuccessModal}
          refreshAssignmentsHandler={assignments.refresh}
        />
      )}

      {showSuccessModal && (
        <SuccessModal
          isOpen={showSuccessModal}
          setIsOpen={setShowSuccessModal}
          title="Övning tilldelad"
          successTitle={`Övningen är nu tilldelad till ${patient.data?.name}.`}
        />
      )}
    </S.PatientAssignmentsCard>
  )
}

export default PatientAssignmentsCard
