import styled from 'styled-components'

export const Patient = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`

export const PatientLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.margin.xxs};
`

export const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.xxs};

  @media (min-width: 992px) {
    flex-direction: row;
  }
`

export const CardWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.xxs};
`
