import { pending, success } from '@bonliva-core/store'
import { AxiosError, useCms } from '../../index'
import { IExerciseCategory, IStrapi } from '../../types'
import store from '../store'
import qs from 'qs'

export const useFetchMediaLibrary = () => {
  const cms = useCms()
  const dispatch = store.useDispatch()

  const query = qs.stringify({
    populate: ['deep', 10],
  })

  return async () => {
    dispatch(pending())

    try {
      const res = await cms.get<IStrapi<IExerciseCategory>>(
        `exercise-categories?${query}`
      )

      const exerciseCategories = res.data.data.map(
        ({ attributes }) => attributes
      )

      dispatch(success(exerciseCategories))
      return exerciseCategories
    } catch (error) {
      const err = error as AxiosError
      if (!err.response) throw err
      dispatch(fail(err.response.data))
    }
  }
}
