import styled, { css } from 'styled-components'
import { Link, BodySmall } from '@bonliva-ui/web'
import { Icon } from '@bonliva-traits/web-icons'

export const SideMenu = styled.aside`
  position: fixed;
  top: 56px;
  left: 0;
  width: 240px;
  height: calc(100vh - 56px);
  background-color: ${({ theme }) => theme.colors.web.secondaryDark};
  padding: ${({ theme }) => theme.margin['3xs']} 0;
  z-index: 100;
  border-right: 1px solid ${({ theme }) => theme.colors.web.secondary};
`

export const SideMenuLink = styled(Link)<{
  $isActive: boolean
  $isSubScreen: boolean
}>`
  position: relative;
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.margin['3xs']};
  border-radius: ${({ theme }) => theme.radius.rounded};
  margin: ${({ theme }) => theme.margin['3xs']};
  gap: ${({ theme }) => theme.margin.xxs};
  background-color: ${({ theme, $isActive }) =>
    $isActive ? theme.colors.web.secondary : 'transparent'};

  transition: background-color 0.1s ease-in-out;

  ${({ $isActive }) =>
    !$isActive &&
    css`
      &:hover {
        background-color: ${({ theme }) => theme.colors.web.secondary}66;
      }
    `}

  ${({ $isSubScreen }) =>
    $isSubScreen &&
    css`
      margin-left: ${({ theme }) => theme.margin.s};

      &::before {
        left: -${({ theme }) => theme.margin['3xs']};
        position: absolute;
        content: '';
        display: block;
        width: 1px;
        height: 40px;
        background-color: ${({ theme }) => theme.colors.web.secondary};
        border-radius: 9999px;
      }
    `}
`

export const SideMenuIcon = styled(Icon)`
  width: 20px;
  color: ${({ theme }) => theme.colors.web.secondaryLight};
`

export const SideMenuText = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.secondaryLight};
  font-size: 16px;
  line-height: 0;
`

export const SideMenuNotification = styled(BodySmall)`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  color: ${({ theme }) => theme.colors.web.secondaryLight};
  font-size: 12px;
  padding: ${({ theme }) => theme.margin['3xs']};
  background-color: ${({ theme }) => theme.colors.web.primary};
  height: 12px;
  min-width: 12px;
  border-radius: 9999px;
  font-weight: 400;
`
