import React from 'react'
import * as S from './Footer.style'

const Footer: React.FC = () => {
  return (
    <S.Footer>
      <S.CopyRight>© 2023 Bonliva Care</S.CopyRight>

      <S.FooterLinks>
        <S.FooterLink
          to="https://bonliva-app-production-cms-assets.s3.eu-north-1.amazonaws.com/Bonliva_Care_integritetspolicy_%5B7%5D.pdf"
          target="_blank"
        >
          Integritet
        </S.FooterLink>
        <S.FooterLink
          to="https://forms.office.com/pages/responsepage.aspx?id=OGRa6DOLKUq4fpqu_-y7-cj-0N1p5MhDkC8E66mwBFpUNUxUMVo0TDZPVU5PWTM4V1hKSVlJRzlITi4u"
          target="_blank"
        >
          Förbättringsförslag & felanmälan
        </S.FooterLink>
      </S.FooterLinks>
    </S.Footer>
  )
}

export default Footer
