import * as React from 'react'
import { ResponseError } from '@bonliva-core/store'
import { AxiosError, AxiosRequestConfig } from 'axios'
import { useCms } from '@bonliva-traits/api'
import qs from 'qs'

function useCmsState<T = unknown>(url: string, initialState: T[]) {
  const cms = useCms()
  const controllerRef = React.useRef<AbortController>(new AbortController())

  const [data, setData] = React.useState<T[]>(initialState)
  const [error, setError] = React.useState<ResponseError<T>>()
  const [hasError, setHasError] = React.useState(false)
  const [hasLoaded, setHasLoaded] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)

  const unload = React.useCallback(() => {
    setData(initialState)
    setError(undefined)
    setHasError(false)
    setHasLoaded(false)
    setIsLoading(false)
  }, [])

  const cancel = React.useCallback(() => {
    controllerRef.current.abort()
  }, [])

  const fetch = React.useCallback(
    (method: string) =>
      async (
        obj?: object,
        options?: qs.IStringifyOptions,
        payload?: AxiosRequestConfig
      ) => {
        const signal = controllerRef.current?.signal

        const query = obj && qs.stringify(obj, options)

        setIsLoading(true)
        setHasError(false)

        try {
          const res = await cms.request<{ data: { attributes: T }[] }>({
            method,
            url: query ? `${url}?${query}` : url,
            ...payload,
            ...(signal && { signal }),
          })

          setData(res.data.data.map((i) => i.attributes))
          return res.data
        } catch (err) {
          setHasError(true)
          if (!(err as AxiosError).response) throw err
          setError((err as AxiosError).request.data)
        } finally {
          setIsLoading(false)
          setHasLoaded(true)
        }
      },
    [url]
  )

  return {
    data,
    error,
    hasError,
    hasLoaded,
    isLoading,
    unload,
    cancel,
    get: fetch('get'),
    delete: fetch('delete'),
    post: fetch('post'),
    put: fetch('put'),
  }
}

export default useCmsState
