import styled from 'styled-components'
import { BodySmall } from '@bonliva-ui/web'

export const PatientAppointments = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`

export const PatientAppointmentsLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.margin.xxs};
  margin-bottom: -${({ theme }) => theme.margin.xxs};
`

export const PatientAppointmentsTableWrapper = styled.div`
  margin: ${({ theme }) => theme.margin.xxs};
`

export const RowText = styled(BodySmall)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const EmptyInbox = styled.tr`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 120px;
  padding: 0 ${({ theme }) => theme.margin.xxs};

  border-bottom: 0.5px solid ${({ theme }) => theme.colors.web.light};
`

export const TableRow = styled.tr`
  position: relative;
  padding: ${({ theme }) => theme.margin.xxs};

  display: grid;
  grid-template-columns: repeat(5, calc(20% - 16px));
  gap: ${({ theme }) => theme.margin.xxs};
  grid-template-rows: 1fr;
  height: 58px;
  background-color: ${({ theme }) => theme.colors.web.white}33;
  border-top: 0.5px solid ${({ theme }) => theme.colors.web.light};

  &:last-child {
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.web.light};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.web.light}33;
    cursor: pointer;
  }
`

export const FlexSpan = styled.td`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.margin.xxs};
  overflow: hidden;
`
export const IconWrapper = styled.div`
  color: ${({ theme }) => theme.colors.web.primary};
`
