import React, { useState } from 'react'
import * as S from '../NoMedicalRecord.style'
import Modal from '@bonliva-components/web/shared/Modal'
import SuccessModal from '@bonliva-components/web/shared/SuccessModal'
import ErrorModal from '@bonliva-components/web/shared/ErrorModal'
import Spinner from '@bonliva-components/web/shared/Spinner'
import Select from '@bonliva-components/web/shared/Select'
import SelectAsync from '@bonliva-components/web/shared/SelectAsync'
import { IBooking } from '@bonliva-traits/api/types'
import { useIcdCodes } from '@bonliva-traits/hooks/useIcdCodes'
import { useWebdocNotes } from '@bonliva-traits/webdoc-notes'
import { useToast } from '@bonliva-traits/web-toasts'

type Props = {
  booking: IBooking
  refreshReminders: () => void
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const WebDocModal: React.FC<Props> = (props) => {
  const toast = useToast()
  const { getIcdCodesHandler } = useIcdCodes()
  const [isLoading, setIsLoading] = useState(false)
  const {
    webdocTemplates,
    webdocTemplate,
    notes,
    selectedIcdCodes,
    setSelectedIcdCodes,
    setNote,
    setWebdocTemplate,
    saveToWebdoc,
    unload,
  } = useWebdocNotes()

  const saveClickHandler = async () => {
    setIsLoading(true)

    if (!webdocTemplate || !notes) return

    try {
      const record = {
        bookingId: props.booking.id,
        recordTemplateId: webdocTemplate?.id,
        record: {
          keywords: notes?.map((note) => ({
            id: note.id,
            value: note.value,
          })),
          diagnosisCodes: selectedIcdCodes.map((icdCode) => icdCode.code),
        },
      }

      await saveToWebdoc(record)

      props.refreshReminders()

      props.setIsOpen(false)

      toast.addToast({
        toast: (
          <SuccessModal
            title="Sparat till Webdoc"
            successTitle="Dina journal anteckningar är nu sparade till webdoc."
            content={
              <span>
                Glöm inte bort att logga in i Webdoc och signera dina sparande
                anteckningar.
              </span>
            }
          />
        ),
      })
    } catch (error) {
      toast.addToast({
        toast: (
          <ErrorModal
            title="Något gick fel"
            errorTitle="Något gick fel när vi försökte spara dina anteckningar."
          />
        ),
      })
    } finally {
      setIsLoading(false)
    }
  }

  const promiseOptions = async (inputValue: string) => {
    const data = await getIcdCodesHandler(inputValue)
    return data?.map((icdCode) => ({
      value: icdCode,
      label: `${icdCode.code}`,
    }))
  }

  return (
    <Modal.Wrapper isOpen={props.isOpen}>
      <Modal.Contents
        onDismiss={() => {
          props.setIsOpen(false)
          unload()
        }}
      >
        <S.NoMedicalRecordModal>
          <Modal.CloseButton />

          <S.Header>
            <S.Title>Journalanteckningar</S.Title>
          </S.Header>

          <S.Content>
            <span>
              <S.MeetingNotepadDropdownTitle>
                Välj journalmall
              </S.MeetingNotepadDropdownTitle>

              <Select
                placeholder="Journalmall"
                value={
                  webdocTemplate
                    ? {
                        value: webdocTemplate,
                        label: webdocTemplate.title,
                      }
                    : undefined
                }
                options={webdocTemplates?.map((template) => ({
                  value: template,
                  label: `${template.title}`,
                }))}
                onChange={(e) => e?.value && setWebdocTemplate(e.value)}
              />
            </span>

            {notes?.map((keyword, index) => (
              <React.Fragment key={keyword.id}>
                {index === 0 && (
                  <React.Fragment>
                    <span>
                      <S.MeetingNotepadDropdownTitle>
                        ICD-koder
                      </S.MeetingNotepadDropdownTitle>

                      <SelectAsync
                        loadOptions={promiseOptions}
                        isMulti
                        formatOptionLabel={(option) =>
                          `${option.value.code} - ${option.value.title}`
                        }
                        onChange={(e) =>
                          setSelectedIcdCodes(
                            [...e.values()].map((value) => value.value)
                          )
                        }
                      />
                    </span>
                  </React.Fragment>
                )}

                <span>
                  <S.MeetingNotepadDropdownTitle>
                    {keyword.warnIfEmpty ? keyword.title + ' *' : keyword.title}
                  </S.MeetingNotepadDropdownTitle>

                  <S.MeetingNotepadInput
                    placeholder="Skriv här..."
                    value={keyword.value}
                    onChange={(e) => setNote(keyword.id, e.target.value)}
                  />
                </span>
              </React.Fragment>
            ))}
          </S.Content>

          <S.Footer>
            <S.MeetingNotepadButton
              onClick={saveClickHandler}
              disabled={
                !notes ||
                isLoading ||
                notes?.some((note) => note.warnIfEmpty && !note.value) ||
                notes?.every((note) => !note.value)
              }
            >
              {isLoading ? <Spinner color="neutral" /> : 'Spara till Webdoc'}
            </S.MeetingNotepadButton>
          </S.Footer>
        </S.NoMedicalRecordModal>
      </Modal.Contents>
    </Modal.Wrapper>
  )
}

export default WebDocModal
