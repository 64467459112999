import styled from 'styled-components'
import { H4 } from '@bonliva-ui/web'

export const TreatmentPlanAddCard = styled.div`
  cursor: pointer;
  width: 248px;
  height: auto;
  min-height: 212px;
  background-color: rgb(255, 184, 170, 0.1);
  border: 1px dashed ${({ theme }) => theme.colors.web.primary};
  border-radius: ${({ theme }) => theme.radius.rounded};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.web.primary};
`

export const TreatmentPlanAddHeader = styled(H4)`
  color: ${({ theme }) => theme.colors.web.primary};
`
