import React from 'react'
import * as S from './Patient.style'

import PatientInfoCard from './components/PatientInfoCard'
import PatientUpcomingMeetings from './components/PatientUpcomingMeetings'
import PatientAssignmentsCard from './components/PatientAssignmentsCard'
import PatientTopBar from '@bonliva-components/web/shared/PatientTopBar'

const Patient: React.FC = () => {
  return (
    <S.Patient>
      <S.PatientLayout>
        <PatientTopBar />

        <S.CardContainer>
          <S.CardWrapper>
            <PatientInfoCard />

            <PatientUpcomingMeetings />
          </S.CardWrapper>

          <PatientAssignmentsCard />
        </S.CardContainer>
      </S.PatientLayout>
    </S.Patient>
  )
}

export default Patient
