import styled from 'styled-components'
import { BodyRegular, ButtonPrimary, H1 } from '@bonliva-ui/web'

export const Exception = styled.div`
  height: calc(100vh - 116px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${({ theme }) => theme.margin.xxs};
  margin: ${({ theme }) => theme.margin.xxs};
`

export const ExceptionLayout = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.margin['3xs']};
`

export const Exception404 = styled.div`
  font-family: Calibre;
  z-index: 0;
  color: ${({ theme }) => theme.colors.web.primary};
  font-size: 200px;
  font-weight: 700;
  line-height: 140px;
  opacity: 0.4;
  letter-spacing: 10px;
`

export const ExceptionTitle = styled(H1)`
  color: ${({ theme }) => theme.colors.web.black};
  text-align: center;
  z-index: 10;
`

export const ExceptionDescription = styled(BodyRegular)`
  color: ${({ theme }) => theme.colors.web.darkest};
  max-width: 600px;
  font-weight: 400;
  z-index: 10;
  text-align: center;
`
export const ExceptionButtonWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const ExceptionButton = styled(ButtonPrimary)`
  margin-top: ${({ theme }) => theme.margin['3xs']};
  z-index: 10;
`
