import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import * as S from './Calendar.style'
import { useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { EventClickArg } from '@fullcalendar/core'
import { IAppointment } from '@bonliva-traits/api/types'
import { useCalendarEvents } from './hooks/useCalendarEvents'
import useApiState from '@bonliva-traits/hooks/useApiState'
import Alert from '@bonliva-components/web/shared/Alert'
import DeleteModal from '@bonliva-components/web/shared/DeleteModal'
import SuccessModal from '@bonliva-components/web/shared/SuccessModal'
import AppointmentInfoModal from './components/AppointmentInfoModal'
import SharedCalendar, {
  CalendarRef,
} from '@bonliva-components/web/shared/Calendar'
import CreateBookingModal from './components/CreateBookingModal/CreateBookingModal'
import { ButtonPrimary, ButtonSecondary } from '@bonliva-ui/web'
import OutlookIntegrationModal from './components/OulookIntegrationModal/OutlookIntegrationModal'
const Calendar = () => {
  const params = useParams<{ date?: string; type?: 'day' | 'week' | 'month' }>()
  const calendarRefreshRef = useRef<CalendarRef>(null)
  const calendarEvents = useCalendarEvents(params.type)

  const [selected, setSelected] = useState<IAppointment>()
  const [infoModalOpen, setInfoModalOpen] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showBookingModal, setShowBookingModal] = useState(false)
  const [showOutlookModal, setShowOutlookModal] = useState(false)
  const [comment, setComment] = useState('')

  const appointment = useApiState<IAppointment>(`/v1/treaters/appointments`)

  const showInfoModalHandler = useCallback((i: EventClickArg) => {
    setSelected(i.event.extendedProps.appointment)
    setInfoModalOpen(true)
  }, [])

  const onDeleteHandler = useCallback(async () => {
    if (!selected) return
    const data = { cancelReason: comment }
    await appointment.put({ data }, `${selected.id}/cancel`)
    calendarRefreshRef.current?.refreshCalendar()
    setShowDeleteModal(false)
    setShowSuccessModal(true)
    setComment('')
  }, [selected, comment])

  useEffect(() => {
    if (showDeleteModal) setComment('')
  }, [showDeleteModal])

  const deleteModalContent = useMemo(() => {
    if (!selected) return null
    const patientName = selected.patient?.name

    return (
      <React.Fragment>
        <Alert
          type="info"
          title="Kom ihåg att skriva en anledningen till avbokningen."
        />

        <S.DeleteModalLabel>
          Skriv en kommentar till {patientName}
        </S.DeleteModalLabel>

        <S.DeleteModalCommentField
          placeholder="Ange en anledning för avbokningen här."
          onChange={(e) => setComment(e.target.value)}
          value={comment}
        />
      </React.Fragment>
    )
  }, [selected, comment])

  const successModalContent = useMemo(() => {
    if (!selected) return null
    const patientName = selected.patient?.name
    const date = format(new Date(selected?.startDate), 'yyyy-MM-dd')
    const startDate = format(new Date(selected?.startDate), 'HH:mm')
    const endDate = format(new Date(selected?.endDate), 'HH:mm')
    return (
      <React.Fragment>
        <S.SuccessModalTitle>
          Ditt samtal med {patientName} är nu avbokat.
        </S.SuccessModalTitle>

        <S.SuccessModalInfoWrapper>
          <S.SuccessModalInfo>
            Datum:
            <S.SuccessModalBold>{date}</S.SuccessModalBold>
          </S.SuccessModalInfo>

          <S.SuccessModalInfo>
            Tid:
            <S.SuccessModalBold>
              {startDate} - {endDate}
            </S.SuccessModalBold>
          </S.SuccessModalInfo>
        </S.SuccessModalInfoWrapper>
      </React.Fragment>
    )
  }, [selected])

  return (
    <S.Calendar>
      <S.CalendarLayout>
        <SharedCalendar
          ref={calendarRefreshRef}
          type={params.type}
          date={params.date}
          onEventClick={showInfoModalHandler}
          events={calendarEvents}
          onCreateNewMeetingClick={() => setShowBookingModal(true)}
          sideMenuContent={
            <div
              style={{
                display: 'flex',
                width: '100%',
                height: '100%',
                justifyContent: 'end',
                flexDirection: 'column',
              }}
            >
              <div
                style={{
                  width: '100%',
                  height: '100px',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                }}
              >
                <ButtonSecondary
                  onClick={() => {
                    setShowOutlookModal(true)
                  }}
                >
                  Anslut till Outlook
                </ButtonSecondary>
                <ButtonPrimary>Skapa schema</ButtonPrimary>
              </div>
            </div>
          }
        />
      </S.CalendarLayout>

      <OutlookIntegrationModal
        isOpen={showOutlookModal}
        setIsOpen={setShowOutlookModal}
      />

      {selected && (
        <React.Fragment>
          <AppointmentInfoModal
            calendarRefreshRef={calendarRefreshRef}
            appointment={selected}
            isOpen={infoModalOpen}
            setIsOpen={setInfoModalOpen}
            setCancelModalOpen={setShowDeleteModal}
          />

          <DeleteModal
            items={selected}
            isOpen={showDeleteModal}
            setIsOpen={setShowDeleteModal}
            isLoading={appointment.isLoading}
            hasError={appointment.hasError}
            onDelete={onDeleteHandler}
            onDeleteTitle="Avboka"
            disableOnDelete={!comment}
            title="Avboka möte"
            warningTitle="Är du säker på att du vill avboka den här bokningen?"
            warningMessages={['Om du avbokar går det inte att ångra.']}
            errorTitle="Något gick fel med avbokningen."
            content={deleteModalContent}
          />

          <SuccessModal
            isOpen={showSuccessModal}
            setIsOpen={setShowSuccessModal}
            title="Mötet är avbokat"
            successTitle="Tiden är nu avbokad."
            content={successModalContent}
          />
        </React.Fragment>
      )}
      {showBookingModal && (
        <CreateBookingModal
          isOpen={showBookingModal}
          setIsOpen={setShowBookingModal}
          calendarRefreshRef={calendarRefreshRef}
        />
      )}
    </S.Calendar>
  )
}

export default Calendar
