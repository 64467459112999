import { MeetingStatus, useMeeting } from '@bonliva-traits/meeting-web'
import { MeetingPublisher } from '@bonliva-traits/meeting-web/MeetingPublisher'
import { MeetingSubscribers } from '@bonliva-traits/meeting-web/MeetingSubscribers'
import { useWebdocNotes } from '@bonliva-traits/webdoc-notes'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import * as S from './MeetingRoom.style'
import MeetingRoomControls from './components/MeetingRoomControls'
import MeetingRoomNotepad from './components/MeetingRoomNotepad'

const MeetingRoom: React.FC = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { status } = useMeeting()
  const { showNotepad } = useWebdocNotes()

  useEffect(() => {
    if (!params.appointmentId) {
      throw new Error('AppointmentId is required')
    }

    if (status === MeetingStatus.Connected) return
    navigate(`/meeting-room/${params.appointmentId}/pre-meeting`)
  }, [status])

  if (status !== MeetingStatus.Connected) return null

  return (
    <S.MeetingRoom $showNotepad={showNotepad}>
      <S.MeetingRoomLayout>
        <S.MeetingRoomContent>
          <S.MeetingRoomVideoContainer $showNotepad={showNotepad}>
            <S.MeetingRoomPatientVideoWrapper>
              <MeetingSubscribers />
            </S.MeetingRoomPatientVideoWrapper>

            <S.MeetingRoomTreaterVideoWrapper>
              <MeetingPublisher />
            </S.MeetingRoomTreaterVideoWrapper>
          </S.MeetingRoomVideoContainer>

          <MeetingRoomNotepad />
        </S.MeetingRoomContent>

        <MeetingRoomControls />
      </S.MeetingRoomLayout>
    </S.MeetingRoom>
  )
}

export default MeetingRoom
