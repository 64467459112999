import styled from 'styled-components'
import { BodyRegular, ButtonPrimary, H2 } from '@bonliva-ui/web'
import { Icon } from '@bonliva-traits/web-icons'

export const TermsModal = styled.div`
  padding: ${({ theme }) => theme.margin.xxs};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.margin.s};
`

export const BonlivaLogo = styled(Icon)`
  height: 26px;
  width: 100px;
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.margin.xxs};
  color: ${({ theme }) => theme.colors.web.primary};
`

export const Title = styled(H2)`
  text-align: center;
`

export const Label = styled(BodyRegular)`
  max-width: 65%;
  text-align: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.xxs};
  align-items: center;
`

export const Button = styled(ButtonPrimary)`
  height: 56px;
  width: 100%;
  margin: ${({ theme }) => theme.margin.xxs} auto;
`

export const Link = styled.a`
  font-family: Calibre;
  font-weight: 300;
  font-size: 18px;
  line-height: 24px;

  cursor: pointer;
  color: ${({ theme }) => theme.colors.web.primary};
  transition: filter 0.1s ease-in-out;
  text-decoration: none;

  &:hover {
    filter: brightness(125%);
  }
`

export const CheckContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin['3xs']};
  width: 50%;
`

export const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const CheckBoxLabel = styled(BodyRegular)`
  display: flex;
  gap: ${({ theme }) => theme.margin['3xs']};
`

export const Check = styled(Icon)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.web.primary};
  color: ${({ theme }) => theme.colors.web.white};
  padding: 3px;
`

export const CheckBox = styled.div`
  margin-left: ${({ theme }) => theme.margin['4xs']};
  width: 18px;
  height: 18px;
  min-height: 18px;
  min-width: 18px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.web.white};
  border: 1px solid ${({ theme }) => theme.colors.web.primary};
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`
