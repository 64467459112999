import styled, { css } from 'styled-components'
import { BodyRegular } from '@bonliva-ui/web'

export const RadioWrapper = styled.div<{ disabled: boolean }>`
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: flex;
  align-items: center;
  height: 24px;
`

export const RadioButton = styled.div<{ isSelected: boolean }>`
  width: 24px;
  height: 24px;
  min-height: 24px;
  min-width: 24px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.web.white};
  border: 1px solid
    ${({ theme, isSelected }) =>
      isSelected ? theme.colors.web.primary : theme.colors.web.neutral};
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isSelected }) =>
    isSelected &&
    css`
      :after {
        content: '';
        width: 18px;
        height: 18px;
        border-radius: 50%;
        background-color: ${({ theme }) => theme.colors.web.primary};
      }
    `}
`

export const RadioLabel = styled(BodyRegular)`
  line-height: 16px;
  margin-left: ${({ theme }) => theme.margin['3xs']};
`
