import { IAppointment } from '@bonliva-traits/api/types'
import useInterval from '@bonliva-traits/hooks/useInterval'
import { MeetingStatus, useMeeting } from '@bonliva-traits/meeting-web'
import { ButtonPrimary } from '@bonliva-ui/web'
import { format } from 'date-fns'
import React, { useCallback, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'

type Props = {
  appointment: IAppointment
  alwaysShow?: boolean
}

export const StartMeetingButton: React.FC<Props> = (props) => {
  const { status } = useMeeting()
  const navigate = useNavigate()
  const [currentTime, setCurrentTime] = useState(new Date())

  const start = new Date(props.appointment.startDate).getTime()
  const end = new Date(props.appointment.endDate).getTime()
  const current = currentTime.getTime()
  const moreThanFifteenMinLeft = start - current > 15 * 60 * 1000

  useInterval(
    () => setCurrentTime(new Date()),
    moreThanFifteenMinLeft ? 60000 : 1000
  )

  const navigateToMeetingRoomHandler = useCallback(() => {
    navigate(`/meeting-room/${props.appointment.id}/`)
  }, [props.appointment.id, navigate])

  const buttonLabel = useMemo(() => {
    if (status === MeetingStatus.Connected) {
      return 'Ett annat samtal pågår'
    } else if (moreThanFifteenMinLeft) {
      return `Samtalet börjar kl. ${format(start, 'HH:mm')}`
    } else if (current < start) {
      return `Starta samtal om (${format(start - current, 'mm:ss')})`
    } else if (current > end) {
      return 'Passerad tid'
    } else {
      return 'Starta samtal'
    }
  }, [start, end, current, moreThanFifteenMinLeft])

  const isDisabled =
    moreThanFifteenMinLeft ||
    current > end ||
    status === MeetingStatus.Connected

  return props.alwaysShow || !isDisabled ? (
    <ButtonPrimary disabled={isDisabled} onClick={navigateToMeetingRoomHandler}>
      {buttonLabel}
    </ButtonPrimary>
  ) : null
}
