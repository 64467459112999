import React from 'react'
import * as S from './CalendarEvent.style'
import { format } from 'date-fns'
import { EventContentArg } from '@fullcalendar/core'
import Paid from '@mui/icons-material/Paid'
import { Icon, IconType } from '@bonliva-traits/web-icons'
import { BookingMeetingType } from '@bonliva-traits/api/enums'

interface CalendarEventProps extends EventContentArg {
  disabled?: boolean
}

const CalendarEvent: React.FC<CalendarEventProps> = (args) => {
  const hourly = format(new Date(args.event.startStr), 'mm') === '00'

  if (args.event.extendedProps.isBackground) {
    return <S.CalendarBackgroundEvent hourly={hourly} />
  }

  if (args.event.extendedProps.isAvailable) {
    return <S.CalendarAvailableEvent hourly={hourly} />
  }

  const showPaymentStatus = args.event.extendedProps.showPaymentStatus
    ? args.event.extendedProps.showPaymentStatus
    : false
  const isPaid = args.event.extendedProps.isPaid

  const showMeetingType = true
  const meetingType = args.event.extendedProps.appointment?.meetingType

  return (
    <S.CalendarEventWrapper hourly={hourly}>
      <S.CalendarEvent
        isLoading={args.event.extendedProps.isLoading}
        disableEventClick={args.disabled || false}
        meetingType={args.event.extendedProps.appointment?.meetingType}
        type={args.event.extendedProps.appointment?.type}
      >
        <S.CalenderEventTitle>
          {format(new Date(args.event.startStr), 'HH:mm')} {' - '}
          {format(new Date(args.event.endStr), 'HH:mm')}
          {', '}
          {args.event.title}
        </S.CalenderEventTitle>
        {showMeetingType && (
          <div style={{ alignItems: 'center', display: 'flex' }}>
            {meetingType === BookingMeetingType.Physical && (
              <Icon
                width={12}
                src={IconType.Physical}
                style={{ color: 'grey', marginRight: '4px' }}
              />
            )}
            {meetingType === BookingMeetingType.Digital && (
              <Icon
                width={12}
                src={IconType.Digital}
                style={{ color: 'grey', marginRight: '4px' }}
              />
            )}
          </div>
        )}
        {showPaymentStatus && (
          <div style={{ alignItems: 'center', display: 'flex' }}>
            {isPaid && <Paid color={'success'} fontSize={'small'} />}
            {!isPaid && <Paid color={'error'} fontSize={'small'} />}
          </div>
        )}
      </S.CalendarEvent>
    </S.CalendarEventWrapper>
  )
}

export default CalendarEvent
