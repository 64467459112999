import { H3 } from '@bonliva-ui/web'
import styled from 'styled-components'
import { Icon } from '@bonliva-traits/web-icons'

export const CalendarSideMenu = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.theme.colors.web.white};
  border-radius: 8px 0 0 8px;
  padding: ${(props) => props.theme.margin.xxs};
  min-width: 270px;
  max-width: 270px;

  border-right: 1px solid ${(props) => props.theme.colors.web.neutral}66;
  height: calc(100vh - 120px);
  max-height: 1316px;
`

export const CalendarSideMenuTopBar = styled.div`
  display: flex;
  width: 100%;
  min-height: 63px;
  align-items: center;
  justify-content: space-between;
  gap: ${(props) => props.theme.margin.xxs};
  padding-bottom: ${(props) => props.theme.margin.xxs};
  margin-bottom: ${(props) => props.theme.margin.xxs};
  border-bottom: 1px solid ${(props) => props.theme.colors.web.light};
`

export const CalendarSideMenuTopBarDate = styled(H3)`
  color: ${(props) => props.theme.colors.web.darkest};
  margin-top: 4px;
  text-transform: capitalize;
`

export const CalendarSideMenuTopBarNavigation = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.margin.xxs};
`

export const CalendarSideMenuTopBarNavigationButton = styled(Icon)`
  min-height: 28px;
  min-width: 28px;
  height: 28px;
  width: 28px;
  color: ${(props) => props.theme.colors.web.secondary};
  background-color: ${(props) => props.theme.colors.web.secondaryLight};
  border-radius: 9999px;
  padding: 8px;
  transition: filter 0.1s ease-in-out;
  border: 0.5px solid ${(props) => props.theme.colors.web.secondary}33;

  cursor: pointer;

  &:hover {
    filter: brightness(95%);
  }
`

export const CalendarContainer = styled.div`
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid ${(props) => props.theme.colors.web.light};
`

export const CalendarWrapper = styled.div<{ $selectedDate?: string }>`
  width: 100%;

  .fc-scrollgrid {
    background-color: none !important;
  }
  .fc-col-header-cell {
    border: none !important;
  }
  .fc-daygrid-day-frame  {
    height: 20px !important;
    width: 20px !important;
  }

  table {
    font-family: Calibre;
    font-weight: 300;
    font-size: 15px;
    color: ${(props) => props.theme.colors.web.dark};
  }

  th {
    border: none !important;
  }

  td {
    background-color: white !important;
  }

  .fc-daygrid-day-top {
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1.2 !important;
    border-radius: 50% !important;
    height: 28px !important;
    width: 28px !important;
    cursor: pointer;
    transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;

    &:hover {
      background-color: #ff867b66 !important;
      color: #ffffff !important;
    }

    @-moz-document url-prefix() {
      padding-top: 2px;
    }
  }

  [data-date='${({ $selectedDate }) => $selectedDate}'] {
    .fc-daygrid-day-top {
      background-color: ${(props) => props.theme.colors.web.light} !important;

      &:hover {
        background-color: #ff867bcc !important;
        color: #ffffff !important;
      }
    }
  }

  .fc-theme-standard td {
    border: none !important;
  }

  .fc-scrollgrid,
  .fc-scrollgrid-liquid {
    border: 1px solid transparent !important;
  }

  .fc-daygrid-day {
    padding: 2px !important;
    height: 32px !important;
    width: 32px !important;

    &.fc-day-today {
      .fc-daygrid-day-top {
        background-color: #ff867b !important;
        color: #ffffff !important;
      }
    }
  }
`

export const CalendarSideMenuContent = styled.div`
  overflow: hidden;
  height: 100%;
`
