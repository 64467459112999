import React, { useState, useCallback } from 'react'
import * as S from './PatientMessages.style'
import { useNavigate } from 'react-router-dom'
import {
  useNotifications,
  usePatient,
} from '@bonliva-routes/web/special-routes/PatientRoute'
import { format } from 'date-fns'
import PatientTopBar from '@bonliva-components/web/shared/PatientTopBar'
import Table from '@bonliva-components/web/shared/Table'
import Alert from '@bonliva-components/web/shared/Alert'
import MessageModal from '@bonliva-components/web/shared/MessageModal'
import SuccessModal from '@bonliva-components/web/shared/SuccessModal'

const tableColumns = [
  {
    label: 'Skickat',
    sortable: 'n.created_at',
  },
  {
    label: 'Tid',
  },
  {
    label: 'Läst',
  },
  {
    label: 'Meddelande',
  },
]

const PatientMessages: React.FC = () => {
  const notifications = useNotifications()
  const patient = usePatient()
  const [showMessageModal, setShowMessageModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const navigate = useNavigate()

  const showMessageModalHandler = useCallback(() => {
    setShowMessageModal(true)
  }, [])

  return (
    <S.PatientMessages>
      <S.PatientMessagesLayout>
        <PatientTopBar />
      </S.PatientMessagesLayout>
      <S.PatientMessagesTableWrapper>
        <Table.Wrapper
          header={
            <>
              <Table.Header
                title="Skickade meddelanden"
                showSearchBar
                searchBarPlaceholder="Sök..."
                searchBarValue={notifications.searchValue}
                setSearchValue={notifications.setSearchValue}
              />
              <S.ButtonWrapper>
                <S.Button onClick={showMessageModalHandler}>
                  Skicka meddelande
                </S.Button>
              </S.ButtonWrapper>
            </>
          }
          head={
            <Table.Head
              columns={tableColumns}
              sortBy={notifications.sortBy}
              setSortBy={notifications.setSortBy}
              isAscending={notifications.isAscending}
              setIsAscending={notifications.setIsAscending}
            />
          }
          body={
            <Table.Body isLoading={notifications.isLoading}>
              {notifications.hasLoaded && !notifications.data?.length && (
                <S.EmptyInbox>
                  <Alert type="info" title="Inga meddelanden hittades" />
                </S.EmptyInbox>
              )}

              {notifications.data?.map((n) => {
                const onClickHandler = () => {
                  navigate(`${n.id}`)
                }

                return (
                  <S.TableRow key={n.id} onClick={onClickHandler}>
                    <S.FlexSpan>
                      <S.RowText>
                        {format(new Date(n.createdAt), 'yyyy-MM-dd')}
                      </S.RowText>
                    </S.FlexSpan>

                    <S.FlexSpan>
                      <S.RowText>
                        {format(new Date(n.createdAt), 'HH:mm')}
                      </S.RowText>
                    </S.FlexSpan>

                    <S.FlexSpan>
                      <S.RowText>{n.unread ? 'Nej' : 'Ja'}</S.RowText>
                    </S.FlexSpan>

                    <S.FlexSpan>
                      <S.RowText>{n.metadata?.message}</S.RowText>
                    </S.FlexSpan>
                  </S.TableRow>
                )
              })}
            </Table.Body>
          }
          footer={
            <Table.Footer
              page={notifications.page}
              setPage={notifications.setPage}
              numberOfPages={notifications.numberOfPages}
            />
          }
        />
      </S.PatientMessagesTableWrapper>

      {showMessageModal && (
        <MessageModal
          isOpen={showMessageModal}
          setIsOpen={setShowMessageModal}
          setSuccessModalOpen={setShowSuccessModal}
          patient={patient.data}
          refreshNotifications={notifications.refresh}
        />
      )}

      {showSuccessModal && (
        <SuccessModal
          isOpen={showSuccessModal}
          setIsOpen={setShowSuccessModal}
          title="Anteckningen har skickats"
          successTitle={`Din anteckning har skickats till ${patient.data?.name}`}
        />
      )}
    </S.PatientMessages>
  )
}

export default PatientMessages
