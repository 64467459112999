import { fail, pending, success } from '@bonliva-core/store'
import { AxiosError, useApi } from '../../index'
import { ITreater } from '../../types'
import store from '../store'

export const useFetchTreaterProfile = () => {
  const api = useApi()
  const dispatch = store.useDispatch()

  return async () => {
    dispatch(pending())

    try {
      const treater = await api.get<ITreater>('/v1/treaters/me')
      dispatch(success(treater.data))
      return treater.data
    } catch (error) {
      const err = error as AxiosError
      if (!err.response) throw err
      dispatch(fail(err.response.data))
    }
  }
}
