import React, { useReducer, createContext, useContext } from 'react'
import { Action, State, Types } from './types'
import { v4 } from 'uuid'
import ReactDom from 'react-dom'

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <ToastsProvider>.')
}

const initialState: State = {
  toasts: [],
  addToast: stub,
  removeToast: stub,
  updateToast: stub,
}

export const ToastContext = createContext(initialState)

export const useToast = () => useContext(ToastContext)

export const ToastProvider: React.FC<React.PropsWithChildren> = (props) => {
  const [state, dispatch] = useReducer((state: State, action: Action) => {
    switch (action.type) {
      case Types.ADD_TOAST: {
        const id = action.payload.id || v4()
        const toast = { ...action.payload, id }
        return { ...state, toasts: [...state.toasts, toast] }
      }

      case Types.REMOVE_TOAST: {
        const toasts = state.toasts.filter((toast) => {
          return toast.id !== action.payload
        })

        return { ...state, toasts }
      }

      case Types.UPDATE_TOAST: {
        const toasts = state.toasts.map((toast) => {
          return toast.id === action.payload.id ? action.payload : toast
        })

        return { ...state, toasts }
      }

      default: {
        const exhaustiveCheck: never = action
        throw new Error(`Unhandled action case: ${exhaustiveCheck}`)
      }
    }
  }, initialState)

  function addToast(toast: { id?: string; toast: React.ReactNode }) {
    dispatch({ type: Types.ADD_TOAST, payload: toast })
  }

  function removeToast(id: string) {
    dispatch({ type: Types.REMOVE_TOAST, payload: id })
  }

  function updateToast(toast: { id: string; toast: React.ReactNode }) {
    dispatch({ type: Types.UPDATE_TOAST, payload: toast })
  }

  return (
    <ToastContext.Provider
      value={{
        toasts: state.toasts,
        addToast,
        removeToast,
        updateToast,
      }}
    >
      {props.children}

      {state.toasts.map((toast) => {
        return ReactDom.createPortal(
          <span key={toast.id}>{toast.toast}</span>,
          document.getElementById('popup') || document.body
        )
      })}
    </ToastContext.Provider>
  )
}
