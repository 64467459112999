import React, { useState, useCallback, useRef } from 'react'
import * as S from './MessageModal.style'
import Modal from '../Modal'
import Alert from '../Alert'
import Spinner from '../Spinner'
import { IFile, IPatient } from '@bonliva-traits/api/types'
import useApiState from '@bonliva-traits/hooks/useApiState'

type ILink = {
  label: string
  url: string
}

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  setSuccessModalOpen: (isOpen: boolean) => void
  refreshNotifications: () => void
  patient?: IPatient
}

const MessageModal: React.FC<Props> = (props) => {
  const [message, setMessage] = useState('')
  const [links, setLinks] = useState<ILink[]>([])
  const [newLink, setNewLink] = useState({ label: '', url: '' })
  const [files, setFiles] = useState<IFile[]>([])
  const [invalidLinks, setInvalidLinks] = useState<ILink[]>([])

  const hiddenFileInput = useRef<HTMLInputElement>(null)

  const fileUpload = useApiState<IFile>('v1/uploads')
  const patientMessage = useApiState(`/v1/treaters/patients`)

  const uploadFileHandlerHandler = useCallback(async () => {
    if (!hiddenFileInput.current?.files) return

    await Promise.all(
      Array.from(hiddenFileInput.current.files).map(async (file) => {
        const formData = new FormData()
        formData.append('file', file)
        const res = await fileUpload.post({ data: formData })
        setFiles((files) => [...files, res])
        return res
      })
    )
  }, [])

  const verifyLinkHandler = useCallback((l: ILink[]) => {
    const invalidLinks = l.filter((link) => {
      try {
        new URL(link.url)
        return false
      } catch (e) {
        return true
      }
    })

    setInvalidLinks(invalidLinks)
    return invalidLinks
  }, [])

  const sendMessageHandler = useCallback(async () => {
    if (!props.patient) return

    const hasInvalidLinks = verifyLinkHandler(links)

    if (hasInvalidLinks.length) return

    try {
      const data = { message, links, fileIds: files.map((f) => f.id) }
      await patientMessage.post({ data }, `${props.patient?.id}/notifications`)
      props.refreshNotifications()
      props.setSuccessModalOpen(true)
      props.setIsOpen(false)
    } catch (e) {
      throw new Error('Error sending message')
    }
  }, [message, props.patient, links, files])

  const removeLinkHandler = useCallback(
    (index: number) => {
      const newLinks = [...links]
      newLinks.splice(index, 1)
      setLinks(newLinks)
    },
    [links]
  )

  const removeFileHandler = useCallback(
    (index: number) => {
      const newFiles = [...files]
      newFiles.splice(index, 1)
      setFiles(newFiles)
    },
    [files]
  )

  return (
    <Modal.Wrapper isOpen={props.isOpen}>
      <Modal.Contents onDismiss={() => props.setIsOpen(false)}>
        <S.MessageModal>
          <Modal.CloseButton />

          <S.Header>
            <S.Title>Skriv nytt meddelande</S.Title>
          </S.Header>

          <S.Content>
            <Alert
              type="info"
              title="Kom ihåg"
              messages={[
                'Patienten kan inte svara på ditt meddelande.',
                'Du måste skriva ett meddelande för att kunna skicka.',
              ]}
            />

            {invalidLinks.length > 0 && (
              <Alert
                type="error"
                title="Länkar är inte giltiga"
                messages={invalidLinks.map(
                  (i, index) => `${index + 1}. ${i.label} har en ogiltig länk.`
                )}
              />
            )}

            {patientMessage.hasError && (
              <Alert
                type="error"
                title="Tyvärr gick något fel. "
                messages={['Vänligen försök igen.']}
              />
            )}

            <span>
              <S.Label>Skriv ett meddelande till {props.patient?.name}</S.Label>

              <S.CommentField
                placeholder="Skriv ett meddelande här..."
                onChange={(e) => setMessage(e.target.value)}
                value={message}
              />
            </span>

            <span>
              <S.AttachHeader>
                <S.AttachHeaderIcon src="LinkAttach" />
                <S.AttachHeaderLabel>Bifoga länk</S.AttachHeaderLabel>
              </S.AttachHeader>

              {links.length > 0 && (
                <S.FlexWrap>
                  {links.map((link, index) => (
                    <S.ListWrapper key={index}>
                      <S.ListItem href={link.url} target="_blank">
                        {link.label} - {link.url}
                        <S.RemoveIcon
                          onClick={(e) => {
                            e.preventDefault()
                            removeLinkHandler(index)
                          }}
                          src="Close"
                        />
                      </S.ListItem>
                    </S.ListWrapper>
                  ))}
                </S.FlexWrap>
              )}

              <S.InputForm>
                <S.InputWrapper>
                  <S.Input
                    placeholder="Artikel om ..."
                    value={newLink.label}
                    onChange={(e) => {
                      setNewLink({ ...newLink, label: e.target.value })
                    }}
                  />

                  <S.Input
                    placeholder="https://artiklar.se/..."
                    value={newLink.url}
                    onChange={(e) => {
                      setNewLink({ ...newLink, url: e.target.value })
                    }}
                  />
                </S.InputWrapper>

                <S.AddButton
                  disabled={!newLink.label || !newLink.url}
                  onClick={() => {
                    setLinks((prev) => [
                      ...prev,
                      { label: newLink.label, url: newLink.url },
                    ])
                    setNewLink({ label: '', url: '' })
                  }}
                >
                  Lägg till
                </S.AddButton>
              </S.InputForm>

              <S.AttachHeader>
                <S.AttachHeaderIcon src="fileAttach" />
                <S.AttachHeaderLabel>Bifoga fil</S.AttachHeaderLabel>
              </S.AttachHeader>

              {files.length > 0 && (
                <S.FlexWrap>
                  {files.map((file, index) => (
                    <S.ListWrapper key={index}>
                      <S.ListItem href={file.url} target="_blank">
                        {file.name.substring(13)}
                        <S.RemoveIcon
                          onClick={(e) => {
                            e.preventDefault()
                            removeFileHandler(index)
                          }}
                          src="Close"
                        />
                      </S.ListItem>
                    </S.ListWrapper>
                  ))}
                </S.FlexWrap>
              )}

              <S.AddButton onClick={() => hiddenFileInput.current?.click()}>
                Bifoga fil
              </S.AddButton>
            </span>

            <input
              type="file"
              accept="image/*,video/*,.pdf"
              ref={hiddenFileInput}
              onChange={uploadFileHandlerHandler}
              style={{ display: 'none' }}
            />
          </S.Content>

          <S.Footer>
            <Modal.DismissButton>
              <S.OutlineButton>Stäng</S.OutlineButton>
            </Modal.DismissButton>

            <S.Button
              disabled={
                patientMessage.isLoading ||
                !message ||
                !props.patient ||
                links.some((link) => !link.label || !link.url)
              }
              onClick={sendMessageHandler}
            >
              {patientMessage.isLoading ? <Spinner /> : 'Skicka meddelande'}
            </S.Button>
          </S.Footer>
        </S.MessageModal>
      </Modal.Contents>
    </Modal.Wrapper>
  )
}

export default MessageModal
