export const markdownParser = (text: string, native = true) => {
  let toHTML = text

  // H6
  toHTML = toHTML.replace(
    /^###### (.*$)/gim,
    "<h6 style='color: #FF867B; margin: 0; font-size: 32px; line-height: 38px;'>$1</h6>"
  )

  // H5
  toHTML = toHTML.replace(
    /^##### (.*$)/gim,
    "<h5 style='font-size: 16px; color: #121212; line-height: 22px;'>$1</h5>"
  )

  // H4
  toHTML = toHTML.replace(
    /^#### (.*$)/gim,
    "<h4 style='font-size: 18px; color: #121212; line-height: 24px;'>$1</h4>"
  )

  // H3
  toHTML = toHTML.replace(
    /^### (.*$)/gim,
    "<h3 style='font-size: 20px; color: #121212; line-height: 26px; font-weight: 600;'>$1</h3>"
  )

  // H2
  toHTML = toHTML.replace(
    /^## (.*$)/gim,
    "<h2 style='font-size: 24px; color: #121212; line-height: 32px; font-weight: 600;'>$1</h2>"
  )

  // H1
  toHTML = toHTML.replace(
    /^# (.*$)/gim,
    "<h1 style='font-size: 32px; color: #121212; line-height: 40px; margin: 12px 0 18px; font-weight: 600'>$1</h1>"
  )

  // Bold
  toHTML = toHTML.replace(
    /\*\*(.*)\*\*/gim,
    "<span style='font-weight: 500;'>$1</span>"
  ) // bold

  // Italic
  toHTML = toHTML.replace(/(?:[ ])\_(.*?)\_/gim, '<i>$1</i>') // italic

  // Italic
  toHTML = toHTML.replace(/^\_(.*?)\_/gim, '<i>$1</i>') // italic

  // Strike through
  toHTML = toHTML.replace(/\~~(.*)\~~/gim, '<s>$1</s>') // strike through

  // List item ul
  toHTML = toHTML.replace(
    /^\-(.*)/gim,
    native
      ? "<div style='display: flex; flex-direction: row; align-items: flex-start; margin: 4px 0px;'><div style='background: #FF867B; height: 8px; width: 8px; border-radius: 16px; margin-right: 16px; margin-top: 10px;'></div><li style='width: 90%; list-style-type: none;'>$1</li></div>"
      : "<div style='display: flex; flex-direction: row; align-items: flex-start; margin: 4px 0px;'><ul style='display: flex;'><div style='background: #FF867B; height: 8px; width: 8px; border-radius: 16px; margin-right: 16px; margin-top: 6px;'></div><li style='width: 90%; list-style-type: none; margin-bottom: 4px;'>$1</li></ul></div>"
  )

  // List item ol
  toHTML = toHTML.replace(
    /^(\d+)\.*(.*)/gm,
    native
      ? '<li style="margin: 0 0 8px">$1. $2</li>'
      : '<ol><li style="margin: 0 0 8px">$1. $2</li></ol>'
  )

  // Img
  toHTML = toHTML.replace(
    /^!\[(.*)\]\((.*)\)/gm,
    native
      ? "<img style='margin: 0 0 12px;' src='$2' alt='$1' />"
      : "<img style='margin: 10px -10px 12px -10px; width: calc(100% + 20px);' src='$2' alt='$1' />"
  )

  // Link
  toHTML = toHTML.replace(
    /\[(.*?)\]+\((.*?)\)/gm,
    "<a style='color: #FF867B; text-decoration: none; font-weight: 500; font-size: 18px;' href='$2'>$1</a>"
  )

  // Quote
  toHTML = toHTML.replace(/^>(.*)/gim, '<blockquote>$1</blockquote>')

  // Paragraph
  toHTML = toHTML.replace(
    /^(?!<h[1-6]|<ul|<ol|<li|<blockquote|<img|<div|<a|<br|<i|<b|<s).*$/gm,
    "<p style='margin: 0px 0px 12px;'>$&</p>"
  )

  // Line break
  toHTML = toHTML.replace(
    /^<br ?\/?>(.*$)/gim,
    '<div style="width: 100%; height: 12px;"></div>'
  )

  return toHTML.trim() // using trim method to remove whitespace
}
