import React from 'react'
import * as S from './Image.style'
import { IComponentImage } from '@bonliva-traits/api/types'

export const Image: React.FC<IComponentImage> = (props) => {
  const originalAspectRatio =
    (props.image?.data?.attributes?.width || 0) /
    (props.image?.data?.attributes.height || 1)

  const width = 648 * (9 / 18)
  const height = width / originalAspectRatio

  return (
    <S.MediaPhoneDisplayImage
      width={width}
      height={height}
      src={encodeURI(props.image.data?.attributes.url || '')}
    />
  )
}
