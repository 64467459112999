import React, { useState, useEffect } from 'react'
import ReactDom from 'react-dom'
import { Outlet } from 'react-router-dom'
import { format } from 'date-fns'
import { useTreaterProfile } from '@bonliva-traits/api/treater-profile'
import { IDeviatingTime, IWorkingTime } from '@bonliva-traits/api/types'
import useApiState from '@bonliva-traits/hooks/useApiState'
import NoWorkingTimesWarning from '@bonliva-web/work-times/components/NoWorkingTimesWarning'

type Props = {
  isLoggedIn?: boolean
}

const PopupRoute: React.FC<Props> = (props) => {
  const profile = useTreaterProfile()
  const [showNoTimesWarning, setShowNoTimesWarning] = useState(false)

  const workingTimes = useApiState<IWorkingTime[]>(
    '/v1/treater/working-times',
    []
  )

  const deviatingTimes = useApiState<IDeviatingTime[]>(
    '/v1/treater/deviating-working-times',
    []
  )

  useEffect(() => {
    if (!props.isLoggedIn) return

    workingTimes.get()
    deviatingTimes.get()
  }, [profile, props.isLoggedIn])

  useEffect(() => {
    if (
      !props.isLoggedIn ||
      !workingTimes.hasLoaded ||
      !deviatingTimes.hasLoaded
    )
      return

    const hasNoTimes =
      !workingTimes.data?.length && !deviatingTimes.data?.length

    const hasNoActiveWorkingTimes = !!workingTimes.data?.every((w) => {
      const date = new Date(w.endDate)
      const endTime = w.afternoonEndTime || w.morningEndTime
      const prefix = format(date, 'MM/dd/yyyy ')
      const workingTime = new Date(prefix + endTime.substring(0, 5))
      return workingTime < new Date()
    })

    const hasNoActiveDeviatingTimes = !!deviatingTimes.data?.every(
      (w) => new Date(w.deviationDateEnd) < new Date()
    )

    setShowNoTimesWarning(
      hasNoTimes || (hasNoActiveWorkingTimes && hasNoActiveDeviatingTimes)
    )
  }, [profile, workingTimes, deviatingTimes])

  return (
    <React.Fragment>
      {ReactDom.createPortal(
        <React.Fragment>
          <NoWorkingTimesWarning showNoTimesWarning={showNoTimesWarning} />
        </React.Fragment>,
        document.getElementById('warning') || document.body
      )}

      <Outlet />
    </React.Fragment>
  )
}

export default PopupRoute
