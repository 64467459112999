import styled from 'styled-components'
import {
  H3,
  BodyRegular,
  BodySmall,
  H5,
  H4,
  ButtonPrimary,
} from '@bonliva-ui/web'
import { Icon } from '@bonliva-traits/web-icons'

export const CompetenceSlide = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const CompetenceSlideTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const CompetenceSlideTitle = styled(H3)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const CompetenceSlideBody = styled(BodyRegular)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const FlexWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: flex-end;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const Button = styled(ButtonPrimary)``

export const Competence = styled.div`
  padding: ${({ theme }) => theme.margin['3xs']};
  background-color: ${({ theme }) => theme.colors.web.light}66;
  border: 1px solid ${({ theme }) => theme.colors.web.light};
  border-radius: ${({ theme }) => theme.radius.rounded};
`

export const CompetenceHeader = styled.div`
  margin-bottom: ${({ theme }) => theme.margin['3xs']};
`

export const CompetenceTitle = styled(H4)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const CompetenceDetail = styled(H5)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const CompetenceContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const CompetenceLabel = styled(BodyRegular)`
  color: ${({ theme }) => theme.colors.web.darkest};
  margin-left: ${({ theme }) => theme.margin['4xs']};
  margin-bottom: ${({ theme }) => theme.margin['4xs']};
`

export const CheckboxLabel = styled(BodyRegular)`
  color: ${({ theme }) => theme.colors.web.darkest};
  line-height: 16px;
`

export const Check = styled(Icon)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.web.primary};
  color: ${({ theme }) => theme.colors.web.white};
  padding: 3px;
`

export const Checkbox = styled.div`
  margin-left: ${({ theme }) => theme.margin['4xs']};
  width: 18px;
  height: 18px;
  min-height: 18px;
  min-width: 18px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.web.white};
  border: 1px solid ${({ theme }) => theme.colors.web.primary};
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`

export const Input = styled.input`
  display: flex;
  width: 100%;
  height: 46px;
  border-radius: ${({ theme }) => theme.radius.rounded};
  border: 1px solid ${({ theme }) => theme.colors.web.light};
  padding: 0 ${({ theme }) => theme.margin['3xs']};
  font-size: ${({ theme }) => theme.margin.xxs};
`

export const CourseList = styled.div`
  margin-top: ${({ theme }) => theme.margin['3xs']};
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.margin['3xs']};
`

export const CourseRemoveButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Course = styled(BodySmall)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.margin['3xs']};
  width: fit-content;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.web.secondaryLight};
  color: ${({ theme }) => theme.colors.web.secondary};
  padding: 2px ${({ theme }) => theme.margin['3xs']};
  border-radius: ${({ theme }) => theme.radius.rounded};
  border: 0.5px solid ${({ theme }) => theme.colors.web.secondary}66;

  ${CourseRemoveButton} {
    svg {
      transition: color 0.1s ease-in-out;
      width: 12px;
      height: 12px;
      color: ${({ theme }) => theme.colors.web.secondary};
    }
  }

  &:hover {
    ${CourseRemoveButton} {
      svg {
        color: ${({ theme }) => theme.colors.web.primary};
      }
    }
  }
`
