import { setArrayObjects } from '@bonliva-traits/utils'
import { useState, useCallback } from 'react'

const useCheckboxState = <T extends object>(defaultValue: T[]) => {
  const [checked, setChecked] = useState<T[]>(defaultValue)

  const clean = useCallback(
    () => setChecked(defaultValue),
    [setChecked, defaultValue]
  )

  const setArray = useCallback(
    (i: T, key: keyof T) => setChecked((prev) => setArrayObjects(prev, i)(key)),
    [setChecked, setArrayObjects]
  )

  return [checked, setArray, clean] as const
}

export default useCheckboxState
