import { useCallback } from 'react'
import { eachMinuteOfInterval, add, sub } from 'date-fns'
import { useApi } from '@bonliva-traits/api'
import { IBooking } from '@bonliva-traits/api/types'
import { format } from 'date-fns'
import { BookingStatus } from '@bonliva-traits/api/enums'
import { unique } from '@bonliva-traits/utils'

type ITimeSlot = { startDate: Date; endDate: Date }

export type IInfo = {
  start: Date
  end: Date
  startStr: string
  endStr: string
  timeZone: string
}

export const useCalendarEvents = (referralPlanId: string) => {
  const api = useApi()

  return useCallback(async (info: IInfo) => {
    const appointments = await api.get<IBooking[]>(
      `v1/treaters/calendar/appointments`,
      {
        params: {
          fromDate: info.start.toISOString(),
          toDate: info.end.toISOString(),
        },
      }
    )

    const referralPlanAppointments = await api.get<IBooking[]>(
      `v1/treaters/referral-plans/${referralPlanId}/appointments`,
      {
        params: {
          status: [BookingStatus.Reserved],
          _start: 0,
          _end: 100,
        },
      }
    )

    const uniqueAppointments = unique(
      appointments.data,
      referralPlanAppointments.data
    )('id')

    const appointmentEvents = uniqueAppointments.map((appointment) => ({
      id: appointment.id,
      title: appointment.patient?.name,
      start: new Date(appointment.startDate),
      end: new Date(appointment.endDate),
      display: 'block',
      extendedProps: { appointment },
    }))

    const intervals = eachMinuteOfInterval(
      {
        start: sub(info.start, { weeks: 1 }),
        end: add(info.end, { weeks: 1 }),
      },
      { step: 30 }
    )

    const timeslots: ITimeSlot[] = intervals.map((date) => ({
      startDate: date,
      endDate: add(date, { minutes: 30 }),
    }))

    const filteredTimeslots = timeslots.filter(
      (timeslot) => timeslot.startDate > add(new Date(), { weeks: 1 })
    )

    const backgroundEvents = filteredTimeslots.map((timeslot) => ({
      id: format(timeslot.startDate, 'dd-HH-mm') + 'background',
      groupId: 'availableTimes',
      start: timeslot.startDate,
      end: timeslot.endDate,
      display: 'background',
      extendedProps: { isBackground: true },
    }))

    const availableTimes = appointmentEvents.length
      ? backgroundEvents.filter(
          (backgroundEvent) =>
            !appointmentEvents.some(
              (appointmentEvent) =>
                (appointmentEvent.start > backgroundEvent.start &&
                  sub(appointmentEvent.start, { minutes: 30 }) <
                    backgroundEvent.end) ||
                (appointmentEvent.end > backgroundEvent.start &&
                  appointmentEvent.end < backgroundEvent.end)
            )
        )
      : backgroundEvents

    const availableEvents = availableTimes.map((timeslot) => ({
      id: format(timeslot.start, 'dd-HH-mm') + 'available',
      start: timeslot.start,
      end: timeslot.end,
      display: 'background',
      extendedProps: { isAvailable: true },
    }))

    return [appointmentEvents, backgroundEvents, availableEvents].flat()
  }, [])
}
