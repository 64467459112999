import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
`

export const MeetingSubscriberInner = styled.div<{ hide?: boolean }>`
  width: 100%;
  height: 100%;

  display: ${({ hide }) => (hide ? 'none' : 'block')};

  video {
    width: 100%;
    height: 100%;
  }
`

export const MeetingSubscriberInnerMiniature = styled.div`
  width: 150px;
  aspect-ratio: 3 / 4;
  video {
    width: 100%;
    height: 100%;
    border-radius: ${({ theme }) => theme.radius.rounded};
  }
`

export const PatientPlaceholder = styled.div`
  background-color: ${({ theme }) => theme.colors.native.darkest};
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: ${({ theme }) => theme.radius.rounded};
`

export const PatientPlaceholderCircleWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'Calibre';
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 40px;
`

export const PatientPlaceholderCircleWrapperMiniature = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-family: 'Calibre';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 40px;
  padding: 16px;
  text-align: center;
`
