import styled from 'styled-components'
import {
  BodyRegular,
  ButtonPrimary,
  ButtonSecondary,
  H1,
} from '@bonliva-ui/web'

export const PostMeetingRoom = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  min-width: 768px;
  overflow-x: hidden;
`

export const PostMeetingRoomLayout = styled.div`
  margin: ${(props) => props.theme.margin.xxs};
  display: flex;
  height: calc(100vh - 186px);
  gap: ${(props) => props.theme.margin.xs};
`

export const PostMeetingRoomContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 6px;
  background-color: ${(props) => props.theme.colors.web.white};
  border-radius: ${(props) => props.theme.radius.rounded};
`

export const PostMeetingRoomInfo = styled(BodyRegular)`
  color: ${(props) => props.theme.colors.web.darkest};
  text-align: center;
`

export const PostMeetingRoomTitle = styled(H1)`
  color: ${(props) => props.theme.colors.web.darkest};
  text-align: center;
`

export const PostMeetingRoomButtonsWrapper = styled.div`
  margin-top: ${(props) => props.theme.margin.s};
  display: flex;
  gap: ${(props) => props.theme.margin.xxs};
`
export const PostMeetingRoomButton = styled(ButtonPrimary)`
  min-width: 100px;
`

export const PostMeetingRoomOutlineButton = styled(ButtonSecondary)`
  min-width: 100px;
`
