import styled from 'styled-components'
import { H3, BodyRegular } from '@bonliva-ui/web'

export const InfoSlide = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const InfoSlideImage = styled.img`
  width: 100%;
  border-radius: ${({ theme }) => theme.radius.rounded};
  object-fit: cover;
  aspect-ratio: 21/9;
`

export const InfoSlideTitle = styled(H3)`
  margin-bottom: ${({ theme }) => theme.margin['3xs']};
`

export const InfoSlideBody = styled(BodyRegular)``
