import Select from '@bonliva-components/web/shared/Select'
import { useMeeting } from '@bonliva-traits/meeting-web'
import React from 'react'
import * as S from './DeviceSelector.style'
import Modal from '@bonliva-components/web/shared/Modal/Modal'

const DeviceSelector = () => {
  const {
    availableAudioInputDevices,
    availableAudioOutputDevices,
    availableVideoDevices,
    audioInputDevice,
    audioOutputDevice,
    videoDevice,
    setVideoDevice,
    getDevices,
    setAudioInputDevice,
    setAudioOutputDevice,
  } = useMeeting()

  const getOptionFromDevice = (device: MediaDeviceInfo) => {
    return {
      label: device.label,
      value: device.deviceId,
    }
  }

  const handleChange = (
    option: { label: string; value: string } | null,
    deviceList: MediaDeviceInfo[],
    setDevice: (device: MediaDeviceInfo) => void,
    currentDevice?: MediaDeviceInfo
  ) => {
    if (currentDevice?.deviceId === option?.value) {
      return
    }

    const matching = option
      ? deviceList.find((d) => d.deviceId === option.value)
      : null
    if (!matching) {
      return
    }
    setDevice(matching)
  }

  return (
    <Modal.Wrapper>
      <Modal.OpenButton onClick={getDevices}>
        <S.DeviceSelectorButton>
          <S.DeviceSelectorIcon src="Settings" />
        </S.DeviceSelectorButton>
      </Modal.OpenButton>
      <Modal.Contents>
        <S.DeviceSelectorModal>
          <React.Fragment>
            <S.Header>
              <S.Title>Välj enheter</S.Title>
              <Modal.CloseButton />
            </S.Header>

            <S.Content>
              <S.DeviceWrapper>
                <span>Mikrofon</span>
                <Select
                  isSearchable={false}
                  value={
                    audioInputDevice
                      ? getOptionFromDevice(audioInputDevice)
                      : null
                  }
                  options={availableAudioInputDevices.map(getOptionFromDevice)}
                  onChange={(e) =>
                    handleChange(
                      e,
                      availableAudioInputDevices,
                      setAudioInputDevice,
                      audioInputDevice
                    )
                  }
                />
              </S.DeviceWrapper>
              {!(window as any).safari && (
                <S.DeviceWrapper>
                  <span>Högtalare</span>
                  <Select
                    isSearchable={false}
                    value={
                      audioOutputDevice
                        ? getOptionFromDevice(audioOutputDevice)
                        : null
                    }
                    options={availableAudioOutputDevices.map((d) => ({
                      label: d.label,
                      value: d.deviceId,
                    }))}
                    onChange={(e) =>
                      handleChange(
                        e,
                        availableAudioOutputDevices,
                        setAudioOutputDevice,
                        audioOutputDevice
                      )
                    }
                  />
                </S.DeviceWrapper>
              )}
              <S.DeviceWrapper>
                <span>Kamera</span>
                <Select
                  isSearchable={false}
                  value={videoDevice ? getOptionFromDevice(videoDevice) : null}
                  options={availableVideoDevices.map((d) => ({
                    label: d.label,
                    value: d.deviceId,
                  }))}
                  onChange={(e) =>
                    handleChange(
                      e,
                      availableVideoDevices,
                      setVideoDevice,
                      videoDevice
                    )
                  }
                />
              </S.DeviceWrapper>
            </S.Content>
          </React.Fragment>

          <S.Footer>
            <Modal.DismissButton>
              <S.Close>Stäng</S.Close>
            </Modal.DismissButton>
          </S.Footer>
        </S.DeviceSelectorModal>
      </Modal.Contents>
    </Modal.Wrapper>
  )
}

export default DeviceSelector
