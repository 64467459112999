import { BodySmall, ButtonPrimary, H2, H5 } from '@bonliva-ui/web'
import styled from 'styled-components'

export const ProfileCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  min-width: 30%;
  max-width: 50%;
  background-color: ${({ theme }) => theme.colors.web.white};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: ${({ theme }) => theme.margin.xxs};
  margin-bottom: 0;
`

export const FlexWrapper = styled.div`
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: ${({ theme }) => theme.margin.xxs};
  margin: -${({ theme }) => theme.margin['4xs']};
  margin-top: ${({ theme }) => theme.margin.xxs};
  padding: ${({ theme }) => theme.margin.xxs};
  border: 1px solid ${({ theme }) => theme.colors.web.light};
  border-radius: ${({ theme }) => theme.radius.rounded};
`

export const Underline = styled.div`
  border: 0.5px solid ${({ theme }) => theme.colors.web.light}99;
  width: calc(100% + ${({ theme }) => theme.margin.xxs} * 2);
  margin: 0 -${({ theme }) => theme.margin.xxs};
`

export const Title = styled(H2)`
  color: ${({ theme }) => theme.colors.web.darkest};
  margin-top: ${({ theme }) => theme.margin['4xs']};
`

export const Label = styled(H5)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const Body = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const Button = styled(ButtonPrimary)``

export const VideoWrapper = styled.div`
  margin-top: ${({ theme }) => theme.margin['4xs']};
  max-width: 300px;
  position: relative;
  border-radius: 4px;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  border-radius: 4px;
`

export const Video = styled.video`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

export const NoVideo = styled.div`
  cursor: pointer;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.web.secondary};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: filter 0.2s ease-in-out;

  svg {
    min-width: 42px;
    min-height: 42px;
  }

  &:hover {
    filter: brightness(130%);
  }
`
