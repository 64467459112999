import React from 'react'
import * as S from './Body.style'
import { IComponentBody } from '@bonliva-traits/api/types'
import { markdownParser } from '@bonliva-traits/utils/markdown-parser'

export const Body: React.FC<IComponentBody> = (props) => {
  return (
    <S.MediaPhoneDisplayMarkdown>
      <div
        dangerouslySetInnerHTML={{
          __html: markdownParser(props.body, false),
        }}
      />
    </S.MediaPhoneDisplayMarkdown>
  )
}
