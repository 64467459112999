import styled from 'styled-components'

export const TreaterPlaceholder = styled.div`
  background-color: ${({ theme }) => theme.colors.native.darkest};
  border: 1px solid white;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const TreaterPlaceholderCircleWrapper = styled.span`
  color: white;
  font-family: 'Calibre';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
`
