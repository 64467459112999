import React, { useEffect, useCallback, useState } from 'react'
import * as S from './PreMeetingRoom.style'
import { useNavigate, useParams } from 'react-router-dom'
import { MeetingStatus, useMeeting } from '@bonliva-traits/meeting-web'
import { format } from 'date-fns'
import { IAppointment } from '@bonliva-traits/api/types'
import Spinner from '@bonliva-components/web/shared/Spinner'
import Alert from '@bonliva-components/web/shared/Alert'
import Webcam from 'react-webcam'
import useVolume from './hooks/useVolume'
import VolumeMeter from './components/VolumeMeter'
import useApiState from '@bonliva-traits/hooks/useApiState'
import useInterval from '@bonliva-traits/hooks/useInterval'
import DeviceSelector from '@bonliva-web/meeting-room/components/DeviceSelector'

const PreMeetingRoom = () => {
  const params = useParams()
  const navigate = useNavigate()
  const {
    status: meetingStatus,
    appointment: meetingAppointment,
    startMeeting,
    publishAudio,
    publishVideo,
    setPublishAudio,
    setPublishVideo,
    videoDevice,
  } = useMeeting()

  const [volume, startGetVolume, stopGetVolume] = useVolume()
  const [isLoading, setIsLoading] = useState(false)

  const appointmentUrl = `v1/treaters/appointments/${params.appointmentId}`

  const appointment = useApiState<IAppointment>(appointmentUrl)

  const hasPatientJoined = useApiState<boolean>(
    `${appointmentUrl}/patient-joined`
  )

  const toggleMicHandler = () => {
    setPublishAudio(!publishAudio)
  }

  const toggleVideoHandler = () => {
    setPublishVideo(!publishVideo)
  }

  const startMeetingHandler = useCallback(async () => {
    if (!params.appointmentId) {
      throw new Error('No appointment id')
    }

    if (meetingStatus !== MeetingStatus.Connected) {
      setIsLoading(true)

      await startMeeting(params.appointmentId)
      stopGetVolume()
      setIsLoading(false)
    }

    navigate(`/meeting-room/${params.appointmentId}/meeting`)
  }, [])

  useEffect(() => {
    if (publishAudio) startGetVolume()
    else stopGetVolume()
  }, [publishAudio])

  useEffect(() => {
    appointment.get({ params: { expandPatient: true, expandTreatment: true } })
  }, [])

  useInterval(() => {
    hasPatientJoined.get()
  }, 10000)

  return (
    <S.PreMeetingRoom>
      <S.PreMeetingRoomLayout>
        <S.PreMeetingRoomWebcamInfoWrapper>
          <S.PreMeetingRoomWebcamWrapper>
            {publishVideo ? (
              <Webcam
                audio={false}
                forceScreenshotSourceSize
                screenshotFormat="image/jpeg"
                videoConstraints={{
                  deviceId: videoDevice?.deviceId,
                  width: 1280,
                  height: 720,
                  facingMode: 'user',
                }}
              />
            ) : (
              <S.PreMeetingRoomNoWebcamText>
                Kameran är avstängd
              </S.PreMeetingRoomNoWebcamText>
            )}
            {volume !== undefined && <VolumeMeter level={volume} />}

            <S.PreMeetingRoomControlWrapper>
              <DeviceSelector />

              <S.PreMeetingRoomControl
                $active={publishAudio}
                onClick={toggleMicHandler}
              >
                <S.PreMeetingRoomIcon src={publishAudio ? 'Mic' : 'NoMic'} />
              </S.PreMeetingRoomControl>

              <S.PreMeetingRoomControl
                $active={publishVideo}
                onClick={toggleVideoHandler}
              >
                <S.PreMeetingRoomIcon
                  src={publishVideo ? 'Video' : 'NoVideo'}
                />
              </S.PreMeetingRoomControl>
            </S.PreMeetingRoomControlWrapper>
          </S.PreMeetingRoomWebcamWrapper>

          <S.PreMeetingRoomDeviceSelectorWrapper>
            <Alert type="info" title="Kontrollera ljud och bild" />
          </S.PreMeetingRoomDeviceSelectorWrapper>
        </S.PreMeetingRoomWebcamInfoWrapper>

        <S.PreMeetingRoomInfoWrapper>
          {!appointment.hasLoaded && appointment.isLoading && (
            <S.PreMeetingRoomInfoSpinner>
              <Spinner />
            </S.PreMeetingRoomInfoSpinner>
          )}

          {appointment.data && (
            <React.Fragment>
              <S.PreMeetingRoomTitle>
                {appointment.data.id !== meetingAppointment?.id
                  ? 'Redo att starta samtalet?'
                  : 'Återgå till samtalet'}
              </S.PreMeetingRoomTitle>
              <S.PreMeetingRoomInfoTextWrapper>
                <S.PreMeetingRoomInfoText>
                  <S.PreMeetingRoomInfoTextBold>
                    {'Tid: '}
                  </S.PreMeetingRoomInfoTextBold>
                  {format(new Date(appointment.data?.startDate), 'HH:mm')}
                  {' - '}
                  {format(new Date(appointment.data?.endDate), 'HH:mm')}
                </S.PreMeetingRoomInfoText>
                <S.PreMeetingRoomInfoText>
                  <S.PreMeetingRoomInfoTextBold>
                    {'Inriktning: '}
                  </S.PreMeetingRoomInfoTextBold>
                  {appointment.data?.treatment?.category}
                </S.PreMeetingRoomInfoText>
              </S.PreMeetingRoomInfoTextWrapper>

              <S.PreMeetingRoomPatientStatus>
                {hasPatientJoined.data
                  ? `${appointment.data?.patient?.name} har gått med i samtalet`
                  : 'Samtalet är tomt'}
              </S.PreMeetingRoomPatientStatus>

              <S.PreMeetingRoomButton
                onClick={startMeetingHandler}
                disabled={
                  isLoading ||
                  (meetingAppointment !== undefined &&
                    appointment.data.id !== meetingAppointment?.id)
                }
              >
                {isLoading ? (
                  <Spinner />
                ) : !meetingAppointment ? (
                  'Starta samtal'
                ) : appointment.data.id === meetingAppointment?.id ? (
                  'Återgå till samtal'
                ) : (
                  'Annat samtal pågår'
                )}
              </S.PreMeetingRoomButton>
            </React.Fragment>
          )}
        </S.PreMeetingRoomInfoWrapper>
      </S.PreMeetingRoomLayout>
    </S.PreMeetingRoom>
  )
}

export default PreMeetingRoom
