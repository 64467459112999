import styled from 'styled-components'

export const DatePicker = styled.div<{
  $hasInput: boolean
}>`
  .date-picker {
    color: ${({ theme, $hasInput }) =>
      $hasInput ? theme.colors.web.darkest : theme.colors.web.dark};
    border: 1px solid ${({ theme }) => theme.colors.web.neutral};
    border-radius: ${({ theme }) => theme.radius.rounded};
    padding: ${({ theme }) => theme.margin.xxs};
    font-family: 'Calibre';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    width: 100%;
    height: 46px;

    &:focus {
      outline: none;
      border: 1px solid ${({ theme }) => theme.colors.web.primary};
    }
  }

  .react-datepicker {
    border-color: ${({ theme }) => theme.colors.web.neutral};
  }

  .react-datepicker__header {
    background-color: ${({ theme }) => theme.colors.web.white};
    border-color: ${({ theme }) => theme.colors.web.neutral};
  }

  .react-datepicker__day {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.web.secondaryDark};
    border-radius: 50%;

    transition: color 0.1s ease-in-out, background-color 0.1s ease-in-out;

    :hover {
      color: ${({ theme }) => theme.colors.web.white};
      background-color: ${({ theme }) => theme.colors.web.primary}cc;
      border-radius: 50%;
    }
  }

  .react-datepicker__day-name {
    &:first-child {
      color: transparent;
    }
  }

  .react-datepicker__week-number {
    background-color: ${({ theme }) => theme.colors.web.lightest};
    border-radius: 2px;
  }

  .react-datepicker__day--selected,
  .react-datepicker__day--keyboard-selected {
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.web.primary};
    color: ${({ theme }) => theme.colors.web.white};
  }

  .react-datepicker__day--disabled {
    opacity: 0.3;
  }

  .react-datepicker__day--outside-month {
    opacity: 0.2;
    pointer-events: none;
    background-color: transparent;
  }

  .react-datepicker__triangle {
    display: none;
  }
`
