import styled from 'styled-components'

export const StatusContainer = styled.div<{
  borderColor: string
  borderStyle: string
  backgroundColor: string
}>`
  display: flex;
  width: fit-content;
  flex-direction: column;
  padding: 6px 8px;
  border-radius: ${({ theme }) => theme.radius.rounded};
  font-weight: 400;
  font-size: 18px;
  line-height: 19px;
  color: ${({ theme }) => theme.colors.native.darkest};

  background: ${({ backgroundColor }) => `${backgroundColor}15`};
  border-color: ${({ borderColor }) => borderColor};
  border-width: 1px;
  border-style: ${({ borderStyle }) => borderStyle};
`

export const StatusWrapper = styled.div<{ color: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ color }) => color};
  gap: 6px;

  svg {
    width: 16px;
    height: 16px;
  }
`

export const StatusTitle = styled.span<{ textColor: string }>`
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: ${({ textColor }) => textColor};
`

export const SummaryBody = styled.span`
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.web.black};
`
