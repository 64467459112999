import { BodyXSmall, H5 } from '@bonliva-ui/web'
import styled from 'styled-components'

export const AudioWrapper = styled.div``

export const Card = styled.div`
  margin: 8px;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.colors.web.neutralLight};
  padding: 12px;
`

export const Title = styled(H5)``

export const ErrorTitle = styled(H5)`
  color: ${({ theme }) => theme.colors.web.error};
`

export const Small = styled(BodyXSmall)``

export const ErrorSmall = styled(BodyXSmall)`
  color: ${({ theme }) => theme.colors.web.error};
`

export const AudioControls = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 4px;
`

export const Range = styled.input`
  -webkit-appearance: none;
  width: 100%;
  background: transparent;

  &::-webkit-slider-thumb {
    border: none;
    -webkit-appearance: none;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.web.primary};
    cursor: pointer;
    margin-top: -14px;
  }

  &::-moz-range-thumb {
    border: none;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.web.primary};
    cursor: pointer;
  }

  &::-ms-thumb {
    border: none;
    height: 8px;
    width: 8px;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.web.primary};
    cursor: pointer;
  }

  &::-webkit-slider-runnable-track {
    -webkit-appearance: none;
    color: ${({ theme }) => theme.colors.web.primary};
  }

  &::-moz-range-progress {
    background-color: ${({ theme }) => theme.colors.web.primary};
  }

  &:focus {
    outline: none;
  }

  &::-ms-track {
    width: 100%;
    cursor: pointer;

    background: transparent;
    border-color: transparent;
    color: transparent;
  }

  width: 100%;
  height: 3px;
  margin: 13px 0;
  background-color: ${({ theme }) => theme.colors.web.neutralLight};
`

export const CounterLeft = styled(BodyXSmall)`
  position: absolute;
  left: 4px;
  top: 0;
`

export const CounterRight = styled(BodyXSmall)`
  position: absolute;
  right: 4px;
  top: 0;
`

export * from '@bonliva-ui/web'
