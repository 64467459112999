import styled from 'styled-components'
import { ButtonPrimary, ButtonSecondary, H3 } from '@bonliva-ui/web'
import { Icon } from '@bonliva-traits/web-icons'

export const CalendarTopBar = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  gap: ${(props) => props.theme.margin.xxs};
  padding-bottom: ${(props) => props.theme.margin.xxs};
  margin-bottom: ${(props) => props.theme.margin.xxs};
  border-bottom: 1px solid ${(props) => props.theme.colors.web.light};
`

export const CalendarTopBarWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.margin.xxs};
`

export const CalendarTopBarSelectWrapper = styled.div`
  max-width: 240px;
  width: 100%;
  display: flex;
  align-items: center;
`

export const CreateButton = styled(ButtonPrimary)`
  margin-right: ${(props) => props.theme.margin.xxs};
`

export const CalendarTopBarTodayButton = styled(ButtonSecondary)`
  height: 36px;
`

export const CalendarTopBarDate = styled(H3)`
  color: ${(props) => props.theme.colors.web.darkest};
  margin-top: 4px;
  text-transform: capitalize;
`

export const CalendarTopBarWeek = styled(H3)`
  color: ${(props) => props.theme.colors.web.dark};
  font-weight: 400;
  margin-top: 4px;
`

export const CalendarTopBarNavigation = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.margin.xxs};
`

export const CalendarTopBarNavigationButton = styled(Icon)`
  min-height: 28px;
  min-width: 28px;
  height: 28px;
  width: 28px;
  color: ${(props) => props.theme.colors.web.secondary};
  background-color: ${(props) => props.theme.colors.web.secondaryLight};
  border-radius: 9999px;
  padding: 8px;
  transition: filter 0.1s ease-in-out;
  border: 0.5px solid ${(props) => props.theme.colors.web.secondary}33;

  cursor: pointer;

  &:hover {
    filter: brightness(95%);
  }
`
