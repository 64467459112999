import React, { useState, useMemo } from 'react'
import * as S from './PatientTable.style'
import { useNavigate } from 'react-router-dom'
import { IPatientWithNextBooking } from '@bonliva-traits/api/types'
import { useDebouncedCallback } from 'use-debounce'
import { requestPagination } from '@bonliva-traits/utils'
import { format } from 'date-fns'
import useApiState from '@bonliva-traits/hooks/useApiState'
import Table from '@bonliva-components/web/shared/Table'
import Alert from '@bonliva-components/web/shared/Alert'

const tableColumns = [
  {
    label: 'Namn',
    sortable: 'name',
  },
  {
    label: 'Inriktning',
    sortable: 'treatment_category',
  },
  {
    label: 'Nästa samtal',
    sortable: 'next_booking_start_date',
  },
]

const PatientTable: React.FC = () => {
  const navigate = useNavigate()

  const [pageSize] = useState(10)
  const [page, setPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isAscending, setIsAscending] = useState(true)
  const [sortBy, setSortBy] = useState('next_booking_start_date')
  const [searchValue, setSearchValue] = useState('')

  const patients = useApiState<IPatientWithNextBooking[]>(
    '/v1/treaters/patients'
  )

  React.useEffect(() => {
    setIsLoading(true)
    fetchNotificationsHandler()
  }, [sortBy, isAscending, searchValue, page])

  const fetchNotificationsHandler = useDebouncedCallback(async () => {
    const order = isAscending ? 'ASC' : 'DESC'

    await patients.get({
      params: {
        ...requestPagination(page, pageSize),
        ['_sort']: sortBy,
        ['_order']: order,
        _search: searchValue,
      },
    })

    setIsLoading(false)
  }, 400)

  const numberOfPages = useMemo(() => {
    if (!patients.headers?.['x-total-count']) return 0
    return Math.ceil(Number(patients.headers['x-total-count']) / pageSize)
  }, [patients.data, pageSize])

  return (
    <S.PatientTable>
      <Table.Wrapper
        header={
          <Table.Header
            title="Patienter"
            showSearchBar
            searchBarPlaceholder="Sök efter namn"
            searchBarValue={searchValue}
            setSearchValue={setSearchValue}
          />
        }
        head={
          <Table.Head
            columns={tableColumns}
            sortBy={sortBy}
            setSortBy={setSortBy}
            isAscending={isAscending}
            setIsAscending={setIsAscending}
          />
        }
        body={
          <Table.Body isLoading={isLoading}>
            {patients.hasLoaded && !patients.data?.length && (
              <S.EmptyInbox>
                <Alert type="info" title="Inga patienter hittades" />
              </S.EmptyInbox>
            )}

            {patients.data?.map((p) => {
              const onClickHandler = () => {
                navigate(`/patients/${p.id}`)
              }

              return (
                <S.TableRow
                  key={p.id + p.nextBooking?.treatment?.category}
                  onClick={onClickHandler}
                >
                  <S.FlexSpan>
                    <S.RowText>{p.name}</S.RowText>
                  </S.FlexSpan>

                  <S.FlexSpan>
                    <S.RowText>
                      {p.nextBooking?.treatment?.category || '-'}
                    </S.RowText>
                  </S.FlexSpan>

                  <S.FlexSpan>
                    <S.RowText>
                      {p.nextBooking?.startDate
                        ? format(
                            new Date(p.nextBooking.startDate),
                            'yyyy-MM-dd HH:mm'
                          )
                        : '-'}
                    </S.RowText>
                  </S.FlexSpan>
                </S.TableRow>
              )
            })}
          </Table.Body>
        }
        footer={
          <Table.Footer
            page={page}
            setPage={setPage}
            numberOfPages={numberOfPages}
          />
        }
      />
    </S.PatientTable>
  )
}

export default PatientTable
