import React, { useState, ChangeEvent, useEffect, useCallback } from 'react'
import * as S from './LocationSlide.style'
import usePlacesAutocomplete, {
  getGeocode,
  getLatLng,
} from 'use-places-autocomplete'
import { Input } from '@bonliva-ui/web'
import { useApi } from '@bonliva-traits/api'
import {
  useFetchTreaterProfile,
  useTreaterProfile,
} from '@bonliva-traits/api/treater-profile'
import { useDebouncedCallback } from 'use-debounce'

const LocationSlide: React.FC = () => {
  const api = useApi()
  const profile = useTreaterProfile()
  const fetchProfile = useFetchTreaterProfile()

  const [location, setLocation] = useState(
    profile.data.physicalLocation?.location
  )
  const [extraInfo, setExtraInfo] = useState<string>(
    profile.data.physicalLocation?.extraInfo || ''
  )

  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      language: 'sv',
      types: ['address'],
    },
    debounce: 300,
  })

  const savePhysicalLocation = useDebouncedCallback(
    useCallback(async () => {
      try {
        if (!location) return

        if (!value && profile.data.physicalLocationId) {
          await api.delete(
            `v1/treaters/physical-locations/${profile.data.physicalLocationId}`
          )
          return
        }

        const physicalLocation = {
          adress: value,
          location: location,
          extraInfo: extraInfo,
        }

        if (profile.data.physicalLocation?.id) {
          await api.put(`v1/treaters/physical-locations`, physicalLocation)
        } else {
          await api.post(`v1/treaters/physical-locations`, physicalLocation)
        }
      } catch (error) {
        throw new Error('error when creating physical location')
      } finally {
        await fetchProfile()
      }
    }, [location, value, profile, extraInfo]),
    400
  )

  const handleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.value)
    savePhysicalLocation()
  }

  const handleSelect =
    ({ description }: { description: string }) =>
    async () => {
      setValue(description, false)
      clearSuggestions()

      await getGeocode({ address: description }).then((results) => {
        const { lat, lng } = getLatLng(results[0])
        setLocation({
          type: 'Point',
          coordinates: [lat, lng],
        })
      })
    }

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const { place_id, description } = suggestion

      return (
        <S.LocationSuggestion key={place_id} onClick={handleSelect(suggestion)}>
          <S.Suggestion>{description}</S.Suggestion>
        </S.LocationSuggestion>
      )
    })

  useEffect(() => {
    setValue(profile.data.physicalLocation?.adress || '', false)
  }, [])

  useEffect(() => {
    if (!location) return
    savePhysicalLocation()
  }, [location])

  return (
    <S.LocationSlide>
      <S.LocationSlideTextWrapper>
        <S.LocationSlideLocation>
          Om du erbjuder fysiska samtal, välj vilken address du är tillgänglig
          på.
        </S.LocationSlideLocation>
        <S.LocationSlideBody>
          Din address kommer att synas för patienterna.
        </S.LocationSlideBody>
      </S.LocationSlideTextWrapper>

      <S.LocationSlideSelectWrapper>
        <Input
          style={{ width: '100%' }}
          value={value}
          onChange={handleInput}
          disabled={!ready}
          placeholder="Skriv in din adress här"
        />
        {status === 'OK' && (
          <S.LocationSuggestionWrapper>
            {renderSuggestions()}
          </S.LocationSuggestionWrapper>
        )}
      </S.LocationSlideSelectWrapper>

      <S.LocationSlideSelectWrapper>
        <Input
          style={{ width: '100%' }}
          value={extraInfo}
          onChange={(e) => setExtraInfo(e.target.value)}
          placeholder="Lägg till extra information (t.ex. våning, portkod)"
          onBlur={savePhysicalLocation}
        />
      </S.LocationSlideSelectWrapper>
    </S.LocationSlide>
  )
}

export default LocationSlide
