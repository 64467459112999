import styled from 'styled-components'
import { Icon } from '@bonliva-traits/web-icons'
import { BodySmall, ButtonPrimary } from '@bonliva-ui/web'
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalWrapper,
} from '@bonliva-components/web/shared/Modal/Modal.style'

export const Onboarding = styled(ModalWrapper)``

export const Header = styled(ModalHeader)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`

export const BackArrowIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.web.darkest};
  width: 12px;
  height: 12px;

  transition: 0.2s ease-in-out;

  @-moz-document url-prefix() {
    margin-bottom: 2px;
  }
`

export const BackArrowLabel = styled(BodySmall)<{ $disabled: boolean }>`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.web.black};
  font-weight: 400;
  gap: ${({ theme }) => theme.margin['4xs']};
  cursor: pointer;

  transition: opacity 0.2s ease-in-out, width 0.2s ease-in-out;
  opacity: ${({ $disabled }) => ($disabled ? 0.3 : 1)};
  pointer-events: ${({ $disabled }) => ($disabled ? 'none' : 'all')};

  ${BackArrowIcon} {
    transform: translate(0px, 0);
  }

  &:hover {
    ${BackArrowIcon} {
      transform: translate(-2px, 0);
    }
  }
`

export const Title = styled(ModalTitle)``

export const Content = styled(ModalContent)<{ $center: boolean }>`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: ${({ $center }) => ($center ? 'center' : 'flex-start')};
`

export const Footer = styled(ModalFooter)``

export const NextButton = styled(ButtonPrimary)`
  width: 100%;
`
