import styled from 'styled-components'
import {
  BodyRegular,
  ButtonPrimary,
  ButtonSecondary,
  H3,
  H4,
  H5,
  Input,
} from '@bonliva-ui/web'

export const BookingModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.xxs};
  padding: ${({ theme }) => theme.margin.xxs};
`

export const BookingModalTitle = styled(H4)`
  font-weight: 500;
  font-size: 24px;
`

export const CloseButtonWrapper = styled.div`
  cursor: pointer;
`

export const Divider = styled.div`
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.web.neutral};
`

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const FlexWrapperCol = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 100%;
`

export const FlexWrapperSpaceBetween = styled(FlexWrapper)`
  justify-content: space-between;
  align-items: center;
`

export const FlexEndWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ReferralInfo = styled(BodyRegular)``

export const CancelButton = styled(ButtonSecondary)`
  margin-right: ${({ theme }) => theme.margin.xxs};
`

export const ConfirmButton = styled(ButtonPrimary)`
  width: 92px;
`

export const AddNewPatientButton = styled(ButtonSecondary)`
  margin-left: 40px;
  margin-top: 10px;
  margin-bottom: 10px;
  border: none;
  padding: 0px;
`

export const SuccessWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ImageWrapper = styled.div`
  margin-top: 94px;
  display: flex;
  justify-content: center;
  width: 100%;
`

export const SuccessTitle = styled(H3)`
  margin-top: 30px;
`

export const Body = styled(BodyRegular)`
  margin-right: ${({ theme }) => theme.margin.xxs};
  :last-child {
    margin-right: 0;
  }
`

export const FlexCenter = styled.div`
  margin-top: ${({ theme }) => theme.margin.xxs};
  display: flex;
  justify-content: center;
`

export const BackToCalendarButton = styled(ButtonPrimary)`
  margin-top: 42px;
  margin-bottom: ${({ theme }) => theme.margin.s};
`

export const TextInput = styled(Input)`
  min-width: 100%;
`

export const Label = styled(H5)`
  color: ${({ theme }) => theme.colors.web.darkest};
  margin-left: ${({ theme }) => theme.margin['4xs']};
  margin-bottom: ${({ theme }) => theme.margin['4xs']};
`

export const Bold = styled.span`
  font-weight: 500;
`

export const NewPatientWrapper = styled.div`
  width: 100%;
  padding-left: 40px;
  display: flex;
  flex-direction: column;
  gap: 12px;
`

export const ErrorMessage = styled(BodyRegular)`
  color: ${({ theme }) => theme.colors.web.error};
  margin-bottom: ${({ theme }) => theme.margin.xxs};
  margin-top: 4px;
  margin-bottom: 0px;
`

export const AlertMessage = styled(BodyRegular)`
  color: ${({ theme }) => theme.colors.web.dark};
  font-style: italic;
`
