import React from 'react'
import * as S from './PatientTreatmentPlan.style'
import PatientTopBar from '@bonliva-components/web/shared/PatientTopBar'
import PlanSummary from './components/plan-summary'
import { useParams } from 'react-router-dom'
import { useTreatmentPlan } from './hooks/useTreatmentPlan'
import { useTreatmentPlanAppointments } from './hooks/useTreatmentPlanAppointments'
import { useTreatmentPlanAssignments } from './hooks/useTreatmentPlanAssignments'
import { useTreatmentPlanTreatmentForms } from './hooks/useTreatmentPlanTreatmentForms'
import PlanAppointments from '@bonliva-components/web/shared/PlanAppointments'
import PlanAssignments from '@bonliva-components/web/shared/PlanAssignments'
import PlanAnalysis from '@bonliva-components/web/shared/PlanAnalysis'
import PlanWellbeingScale from '@bonliva-components/web/shared/PlanWellbeingScale'

const PatientTreatmentPlan = () => {
  const params = useParams()
  const treatmentPlanId = params.treatmentPlanId as string
  const treatmentPlan = useTreatmentPlan(treatmentPlanId)
  const appointments = useTreatmentPlanAppointments(treatmentPlanId)
  const assignments = useTreatmentPlanAssignments(treatmentPlanId)
  const analysis = useTreatmentPlanTreatmentForms(treatmentPlanId)
  const wellbeingScale = useTreatmentPlanTreatmentForms(treatmentPlanId)

  return (
    <S.PatientTreatmentPlan>
      {treatmentPlan.data && (
        <S.PatientTreatmentPlanLayout>
          <PatientTopBar />
          <S.TreatmentPlanWrapper>
            <PlanSummary
              treatmentPlan={treatmentPlan.data}
              refetch={treatmentPlan.get}
            />
          </S.TreatmentPlanWrapper>

          <S.TreatmentPlanWrapper>
            <PlanAppointments {...appointments} />
          </S.TreatmentPlanWrapper>

          <S.TreatmentPlanWrapper>
            <PlanAssignments
              {...assignments}
              treatmentPlanId={params.treatmentPlanId}
            />
          </S.TreatmentPlanWrapper>

          <S.TreatmentPlanWrapper>
            <PlanAnalysis {...analysis} />
          </S.TreatmentPlanWrapper>

          <S.TreatmentPlanWrapper>
            <PlanWellbeingScale {...wellbeingScale} />
          </S.TreatmentPlanWrapper>
        </S.PatientTreatmentPlanLayout>
      )}
    </S.PatientTreatmentPlan>
  )
}

export default PatientTreatmentPlan
