import { success } from '@bonliva-core/store'
import { useApi, AxiosError } from '../../index'
import { ITreater } from '../../types'
import store from '../store'

type IUpdateTreater = Pick<
  ITreater,
  | 'type'
  | 'name'
  | 'email'
  | 'phone'
  | 'bio'
  | 'title'
  | 'avatarId'
  | 'presentationId'
  | 'workplace'
  | 'languages'
  | 'specialistCompetences'
  | 'treatmentCategories'
  | 'termsAndConditionsAccepted'
  | 'privacyPolicyAccepted'
  | 'licensedAt'
  | 'physicalLocation'
  | 'completedOnboardingAt'
>

export const useUpdateTreaterProfile = () => {
  const api = useApi()
  const dispatch = store.useDispatch()

  return async (body: Partial<IUpdateTreater>) => {
    try {
      const treater = await api.put('/v1/treaters/me', body)
      dispatch(success(treater.data))
      return treater.data
    } catch (error) {
      const err = error as AxiosError
      if (!err.response) throw err
      dispatch(fail(err.response.data))
    }
  }
}
