import { Icon } from '@bonliva-traits/web-icons'
import { BodySmall } from '@bonliva-ui/web'
import styled from 'styled-components'

export const MediaInfoBar = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const FlexWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.margin['3xs']};
`

export const IconButton = styled(Icon)`
  height: 38px;
  width: 38px;
  background-color: ${({ theme }) => theme.colors.web.primary};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: 10px;
  color: ${({ theme }) => theme.colors.web.white};

  -webkit-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  &:hover {
    filter: brightness(125%);
  }
`

export const MediaInfoBarContent = styled.div`
  padding: ${({ theme }) => theme.margin['3xs']};
  margin-left: -2px;

  border: 1px solid ${({ theme }) => theme.colors.web.neutral}cc;
  border-radius: ${({ theme }) => theme.radius.rounded};
  display: grid;
  grid-template-columns: 1fr;
  column-gap: ${({ theme }) => theme.margin.s};
  row-gap: ${({ theme }) => theme.margin['xxs']};
  align-items: center;

  @media (min-width: 746px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1500px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`

export const MediaInfoBarLabel = styled(BodySmall)`
  font-weight: 400;
  line-height: 16px;
`

export const MediaInfoBarValue = styled(BodySmall)`
  line-height: 16px;
`
