import styled from 'styled-components'
import { Icon } from '@bonliva-traits/web-icons'
import { Link as ReactLink } from 'react-router-dom'
import {
  BodyRegular,
  BodySmall,
  ButtonPrimary,
  ButtonSecondary,
} from '@bonliva-ui/web'
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalWrapper,
} from '@bonliva-components/web/shared/Modal/Modal.style'

export const InboxModal = styled(ModalWrapper)``

export const Header = styled(ModalHeader)``

export const Title = styled(ModalTitle)``

export const Content = styled(ModalContent)``

export const Center = styled.div`
  height: 200px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ChevronRight = styled(Icon)`
  color: ${({ theme }) => theme.colors.web.primary};
  width: 12px;
  height: 12px;
  transition: 0.2s ease-in-out;

  @-moz-document url-prefix() {
    margin-bottom: 2px;
  }
`

export const Link = styled(ReactLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.web.primary};
  font-weight: 400;
  gap: ${({ theme }) => theme.margin['4xs']};

  ${ChevronRight} {
    transform: translate(0px, 0);
  }

  &:hover {
    ${ChevronRight} {
      transform: translate(4px, 0);
    }
  }
`

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: ${({ theme }) => theme.margin.xxs};
`

export const GridItem = styled.div`
  padding: ${({ theme }) => theme.margin['3xs']};
  border-bottom: 1px solid ${({ theme }) => theme.colors.web.light};
`

export const Label = styled(BodySmall)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.web.dark};
`

export const Value = styled(BodyRegular)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const Footer = styled(ModalFooter)``

export const Delete = styled(ButtonSecondary)`
  margin-right: auto;
`

export const Cancel = styled(ButtonPrimary)``

export const RefundButton = styled(ButtonPrimary)``
