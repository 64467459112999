import { useEffect } from 'react'
import { IAppointment } from '@bonliva-traits/api/types'
import useApi from '@bonliva-traits/hooks/useApiState'
import { BookingStatus } from '@bonliva-traits/api/enums'

export const useReferralPlanBookings = (referralPlanId?: string) => {
  const appointments = useApi<IAppointment[]>(`v1/treaters/referral-plans`)

  useEffect(() => {
    if (!referralPlanId) return
    const params = {
      status: [
        BookingStatus.Reserved,
        BookingStatus.Created,
        BookingStatus.Paid,
        BookingStatus.Completed,
      ],
      ['_sort']: 'b.start_date',
    }

    appointments.get({ params }, `${referralPlanId}/appointments`)
  }, [referralPlanId])

  return appointments
}
