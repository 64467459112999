import React from 'react'
import * as S from './LateCanceledBooking.style'
import { IBooking } from '@bonliva-traits/api/types'
import { format } from 'date-fns'
import { useToast } from '@bonliva-traits/web-toasts'
import useApiState from '@bonliva-traits/hooks/useApiState'
import SuccessModal from '@bonliva-components/web/shared/SuccessModal'
import ErrorModal from '@bonliva-components/web/shared/ErrorModal'

type Props = {
  booking: IBooking
  refreshReminders: () => void
}

const LateCanceledBooking: React.FC<Props> = (props) => {
  const toast = useToast()

  const refund = useApiState(
    `v1/treaters/appointments/${props.booking.id}/refund`
  )

  const acceptRefundHandler = async () => {
    try {
      const data = { isAccepted: true }
      await refund.post({ data })
      props.refreshReminders()

      toast.addToast({
        toast: (
          <SuccessModal
            title="Återbetalning genomförd"
            successTitle="Återbetalningen är genomförd och pengarna kommer att överföras till patientens bankkonto inom 3-5 arbetsdagar."
          />
        ),
      })
    } catch (error) {
      toast.addToast({
        toast: (
          <ErrorModal
            title="Något gick fel"
            errorTitle="Något gick fel när återbetalningen skulle göras."
          />
        ),
      })
    }
  }

  const declineRefundHandler = async () => {
    try {
      const data = { isAccepted: false }
      await refund.post({ data })
      props.refreshReminders()
      toast.addToast({
        toast: (
          <SuccessModal
            title="Återbetalning avslagen"
            successTitle="Återbetalningen är avslagen."
          />
        ),
      })
    } catch (error) {
      toast.addToast({
        toast: (
          <ErrorModal
            title="Något gick fel"
            errorTitle="Något gick fel när avslaget skulle göras."
          />
        ),
      })
    }
  }

  return (
    <S.LateCanceledBookingContainer>
      <S.ListItem>
        <div>
          <S.ListItemPatientName>
            {props.booking.patient?.name}
          </S.ListItemPatientName>

          <S.ListItemAppointmentCause>
            {props.booking.treatment?.category}
            {' - '}
            {format(new Date(props.booking.startDate), 'yyyy-MM-dd')}
            {' : '}
            {format(new Date(props.booking.startDate), 'HH:mm')} -{' '}
            {format(new Date(props.booking.endDate), 'HH:mm')}
          </S.ListItemAppointmentCause>
        </div>
      </S.ListItem>

      <S.Body>{props.booking.patient?.name} har gjort en sen avbokning.</S.Body>

      <S.Body>
        Anledning:{' '}
        {props.booking.notifications?.[0].metadata?.cancelReason ||
          'Patienten har inte angett någon anledning.'}
      </S.Body>

      <S.ButtonWrapper>
        <S.OutlineButton onClick={declineRefundHandler}>
          Nej, återbetala inte
        </S.OutlineButton>

        <S.Button onClick={acceptRefundHandler}>
          Ja, gör en återbetalning
        </S.Button>
      </S.ButtonWrapper>
    </S.LateCanceledBookingContainer>
  )
}

export default LateCanceledBooking
