import {
  BodyRegular,
  BodySmall,
  ButtonPrimary,
  ButtonSecondary,
  H4,
  TextArea,
} from '@bonliva-ui/web'
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalWrapper,
} from '@bonliva-components/web/shared/Modal/Modal.style'
import styled from 'styled-components'

export const NoMedicalRecordContainer = styled.div`
  width: 100%;
  min-height: 150px;
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: ${({ theme }) => theme.margin.xxs};
  border: 1px solid ${({ theme }) => theme.colors.web.light};
`

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.margin.xxs};
  flex-wrap: wrap;
  margin-bottom: 16px;
`

export const ListItemPatientName = styled(BodyRegular)`
  color: ${({ theme }) => theme.colors.web.darkest};
  font-weight: 500;
`

export const ListItemAppointmentCause = styled(BodySmall)``

export const ListItemAppointmentTime = styled(BodyRegular)``

export const Body = styled(BodySmall)``

export const ButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.margin.xxs};
  display: flex;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const ModalPadding = styled.div`
  padding: ${({ theme }) => theme.margin.xxs};
`

export const Button = styled(ButtonPrimary)``

export const OutlineButton = styled(ButtonSecondary)``

export const NoMedicalRecordModal = styled(ModalWrapper)``

export const Header = styled(ModalHeader)``

export const Title = styled(ModalTitle)``

export const Content = styled(ModalContent)``

export const Footer = styled(ModalFooter)``

export const MeetingNotepadInput = styled(TextArea)`
  resize: none;
  border: 1px solid ${({ theme }) => theme.colors.web.light};
  min-height: 200px;
  width: 100%;
`

export const MeetingNotepadButton = styled(ButtonPrimary)``

export const MeetingNotepadDropdownTitle = styled(H4)`
  margin-bottom: ${({ theme }) => theme.margin['3xs']};
`

export const MeetingNotepadDropdownWrapper = styled.div``
