import { useCallback } from 'react'
import { add, sub, startOfWeek, endOfWeek } from 'date-fns'
import { useApi } from '@bonliva-traits/api'
import { IAvailableTimes, IBooking } from '@bonliva-traits/api/types'
import { format } from 'date-fns'
import * as Enums from '@bonliva-traits/api/enums'

export type IInfo = {
  start: Date
  end: Date
  startStr: string
  endStr: string
  timeZone: string
}

export const useCalendarEvents = (type?: string) => {
  const api = useApi()

  return useCallback(async (info: IInfo) => {
    const params =
      type === 'month'
        ? {
            fromDate: sub(info.start, { months: 1 }).toISOString(),
            toDate: add(info.end, { months: 1 }).toISOString(),
          }
        : {
            fromDate: sub(startOfWeek(info.start, { weekStartsOn: 1 }), {
              weeks: 1,
            }).toISOString(),
            toDate: add(endOfWeek(info.end, { weekStartsOn: 1 }), {
              weeks: 1,
            }).toISOString(),
          }

    const appointments = await api.get<IBooking[]>(
      `v1/treaters/calendar/appointments`,
      { params }
    )

    const appointmentEvents = appointments.data.map((appointment) => ({
      id: appointment.id,
      title: appointment.patient?.name,
      start: new Date(appointment.startDate),
      end: new Date(appointment.endDate),
      display: 'block',
      extendedProps: {
        appointment,
        showPaymentStatus: true,
        isPaid:
          appointment.paymentProduct?.status ===
          Enums.PaymentProductStatus.CompletedPayment,
      },
    }))

    const workTImes = await api.get<IAvailableTimes[]>(
      '/v1/treaters/appointments/available-times',
      { params }
    )

    const backgroundEvents = workTImes.data
      .map((availableTime) =>
        availableTime.timeSlots.map((timeSlot) => ({
          id: format(new Date(timeSlot.startDate), 'dd-HH-mm') + 'background',
          groupId: 'availableTimes',
          start: new Date(timeSlot.startDate),
          end: new Date(timeSlot.endDate),
          display: 'background',
          extendedProps: { isBackground: true },
        }))
      )
      .flat()

    const events = [appointmentEvents, backgroundEvents].flat()

    return events
  }, [])
}
