import styled, { css } from 'styled-components'
import { Icon } from '@bonliva-traits/web-icons'
import { BodySmall } from '@bonliva-ui/web'

export const Inbox = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.margin.xxs};
`

export const RowText = styled(BodySmall)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const EmptyInbox = styled.tr`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 120px;
  padding: 0 ${({ theme }) => theme.margin.xxs};

  border-bottom: 0.5px solid ${({ theme }) => theme.colors.web.light};
`

export const TableRow = styled.tr<{ $isUnread: boolean }>`
  position: relative;
  padding: ${({ theme }) => theme.margin.xxs};

  display: grid;
  grid-template-columns: repeat(4, calc(25% - 16px));
  gap: ${({ theme }) => theme.margin.xxs};
  grid-template-rows: 1fr;
  height: 58px;
  background-color: ${({ theme }) => theme.colors.web.white}33;
  border-top: 0.5px solid ${({ theme }) => theme.colors.web.light};

  &:last-child {
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.web.light};
  }

  &:hover {
    background-color: ${({ theme }) => theme.colors.web.light}33;
    cursor: pointer;
  }

  ${({ $isUnread, theme }) =>
    $isUnread &&
    css`
      background-color: ${theme.colors.web.primaryLight}33;
      border-top: 0.5px solid ${theme.colors.web.primaryLight}66;

      &:hover {
        background-color: ${theme.colors.web.primary}33;
        cursor: pointer;
      }

      ${RowText} {
        font-weight: 400;
        color: ${({ theme }) => theme.colors.web.black};
      }
    `}
`

export const CheckBox = styled.div`
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.web.white};
  border: 1px solid ${({ theme }) => theme.colors.web.primary};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const FlexSpan = styled.td`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.margin.xxs};
  overflow: hidden;
`

export const Check = styled(Icon)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.web.primary};
  color: ${({ theme }) => theme.colors.web.white};
  padding: 3px;
`

export const BonlivaIcon = styled(Icon)`
  max-height: 26px;
  min-height: 26px;
  min-width: 26px;
  max-width: 26px;
  background-color: ${({ theme }) => theme.colors.web.primaryLight}77;
  color: ${({ theme }) => theme.colors.web.primary};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: 4px;

  display: none;

  @media (min-width: 982px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
