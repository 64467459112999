import React from 'react'
import { useTheme } from 'styled-components'
import * as S from './PlanAnalysis.style'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js'
import { Line } from 'react-chartjs-2'
import annotationPlugin from 'chartjs-plugin-annotation'
import { format } from 'date-fns'
import { ITreatmentForm } from '@bonliva-traits/api/types'
import Accordion from '../Accordion'

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  Filler,
  annotationPlugin
)

export const options = {
  responsive: true,
  scales: {
    y: {
      min: 0,
      max: 72,
      ticks: {
        stepSize: 9,
      },
    },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
      text: 'Chart.js Line Chart',
    },
    annotation: {
      annotations: [
        {
          scaleID: 'y',
          value: '45',
          borderColor: '#2A6DDD',
          borderWidth: 2,
          borderDash: [10, 10],
        },
      ],
    },
  },
}

type Props = {
  treatmentForms?: ITreatmentForm[]
}

const PlanAnalysis: React.FC<Props> = (props) => {
  const theme = useTheme()

  const filteredCompletedForms = props.treatmentForms?.filter(
    (form) => form.completedAt
  )

  const labels = filteredCompletedForms?.map((form) => {
    return format(new Date(form.completedAt || ''), 'yy-MM-dd')
  })

  const points = filteredCompletedForms?.map((form) => {
    return form.treatmentQuestionAnswers?.reduce((acc, { answer }) => {
      return acc + answer
    }, 0)
  })

  const data = {
    labels,
    datasets: [
      {
        data: points,
        borderColor: [theme.colors.web.primary],
        borderWidth: 3,
        fill: true,
        backgroundColor: [`${theme.colors.web.primary}34`],
        pointBackgroundColor: 'white',
      },
    ],
  }

  return (
    <Accordion title="Analys - Välmåendeskalan">
      <S.PlanAnalysis>
        {props.treatmentForms && props.treatmentForms.length >= 2 ? (
          <Line options={options} data={data} />
        ) : (
          <S.Body>
            Det finns inte tillräckligt med data för att visa analysen...
          </S.Body>
        )}
      </S.PlanAnalysis>
    </Accordion>
  )
}

export default PlanAnalysis
