import React from 'react'
import * as S from './Layout.style'
import Header from '../Header'
import Main from '../Main'
import Footer from '../Footer'
import SideMenu from '../SideMenu'

const Layout: React.FC<React.PropsWithChildren> = (props) => {
  return (
    <S.Layout>
      <Header />
      <SideMenu />
      <Main>{props.children}</Main>
      <Footer />
    </S.Layout>
  )
}

export default Layout
