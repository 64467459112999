import React, { useMemo } from 'react'
import * as S from './MediaModal.style'
import Modal from '../Modal/Modal'
import { IAssignment, IPatient } from '@bonliva-traits/api/types'
import { format } from 'date-fns'
import { getAssignmentCompletionRate } from '@bonliva-traits/api/assignments/helpers/getAssignmentCompletionRate'

type Props = {
  assignment: IAssignment
  patient?: IPatient
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const MediaModal: React.FC<Props> = (props) => {
  const { count, completed, isCompleted } = getAssignmentCompletionRate(
    props.assignment.data
  )

  const rows = useMemo(() => {
    return [
      {
        label: 'Patient',
        value: props.patient?.name,
      },
      {
        label: 'Status',
        value: isCompleted
          ? 'Avslutad'
          : completed !== 0
          ? 'Påbörjad'
          : 'Tilldelad',
      },
      {
        label: 'Antal övningar',
        value: count,
      },
      {
        label: 'Antal avklarade övningar',
        value: completed,
      },
      {
        label: 'Tilldelad',
        value: format(new Date(props.assignment.createdAt), 'yyyy-MM-dd'),
      },
      {
        label: 'Avslutad',
        value: props.assignment.completedAt
          ? format(new Date(props.assignment.completedAt), 'yyyy-MM-dd')
          : '-',
      },
    ]
  }, [props.assignment, props.patient, count, completed, isCompleted])

  return (
    <Modal.Wrapper isOpen={props.isOpen}>
      <Modal.Contents onDismiss={() => props.setIsOpen(false)}>
        <S.MediaModal>
          <Modal.CloseButton />

          <React.Fragment>
            <S.Header>
              <S.Title>{props.assignment.data?.title}</S.Title>
            </S.Header>

            <S.Content>
              <S.ContentInner>
                <S.ContentHeader>
                  <S.ContentDetail>Beskrivning</S.ContentDetail>

                  <S.ContentDescription>
                    {props.assignment.data.description}
                  </S.ContentDescription>
                </S.ContentHeader>
              </S.ContentInner>

              <S.Grid>
                {rows.map((row) => (
                  <S.GridItem key={row.label}>
                    <S.Label>{row.label}</S.Label>
                    <S.Value>{row.value}</S.Value>
                  </S.GridItem>
                ))}
              </S.Grid>
            </S.Content>
          </React.Fragment>

          <S.Footer>
            <Modal.DismissButton>
              <S.Close>Stäng</S.Close>
            </Modal.DismissButton>
          </S.Footer>
        </S.MediaModal>
      </Modal.Contents>
    </Modal.Wrapper>
  )
}

export default MediaModal
