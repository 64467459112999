import React, { useCallback } from 'react'
import * as S from './BankIdModal.style'
import useInterval from '@bonliva-traits/hooks/useInterval'
import Modal from '@bonliva-components/web/shared/Modal'
import Spinner from '@bonliva-components/web/shared/Spinner'
import Alert from '@bonliva-components/web/shared/Alert'
import QRCode from 'react-qr-code'
import { messages } from '@bonliva-auth/api/messages'
import { useAuth } from '@bonliva-auth/context'
import { AxiosError } from '@bonliva-traits/api'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  openOnThisDevice: boolean
  setOpenOnThisDevice: (openOnThisDevice: boolean) => void
}

const BankIdModal: React.FC<Props> = (props) => {
  const auth = useAuth()
  const [qrCode, setQrCode] = React.useState('')
  const [hasError, setHasError] = React.useState(false)
  const [isInactivated, setIsInactivated] = React.useState(false)

  const openBankIdHandler = useCallback(() => {
    window.open(auth.getBankIDLink(window.location.hash), '_self')
  }, [auth])

  useInterval(
    async () => {
      if (!props.isOpen) return
      try {
        const res = await auth.verify()
        if (res.messageCode === 'USERINACTIVATED') {
          setIsInactivated(true)
          return
        }
      } catch (error) {
        const err = error as AxiosError
        if (err.response?.status === 404) setHasError(true)
      }
    },
    props.isOpen && !hasError && !isInactivated ? 2000 : null
  )

  useInterval(
    async () => {
      props.isOpen && setQrCode((await auth.qrCode()).data.qrData)
    },
    !props.openOnThisDevice && !hasError && !isInactivated && props.isOpen
      ? 1000
      : null
  )

  const onDismissHandler = useCallback(() => {
    props.setIsOpen(false)
    props.setOpenOnThisDevice(false)
    setHasError(false)
    setIsInactivated(false)
  }, [])

  return (
    <Modal.Wrapper isOpen={props.isOpen}>
      <Modal.Contents maxWidth="600px" onDismiss={onDismissHandler}>
        <S.BankIdModal>
          <Modal.CloseButton />

          <S.BonlivaLogo src="Bonliva" />

          {props.openOnThisDevice ? (
            <S.Content>
              <S.Title>BankID på den här enheten</S.Title>
              <S.Label>{messages[auth.messageCode || '']}</S.Label>

              {hasError && (
                <Alert
                  type="error"
                  title="Det finns inget konto kopplat till detta BankId."
                />
              )}
              {isInactivated && (
                <Alert type="error" title="Denna användare är inaktiverad." />
              )}

              <S.Button onClick={openBankIdHandler}>Öppna BankID</S.Button>

              <S.Label align="center">Har du BankID på en annan enhet?</S.Label>

              <S.UnderlineLabel
                onClick={() => props.setOpenOnThisDevice(false)}
              >
                Klicka här för att komma till en QR-kod för inloggning
              </S.UnderlineLabel>
            </S.Content>
          ) : (
            <S.Content>
              <S.Title>Logga in med mobilt BankID</S.Title>
              <S.Label>{messages[auth.messageCode || '']}</S.Label>

              {hasError && (
                <Alert
                  type="error"
                  title="Det finns inget konto kopplat till detta BankId."
                />
              )}
              {isInactivated && (
                <Alert type="error" title="Denna användare är inaktiverad." />
              )}

              <S.QrWrapper>
                {qrCode ? <QRCode value={qrCode} size={208} /> : <Spinner />}
              </S.QrWrapper>

              <S.Label align="center">
                Har du BankID på den här enheten?
              </S.Label>

              <S.UnderlineLabel onClick={() => props.setOpenOnThisDevice(true)}>
                Logga in med BankID på den här enheten
              </S.UnderlineLabel>
            </S.Content>
          )}
        </S.BankIdModal>
      </Modal.Contents>
    </Modal.Wrapper>
  )
}

export default BankIdModal
