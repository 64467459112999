import styled from 'styled-components'
import {
  BodyRegular,
  BodySmall,
  ButtonPrimary,
  ButtonSecondary,
  H5,
  Input,
  TextArea,
} from '@bonliva-ui/web'
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalWrapper,
} from '@bonliva-components/web/shared/Modal/Modal.style'

export const EditProfileModal = styled(ModalWrapper)``

export const Header = styled(ModalHeader)``

export const Title = styled(ModalTitle)``

export const Content = styled(ModalContent)``

export const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const TextInput = styled(Input)`
  min-width: 100%;
`

export const SelectExperienceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(50% - 8px);
  min-width: 120px;
`

export const ColWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const Loading = styled.div`
  border-radius: 4px;
  z-index: 10;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.web.secondary};

  svg {
    background-color: ${({ theme }) => theme.colors.web.white}cc;
    border-radius: 9999px;
    padding: ${({ theme }) => theme.margin['3xs']};
    backdrop-filter: blur(12px);

    min-width: 36px;
    min-height: 36px;
  }

  background-color: ${({ theme }) => theme.colors.web.secondaryDark}cc;
  backdrop-filter: blur(2px);

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation: fadeIn 0.2s ease-in-out;
`

export const ImageWrapper = styled.div`
  position: relative;
  width: 112px;
  height: 112px;
  border-radius: 4px;
  aspect-ratio: 1 / 1;
  overflow: hidden;
`

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const NoImage = styled.img`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.web.secondary};
`

export const VideoWrapper = styled.div`
  position: relative;
  height: 200px;
  width: calc(200px * (16 / 9));
  border-radius: 4px;
  aspect-ratio: 16 / 9;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.radius.rounded};
`

export const Video = styled.video`
  height: 100%;
  width: 100%;
  object-fit: cover;
`

export const NoVideo = styled.div`
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.web.secondary};
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    min-width: 42px;
    min-height: 42px;
  }
`

export const Label = styled(H5)`
  color: ${({ theme }) => theme.colors.web.darkest};
  margin-left: ${({ theme }) => theme.margin['4xs']};
  margin-bottom: ${({ theme }) => theme.margin['4xs']};
`

export const ErrorMessage = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.error};
  margin-left: ${({ theme }) => theme.margin['4xs']};
  margin-bottom: ${({ theme }) => theme.margin['4xs']};
`

export const Body = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darkest};
  margin-left: ${({ theme }) => theme.margin['4xs']};
`

export const TextField = styled(TextArea)`
  width: 100%;
`

export const Footer = styled(ModalFooter)``

export const OutlineButton = styled(ButtonSecondary)``

export const Button = styled(ButtonPrimary)``

export const LocationSlideSelectWrapper = styled.div`
  position: relative;
  width: 100%;
`

export const LocationSuggestionWrapper = styled.div`
  background-color: white;
  padding: ${({ theme }) => theme.margin['3xs']};
  position: absolute;
  margin-top: ${({ theme }) => theme.margin['4xs']};
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.web.neutralLight};
  border-radius: ${({ theme }) => theme.radius.rounded};
  width: 100%;
  z-index: 100;
`

export const LocationSuggestion = styled(BodyRegular)`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.margin['3xs']};

  &:hover {
    background-color: ${({ theme }) => theme.colors.web.lightest};
  }
`

export const SecondaryButton = styled(ButtonSecondary)`
  width: fit-content;
`

export const Group = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.web.primary};
  padding: ${({ theme }) => theme.margin['3xs']};
  border-radius: ${({ theme }) => theme.radius.rounded};
`

export const DeleteButton = styled(ButtonSecondary)`
  margin-top: ${({ theme }) => theme.margin['3xs']};
  width: fit-content;
`

export const Suggestion = styled(BodyRegular)``
