import { useState, useMemo } from 'react'
import useApi from '@bonliva-traits/hooks/useApiState'
import { IAppointment } from '@bonliva-traits/api/types'
import { requestPagination } from '@bonliva-traits/utils'
import { BookingStatus } from '@bonliva-traits/api/enums'
import useInterval from '@bonliva-traits/hooks/useInterval'

export const useTreatmentPlanAppointments = (treatmentPlanId?: string) => {
  const [currentPage, setCurrentPage] = useState(0)
  const [pageSize] = useState(10)

  const appointments = useApi<IAppointment[]>(`v1/treaters/treatment-plans`)

  useInterval(
    () => {
      const params = {
        status: [
          BookingStatus.Created,
          BookingStatus.Paid,
          BookingStatus.Completed,
        ],
        ...requestPagination(currentPage, pageSize),
        ['_sort']: 'b.start_date',
      }

      appointments.get({ params }, `${treatmentPlanId}/appointments`)
    },
    60000,
    [treatmentPlanId, currentPage, pageSize]
  )

  const numberOfPages = useMemo(() => {
    if (!appointments.headers?.['x-total-count']) return 0
    const total = parseInt(appointments.headers['x-total-count'])
    return Math.ceil(total / pageSize)
  }, [appointments.data])

  return {
    currentPage,
    setCurrentPage,
    numberOfPages,
    appointments: appointments.data,
  }
}
