import styled from 'styled-components'

export const PostMeetingRoomNotepad = styled.div`
  background-color: white;
  height: 100%;
  width: 100%;
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: ${({ theme }) => theme.margin.xxs};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin['3xs']};
`
