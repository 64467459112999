import React from 'react'
import * as S from './ErrorModal.style'
import Modal from '../Modal'
import Alert from '../Alert'

type Props = {
  isOpen?: boolean
  setIsOpen?: (isOpen: boolean) => void
  title: string
  errorTitle: string
  content?: React.ReactNode
}

const ErrorModal: React.FC<Props> = (props) => {
  return (
    <Modal.Wrapper isOpen={props?.isOpen === undefined ? true : props.isOpen}>
      <Modal.Contents onDismiss={() => props.setIsOpen?.(false)}>
        <S.SuccessModal>
          <Modal.CloseButton />

          <S.Header>
            <S.Title>{props.title}</S.Title>
          </S.Header>

          <S.Content>
            <Alert type="error" title={props.errorTitle} />

            {props.content}
          </S.Content>

          <S.Footer>
            <Modal.DismissButton>
              <S.Cancel>Stäng</S.Cancel>
            </Modal.DismissButton>
          </S.Footer>
        </S.SuccessModal>
      </Modal.Contents>
    </Modal.Wrapper>
  )
}

export default ErrorModal
