import React from 'react'
import * as S from './PatientUpcomingMeetings.style'
import Spinner from '@bonliva-components/web/shared/Spinner'
import { useNavigate } from 'react-router-dom'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'
import {
  usePatient,
  useUpcomingAppointments,
} from '@bonliva-routes/web/special-routes/PatientRoute'

const PatientUpcomingMeetings: React.FC = () => {
  const patient = usePatient()
  const appointments = useUpcomingAppointments()
  const navigate = useNavigate()

  const navigateToAppointmentHandler = (appointmentId: string) => {
    navigate(`appointments/${appointmentId}`)
  }

  const numberOfAppointments = appointments.headers['x-total-count']

  return (
    <S.PatientUpcomingMeetings>
      <S.Header>
        <S.Title>
          Kommande samtal{' '}
          {!!numberOfAppointments && (
            <S.TitleNumber>({numberOfAppointments})</S.TitleNumber>
          )}
        </S.Title>

        <S.Link to="appointments">
          Visa alla samtal <S.ChevronRight src="ChevronRight" />
        </S.Link>
      </S.Header>

      <S.MeetingsList>
        {appointments.data?.map((appointment) => {
          const treatment = appointment.treatment

          return (
            <S.ListItem
              key={appointment.id}
              onClick={() => navigateToAppointmentHandler(appointment.id)}
            >
              <div>
                <S.ListItemDateName>
                  {format(
                    new Date(appointment.startDate),
                    'EEEE dd MMMM yyyy',
                    { locale: sv }
                  )}
                </S.ListItemDateName>

                <S.ListItemAppointmentCause>
                  {treatment?.category}
                  {' - '}
                  {format(new Date(appointment.startDate), 'HH:mm')} -{' '}
                  {format(new Date(appointment.endDate), 'HH:mm')}
                </S.ListItemAppointmentCause>
              </div>
            </S.ListItem>
          )
        })}

        {appointments.hasLoaded && appointments.data?.length === 0 && (
          <S.NoMeetingsMessage>
            Du har inga inbokade samtal med {patient.data?.name}
          </S.NoMeetingsMessage>
        )}

        {!appointments.data && appointments.isLoading && (
          <S.LoadingWrapper>
            <Spinner color="neutral" />
          </S.LoadingWrapper>
        )}
      </S.MeetingsList>
    </S.PatientUpcomingMeetings>
  )
}

export default PatientUpcomingMeetings
