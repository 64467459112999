import React from 'react'
import { useMeeting } from './context'
import {
  TreaterPlaceholder,
  TreaterPlaceholderCircleWrapper as TreaterPlaceholderName,
} from '../MeetingPublisher.style'

export const MeetingPublisher: React.FC = () => {
  const videoRef = React.useRef<HTMLVideoElement>(null)
  const {
    publisher,
    publishVideo,
    screenSharingStatus,
    screenSharePublisher,
    appointment,
  } = useMeeting()

  React.useEffect(() => {
    if (!videoRef.current || !publisher) return

    let _publisher = publisher

    if (screenSharingStatus === 'sharing' && screenSharePublisher) {
      _publisher = screenSharePublisher
    } else {
      _publisher = publisher
    }

    const videoTrack = _publisher.getVideoSource().track

    if (!videoTrack) return

    const mediaSteam = new MediaStream([videoTrack])
    videoRef.current.srcObject = mediaSteam
    videoRef.current.onloadedmetadata = () => {
      videoRef.current?.play()
    }
  }, [
    publisher,
    videoRef,
    publishVideo,
    screenSharingStatus,
    screenSharePublisher,
  ])

  const isScreenSharing =
    screenSharingStatus === 'sharing' && screenSharePublisher

  return (
    <div>
      <video
        style={{ transform: isScreenSharing ? '' : 'rotateY(180deg)' }}
        ref={videoRef}
      />
      {!publishVideo && (
        <TreaterPlaceholder>
          <TreaterPlaceholderName>
            {appointment?.treater?.name}
          </TreaterPlaceholderName>
        </TreaterPlaceholder>
      )}
    </div>
  )
}
