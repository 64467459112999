import { ButtonSecondary, H5, TextArea } from '@bonliva-ui/web'
import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.web.white};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: ${({ theme }) => theme.margin.xxs};
  width: 100%;
  display: flex;
  justify-content: space-between;
`

export const InfoAndCategoryFormWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const DeleteButton = styled(ButtonSecondary)``

export const CategoryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 282px;
`

export const NoteWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 282px;
`

export const Label = styled(H5)`
  color: ${({ theme }) => theme.colors.web.darkest};
  margin-left: ${({ theme }) => theme.margin['4xs']};
  margin-bottom: ${({ theme }) => theme.margin['4xs']};
`

export const TextInput = styled(TextArea)`
  width: 100%;
  height: 46px;
  max-height: 200px;
`
