import React, { useRef } from 'react'
import * as S from './Switch.style'

type Props = {
  label: string
  isChecked: boolean
  onChange: (isChecked: boolean) => void
  disabled?: boolean
}

const Switch: React.FC<Props> = (props) => {
  const ref = useRef<HTMLInputElement>(null)

  return (
    <S.Switch>
      <span>
        <S.SwitchInput
          ref={ref}
          type="checkbox"
          id={props.label}
          checked={props.isChecked}
          onChange={() => props.onChange(!props.isChecked)}
        />

        <S.SwitchSlider onClick={() => ref.current?.click()} />
      </span>

      <S.SwitchLabel htmlFor={props.label}>{props.label}</S.SwitchLabel>
    </S.Switch>
  )
}

export default Switch
