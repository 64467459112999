interface IUrl {
  url: string
}

interface ICalendarType extends IUrl {
  type: string
}

interface ICalendarDate extends IUrl {
  date: string
}

interface ICalendarTypeAndDate extends IUrl {
  type: string
  date: string
}

type ICalendarUrlParams =
  | IUrl
  | ICalendarType
  | ICalendarDate
  | ICalendarTypeAndDate

export const getCalendarUrlParams = (inputUrl: string): ICalendarUrlParams => {
  const dateRegex = /(\d{4}-\d{2}-\d{2})/g
  const typeRegex = /(week|month|day)/g

  const date = inputUrl.match(dateRegex)?.shift()
  const type = inputUrl.match(typeRegex)?.shift()
  const rest = inputUrl.replace(dateRegex, '').replace(typeRegex, '')
  const url =
    '/' +
    rest
      .split('/')
      .filter((i) => !!i)
      .join('/')

  return { url, type, date }
}

const isWithType = (i: ICalendarUrlParams): i is ICalendarType =>
  !!(i as ICalendarType).type

const isWithDate = (i: ICalendarUrlParams): i is ICalendarDate =>
  !!(i as ICalendarDate).date

const isWithTypeAndDate = (i: ICalendarUrlParams): i is ICalendarTypeAndDate =>
  !!(i as ICalendarTypeAndDate).date && !!(i as ICalendarTypeAndDate).type

export const isCalendarWith = {
  type: isWithType,
  date: isWithDate,
  typeAndDate: isWithTypeAndDate,
}
