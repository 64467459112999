import styled from 'styled-components'
import {
  BodyRegular,
  ButtonPrimary,
  ButtonSecondary,
  Input,
  TextArea,
} from '@bonliva-ui/web'
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalWrapper,
} from '@bonliva-components/web/shared/Modal/Modal.style'

export const FeedbackFormModal = styled(ModalWrapper)``

export const Header = styled(ModalHeader)``

export const Title = styled(ModalTitle)``

export const Content = styled(ModalContent)``

export const Label = styled(BodyRegular)`
  margin-left: ${({ theme }) => theme.margin['4xs']};
  margin-bottom: ${({ theme }) => theme.margin['4xs']};
`

export const TitleInput = styled(Input)`
  min-width: 100%;
`

export const DescriptionInput = styled(TextArea)`
  min-width: 100%;
  height: 300px;
  min-height: 100px;
`

export const Footer = styled(ModalFooter)``

export const Cancel = styled(ButtonSecondary)``

export const Send = styled(ButtonPrimary)``
