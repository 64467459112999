import React, { useEffect, useState, useCallback } from 'react'
import * as S from './CompetenceCard.style'
import useApiState from '@bonliva-traits/hooks/useApiState'
import { useTreaterProfile } from '@bonliva-traits/api/treater-profile'
import { ISpecialistCompetence } from '@bonliva-traits/api/types'
import EditCompetenceModal from '../EditCompetenceModal'

const CompetenceCard = () => {
  const profile = useTreaterProfile()
  const [showEditModal, setShowEditModal] = useState(false)

  const specialistCompetences = useApiState<ISpecialistCompetence[]>(
    '/v1/treater/specialist-competences'
  )

  useEffect(() => {
    specialistCompetences.get()
  }, [])

  const openEditModalHandler = useCallback(() => {
    setShowEditModal(true)
  }, [])

  return (
    <S.CompetenceCard>
      <S.Header>
        <S.Title>Kompetens</S.Title>
        <S.Button onClick={openEditModalHandler}>Redigera kompetenser</S.Button>
      </S.Header>
      <S.Content>
        <S.CompetenceList>
          {profile.data?.specialistCompetences?.map(
            ({ specialistCompetenceId }) => {
              if (!specialistCompetences.data) return

              const competence = specialistCompetences.data.find(
                ({ id }) => id === specialistCompetenceId
              )

              return (
                <S.Competence key={specialistCompetenceId}>
                  {competence?.name}
                </S.Competence>
              )
            }
          )}
        </S.CompetenceList>
      </S.Content>

      {showEditModal && (
        <EditCompetenceModal
          isOpen={showEditModal}
          setIsOpen={setShowEditModal}
        />
      )}
    </S.CompetenceCard>
  )
}

export default CompetenceCard
