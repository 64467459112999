import styled from 'styled-components'
import { BodySmall, ButtonPrimary, ButtonSecondary, H3 } from '@bonliva-ui/web'
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalWrapper,
} from '../Modal/Modal.style'
import { Icon } from '@bonliva-traits/web-icons'

export const ShareMediaModal = styled(ModalWrapper)``

export const Header = styled(ModalHeader)``

export const Title = styled(ModalTitle)``

export const Content = styled(ModalContent)``

export const ShareMediaSearchBar = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
  min-height: 36px;
  border-radius: ${({ theme }) => theme.radius.rounded};
`

export const ShareMediaSearchBarIcon = styled(Icon)`
  position: absolute;
  z-index: 1;
  color: ${({ theme }) => theme.colors.web.darkest};

  top: 50%;
  left: 10px;
  transform: translateY(-50%);

  height: 50%;
`

export const ShareMediaSearchBarClearIcon = styled(Icon)<{
  $hasValue: boolean
}>`
  opacity: 1;
  position: absolute;
  top: 50%;
  right: 10px;
  color: ${({ theme }) => theme.colors.web.darkest};
  transition: color 0.1s ease-in-out, opacity 0.1s ease-in-out;
  transform: translateY(-50%);
  height: 30%;
  z-index: 1;

  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.web.primary};
  }

  ${({ $hasValue }) => !$hasValue && 'opacity: 0;'}
`

export const ShareMediaSearchBarInput = styled.input`
  position: absolute;
  height: 100%;
  width: 100%;
  border: none;
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: ${({ theme }) => theme.margin['3xs']};
  padding-left: ${({ theme }) => theme.margin.m};
  padding-right: ${({ theme }) => theme.margin.m};
  background-color: ${({ theme }) => theme.colors.web.white};
  color: ${({ theme }) => theme.colors.web.darkest};
  border: 1px solid ${({ theme }) => theme.colors.web.neutral}cc;
  font-weight: 300;
`

export const ShareMediaList = styled.div``

export const MediaLibraryLayout = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const MediaLibraryContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: ${({ theme }) => theme.margin.xxs};
`

export const MediaLibrarySection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin['4xs']};
`

export const MediaLibraryTitle = styled(H3)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const MediaLibraryUnderline = styled.div`
  display: flex;
  width: 100%;
  height: 0.5px;
  background-color: ${({ theme }) => theme.colors.web.neutral};
  margin-bottom: ${({ theme }) => theme.margin['3xs']};
`

export const MediaLibraryLoading = styled.div`
  height: calc(100vh / 1.5);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MediaCardThumbnail = styled.img`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.radius.rounded};
`

export const MediaCardThumbnailOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.2s ease;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
`

export const MediaCard = styled.div`
  position: relative;
  width: 100%;
  min-height: 160px;
  position: relative;
  border-radius: ${({ theme }) => theme.radius.rounded};
  border: 1px solid ${({ theme }) => theme.colors.web.light};
  overflow: hidden;

  cursor: pointer;

  &:hover {
    ${MediaCardThumbnailOverlay} {
      opacity: 0.7;
    }
  }
`

export const MediaCardContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: ${({ theme }) => theme.margin.xxs};
`

export const MediaCardTitle = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.white};
  font-weight: 500;
`

export const CardWrapperOverlay = styled.div`
  background-color: #00000066;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;

  animation: fadeIn 0.1s ease-in-out;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`

export const CardWrapperCheck = styled(Icon)`
  height: 30px;
  width: 30px;
  background-color: ${({ theme }) => theme.colors.web.primary};
  color: ${({ theme }) => theme.colors.web.white};

  border-radius: 9999px;
  padding: ${({ theme }) => theme.margin['3xs']};
`

export const NoMediaFound = styled(BodySmall)`
  padding: ${({ theme }) => theme.margin.xxs};
  color: ${({ theme }) => theme.colors.web.darker};
  font-weight: 400;
  text-align: center;
`

export const Footer = styled(ModalFooter)``

export const OutlineButton = styled(ButtonSecondary)``

export const Button = styled(ButtonPrimary)``
