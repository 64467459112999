import { Icon } from '@bonliva-traits/web-icons'
import { BodySmall, ButtonPrimary, H2 } from '@bonliva-ui/web'
import styled, { css } from 'styled-components'

export const Table = styled.table`
  width: 100%;
  height: 100%;
`

export const TableWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.web.white};
  border-radius: ${({ theme }) => theme.radius.rounded};
`

export const TableHeader = styled.div`
  min-height: 72px;
  padding: ${({ theme }) => theme.margin.xxs};
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  column-gap: ${({ theme }) => theme.margin.xxs};
  row-gap: ${({ theme }) => theme.margin['4xs']};
`

export const TableHeaderTitle = styled(H2)`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.margin['3xs']};
`

export const SideContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.margin.xxs};
  height: 100%;
  width: 100%;

  @media (min-width: 768px) {
    width: auto;
  }
`

export const TableHeaderUnreadCount = styled.span`
  font-size: 16px;
  line-height: 26px;
  color: ${({ theme }) => theme.colors.web.dark};
  font-weight: 400;
`

export const TableHeaderSearch = styled.div`
  position: relative;
  width: 250px;
  height: 36px;
  border-radius: ${({ theme }) => theme.radius.rounded};
`

export const TableHeaderSearchIcon = styled(Icon)`
  position: absolute;
  z-index: 1;
  color: ${({ theme }) => theme.colors.web.darkest};

  top: 50%;
  left: 10px;
  transform: translateY(-50%);

  height: 50%;
`

export const TableHeaderSearchInput = styled.input`
  position: absolute;
  height: 100%;
  width: 100%;
  border: none;
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: ${({ theme }) => theme.margin['3xs']};
  padding-left: ${({ theme }) => theme.margin.m};
  background-color: ${({ theme }) => theme.colors.web.light}cc;
  color: ${({ theme }) => theme.colors.web.darkest};
  border: 1px solid ${({ theme }) => theme.colors.web.neutral};
  font-weight: 300;
  font-size: 14px;
`

export const TableHeaderButton = styled(ButtonPrimary)`
  display: flex;
  align-items: center;

  position: relative;
`

export const TableHeaderDeleteButton = styled(ButtonPrimary)`
  display: flex;
  align-items: center;

  position: relative;
`

export const TableHeaderDeleteIcon = styled(Icon)`
  height: 18px;
`

export const TableHead = styled.thead<{ $numberOfColumns: number }>`
  display: grid;
  gap: ${({ theme }) => theme.margin.xxs};
  grid-template-rows: 1fr;
  padding: ${({ theme }) => theme.margin.xxs};
  background-color: ${({ theme }) => theme.colors.web.secondaryLight};
  height: 46px;
  width: 100%;
  border-top: 0.5px solid ${({ theme }) => theme.colors.web.secondary}33;
  ${({ $numberOfColumns }) =>
    css`
      grid-template-columns: repeat(
        ${$numberOfColumns},
        calc(${`${100 / $numberOfColumns}%`} - 16px)
      );
    `}
`

export const TableHeadCell = styled.td`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const TableHeadCellSortIcon = styled(Icon)<{
  $isSelected: boolean
  $isAscending: boolean
}>`
  border-radius: 9999px;
  padding: ${({ theme }) => theme.margin['4xs']};
  border: 1px solid ${({ theme }) => theme.colors.web.secondary};
  min-height: 18px;
  min-width: 18px;
  color: ${({ theme }) => theme.colors.web.secondary};
  transform: ${({ $isAscending }) =>
    $isAscending ? 'rotate(180deg)' : 'rotate(0deg)'};
  opacity: ${({ $isSelected }) => ($isSelected ? 1 : 0.5)};

  transition: all 0.2s ease-in-out;
`

export const TableBody = styled.tbody`
  position: relative;
  display: block;
  width: 100%;
  min-height: 58px;
  border-top: 0.5px solid ${({ theme }) => theme.colors.web.neutral}99;
`

export const Loading = styled.tr`
  z-index: 10;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.web.secondary};

  svg {
    background-color: ${({ theme }) => theme.colors.web.white}cc;
    border-radius: 9999px;
    padding: ${({ theme }) => theme.margin['3xs']};
    backdrop-filter: blur(6px);

    min-width: 36px;
    min-height: 36px;
  }

  background-color: ${({ theme }) => theme.colors.web.secondary}33;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation: fadeIn 0.2s ease-in-out;
`

export const TableFooter = styled.tfoot`
  width: 100%;
  min-height: 46px;
`

export const TableFooterPagination = styled.td`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.margin['4xs']};
  padding: ${({ theme }) => theme.margin['4xs']};
`

export const TableFooterPaginationButton = styled.button<{
  disabled: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.margin.xxs};
  padding: ${({ theme }) => theme.margin.xxs};
  border: none;
  background-color: transparent;
  cursor: pointer;

  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
`

export const TableFooterPaginationButtonIcon = styled(Icon)`
  min-height: 24px;
  min-width: 24px;
  border-radius: 9999px;
  padding: 6px;
  border: 1px solid ${({ theme }) => theme.colors.web.dark};
  color: ${({ theme }) => theme.colors.web.dark};
`

export const TableFooterPaginationPage = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darkest};
`
