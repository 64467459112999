import styled from 'styled-components'
import { ButtonPrimary } from '@bonliva-ui/web'
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalWrapper,
} from '../Modal/Modal.style'

export const SuccessModal = styled(ModalWrapper)``

export const Header = styled(ModalHeader)``

export const Title = styled(ModalTitle)``

export const Content = styled(ModalContent)``

export const Footer = styled(ModalFooter)``

export const Cancel = styled(ButtonPrimary)``
