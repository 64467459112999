import React, { useState, useMemo } from 'react'
import * as S from './ShareAssignmentModal.style'
import { isExercise } from '@bonliva-traits/api/assignments/helpers/isExercise'
import { useMediaLibrary } from '@bonliva-traits/api/media-library'
import Spinner from '../Spinner/Spinner'
import Modal from '../Modal/Modal'
import useApiState from '@bonliva-traits/hooks/useApiState'
import {
  IAssignment,
  IExercise,
  IExerciseProgram,
  IPatient,
} from '@bonliva-traits/api/types'
import useCheckboxState from '@bonliva-traits/hooks/useCheckboxState'
import { isExerciseProgram } from '@bonliva-traits/api/assignments/helpers/isExerciseProgram'

type Props = {
  patient: IPatient
  treatmentPlanId?: string
  referralPlanId?: string
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  setSuccessModalOpen: (isOpen: boolean) => void
  refreshAssignmentsHandler?: () => void
}

const ShareAssignmentModal: React.FC<Props> = (props) => {
  const mediaLibrary = useMediaLibrary()
  const [searchBarValue, setSearchBarValue] = useState('')
  const [selectedMedia, setSelectedMedia] = useCheckboxState<
    IExercise | IExerciseProgram
  >([])

  const assignment = useApiState<IAssignment[]>('/v1/treaters/assignments')

  const filterSearchBarValue = (item: IExercise | IExerciseProgram) => {
    if (searchBarValue.length === 0) return true

    const searchValue = searchBarValue.toLowerCase()

    return (
      item?.title?.toLowerCase().includes(searchValue) ||
      item?.description?.toLowerCase().includes(searchValue)
    )
  }

  const shareMediaHandler = async () => {
    try {
      const formattedMedia = selectedMedia.map((media) => {
        if (isExercise(media)) {
          return { type: 'exercise', id: media.uuid }
        }

        if (isExerciseProgram(media)) {
          return { type: 'program', id: media.uuid }
        }
      })

      const data = {
        treatmentPlanId: props.treatmentPlanId,
        referralPlanId: props.referralPlanId,
        patientId: props.patient.id,
        assignments: formattedMedia,
      }

      await assignment.post({ data })

      props.setSuccessModalOpen(true)
      props.refreshAssignmentsHandler?.()
      props.setIsOpen(false)
    } catch (error) {
      throw new Error("Couldn't share media")
    }
  }

  const combined = mediaLibrary.data?.reduce(
    (acc, item) => {
      acc.exercises.push(...item.exercises.data.map((item) => item.attributes))
      acc.programs.push(
        ...item.exercisePrograms.data.map((item) => item.attributes)
      )
      return acc
    },
    {
      programs: [],
      exercises: [],
    } as { programs: IExerciseProgram[]; exercises: IExercise[] }
  )

  const filteredPrograms = useMemo(() => {
    return combined.programs.filter(filterSearchBarValue)
  }, [combined.programs, filterSearchBarValue])

  const filteredExercises = useMemo(() => {
    return combined.exercises.filter(filterSearchBarValue)
  }, [combined.exercises, filterSearchBarValue])

  return (
    <Modal.Wrapper isOpen={props.isOpen}>
      <Modal.Contents onDismiss={() => props.setIsOpen(false)}>
        <S.ShareMediaModal>
          <Modal.CloseButton />

          <S.Header>
            <S.Title>
              Dela övning eller program med {props.patient.name}
            </S.Title>
          </S.Header>

          <S.Content>
            <S.ShareMediaSearchBar>
              <S.ShareMediaSearchBarIcon src="Search" />

              <S.ShareMediaSearchBarClearIcon
                $hasValue={!!searchBarValue}
                src="Close"
                onClick={() => setSearchBarValue('')}
              />

              <S.ShareMediaSearchBarInput
                placeholder="Sök efter övning eller program"
                value={searchBarValue}
                onChange={(e) => setSearchBarValue(e.target.value)}
              />
            </S.ShareMediaSearchBar>

            <S.ShareMediaList>
              {searchBarValue.length > 0 ? (
                <S.MediaLibraryLayout>
                  {filteredPrograms.length > 0 && (
                    <S.MediaLibrarySection>
                      <S.MediaLibraryTitle>Program</S.MediaLibraryTitle>
                      <S.MediaLibraryUnderline />
                      <S.MediaLibraryContent>
                        {filteredPrograms.map((media) => {
                          const thumbnail = media.thumbnail.data?.attributes

                          const isChecked = selectedMedia.some(
                            (s) => s.uuid === media.uuid
                          )

                          const mediaCheckboxHandler = () => {
                            setSelectedMedia(media, 'uuid')
                          }

                          return (
                            <S.MediaCard
                              key={media.uuid}
                              onClick={mediaCheckboxHandler}
                            >
                              <S.MediaCardThumbnail
                                src={thumbnail?.url}
                                alt={thumbnail?.alternativeText}
                              />

                              <S.MediaCardThumbnailOverlay />

                              <S.MediaCardContent>
                                <S.MediaCardTitle>
                                  {media.title}
                                </S.MediaCardTitle>
                              </S.MediaCardContent>

                              {isChecked && (
                                <S.CardWrapperOverlay>
                                  <S.CardWrapperCheck src="Check" />
                                </S.CardWrapperOverlay>
                              )}
                            </S.MediaCard>
                          )
                        })}
                      </S.MediaLibraryContent>
                    </S.MediaLibrarySection>
                  )}

                  {filteredExercises.length > 0 && (
                    <S.MediaLibrarySection>
                      <S.MediaLibraryTitle>Övningar</S.MediaLibraryTitle>
                      <S.MediaLibraryUnderline />
                      <S.MediaLibraryContent>
                        {filteredExercises.map((media) => {
                          const thumbnail = media.thumbnail.data?.attributes

                          const isChecked = selectedMedia.some(
                            (s) => s.uuid === media.uuid
                          )

                          const mediaCheckboxHandler = () => {
                            setSelectedMedia(media, 'uuid')
                          }

                          return (
                            <S.MediaCard
                              key={media.uuid}
                              onClick={mediaCheckboxHandler}
                            >
                              <S.MediaCardThumbnail
                                src={thumbnail?.url}
                                alt={thumbnail?.alternativeText}
                              />

                              <S.MediaCardThumbnailOverlay />

                              <S.MediaCardContent>
                                <S.MediaCardTitle>
                                  {media.title}
                                </S.MediaCardTitle>
                              </S.MediaCardContent>

                              {isChecked && (
                                <S.CardWrapperOverlay>
                                  <S.CardWrapperCheck src="Check" />
                                </S.CardWrapperOverlay>
                              )}
                            </S.MediaCard>
                          )
                        })}
                      </S.MediaLibraryContent>
                    </S.MediaLibrarySection>
                  )}
                </S.MediaLibraryLayout>
              ) : (
                <S.MediaLibraryLayout>
                  {mediaLibrary.data.map((exerciseCategory) => {
                    const combined = [
                      ...exerciseCategory.exercisePrograms.data.map(
                        (exerciseCategory) => exerciseCategory.attributes
                      ),
                      ...exerciseCategory.exercises.data.map(
                        (exerciseCategory) => exerciseCategory.attributes
                      ),
                    ]

                    return (
                      <S.MediaLibrarySection key={exerciseCategory.uuid}>
                        <S.MediaLibraryTitle>
                          {exerciseCategory.title}
                        </S.MediaLibraryTitle>
                        <S.MediaLibraryUnderline />
                        <S.MediaLibraryContent>
                          {combined.map((media) => {
                            const thumbnail = media.thumbnail.data?.attributes
                            const isChecked = selectedMedia.some(
                              (s) => s.uuid === media.uuid
                            )

                            const mediaCheckboxHandler = () => {
                              setSelectedMedia(media, 'uuid')
                            }

                            return (
                              <S.MediaCard
                                key={media.uuid}
                                onClick={mediaCheckboxHandler}
                              >
                                <S.MediaCardThumbnail
                                  src={thumbnail?.url}
                                  alt={thumbnail?.alternativeText}
                                />

                                <S.MediaCardThumbnailOverlay />

                                <S.MediaCardContent>
                                  <S.MediaCardTitle>
                                    {media.title}
                                  </S.MediaCardTitle>
                                </S.MediaCardContent>

                                {isChecked && (
                                  <S.CardWrapperOverlay>
                                    <S.CardWrapperCheck src="Check" />
                                  </S.CardWrapperOverlay>
                                )}
                              </S.MediaCard>
                            )
                          })}
                        </S.MediaLibraryContent>
                      </S.MediaLibrarySection>
                    )
                  })}
                </S.MediaLibraryLayout>
              )}

              {!mediaLibrary.hasLoaded && mediaLibrary.isLoading && (
                <S.MediaLibraryLoading>
                  <Spinner color="neutral" />
                </S.MediaLibraryLoading>
              )}

              {searchBarValue.length > 0 &&
                filteredPrograms.length === 0 &&
                filteredExercises.length === 0 && (
                  <S.NoMediaFound>
                    Inga övningar eller program hittades med sökordet{' '}
                    {`"${searchBarValue}"`}
                  </S.NoMediaFound>
                )}
            </S.ShareMediaList>
          </S.Content>

          <S.Footer>
            <Modal.DismissButton>
              <S.OutlineButton>Stäng</S.OutlineButton>
            </Modal.DismissButton>

            <S.Button
              onClick={shareMediaHandler}
              disabled={!selectedMedia.length || assignment.isLoading}
            >
              {assignment.isLoading ? <Spinner /> : 'Dela'}
            </S.Button>
          </S.Footer>
        </S.ShareMediaModal>
      </Modal.Contents>
    </Modal.Wrapper>
  )
}

export default ShareAssignmentModal
