import React, { useCallback } from 'react'
import * as S from './CalendarHeader.style'
import { format } from 'date-fns'
import { DayHeaderContentArg } from '@fullcalendar/core'
import { sv } from 'date-fns/locale'
import { useLocation, useNavigate } from 'react-router-dom'
import { getCalendarUrlParams } from '../../utils/getCalendarUrlParams'

interface CalendarHeaderProps extends DayHeaderContentArg {
  disableOnDayClick: boolean
}

const CalendarHeader: React.FC<CalendarHeaderProps> = (props) => {
  const navigate = useNavigate()
  const location = useLocation()

  const onDateClickHandler = useCallback(() => {
    const formattedDate = format(props.date, 'yyyy-MM-dd')

    const calendarParams = getCalendarUrlParams(location.pathname)
    navigate(`${calendarParams.url}/day/${formattedDate}`)
  }, [location, navigate])

  if (props.view.type === 'timeGridDay')
    return (
      <S.CalendarDayHeaderWrapper $isToday={props.isToday}>
        <S.CalendarDayHeaderWrapperInner>
          <S.CalendarDayHeader>
            {format(props.date, 'EEE', { locale: sv })}
          </S.CalendarDayHeader>

          <S.CalendarDayHeaderDate>
            {format(props.date, 'd', { locale: sv })}
          </S.CalendarDayHeaderDate>
        </S.CalendarDayHeaderWrapperInner>
      </S.CalendarDayHeaderWrapper>
    )

  if (props.view.type === 'timeGridWeek')
    return (
      <S.CalendarWeekHeaderWrapper
        $isToday={props.isToday}
        onClick={!props.disableOnDayClick ? onDateClickHandler : undefined}
      >
        <S.CalendarWeekHeader>
          {format(props.date, 'EEE', { locale: sv })}
        </S.CalendarWeekHeader>

        <S.CalendarWeekHeaderDate>
          {format(props.date, 'd', { locale: sv })}
        </S.CalendarWeekHeaderDate>
      </S.CalendarWeekHeaderWrapper>
    )

  if (props.view.type === 'dayGridMonth')
    return (
      <S.CalendarMonthHeaderWrapper $isToday={props.isToday}>
        <S.CalendarMonthHeader>
          {format(props.date, 'EEE', { locale: sv })}
        </S.CalendarMonthHeader>
      </S.CalendarMonthHeaderWrapper>
    )

  return null
}

export default CalendarHeader
