import { BodySmall, H4 } from '@bonliva-ui/web'
import styled from 'styled-components'

export const PlanCard = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 248px;
  height: 100%;
  min-height: 212px;
  cursor: pointer;
`

export const CardHeaderWrapper = styled.div`
  width: 100%;
  height: 107px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.web.primaryLight};
  border-radius: 8px 8px 0 0;
  border: 1px solid ${({ theme }) => theme.colors.web.primary};
`
export const CardHeader = styled(H4)``

export const CardBodyWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 8px 8px;
  padding: ${({ theme }) => theme.margin.xxs};
  border: 1px solid ${({ theme }) => theme.colors.web.neutral}99;
`

export const ProgressBarWrapper = styled.div`
  margin-bottom: 8px;
`

export const ProgressInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`

export const StatusWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ProgressInfo = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.native.darkest};
`
