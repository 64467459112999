import React from 'react'
import * as S from './DashboardHeader.style'
import ProfilePicture from '@bonliva-components/web/shared/ProfilePicture'
import AnalogClock from '@bonliva-components/web/shared/AnalogClock'
import DigitalClock from '@bonliva-components/web/shared/DigitalClock'
import { useTreaterProfile } from '@bonliva-traits/api/treater-profile'

const DashboardHeader: React.FC = () => {
  const profile = useTreaterProfile()

  return (
    <S.DashboardHeader>
      <ProfilePicture profile={profile.data} height={130} width={130} />
      <S.DashboardHeaderTitle>
        Välkommen {profile.data.name}
      </S.DashboardHeaderTitle>
      <AnalogClock />
      <DigitalClock />
    </S.DashboardHeader>
  )
}

export default DashboardHeader
