import React, { useState, useCallback } from 'react'
import * as S from './Header.style'
import { Link } from 'react-router-dom'
import ProfileDropdown from '../ProfileDropdown'
import ProfilePicture from '../../shared/ProfilePicture'
import { useTreaterProfile } from '@bonliva-traits/api/treater-profile'
import { useNotifications } from '@bonliva-traits/api/notifications'
import { MeetingStatus, useMeeting } from '@bonliva-traits/meeting-web'

const Header: React.FC = () => {
  const { status: meetingStatus, appointment } = useMeeting()
  const profile = useTreaterProfile()
  const notifications = useNotifications()
  const [isOpen, setIsOpen] = useState(false)

  const openProfileMenuHandler = useCallback(() => {
    setIsOpen(true)
  }, [])

  const closeProfileMenuHandler = useCallback(() => {
    setIsOpen(false)
  }, [])

  return (
    <S.Header>
      <Link to="/">
        <S.BonlivaLogo src="Bonliva" />
      </Link>

      <S.Content>
        {meetingStatus === MeetingStatus.Connected && (
          <S.CallWrapper to={`/meeting-room/${appointment?.id}/meeting`}>
            <S.CallIcon src="Phone" />
            <span>Samtal pågår</span>
          </S.CallWrapper>
        )}
        <S.NotificationWrapper to={'/inbox'}>
          <S.Bell src="Bell" />
          {!!notifications.unreadCount && <S.NotificationDot />}
        </S.NotificationWrapper>

        <S.ProfileInfo onClick={openProfileMenuHandler}>
          <ProfilePicture profile={profile.data} width={26} height={26} />

          <S.ProfileName>{profile.data.name}</S.ProfileName>

          <S.ProfileDropdownChevron src="ChevronDown" $isOpen={isOpen} />
        </S.ProfileInfo>
      </S.Content>

      <ProfileDropdown onClose={closeProfileMenuHandler} isOpen={isOpen} />
    </S.Header>
  )
}

export default Header
