import styled, { css } from 'styled-components'

export const MeetingRoom = styled.div<{ $showNotepad: boolean }>`
  margin: 0 auto;
  min-width: 768px;
  overflow-x: hidden;
  transition: max-width 0.3s ease-in-out;

  ${({ $showNotepad }) =>
    $showNotepad
      ? css`
          max-width: 2320px;
        `
      : css`
          max-width: 1920px;
        `}
`

export const MeetingRoomLayout = styled.div`
  height: calc(100vh - 186px);
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin['3xs']};
  margin: ${({ theme }) => theme.margin.xxs};
  max-height: 1080px;
`

export const MeetingRoomContent = styled.div`
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: row;
  gap: ${({ theme }) => theme.margin.xxs};
  max-height: 65vh;
`

export const MeetingRoomBottomBar = styled.div`
  height: 64px;
  display: flex;
`

export const MeetingRoomVideoContainer = styled.div<{ $showNotepad: boolean }>`
  position: relative;
  min-width: 296px;
  width: ${({ $showNotepad }) =>
    $showNotepad ? 'calc(100% - 416px)' : '100%'};
  transition: width 0.3s ease-in-out;
`

export const MeetingRoomTreaterVideoWrapper = styled.div`
  position: absolute;
  bottom: 16px;
  right: 16px;
  border-radius: 4px;
  overflow: hidden;
  background-color: black;
  z-index: 10;
  max-width: 230px;
  width: 40%;

  video {
    width: 100%;
  }
`

export const MeetingRoomPatientVideoWrapper = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
  border-radius: ${({ theme }) => theme.radius.rounded};
  background-color: black;

  > div > div > div {
    height: 100%;
    width: 100%;
    position: absolute;
    border-radius: 8px;
    overflow: hidden;
  }
`
