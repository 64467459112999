import React, {
  useReducer,
  createContext,
  useContext,
  useCallback,
  useEffect,
} from 'react'
import { Action, State, Types } from './types'
import { IcdCode, IWebdocTemplate, Record } from '@bonliva-traits/api/types'
import useApiState from '@bonliva-traits/hooks/useApiState'
import { useApi } from '@bonliva-traits/api'
import { useAuth, Status as AuthStatus } from '@bonliva-auth/context'

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <WebdocNotesProvider>.')
}

const initialState: State = {
  webdocTemplates: undefined,
  webdocTemplate: undefined,
  showNotepad: false,
  notepadText: '',
  notes: undefined,
  selectedIcdCodes: [],
  setNote: stub,
  setSelectedIcdCodes: stub,
  setWebdocTemplate: stub,
  setShowNotepad: stub,
  setNotepadText: stub,
  saveToWebdoc: stub,
  unload: stub,
}

export const WebdocNotesContext = createContext(initialState)

export const useWebdocNotes = () => useContext(WebdocNotesContext)

export const WebdocNotesProvider: React.FC<React.PropsWithChildren> = (
  props
) => {
  const api = useApi()
  const { status: authStatus } = useAuth()
  const webdocTemplates = useApiState<ReadonlyArray<IWebdocTemplate>>(
    `v1/treaters/webdoc/templates`
  )

  const [state, dispatch] = useReducer((state: State, action: Action) => {
    switch (action.type) {
      case Types.UNLOAD:
        return { ...initialState, webdocTemplates: state.webdocTemplates }

      case Types.SET_WEBDOC_TEMPLATES:
        return { ...state, webdocTemplates: action.payload }
      case Types.SET_SHOW_NOTEPAD:
        return { ...state, showNotepad: action.payload }
      case Types.SET_WEBDOC_TEMPLATE:
        return {
          ...state,
          webdocTemplate: action.payload,
          notes: action.payload.keywords.map((keyword) => ({
            id: keyword.id,
            value: '',
            title: keyword.title,
            warnIfEmpty: keyword.warnIfEmpty,
          })),
        }
      case Types.SET_NOTEPAD_TEXT:
        return { ...state, notepadText: action.payload }
      case Types.SET_NOTE: {
        const newNotes = state.notes?.map((note) => {
          if (note.id !== action.payload.id) return note

          return { ...note, value: action.payload.note }
        })

        return { ...state, notes: newNotes }
      }
      case Types.SET_SELECTED_ICD_CODES:
        return { ...state, selectedIcdCodes: action.payload }
      default:
        return state
    }
  }, initialState)

  const unload = () => dispatch({ type: Types.UNLOAD })

  const setWebdocTemplate = (template: IWebdocTemplate) => {
    dispatch({ type: Types.SET_WEBDOC_TEMPLATE, payload: template })
  }

  const setShowNotepad = (show: boolean) => {
    dispatch({ type: Types.SET_SHOW_NOTEPAD, payload: show })
  }

  const setNotepadText = (text: string) => {
    dispatch({ type: Types.SET_NOTEPAD_TEXT, payload: text })
  }

  const setNote = (id: number, note: string) => {
    dispatch({ type: Types.SET_NOTE, payload: { note, id } })
  }

  const setSelectedIcdCodes = (icdCode: IcdCode[]) => {
    dispatch({ type: Types.SET_SELECTED_ICD_CODES, payload: icdCode })
  }

  const setWebdocTemplates = useCallback(async () => {
    const data = await webdocTemplates.get()
    dispatch({
      type: Types.SET_WEBDOC_TEMPLATES,
      payload: data,
    })
  }, [])

  useEffect(() => {
    if (authStatus === AuthStatus.Authenticated) {
      setWebdocTemplates()
    }
  }, [authStatus])

  useEffect(() => {
    if (!state.webdocTemplate) return

    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault()
      event.returnValue = 'Vill du verkligen stänga dina anteckningar?'
      return 'Du har osparade anteckningar. Vill du verkligen stänga?'
    }

    window.addEventListener('beforeunload', handler)

    return () => {
      window.removeEventListener('beforeunload', handler)
    }
  }, [state.webdocTemplate])

  const saveToWebdoc = useCallback(async (record: Record) => {
    await api.post(`v1/treaters/webdoc/templates`, record)
    dispatch({ type: Types.UNLOAD })
    setWebdocTemplates()
    return
  }, [])

  return (
    <WebdocNotesContext.Provider
      value={{
        webdocTemplates: state.webdocTemplates,
        webdocTemplate: state.webdocTemplate,
        showNotepad: state.showNotepad,
        notepadText: state.notepadText,
        notes: state.notes,
        selectedIcdCodes: state.selectedIcdCodes,
        setSelectedIcdCodes,
        setWebdocTemplate,
        setNotepadText,
        setShowNotepad,
        setNote,
        saveToWebdoc,
        unload,
      }}
    >
      {props.children}
    </WebdocNotesContext.Provider>
  )
}
