import React from 'react'
import * as S from '../InboxModal.style'
import { ITreaterNotification } from '@bonliva-traits/api/types'

type Props = {
  notification: ITreaterNotification
}

const MovedReferralPlan = (props: Props) => {
  return (
    <>
      <S.Header>
        <S.Title>Flyttad remiss</S.Title>

        {props.notification.user && (
          <S.Link to={`/patients/${props.notification.courier?.id}`}>
            Gå till patient <S.ChevronRight src="ChevronRight" />
          </S.Link>
        )}
      </S.Header>

      <S.Content>
        <S.Grid>
          <S.GridItem key="Name">
            <S.Label>Namn</S.Label>
            <S.Value>
              {props.notification.courier?.name || 'Bonliva Care'}
            </S.Value>
          </S.GridItem>
        </S.Grid>
      </S.Content>
    </>
  )
}

export default MovedReferralPlan
