import React, { useState } from 'react'
import * as S from './PlanTimeSlot.style'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'
import { BookingMeetingType } from '@bonliva-traits/api/enums'
import RadioButton from '../RadioButton/RadioButton'
import { useTreaterProfile } from '@bonliva-traits/api/treater-profile'
import { EventInput } from '@bonliva-traits/api/types'

type Props = {
  event: EventInput
  handleDeleteClick: (id: string) => void
  handleMeetingTypeClick: (
    meetingType: BookingMeetingType,
    e: EventInput
  ) => Promise<void>
}

const PlanTimeSlot = (props: Props) => {
  const [isDigital, setIsDigital] = useState<boolean>(
    props.event.extendedProps.appointment?.meetingType ===
      BookingMeetingType.Digital
      ? true
      : false
  )
  const treater = useTreaterProfile()

  const startTime = format(new Date(props.event.start), 'HH:mm', {
    locale: sv,
  })
  const endTime = format(new Date(props.event.end), 'HH:mm', {
    locale: sv,
  })

  const handleMeetingTypeClick = async (meetingType: BookingMeetingType) => {
    try {
      setIsDigital(meetingType === BookingMeetingType.Digital ? true : false)
      await props.handleMeetingTypeClick(meetingType, props.event)
    } catch (error) {
      setIsDigital((prev) => !prev)
    }
  }

  return (
    <S.CreatedBookingSlot>
      <S.RemoveButton
        src="Close"
        onClick={() => props.handleDeleteClick(props.event.id)}
      />

      <S.Time>
        {startTime} - {endTime}
      </S.Time>

      <S.Date>
        {format(new Date(props.event.start), 'dd MMMM yyyy', {
          locale: sv,
        })}
      </S.Date>

      <S.MeetingTypeWrapper>
        <RadioButton
          label="Digitalt"
          isSelected={isDigital}
          onClick={() => handleMeetingTypeClick(BookingMeetingType.Digital)}
        />
        <RadioButton
          label="Fysiskt"
          isSelected={!isDigital}
          onClick={() => handleMeetingTypeClick(BookingMeetingType.Physical)}
          disabled={!treater.data.physicalLocationId}
        />
      </S.MeetingTypeWrapper>
    </S.CreatedBookingSlot>
  )
}

export default PlanTimeSlot
