import React, { useCallback, useEffect, useState } from 'react'
import * as S from './OutlookIntegrationModal.style'
import Modal from '@bonliva-components/web/shared/Modal'
import Spinner from '@bonliva-components/web/shared/Spinner'
import { Images } from '@bonliva-ui/assets/images'
import { useIntegrationsService } from '../../services/integrations.service'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const OutlookIntegrationModal: React.FC<Props> = (props) => {
  const integrationsService = useIntegrationsService()

  const [connectMode, setConnectMode] = useState<'connect' | 'disconnect'>(
    'connect'
  )

  const connectHandler = useCallback(() => {
    switch (connectMode) {
      case 'connect':
        return integrationsService
          .requestIntegration(integrationsService.IntegrationTarget.Outlook)
          .then((alreadyConnected) => {
            if (!alreadyConnected) {
              integrationsService.getOutlookRegisterURL().then((url) => {
                window.location.href = url
              })
            } else {
              setConnectMode('disconnect')
            }
          })
      case 'disconnect':
        return integrationsService
          .closeIntegration(integrationsService.IntegrationTarget.Outlook)
          .then((ok) => {
            if (ok) {
              setConnectMode('connect')
            }
          })
    }
  }, [integrationsService, connectMode])

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    integrationsService.hasIntegration('outlook').then((has) => {
      if (!has) {
        setLoading(false)
        setConnectMode('connect')
      } else {
        setLoading(false)
        setConnectMode('disconnect')
      }
    })
  }, [integrationsService])

  const connectModeLabel = {
    connect: 'Anslut nu',
    disconnect: 'Avbryt anslutning',
  }

  return (
    <Modal.Wrapper isOpen={props.isOpen}>
      <Modal.Contents
        onDismiss={() => props.setIsOpen(false)}
        maxWidth={'60vw'}
        maxHeight={'100vh'}
        foregroundStyle={{
          borderColor: 'cornflowerblue',
          borderStyle: 'solid',
          borderWidth: 'thick',
        }}
      >
        <S.BookingModalWrapper>
          <S.Label></S.Label>
          <S.FlexCenter
            style={{
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'space-between',
              height: '48px',
            }}
          >
            <S.Greyed>
              <S.Bold style={{ fontSize: 20 }}>
                Anslut psykologvy till din Outlook kalender
              </S.Bold>
            </S.Greyed>
            <S.Bold style={{ fontSize: 18 }}>
              Se och administrera alla dina tider i Outlook
            </S.Bold>
          </S.FlexCenter>
          <S.FlexWrapper style={{ height: '128px' }}></S.FlexWrapper>
          <S.FlexCenter>
            <S.ConnectButton onClick={connectHandler}>
              {loading ? (
                <Spinner color="white" />
              ) : (
                connectModeLabel[connectMode]
              )}
            </S.ConnectButton>
          </S.FlexCenter>
          <S.FlexEndWrapper
            style={{ justifyContent: 'space-between', alignItems: 'center' }}
          >
            <img
              style={{ height: '100px' }}
              src={Images.MicrosoftOutlook}
              alt={'Microsoft Outlook Logo'}
            />
            <S.BonlivaLogo src="Bonliva" />
          </S.FlexEndWrapper>
        </S.BookingModalWrapper>
      </Modal.Contents>
    </Modal.Wrapper>
  )
}

export default OutlookIntegrationModal
