import styled from 'styled-components'
import { Icon } from '@bonliva-traits/web-icons'
import { Pressable, BodyRegular } from '@bonliva-ui/web'
import { Link } from 'react-router-dom'

export const Header = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  height: 56px;
  z-index: 100;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.web.white};
  padding: 0 ${({ theme }) => theme.margin.xxs};

  display: flex;
  justify-content: space-between;
  align-items: center;

  -webkit-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.2);

  border-bottom: 1px solid ${({ theme }) => theme.colors.web.light};
`

export const BonlivaLogo = styled(Icon)`
  margin-top: 6px;
  width: 200px;
  color: ${({ theme }) => theme.colors.web.logo};
`

export const Bell = styled(Icon)`
  position: relative;
  margin-top: 2px;
  width: 20px;
  height: 20px;
  color: ${({ theme }) => theme.colors.web.darker};
`

export const NotificationWrapper = styled(Link)`
  position: relative;
`

export const NotificationDot = styled.div`
  position: absolute;
  top: 0;
  right: 1px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.web.primary};
`

export const ProfileDropdownChevron = styled(Icon)<{ $isOpen: boolean }>`
  margin-top: 2px;
  color: ${({ theme }) => theme.colors.web.darker};
  width: 11px;
  height: 11px;
  transform: rotate(${({ $isOpen }) => ($isOpen ? '180deg' : '0deg')});
  transition: transform 0.2s ease-in-out;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.margin.xs};
`

export const ProfileName = styled(BodyRegular)`
  line-height: 0;
  color: ${({ theme }) => theme.colors.web.darker};
  transition: opacity 0.2s ease-in-out;
`

export const ProfileInfo = styled(Pressable)`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.margin.xxs};

  &:hover {
    ${ProfileName} {
      opacity: 0.6;
    }
  }
`

export const CallWrapper = styled(Link)`
  background-color: #4cd964;
  padding: 2px;
  border-radius: 14px;
  color: ${({ theme }) => theme.colors.web.white};
  text-decoration: none;
  display: flex;
  align-items: center;

  span {
    margin-left: 5px;
    margin-right: 5px;
  }
`

export const CallIcon = styled(Icon)`
  background-color: #3eb553;
  height: 24px;
  width: 24px;
  padding: 6px;
  border-radius: 12px;
`
