import styled, { css } from 'styled-components'
import { Icon } from '@bonliva-traits/web-icons'
import { BodyRegular, ButtonPrimary, ButtonSecondary } from '@bonliva-ui/web'
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalWrapper,
} from '@bonliva-components/web/shared/Modal/Modal.style'

export const DeviationsModal = styled(ModalWrapper)``

export const Header = styled(ModalHeader)``

export const Title = styled(ModalTitle)``

export const Content = styled(ModalContent)``

export const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-end;
  gap: ${({ theme }) => theme.margin.xxs};
`
export const FlexClickWrapper = styled.div`
  cursor: pointer;
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const SelectDayButton = styled(ButtonPrimary)<{ $isSelected: boolean }>`
  width: 46px;
  height: 46px;
  margin-top: -${({ theme }) => theme.margin['3xs']};
  cursor: default;

  &:hover {
    filter: brightness(100%);
  }

  ${({ $isSelected, theme }) =>
    $isSelected
      ? css`
          background-color: ${theme.colors.web.primary};
          color: ${theme.colors.web.white};
        `
      : css`
          background-color: ${theme.colors.web.white};
          color: ${theme.colors.web.primary};
          border: 1px solid ${theme.colors.web.primary}99;
        `}
`

export const SelectTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin['3xs']};
  width: calc(50% - 8px);
  min-width: 120px;
`

export const Label = styled(BodyRegular)`
  margin-left: ${({ theme }) => theme.margin['4xs']};
  line-height: 16px;
`

export const CheckboxLabel = styled(BodyRegular)`
  line-height: 16px;
`

export const Check = styled(Icon)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.web.primary};
  color: ${({ theme }) => theme.colors.web.white};
  padding: 3px;
`

export const Checkbox = styled.div`
  margin-left: ${({ theme }) => theme.margin['4xs']};
  width: 18px;
  height: 18px;
  min-height: 18px;
  min-width: 18px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.web.white};
  border: 1px solid ${({ theme }) => theme.colors.web.primary};
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;
`

export const Footer = styled(ModalFooter)``

export const Delete = styled(ButtonSecondary)`
  margin-right: auto;
`

export const Cancel = styled(ButtonSecondary)``

export const Create = styled(ButtonPrimary)``
