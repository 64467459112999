import { useApi } from '@bonliva-traits/api'

export const useIntegrationsService = () => {
  const api = useApi()

  const hasIntegration = async (target: string) => {
    const endpointURL = `/v3/integrations/has-integration/${target}`
    return api
      .get<boolean>(endpointURL)
      .then((result) => {
        return result.data
      })
      .catch((error) => {
        console.log('ERROR', error)
        throw error
      })
  }

  const requestIntegration = async (target: string) => {
    const endpointURL = `/v3/integrations/request-integration/${target}`
    return api
      .post<boolean>(endpointURL)
      .then((result) => {
        return result.data
      })
      .catch((error) => {
        console.log('ERROR', error)
        throw error
      })
  }

  const closeIntegration = async (target: string) => {
    const endpointURL = `/v3/integrations/cancel-integration/${target}`
    return api
      .delete(endpointURL)
      .then((result) => {
        return result.data
      })
      .catch((error) => {
        console.log('ERROR', error)
        throw error
      })
  }

  const getOutlookRegisterURL = async () => {
    const endpointURL = '/v3/msal/auth-code-url'
    return api
      .get<string>(endpointURL)
      .then((result) => {
        return result.data
      })
      .catch((error) => {
        console.log('ERROR', error)
        throw error
      })
  }

  enum IntegrationTarget {
    Outlook = 'outlook',
  }

  enum IntegrationStatus {
    Requested = 'requested',
    Connected = 'connected',
    Canceled = 'canceled',
  }

  return {
    hasIntegration,
    requestIntegration,
    closeIntegration,
    getOutlookRegisterURL,
    IntegrationTarget,
    IntegrationStatus,
  }
}
