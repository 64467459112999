import React, { useCallback, useState } from 'react'
import * as S from './MeetingRoomControls.style'
import { format } from 'date-fns'
import { useNavigate } from 'react-router-dom'
import { ScreenShareStatus, useMeeting } from '@bonliva-traits/meeting-web'
import { useWebdocNotes } from '@bonliva-traits/webdoc-notes'
import useInterval from '@bonliva-traits/hooks/useInterval'
import Spinner from '@bonliva-components/web/shared/Spinner'
import DeviceSelector from '../DeviceSelector'

const MeetingRoomControls = () => {
  const navigate = useNavigate()
  const [date, setDate] = useState(new Date())
  const {
    publishAudio,
    publishVideo,
    setPublishAudio,
    setPublishVideo,
    stopMeeting,
    appointment,
    screenSharingStatus,
    startScreenShare,
    stopScreenShare,
  } = useMeeting()
  const { showNotepad, setShowNotepad } = useWebdocNotes()

  useInterval(() => setDate(new Date()), 60000)

  const toggleMicHandler = () => {
    setPublishAudio(!publishAudio)
  }

  const toggleVideoHandler = () => {
    setPublishVideo(!publishVideo)
  }

  const toggleNotepadHandler = () => {
    setShowNotepad(!showNotepad)
  }

  const cancelMeetingHandler = useCallback(() => {
    if (!appointment?.id) {
      throw new Error('No appointment id')
    }

    stopMeeting(appointment.id)
    navigate(`/meeting-room/${appointment?.id}/post-meeting`)
  }, [navigate, appointment?.id, stopMeeting])

  const toggleScreenShare = () => {
    if (screenSharingStatus === ScreenShareStatus.NotSharing) {
      startScreenShare()
    } else {
      stopScreenShare()
    }
  }

  const treatmentPlanId = appointment?.treatmentPlanId

  const link = (
    <S.Link
      to={
        treatmentPlanId
          ? `/patients/${appointment.patientId}/treatment-plans/${treatmentPlanId}`
          : `/patients/${appointment?.patientId}`
      }
    >
      Gå till {treatmentPlanId ? 'behandlingsplan' : 'patient'}{' '}
      <S.ChevronRight src="ChevronRight" />
    </S.Link>
  )

  return (
    <S.MeetingRoomControls>
      <S.MeetingRoomTitleWrapper>
        <S.MeetingRoomTitle>{appointment?.patient?.name}</S.MeetingRoomTitle>

        <S.MeetingRoomTreatment>
          {appointment?.treatment?.category}
        </S.MeetingRoomTreatment>
      </S.MeetingRoomTitleWrapper>

      <S.MeetingRoomControlsContent>
        <DeviceSelector />

        <S.MeetingRoomControl $active={publishAudio} onClick={toggleMicHandler}>
          <S.MeetingRoomIcon src={publishAudio ? 'Mic' : 'NoMic'} />
        </S.MeetingRoomControl>

        <S.MeetingRoomControl
          $active={publishVideo}
          onClick={toggleVideoHandler}
        >
          <S.MeetingRoomIcon src={publishVideo ? 'Video' : 'NoVideo'} />
        </S.MeetingRoomControl>

        {screenSharingStatus !== ScreenShareStatus.NotSupported && (
          <S.MeetingRoomControl
            $active={screenSharingStatus === ScreenShareStatus.NotSharing}
            disabled={screenSharingStatus === ScreenShareStatus.Starting}
            onClick={toggleScreenShare}
          >
            {screenSharingStatus === ScreenShareStatus.Starting ? (
              <Spinner color="white" />
            ) : (
              <S.MeetingRoomIcon src="ShareScreen" />
            )}
          </S.MeetingRoomControl>
        )}

        <S.MeetingRoomControl
          $active={showNotepad}
          onClick={toggleNotepadHandler}
        >
          <S.MeetingRoomIcon src="Notepad" />
        </S.MeetingRoomControl>

        <S.MeetingRoomControl $close onClick={cancelMeetingHandler}>
          <S.MeetingRoomIcon src="Close" />
        </S.MeetingRoomControl>
      </S.MeetingRoomControlsContent>

      <S.MeetingRoomClock>
        {link}
        {format(date, 'HH:mm')}
      </S.MeetingRoomClock>
    </S.MeetingRoomControls>
  )
}

export default MeetingRoomControls
