import styled from 'styled-components'
import { BodyRegular, H2 } from '@bonliva-ui/web'

export const DigitalClock = styled.div`
  min-width: 140px;
`

export const TimeText = styled(H2)`
  font-weight: 400;
  font-size: 48px;
  margin-bottom: ${({ theme }) => theme.margin['4xs']};
  text-align: start;

  @media (min-width: 1200px) {
    text-align: center;
  }
`

export const DateText = styled(BodyRegular)`
  text-align: start;

  @media (min-width: 1200px) {
    text-align: center;
  }
`
