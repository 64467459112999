import React, { useState } from 'react'
import * as S from './PlanAssignments.style'
import { IAssignment } from '@bonliva-traits/api/types'
import { usePatient } from '@bonliva-routes/web/special-routes/PatientRoute'
import { Icon } from '@bonliva-traits/web-icons'
import { AssignmentStatus } from '@bonliva-traits/api/enums'
import Accordion from '../Accordion'
import ProgressBar from '../ProgressBar'
import ShareAssignmentModal from '../ShareAssignmentModal'
import SuccessModal from '../SuccessModal'
import MediaModal from '../MediaModal'
import { isExerciseProgram } from '@bonliva-traits/api/assignments/helpers/isExerciseProgram'
import { getAssignmentCompletionRate } from '@bonliva-traits/api/assignments/helpers/getAssignmentCompletionRate'
import { isExercise } from '@bonliva-traits/api/assignments/helpers/isExercise'

type Props = {
  assignments?: IAssignment[]
  numberOfPages: number
  currentPage: number
  setCurrentPage: (page: number) => void
  refreshAssignmentsHandler: () => void
  treatmentPlanId?: string
  referralPlanId?: string
}

const PlanAssignments: React.FC<Props> = (props) => {
  const patient = usePatient()
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [showShareMediaModal, setShowShareMediaModal] = useState(false)
  const [showAssignmentModal, setShowAssignmentModal] = useState(false)
  const [selected, setSelected] = useState<IAssignment>()

  return (
    <Accordion title="Övningar">
      <S.Content>
        {props.assignments?.map((assignment) => {
          const { count, completed } = getAssignmentCompletionRate(
            assignment.data
          )

          const openModal = () => {
            setSelected(assignment)
            setShowAssignmentModal(true)
          }

          return (
            <S.Assignment key={assignment.id} onClick={openModal}>
              {isExerciseProgram(assignment.data) && (
                <React.Fragment>
                  <S.ThumbnailWrapper>
                    <S.Thumbnail
                      src={assignment.data.thumbnail.data?.attributes.url}
                      alt={assignment.data.thumbnail.data?.attributes.name}
                    />

                    <S.ThumbnailOverlay />

                    <S.ThumbnailDetail>
                      <span>Program</span>
                      {' • '}
                      <span>
                        {count} {count && count > 1 ? 'övningar' : 'övning'}
                      </span>
                      {' • '}
                      <span>{assignment.data.timeEstimation} min</span>
                    </S.ThumbnailDetail>

                    <S.ThumbnailTitle>{assignment.data.title}</S.ThumbnailTitle>
                  </S.ThumbnailWrapper>

                  <S.AssignmentContent>
                    <ProgressBar
                      count={count || 0}
                      completed={completed || 0}
                    />
                    <S.ProgressBarTextWrapper>
                      <S.ProgressBarText>
                        {assignment.status === AssignmentStatus.Completed
                          ? 'Avslutad'
                          : assignment.status === AssignmentStatus.Ongoing
                          ? 'Påbörjad'
                          : 'Tilldelad'}
                      </S.ProgressBarText>

                      <S.ProgressBarText>
                        {completed &&
                          completed &&
                          (completed / completed) * 100}
                        %
                      </S.ProgressBarText>
                    </S.ProgressBarTextWrapper>
                  </S.AssignmentContent>
                </React.Fragment>
              )}

              {isExercise(assignment.data) && (
                <React.Fragment>
                  <S.ThumbnailWrapper>
                    <S.Thumbnail
                      src={assignment.data.thumbnail.data?.attributes.url}
                      alt={assignment.data.thumbnail.data?.attributes.name}
                    />

                    <S.ThumbnailOverlay />

                    <S.ThumbnailDetail>
                      <span>Övning</span>
                      {' • '}
                      <span>
                        {count} {count && count > 1 ? 'delar' : 'del'}
                      </span>
                      {' • '}
                      <span>{assignment.data.timeEstimation} min</span>
                    </S.ThumbnailDetail>

                    <S.ThumbnailTitle>{assignment.data.title}</S.ThumbnailTitle>
                  </S.ThumbnailWrapper>

                  <S.AssignmentContent>
                    <ProgressBar
                      count={count || 0}
                      completed={completed || 0}
                    />
                    <S.ProgressBarTextWrapper>
                      <S.ProgressBarText>
                        {assignment.status === AssignmentStatus.Completed
                          ? 'Avslutad'
                          : assignment.status === AssignmentStatus.Ongoing
                          ? 'Påbörjad'
                          : 'Tilldelad'}
                      </S.ProgressBarText>

                      <S.ProgressBarText>
                        {completed && count && (completed / count) * 100}%
                      </S.ProgressBarText>
                    </S.ProgressBarTextWrapper>
                  </S.AssignmentContent>
                </React.Fragment>
              )}
            </S.Assignment>
          )
        })}

        <S.PlanAddCard onClick={() => setShowShareMediaModal(true)}>
          <Icon src="Plus" style={{ marginBottom: '7px' }} />
          <S.ReferralPlanAddHeader>Lägg till övning</S.ReferralPlanAddHeader>
        </S.PlanAddCard>
      </S.Content>

      {props.numberOfPages > 1 && (
        <S.Footer>
          <S.FooterPagination>
            <S.FooterPaginationButton
              onClick={() => props.setCurrentPage(props.currentPage - 1)}
              disabled={props.currentPage <= 0}
            >
              <S.FooterPaginationButtonIcon src="ChevronLeft" />
            </S.FooterPaginationButton>

            <S.FooterPaginationPage>
              {props.currentPage + 1} / {props.numberOfPages}
            </S.FooterPaginationPage>

            <S.FooterPaginationButton
              onClick={() => props.setCurrentPage(props.currentPage + 1)}
              disabled={props.currentPage >= props.numberOfPages - 1}
            >
              <S.FooterPaginationButtonIcon src="ChevronRight" />
            </S.FooterPaginationButton>
          </S.FooterPagination>
        </S.Footer>
      )}

      {showAssignmentModal && selected && (
        <MediaModal
          patient={patient.data}
          assignment={selected}
          isOpen={showAssignmentModal}
          setIsOpen={setShowAssignmentModal}
        />
      )}

      {showShareMediaModal && patient.data && (
        <ShareAssignmentModal
          patient={patient.data}
          referralPlanId={props.referralPlanId}
          treatmentPlanId={props.treatmentPlanId}
          isOpen={showShareMediaModal}
          setIsOpen={setShowShareMediaModal}
          setSuccessModalOpen={setShowSuccessModal}
          refreshAssignmentsHandler={props.refreshAssignmentsHandler}
        />
      )}

      {showSuccessModal && (
        <SuccessModal
          isOpen={showSuccessModal}
          setIsOpen={setShowSuccessModal}
          title="Övning tilldelad"
          successTitle={`Övningen är nu tilldelad till ${patient.data?.name}.`}
        />
      )}
    </Accordion>
  )
}

export default PlanAssignments
