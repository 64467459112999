import React, { useMemo } from 'react'
import * as S from './SideMenu.style'
import { useLocation } from 'react-router-dom'
import { IconType } from '@bonliva-traits/web-icons'
import { useNotifications } from '@bonliva-traits/api/notifications'
import { useMediaFavorites } from '@bonliva-traits/api/media-favorites'

type Link = {
  name: string
  path: string
  extended?: boolean
  ignoreExtendedPath?: string[]
  icon: keyof typeof IconType
  activeIcon: keyof typeof IconType
  notifications?: number
}

const SideMenu: React.FC = () => {
  const location = useLocation()
  const pathname = useMemo(() => location.pathname, [location])
  const mediaFavorites = useMediaFavorites()
  const notifications = useNotifications()

  const links: Link[] = useMemo(
    () => [
      {
        name: 'Hem',
        path: '/',
        icon: IconType.Home,
        activeIcon: IconType.HomeFilled,
      },
      {
        name: 'Inkorg',
        path: '/inbox',
        icon: IconType.Inbox,
        activeIcon: IconType.InboxFilled,
        notifications: notifications.unreadCount,
      },
      {
        name: 'Kalender',
        path: '/calendar',
        extended: true,
        icon: IconType.Calender,
        activeIcon: IconType.CalenderFilled,
      },
      {
        name: 'Arbetstider',
        path: '/work-times',
        icon: IconType.Clock,
        activeIcon: IconType.ClockFilled,
      },
      {
        name: 'Patienter',
        path: '/patients',
        extended: true,
        icon: IconType.Patients,
        activeIcon: IconType.PatientsFilled,
      },
      {
        name: 'Mitt konto',
        path: '/account',
        icon: IconType.Profile,
        activeIcon: IconType.ProfileFilled,
      },
      {
        name: 'Mediebibliotek',
        path: '/media-library',
        extended: true,
        ignoreExtendedPath: ['/favorites'],
        icon: IconType.MediaLibrary,
        activeIcon: IconType.MediaLibraryFilled,
      },
      {
        name: `Favoriter (${mediaFavorites.count})`,
        path: '/media-library/favorites',
        icon: IconType.Heart,
        activeIcon: IconType.HeartFilled,
      },
      {
        name: 'Frågor & svar',
        path: '/questions-and-answers',
        extended: true,
        icon: IconType.QuestionAnswer,
        activeIcon: IconType.QuestionAnswerFilled,
      },
    ],
    [mediaFavorites.count, notifications.unreadCount]
  )

  return (
    <S.SideMenu>
      {links.map((link) => {
        const isActive = () => {
          if (!link.extended) return pathname === link.path
          if (!link.ignoreExtendedPath) return pathname.includes(link.path)
          if (pathname.includes(link.path)) {
            return !link.ignoreExtendedPath.some((path) =>
              pathname.includes(path)
            )
          }
          return false
        }

        const isSubScreen = link.path.split('/').length > 2
        const notificationAmount = link.notifications
          ? link.notifications > 99
            ? '99+'
            : link.notifications
          : undefined

        return (
          <S.SideMenuLink
            key={link.path}
            to={link.path}
            $isActive={isActive()}
            $isSubScreen={isSubScreen}
          >
            <S.SideMenuIcon src={isActive() ? link.activeIcon : link.icon} />
            <S.SideMenuText>{link.name}</S.SideMenuText>

            {notificationAmount && (
              <S.SideMenuNotification>
                {notificationAmount}
              </S.SideMenuNotification>
            )}
          </S.SideMenuLink>
        )
      })}
    </S.SideMenu>
  )
}

export default SideMenu
