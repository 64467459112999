import styled from 'styled-components'
import {
  BodySmall,
  BodyXSmall,
  ButtonPrimary,
  ButtonSecondary,
  H2,
  H4,
} from '@bonliva-ui/web'
import { Icon } from '@bonliva-traits/web-icons'

export const CreateBookingsCalendar = styled.div`
  background-color: ${({ theme }) => theme.colors.web.white};
  border-radius: ${({ theme }) => theme.radius.rounded};
`

export const CreateBookingsHeader = styled.div`
  padding: ${({ theme }) => theme.margin.xxs};
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  column-gap: ${({ theme }) => theme.margin.xxs};
  row-gap: ${({ theme }) => theme.margin['4xs']};
`

export const CreateBookingsHeaderTitle = styled(H2)`
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.margin['3xs']};
`

export const CreateBookingsHeaderButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const InfoNote = styled(BodySmall)`
  margin-left: ${({ theme }) => theme.margin.xxs};
  margin-bottom: ${({ theme }) => theme.margin['3xs']};
  font-style: italic;
`

export const CreatedBookings = styled.div`
  overflow: hidden;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-top: ${({ theme }) => theme.margin.xxs};
  gap: ${({ theme }) => theme.margin['3xs']};
`

export const CreatedBookingsTitle = styled(H4)`
  color: ${(props) => props.theme.colors.web.darkest};
  margin-top: 4px;
`

export const CreatedBookingsWrapper = styled.div`
  max-height: 50%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.xxs};
  border-top: 1px solid ${({ theme }) => theme.colors.web.neutral}58;
  padding-top: ${({ theme }) => theme.margin.xxs};
  padding-bottom: ${({ theme }) => theme.margin.s};
  padding-right: 16px;
  padding-left: 4;
`

export const CreatedBookingSlot = styled.div`
  position: relative;
  height: 56px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.radius.rounded};
  border: 1px dotted ${({ theme }) => theme.colors.web.secondary};
  background-color: ${({ theme }) => theme.colors.web.secondaryLight};
  padding: ${({ theme }) => theme.margin['3xs']};
`

export const RemoveButton = styled(Icon)`
  cursor: pointer;
  position: absolute;
  color: ${({ theme }) => theme.colors.web.darkest};
  height: 20px;
  width: 20px;
  top: 4px;
  right: 4px;

  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.web.primary};
  }
`

export const Time = styled(BodyXSmall)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const Date = styled(BodyXSmall)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.web.black};
`

export const Body = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const AbortButton = styled(ButtonSecondary)`
  margin-right: ${({ theme }) => theme.margin.xxs};
`

export const SaveButton = styled(ButtonPrimary)``

export const MeetingType = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darkest};
  font-style: italic;
  margin-top: -8px;
`
