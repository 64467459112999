import React, { useEffect, useCallback, useState, useMemo } from 'react'
import * as S from './PatientAppointment.style'
import { useParams } from 'react-router-dom'
import { usePatient } from '@bonliva-routes/web/special-routes/PatientRoute'
import { useTreaterProfile } from '@bonliva-traits/api/treater-profile'
import { IAppointment, ITreatmentFormAnswers } from '@bonliva-traits/api/types'
import { format } from 'date-fns'
import useApiState from '@bonliva-traits/hooks/useApiState'
import PatientTopBar from '@bonliva-components/web/shared/PatientTopBar'
import Table from '@bonliva-components/web/shared/Table'
import SuccessModal from '@bonliva-components/web/shared/SuccessModal'

const tableColumns = [
  {
    label: 'Datum',
  },
  {
    label: 'Tid',
  },
  {
    label: 'Orsak',
  },
  {
    label: 'Psykolog',
  },
]

const PatientAppointments: React.FC = () => {
  const params = useParams<{ appointmentId: string }>()
  const patient = usePatient()
  const profile = useTreaterProfile()

  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const treatmentForm = useApiState(`v2/treaters/treatment-forms`)
  const appointment = useApiState<IAppointment>(`/v1/treaters/appointments`)
  const formQuestions = useApiState<ITreatmentFormAnswers>(
    `/v1/treaters/treatment-form/booking`
  )

  const fetchAppointmentHandler = useCallback(() => {
    appointment.get({}, params.appointmentId)
  }, [])

  const fetchFormQuestionsHandler = useCallback(() => {
    formQuestions.get({}, `${params.appointmentId}/answers`)
  }, [])

  const sendTreatmentFormHandler = useCallback(async () => {
    const bookingId = appointment.data?.id

    try {
      await treatmentForm.post({ data: { bookingId } }, ``)
      setShowSuccessModal(true)
      fetchAppointmentHandler()
    } catch (error) {
      throw new Error("Couldn't send treatment form")
    }
  }, [appointment.data])

  useEffect(() => {
    fetchAppointmentHandler()
  }, [])

  useEffect(() => {
    if (!appointment.data?.treatmentForm) return
    fetchFormQuestionsHandler()
  }, [appointment.data])

  const treatmentFormQuestionMap = useMemo(
    () => ['Aldrig', 'Sällan', 'Ibland', 'Ofta', 'Alltid'],
    []
  )

  return (
    <S.PatientAppointments>
      <S.PatientAppointmentsLayout>
        <PatientTopBar />
      </S.PatientAppointmentsLayout>

      {appointment.data && (
        <S.PatientAppointmentTableWrapper>
          <Table.Wrapper
            header={
              <Table.Header
                title={`Samtal - ${format(
                  new Date(appointment.data.startDate),
                  'yyyy-MM-dd'
                )}`}
                showButton={!appointment.data.treatmentForm}
                buttonTitle="Skicka ut hälsoformulär"
                buttonOnClick={sendTreatmentFormHandler}
              />
            }
            head={<Table.Head columns={tableColumns} />}
            body={
              <Table.Body isLoading={appointment.isLoading}>
                <S.TableRow>
                  <S.FlexSpan>
                    <S.RowText>
                      {format(
                        new Date(appointment.data?.startDate),
                        'yyyy-MM-dd'
                      )}
                    </S.RowText>
                  </S.FlexSpan>

                  <S.FlexSpan>
                    <S.RowText>
                      {format(new Date(appointment.data?.startDate), 'HH:mm')}
                      {' - '}
                      {format(new Date(appointment.data?.endDate), 'HH:mm')}
                    </S.RowText>
                  </S.FlexSpan>

                  <S.FlexSpan>
                    <S.RowText>
                      {appointment.data?.treatment?.category}
                    </S.RowText>
                  </S.FlexSpan>

                  <S.FlexSpan>
                    <S.RowText>{profile.data.name}</S.RowText>
                  </S.FlexSpan>
                </S.TableRow>
              </Table.Body>
            }
          />
        </S.PatientAppointmentTableWrapper>
      )}

      {!!appointment.data?.treatmentForm && (
        <S.TreatmentFormWrapper>
          <S.FormAnswers>
            <S.FormAnswersHeader>
              <S.FormAnswersTitle>Hälsoformulär</S.FormAnswersTitle>

              <S.FormAnswersDescription>
                Svarsalternativ:
                <S.FormAnswersTypes>
                  {treatmentFormQuestionMap.join(', ')}
                </S.FormAnswersTypes>
              </S.FormAnswersDescription>
            </S.FormAnswersHeader>

            {formQuestions.data?.questions.map((item, i) => (
              <S.FormItem key={item.question}>
                <S.FormItemLabel>Fråga: {++i} / 12</S.FormItemLabel>
                <S.FormItemQuestion>{item.question}</S.FormItemQuestion>
                <S.FormItemAnswer>
                  Svar: {treatmentFormQuestionMap[item.answer]}
                </S.FormItemAnswer>
              </S.FormItem>
            ))}

            {!formQuestions.data && (
              <S.FormItem>
                <S.FormItemLabel>
                  Patienten har inte svarat ännu
                </S.FormItemLabel>
              </S.FormItem>
            )}
          </S.FormAnswers>
        </S.TreatmentFormWrapper>
      )}

      {showSuccessModal && (
        <SuccessModal
          isOpen={showSuccessModal}
          setIsOpen={setShowSuccessModal}
          title="Hälsoformulär skickat"
          successTitle={`Hälsoformuläret har skickats till ${patient.data?.name}`}
        />
      )}
    </S.PatientAppointments>
  )
}

export default PatientAppointments
