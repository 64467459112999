import React, { useState, useEffect, useMemo, useCallback } from 'react'
import * as S from './Inbox.style'
import useApiState from '@bonliva-traits/hooks/useApiState'
import useCheckboxState from '@bonliva-traits/hooks/useCheckboxState'
import { useDebouncedCallback } from 'use-debounce'
import { format } from 'date-fns'
import { ITreaterNotification } from '@bonliva-traits/api/types'
import { requestPagination } from '@bonliva-traits/utils'
import { TreaterNotificationType } from '@bonliva-traits/api/enums'
import Table from '@bonliva-components/web/shared/Table'
import Alert from '@bonliva-components/web/shared/Alert'
import InboxModal from './components/InboxModal'
import SuccessModal from '@bonliva-components/web/shared/SuccessModal'
import DeleteModal from '@bonliva-components/web/shared/DeleteModal'
import {
  useFetchNotificationsUnreadCount,
  useNotifications,
} from '@bonliva-traits/api/notifications'
import useInterval from '@bonliva-traits/hooks/useInterval'
import { useApi } from '@bonliva-traits/api'

const tableColumns = [
  {
    label: 'Ämne',
  },
  {
    label: 'Avsändare',
    sortable: 'courier.name',
  },
  {
    label: 'Datum',
    sortable: 'n.created_at',
  },
  {
    label: 'Tid',
  },
]

const notificationMap: Record<TreaterNotificationType, string> = {
  [TreaterNotificationType.MovedReferralPlan]: 'Flyttad remiss',
  [TreaterNotificationType.CanceledBooking]: 'Avbokning',
  [TreaterNotificationType.NewBooking]: 'Ny bokning',
  [TreaterNotificationType.UpdatedBooking]: 'Justerad bokning',
  [TreaterNotificationType.AcceptedTreatmentPlan]: 'Accepterad behandlingsplan',
  [TreaterNotificationType.RejectedTreatmentPlan]: 'Nekad behandlingsplan',
  [TreaterNotificationType.CompletedBookingTreatmentForm]:
    'Genomfört hälsoformulär',
  [TreaterNotificationType.CompletedTreatmentPlanTreatmentForm]:
    'Genomfört uppföljningsformulär',
}

const Inbox: React.FC = () => {
  const api = useApi()
  const fetchUnreadCount = useFetchNotificationsUnreadCount()
  const { unreadCount } = useNotifications()

  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const [pageSize] = useState(10)
  const [page, setPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isAscending, setIsAscending] = useState(false)
  const [sortBy, setSortBy] = useState('n.created_at')
  const [searchValue, setSearchValue] = useState('')
  const [selected, setSelected] = useState<ITreaterNotification>()
  const [checked, setChecked, unloadChecked] =
    useCheckboxState<ITreaterNotification>([])

  const notifications = useApiState<ITreaterNotification[]>(`/v2/notifications`)

  useEffect(() => {
    setIsLoading(true)
    fetchNotificationsDebounced()
  }, [sortBy, isAscending, searchValue, page])

  const fetchNotificationsHandler = useCallback(async () => {
    const order = isAscending ? 'ASC' : 'DESC'

    await notifications.get({
      params: {
        ...requestPagination(page, pageSize),
        ['_sort']: sortBy,
        ['_order']: order,
        searchQuery: searchValue,
      },
    })

    fetchUnreadCount()
    setIsLoading(false)
  }, [sortBy, isAscending, searchValue, page])

  const fetchNotificationsDebounced = useDebouncedCallback(
    () => fetchNotificationsHandler(),
    400
  )

  useInterval(fetchNotificationsHandler, 30000, [
    sortBy,
    isAscending,
    searchValue,
    page,
  ])

  const onDeletedHandler = useCallback(async () => {
    await Promise.all(
      checked.map((item) => api.delete(`/v2/notifications/${item.id}`))
    )
    await fetchNotificationsHandler()
    unloadChecked()
    setShowDeleteModal(false)
    setShowSuccessModal(true)
  }, [checked])

  const numberOfPages = useMemo(() => {
    if (!notifications.headers?.['x-total-count']) return 0
    return Math.ceil(Number(notifications.headers['x-total-count']) / pageSize)
  }, [notifications.data, pageSize])

  return (
    <S.Inbox>
      <Table.Wrapper
        header={
          <Table.Header
            title="Inkorg"
            unreadCount={unreadCount}
            showSearchBar
            searchBarPlaceholder="Sök..."
            searchBarValue={searchValue}
            setSearchValue={setSearchValue}
            showDeleteButton={checked.length > 0}
            deleteButtonOnClick={() => setShowDeleteModal(true)}
            deleteButtonTitle={
              checked.length > 1
                ? `Ta bort ${checked.length} meddelanden`
                : 'Ta bort meddelande'
            }
          />
        }
        head={
          <Table.Head
            columns={tableColumns}
            sortBy={sortBy}
            setSortBy={setSortBy}
            isAscending={isAscending}
            setIsAscending={setIsAscending}
          />
        }
        body={
          <Table.Body isLoading={isLoading}>
            {notifications.hasLoaded && !notifications.data?.length && (
              <S.EmptyInbox>
                <td>
                  <Alert type="info" title="Inga meddelanden hittades" />
                </td>
              </S.EmptyInbox>
            )}

            {notifications.data?.map((n) => {
              const isChecked = checked.some((checked) => checked.id === n.id)
              const date = format(new Date(n.createdAt), 'yyyy-MM-dd')
              const time = format(new Date(n.createdAt), 'HH:mm')

              const onClickHandler = () => {
                setSelected(n)
                setShowModal(true)
              }

              const onCheckboxHandler = (e: React.MouseEvent) => {
                e.stopPropagation()
                setChecked(n, 'id')
              }
              return (
                <S.TableRow
                  key={n.id}
                  $isUnread={n.unread}
                  onClick={onClickHandler}
                >
                  <S.FlexSpan>
                    <S.CheckBox onClick={onCheckboxHandler}>
                      {isChecked && <S.Check src="Check" />}
                    </S.CheckBox>
                    <S.BonlivaIcon src="BonlivaLogo" />
                    <S.RowText>{notificationMap[n.type]}</S.RowText>
                  </S.FlexSpan>

                  <S.FlexSpan>
                    <S.RowText>{n.courier?.name || 'Bonliva Care'}</S.RowText>
                  </S.FlexSpan>

                  <S.FlexSpan>
                    <S.RowText>{date}</S.RowText>
                  </S.FlexSpan>

                  <S.FlexSpan>
                    <S.RowText>{time}</S.RowText>
                  </S.FlexSpan>
                </S.TableRow>
              )
            })}
          </Table.Body>
        }
        footer={
          <Table.Footer
            page={page}
            setPage={setPage}
            numberOfPages={numberOfPages}
          />
        }
      />

      {showModal && selected && (
        <InboxModal
          selected={selected}
          setSelected={setSelected}
          isOpen={showModal}
          setIsOpen={setShowModal}
          onRead={fetchNotificationsHandler}
          openDeleteModal={(item) => {
            unloadChecked()
            setChecked(item, 'id')
            setShowDeleteModal(true)
          }}
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          items={checked}
          isLoading={notifications.isLoading}
          hasError={notifications.hasError}
          isOpen={showDeleteModal}
          setIsOpen={setShowDeleteModal}
          onDelete={onDeletedHandler}
          title={
            checked.length > 1
              ? `Ta bort (${checked.length}) meddelanden`
              : 'Ta bort meddelande'
          }
          warningTitle={
            checked.length > 1
              ? `Är du säker på att du vill ta bort ${checked.length} meddelanden?`
              : 'Är du säker på att du vill ta bort detta meddelande?'
          }
          warningMessages={[
            'Du kan inte ångra dig efter att du har tagit bort något.',
          ]}
        />
      )}

      {showSuccessModal && (
        <SuccessModal
          isOpen={showSuccessModal}
          setIsOpen={setShowSuccessModal}
          title="Lyckad borttagning"
          successTitle="Borttagningen har genomförts"
        />
      )}
    </S.Inbox>
  )
}

export default Inbox
