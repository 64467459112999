import React from 'react'
import * as S from './MediaTopBar.style'
import { IMedia } from '@bonliva-traits/api/types'
import { Images } from '@bonliva-ui/assets/images'

type Props = {
  detail?: string
  title: string
  description?: string
  image?: IMedia
  showSearchBar?: boolean
  searchBarPlaceholder?: string
  searchBarValue?: string
  setSearchBarValue?: (value: string) => void
}

const MediaTopBar: React.FC<Props> = (props) => {
  const imageUrl = props.image?.url
    ? encodeURI(props.image?.url)
    : Images.MediaLibrary

  return (
    <S.MediaTopBar>
      <S.MediaTopBarThumbnail src={imageUrl} />

      <S.MediaTopBarThumbnailOverlay />

      <S.MediaTopBarLayout>
        {props.detail && (
          <S.MediaTopBarDetail>{props.detail}</S.MediaTopBarDetail>
        )}

        <S.MediaTopBarTitle>{props.title}</S.MediaTopBarTitle>

        {props.description && (
          <S.MediaTopBarDescription>
            {props.description}
          </S.MediaTopBarDescription>
        )}

        {props.showSearchBar && (
          <S.MediaTopBarSearchBarWrapper>
            <S.MediaTopBarSearchBarIcon src="Search" />

            <S.MediaTopBarSearchBarClearIcon
              $hasValue={!!props.searchBarValue}
              src="Close"
              onClick={() => props.setSearchBarValue?.('')}
            />

            <S.MediaTopBarSearchBar
              placeholder={props.searchBarPlaceholder}
              value={props.searchBarValue}
              onChange={(e) => props.setSearchBarValue?.(e.target.value)}
            />
          </S.MediaTopBarSearchBarWrapper>
        )}
      </S.MediaTopBarLayout>
    </S.MediaTopBar>
  )
}

export default MediaTopBar
