import React, { useRef, useCallback, useMemo, useState, useEffect } from 'react'
import * as S from './CalendarSideMenu.style'
import FullCalendar from '@fullcalendar/react'
import calendarSv from '@fullcalendar/core/locales/sv'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin, { DateClickArg } from '@fullcalendar/interaction'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'
import { useNavigate, useLocation } from 'react-router-dom'
import {
  getCalendarUrlParams,
  isCalendarWith,
} from '../../utils/getCalendarUrlParams'

type Props = {
  content: React.ReactNode
}

const CalendarSideMenu: React.FC<Props> = (props) => {
  const location = useLocation()
  const navigate = useNavigate()
  const calendarRef = useRef<FullCalendar>(null)
  const [date, setDate] = useState(new Date())
  const calendarParams = getCalendarUrlParams(location.pathname)

  const nextHandler = useCallback(() => {
    const api = calendarRef.current?.getApi()
    api?.next()
    const currentDate = api?.currentDataManager?.getCurrentData().currentDate
    if (!currentDate) return
    setDate(currentDate)
  }, [])

  const prevHandler = useCallback(() => {
    const api = calendarRef.current?.getApi()
    api?.prev()
    const currentDate = api?.currentDataManager?.getCurrentData().currentDate
    if (!currentDate) return
    setDate(currentDate)
  }, [])

  useEffect(() => {
    const api = calendarRef.current?.getApi()
    const currentDate = api?.currentDataManager?.getCurrentData().currentDate
    if (!currentDate) return
    setDate(currentDate)
  }, [])

  const onDateClickHandler = useCallback(
    (info: DateClickArg) => {
      const formattedDate = format(info.date, 'yyyy-MM-dd')
      if (isCalendarWith.type(calendarParams)) {
        navigate(
          `${calendarParams.url}/${calendarParams.type}/${formattedDate}`
        )
      } else {
        navigate(`${calendarParams.url}/week/${formattedDate}`)
      }
    },
    [location, navigate]
  )

  const selectedDate = useMemo(() => {
    if (isCalendarWith.date(calendarParams)) {
      return calendarParams.date
    }
  }, [location])

  return (
    <S.CalendarSideMenu>
      <S.CalendarSideMenuTopBar>
        <S.CalendarSideMenuTopBarDate>
          {format(date, 'MMMM yyyy', { locale: sv })}
        </S.CalendarSideMenuTopBarDate>

        <S.CalendarSideMenuTopBarNavigation>
          <S.CalendarSideMenuTopBarNavigationButton
            src="ChevronLeft"
            onClick={prevHandler}
          />

          <S.CalendarSideMenuTopBarNavigationButton
            src="ChevronRight"
            onClick={nextHandler}
          />
        </S.CalendarSideMenuTopBarNavigation>
      </S.CalendarSideMenuTopBar>

      <S.CalendarContainer>
        <S.CalendarWrapper $selectedDate={selectedDate}>
          <FullCalendar
            locale={calendarSv}
            ref={calendarRef}
            plugins={[dayGridPlugin, interactionPlugin]}
            initialView="dayGridMonth"
            showNonCurrentDates={false}
            headerToolbar={false}
            height="auto"
            dateClick={onDateClickHandler}
          />
        </S.CalendarWrapper>
      </S.CalendarContainer>

      <S.CalendarSideMenuContent>{props.content}</S.CalendarSideMenuContent>
    </S.CalendarSideMenu>
  )
}

export default CalendarSideMenu
