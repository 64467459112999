import React, { useState, useEffect } from 'react'
import * as S from './DashboardReminders.style'
import { IBooking, IReminder } from '@bonliva-traits/api/types'
import useApiState from '@bonliva-traits/hooks/useApiState'
import Spinner from '@bonliva-components/web/shared/Spinner'
import NoMedicalRecord from './components/NoMedicalRecord'
import { ReminderType } from '@bonliva-traits/api/enums'
import LateCanceledBooking from './components/LateCanceledBooking'

const DashboardReminders = () => {
  const [page, setPage] = useState(0)

  const reminders = useApiState<IReminder[]>('/v1/treaters/reminders')

  useEffect(() => {
    reminders.get()
  }, [])

  const nextPageHandler = () => {
    setPage((prev) => {
      if (prev >= (reminders.data?.length || 0) - 1) return prev
      else return prev + 1
    })
  }

  const prevPageHandler = () => {
    setPage((prev) => {
      if (prev === 0) return prev
      else return prev - 1
    })
  }

  const ReminderMap: Record<
    ReminderType,
    React.FC<{ booking: IBooking; refreshReminders: () => void }>
  > = {
    [ReminderType.NoMedicalRecord]: NoMedicalRecord,
    [ReminderType.LateCanceledBooking]: LateCanceledBooking,
  }

  return (
    <S.DashboardReminders>
      <S.Header>
        <S.Title>
          Påminnelser{' '}
          <S.TitleNumber>({reminders.data?.length || 0})</S.TitleNumber>
        </S.Title>

        <S.DashboardRemindersNavigation>
          <S.DashboardRemindersNavigationButton
            src="ChevronLeft"
            onClick={prevPageHandler}
            disabled={page === 0 || reminders.isLoading}
          />

          <S.DashboardRemindersNavigationButton
            src="ChevronRight"
            onClick={nextPageHandler}
            disabled={
              page >= (reminders.data?.length || 0) - 1 || reminders.isLoading
            }
          />
        </S.DashboardRemindersNavigation>
      </S.Header>

      {reminders.data &&
        reminders.data.length > 0 &&
        React.createElement(ReminderMap[reminders.data[page]?.type], {
          booking: reminders.data[page].booking,
          refreshReminders: reminders.get,
        })}

      {reminders.hasLoaded && (reminders.data?.length || 0) === 0 && (
        <S.NoRemindersMessage>
          Du har inga påminnelser just nu
        </S.NoRemindersMessage>
      )}

      {!reminders.hasLoaded && reminders.isLoading && (
        <S.LoadingWrapper>
          <Spinner color="neutral" />
        </S.LoadingWrapper>
      )}
    </S.DashboardReminders>
  )
}

export default DashboardReminders
