import styled from 'styled-components'
import { ButtonPrimary, H5 } from '@bonliva-ui/web'
import { Icon } from '@bonliva-traits/web-icons'

export const BookingModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.xxs};
  padding: ${({ theme }) => theme.margin.xxs};
`

export const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const FlexEndWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const ConnectButton = styled(ButtonPrimary)`
  width: 256px;
`

export const FlexCenter = styled.div`
  margin-top: ${({ theme }) => theme.margin.xxs};
  display: flex;
  justify-content: center;
`

export const Label = styled(H5)`
  color: ${({ theme }) => theme.colors.web.darkest};
  margin-left: ${({ theme }) => theme.margin['4xs']};
  margin-bottom: ${({ theme }) => theme.margin['4xs']};
`

export const Greyed = styled.span`
  color: grey;
`

export const BonlivaLogo = styled(Icon)`
  margin-top: 6px;
  width: 200px;
  color: ${({ theme }) => theme.colors.web.integrationBonlivaLogo};
`

export const Bold = styled.span`
  font-weight: 500;
`
