import styled from 'styled-components'

export const PatientTreatmentPlans = styled.div`
  max-width: 1920px;
  margin: 0 auto 30px auto;
`

export const PatientTreatmentPlansLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.margin.xxs};
  margin-bottom: -${({ theme }) => theme.margin.xxs};
`

export const TreatmentPlansWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.web.white};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: ${({ theme }) => theme.margin.m};
`

export const TreatmentPlansContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`
