import React from 'react'
import * as S from './Chip.style'
import { BookingMeetingType, BookingType } from '@bonliva-traits/api/enums'
import MenuItem from '@mui/material/MenuItem'
import { SelectChangeEvent } from '@mui/material/Select'
export type ChipProps = {
  editMode: boolean
  meetingType: BookingMeetingType
  setMeetingType?: (meetingType: BookingMeetingType) => void
  type: BookingType
  referral: boolean
}

const getChipLabel = (
  meetingType: BookingMeetingType | string,
  type: BookingType
) => {
  if (
    meetingType === BookingMeetingType.Digital &&
    type === BookingType.Initial
  ) {
    return `Digitalt nybesök`
  } else if (
    meetingType === BookingMeetingType.Digital &&
    type === BookingType.FollowUp
  ) {
    return `Digitalt återbesök`
  } else if (
    meetingType === BookingMeetingType.Physical &&
    type === BookingType.Initial
  ) {
    return `Fysiskt nybesök`
  } else if (
    meetingType === BookingMeetingType.Physical &&
    type === BookingType.FollowUp
  ) {
    return `Fysiskt återbesök`
  }
}

export const Chip: React.FC<ChipProps> = (props) => {
  const handleChange = (event: SelectChangeEvent<unknown>) => {
    if (props.setMeetingType) {
      props.setMeetingType(event.target.value as BookingMeetingType)
    }
  }
  return (
    <S.ChipWrapper>
      {!props.editMode && (
        <S.Chip {...props}>
          {getChipLabel(props.meetingType, props.type)}
        </S.Chip>
      )}
      {props.editMode && (
        <S.Chip {...props}>
          <S.ChipSelect value={props.meetingType} onChange={handleChange}>
            <MenuItem value={BookingMeetingType.Digital}>
              {getChipLabel(BookingMeetingType.Digital, props.type)}
            </MenuItem>
            <MenuItem value={BookingMeetingType.Physical}>
              {getChipLabel(BookingMeetingType.Physical, props.type)}
            </MenuItem>
          </S.ChipSelect>
        </S.Chip>
      )}{' '}
      <S.ReferralChip referral={props.referral}>
        {props.referral ? 'Remiss' : 'Privatbetalande'}
      </S.ReferralChip>
    </S.ChipWrapper>
  )
}
