import React, { useMemo, useState, useEffect } from 'react'
import * as S from './AppointmentInfoModal.style'
import { IAppointment, ITreatmentFormAnswers } from '@bonliva-traits/api/types'
import { format } from 'date-fns'
import useInterval from '@bonliva-traits/hooks/useInterval'
import Modal from '@bonliva-components/web/shared/Modal'
import useApiState from '@bonliva-traits/hooks/useApiState'
import { StartMeetingButton } from '@bonliva-components/web/shared/StartMeetingButton/StartMeetingButton'

type Props = {
  appointment: IAppointment
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  setCancelModalOpen: (isOpen: boolean) => void
}

const AppointmentInfoModal: React.FC<Props> = (props) => {
  const [currentTime, setCurrentTime] = useState(new Date())

  const formQuestions = useApiState<ITreatmentFormAnswers>(
    `/v1/treaters/treatment-form/booking/${props.appointment.id}/answers`
  )

  useEffect(() => {
    formQuestions.get()
  }, [])

  const start = new Date(props.appointment.startDate).getTime()
  const end = new Date(props.appointment.endDate).getTime()
  const current = currentTime.getTime()
  const moreThanFifteenMinLeft = start - current > 15 * 60 * 1000

  useInterval(
    () => setCurrentTime(new Date()),
    moreThanFifteenMinLeft ? 60000 : 1000,
    []
  )

  const rows = useMemo(() => {
    return [
      {
        label: 'Name',
        value: props.appointment.patient?.name,
      },
      {
        label: 'Personnummer',
        value: props.appointment.patient?.personalNumber,
      },
      {
        label: 'Datum',
        value: format(new Date(props.appointment.startDate), 'yyyy-MM-dd'),
      },
      {
        label: 'Tid',
        value: format(new Date(props.appointment.startDate), 'HH:mm'),
      },
      {
        label: 'Inriktning',
        value: props.appointment.treatment?.category,
      },
    ]
  }, [props.appointment])

  const treatmentFormQuestionMap = useMemo(
    () => ['Aldrig', 'Sällan', 'Ibland', 'Ofta', 'Alltid'],
    []
  )

  return (
    <Modal.Wrapper isOpen={props.isOpen}>
      <Modal.Contents onDismiss={() => props.setIsOpen(false)}>
        <S.AppointmentInfoModal>
          <Modal.CloseButton />

          <S.Header>
            <S.Title>{props.appointment.patient?.name}</S.Title>

            <S.Link to={`/patients/${props.appointment.patient?.id}`}>
              Gå till patientprofil <S.ChevronRight src="ChevronRight" />
            </S.Link>
          </S.Header>

          <S.Content>
            <S.Grid>
              {rows.map((row) => (
                <S.GridItem key={row.label}>
                  <S.Label>{row.label}</S.Label>
                  <S.Value>{row.value}</S.Value>
                </S.GridItem>
              ))}
            </S.Grid>

            {formQuestions.data && (
              <S.FormAnswers>
                <S.FormAnswersHeader>
                  <S.FormAnswersTitle>Frågeformulär</S.FormAnswersTitle>

                  <S.FormAnswersDescription>
                    Svarsalternativ:
                    <S.FormAnswersTypes>
                      {treatmentFormQuestionMap.join(', ')}
                    </S.FormAnswersTypes>
                  </S.FormAnswersDescription>
                </S.FormAnswersHeader>

                {formQuestions.data.questions.map((item, i) => (
                  <S.FormItem key={item.question}>
                    <S.FormItemLabel>Fråga: {++i} / 12</S.FormItemLabel>
                    <S.FormItemQuestion>{item.question}</S.FormItemQuestion>
                    <S.FormItemAnswer>
                      Svar: {treatmentFormQuestionMap[item.answer]}
                    </S.FormItemAnswer>
                  </S.FormItem>
                ))}
              </S.FormAnswers>
            )}
          </S.Content>

          <S.Footer>
            <Modal.DismissButton onClick={() => props.setCancelModalOpen(true)}>
              <S.Abort disabled={current > end}>Avboka</S.Abort>
            </Modal.DismissButton>

            <StartMeetingButton
              appointment={props.appointment}
              alwaysShow={true}
            />
          </S.Footer>
        </S.AppointmentInfoModal>
      </Modal.Contents>
    </Modal.Wrapper>
  )
}

export default AppointmentInfoModal
