import { Icon } from '@bonliva-traits/web-icons'
import { BodySmall, BodyXSmall, ButtonPrimary, H4 } from '@bonliva-ui/web'
import styled from 'styled-components'

export const Content = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: ${({ theme }) => theme.margin.xs};
  margin-top: -${({ theme }) => theme.margin.s};
`

export const ThumbnailOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: inherit;
  width: inherit;
  transition: opacity 0.2s ease;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
`

export const Assignment = styled.div`
  width: 248px;
  height: 176px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: ${({ theme }) => theme.margin.s};
  margin-right: ${({ theme }) => theme.margin.s};
  gap: ${({ theme }) => theme.margin.xxs};
  background-color: ${({ theme }) => theme.colors.web.white};
  border-radius: ${({ theme }) => theme.radius.rounded};
  border: 1px solid ${({ theme }) => theme.colors.web.light};
  overflow: hidden;

  cursor: pointer;

  &:hover {
    ${ThumbnailOverlay} {
      opacity: 0.5;
    }
  }
  &:last-child {
    margin-right: 0;
  }
`

export const ThumbnailWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 120px;
  padding: ${({ theme }) => theme.margin.xxs};
`

export const Thumbnail = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  height: inherit;
  width: inherit;
  object-fit: cover;
`

export const ThumbnailDetail = styled(BodyXSmall)`
  font-weight: 400;
  display: flex;
  color: ${({ theme }) => theme.colors.web.white};
  position: absolute;
  bottom: ${({ theme }) => theme.margin.m};
  left: ${({ theme }) => theme.margin.xxs};
  text-transform: uppercase;
  gap: ${({ theme }) => theme.margin['3xs']};
`

export const ThumbnailTitle = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.white};
  position: absolute;
  bottom: ${({ theme }) => theme.margin.xxs};
  left: ${({ theme }) => theme.margin.xxs};
  font-weight: 500;
`

export const AssignmentContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${({ theme }) => theme.margin['3xs']};
  padding: ${({ theme }) => theme.margin.xxs};
  padding-top: 4px;
  padding-bottom: 8px;
`

export const ProgressBarTextWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const LoadingWrapper = styled.div`
  margin-top: ${({ theme }) => theme.margin['4xs']};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const ProgressBarText = styled(BodySmall)``

export const Add = styled(ButtonPrimary)``

export const Footer = styled.div`
  margin-top: ${({ theme }) => theme.margin.xxs};
  border-top: 0.5px solid ${({ theme }) => theme.colors.web.light}cc;
  padding: ${({ theme }) => theme.margin.xxs};
`

export const FooterPagination = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.margin['4xs']};
`

export const FooterPaginationButton = styled.button<{
  disabled: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: ${({ theme }) => theme.margin.xxs};
  padding: 0 ${({ theme }) => theme.margin.xxs};
  border: none;
  background-color: transparent;
  cursor: pointer;

  opacity: ${({ disabled }) => (disabled ? 0.2 : 1)};
`

export const FooterPaginationButtonIcon = styled(Icon)`
  min-height: 24px;
  min-width: 24px;
  border-radius: 9999px;
  padding: 6px;
  border: 1px solid ${({ theme }) => theme.colors.web.dark};
  color: ${({ theme }) => theme.colors.web.dark};
`

export const FooterPaginationPage = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const PlanAddCard = styled.div`
  cursor: pointer;
  width: 248px;
  height: 176px;
  margin-top: ${({ theme }) => theme.margin.s};
  margin-right: ${({ theme }) => theme.margin.s};
  background-color: rgb(255, 184, 170, 0.1);
  border: 1px dashed ${({ theme }) => theme.colors.web.primary};
  border-radius: ${({ theme }) => theme.radius.rounded};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${({ theme }) => theme.colors.web.primary};
`

export const ReferralPlanAddHeader = styled(H4)`
  color: ${({ theme }) => theme.colors.web.primary};
`
