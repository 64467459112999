import { Icon } from '@bonliva-traits/web-icons'
import React, { useState } from 'react'
import * as S from './Accordion.style'

type AccordionProps = {
  children: React.ReactNode
  title: string
  subTitle?: string
}

const Accordion = ({ children, title, subTitle }: AccordionProps) => {
  const [open, setOpen] = useState(true)
  return (
    <S.Accordion>
      <S.AccordionHeader onClick={() => setOpen((prev) => !prev)} open={open}>
        <S.TitleWrapper>
          <S.Title>{title}</S.Title>
          {subTitle && <S.SubTitle> - {subTitle}</S.SubTitle>}
        </S.TitleWrapper>

        <S.IconWrapper isOpen={open}>
          <Icon src={'ChevronUp'} />
        </S.IconWrapper>
      </S.AccordionHeader>
      {open && <S.AccordionBody>{children}</S.AccordionBody>}
    </S.Accordion>
  )
}

export default Accordion
