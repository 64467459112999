import { IReferralPlan } from '../../types'
import { isPast } from 'date-fns'

export const referralPlanStatus = (r: IReferralPlan) => {
  if (r.numberOfAppointmentsLeft === 0) return 'expired'

  if (r.expiryDate && isPast(new Date(r.expiryDate))) return 'expired'

  if (
    r.numberOfAppointments === r.numberOfAppointmentsLeft &&
    r.firstAppointmentExpiryDate &&
    isPast(new Date(r.firstAppointmentExpiryDate))
  ) {
    return 'expired'
  }

  if (r.completedAt) return 'completed'

  return 'active'
}
