import React, { useCallback, useEffect } from 'react'
import * as S from './CalendarTopBar.style'
import { useLocation, useNavigate } from 'react-router-dom'
import { CalendarApi } from '@fullcalendar/core'
import FullCalendar from '@fullcalendar/react'
import Select from '../../../Select'
import { SingleValue } from 'react-select'
import { sv } from 'date-fns/locale'
import {
  format,
  getWeek,
  getDay,
  startOfYear,
  subHours,
  getMonth,
  startOfWeek,
  endOfWeek,
} from 'date-fns'
import {
  getCalendarUrlParams,
  isCalendarWith,
} from '../../utils/getCalendarUrlParams'
import { Icon } from '@bonliva-traits/web-icons'

const types = [
  { value: 'timeGridDay', label: 'Dag', id: 'day' },
  { value: 'timeGridWeek', label: 'Vecka', id: 'week' },
  { value: 'dayGridMonth', label: 'Månad', id: 'month' },
]

type IType = SingleValue<{
  value: string
  label: string
  id: string
}>

type Props = {
  calendarRef: React.RefObject<FullCalendar>
  type?: string
  date?: string
  onCreateNewMeetingClick?: () => void
}

const CalendarTopBar = (props: Props) => {
  const navigate = useNavigate()
  const location = useLocation()
  const calendarParams = getCalendarUrlParams(location.pathname)

  const todayHandler = useCallback(async () => {
    const api = props.calendarRef.current?.getApi()
    const date = api?.currentDataManager?.getCurrentData().currentDate
    await api?.today()

    if (isCalendarWith.type(calendarParams)) {
      navigate(
        `${calendarParams.url}/${calendarParams.type}/${format(
          new Date(),
          'yyyy-MM-dd'
        )}`
      )
    } else {
      navigate(`${calendarParams.url}/week/${format(new Date(), 'yyyy-MM-dd')}`)
    }

    if (date?.getDate() !== new Date().getDate()) return
    await api?.scrollToTime(format(subHours(new Date(), 1), 'HH:mm:ss'))
  }, [calendarParams])

  const setDateParamsHandler = useCallback(
    (api?: CalendarApi) => {
      const date = api?.currentDataManager?.getCurrentData().currentDate

      if (!date) return

      const formattedDate = format(date, 'yyyy-MM-dd')
      if (isCalendarWith.type(calendarParams)) {
        navigate(
          `${calendarParams.url}/${calendarParams.type}/${formattedDate}`
        )
      } else {
        navigate(`${calendarParams.url}/week/${formattedDate}`)
      }
    },
    [location]
  )

  const nextHandler = useCallback(async () => {
    const api = props.calendarRef.current?.getApi()
    await api?.next()
    await setDateParamsHandler(api)
  }, [setDateParamsHandler, props.calendarRef])

  const prevHandler = useCallback(async () => {
    const api = props.calendarRef.current?.getApi()
    await api?.prev()
    await setDateParamsHandler(api)
  }, [setDateParamsHandler, props.calendarRef])

  const onChangeHandler = useCallback(
    (type: IType) => {
      if (!type) return

      if (isCalendarWith.date(calendarParams)) {
        navigate(`${calendarParams.url}/${type.id}/${calendarParams.date}`)
      } else {
        navigate(`${calendarParams.url}/${type.id}`)
      }
    },
    [location, navigate]
  )

  useEffect(() => {
    const type = types.find((t) => t.id === props.type)
    if (!type) return
    props.calendarRef.current?.getApi().changeView(type.value)
  }, [props.type, navigate])

  const date = () => {
    const date = props.date ? new Date(props.date) : new Date()

    const type = types.find((t) => t.id === props.type)

    if (!type || type.id === 'week') {
      const monthOne = getMonth(startOfWeek(date, { weekStartsOn: 1 }))
      const monthTwo = getMonth(endOfWeek(date, { weekStartsOn: 1 }))
      const isBetweenMonths = monthOne !== monthTwo

      if (isBetweenMonths) {
        const formattedMonthOne = format(startOfWeek(date), 'MMM', {
          locale: sv,
        })
        const formattedMonthTwo = format(endOfWeek(date), 'MMM yyyy', {
          locale: sv,
        })

        return `${formattedMonthOne} - ${formattedMonthTwo}`
      }
    }

    return format(date, 'MMMM yyyy', { locale: sv })
  }

  const week = () => {
    const date = props.date ? new Date(props.date) : new Date()

    return getWeek(date, {
      locale: sv,
      weekStartsOn: 1,
      firstWeekContainsDate: getDay(startOfYear(date)) || 1,
    })
  }

  return (
    <S.CalendarTopBar>
      <S.CalendarTopBarWrapper>
        <S.CalendarTopBarTodayButton onClick={todayHandler}>
          Idag
        </S.CalendarTopBarTodayButton>
        <S.CalendarTopBarNavigation>
          <S.CalendarTopBarNavigationButton
            src="ChevronLeft"
            onClick={prevHandler}
          />

          <S.CalendarTopBarNavigationButton
            src="ChevronRight"
            onClick={nextHandler}
          />
        </S.CalendarTopBarNavigation>

        <S.CalendarTopBarDate>{date()}</S.CalendarTopBarDate>

        {(props.type === 'week' || !props.type) && (
          <S.CalendarTopBarWeek>Vecka {week()}</S.CalendarTopBarWeek>
        )}
      </S.CalendarTopBarWrapper>

      {props.type && (
        <S.CalendarTopBarSelectWrapper>
          <S.CreateButton onClick={props.onCreateNewMeetingClick}>
            <Icon src="Plus" style={{ marginRight: '10px' }} />
            Boka
          </S.CreateButton>
          <Select
            isSearchable={false}
            value={types.find((t) => t.id === (props.type || 'week'))}
            options={types}
            onChange={onChangeHandler}
          />
        </S.CalendarTopBarSelectWrapper>
      )}
    </S.CalendarTopBar>
  )
}

export default CalendarTopBar
