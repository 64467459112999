import { IcdCode, IWebdocTemplate, Record } from '@bonliva-traits/api/types'
import { ActionMap } from '@bonliva-core/store'

export type NoteInput = {
  id: number
  value: string
  title: string
  warnIfEmpty: boolean
}

export type State = {
  webdocTemplates?: ReadonlyArray<IWebdocTemplate>
  webdocTemplate?: IWebdocTemplate
  selectedIcdCodes: IcdCode[]
  showNotepad: boolean
  notepadText: string
  notes?: NoteInput[]
  setNote: (id: number, note: string) => void
  setSelectedIcdCodes: (code: IcdCode[]) => void
  setWebdocTemplate: (webdocTemplate: IWebdocTemplate) => void
  setShowNotepad: (showNotepad: boolean) => void
  setNotepadText: (value: string) => void
  saveToWebdoc: (record: Record) => Promise<void>
  unload: () => void
}

export enum Types {
  SET_WEBDOC_TEMPLATES = 'SET_WEBDOC_TEMPLATES',
  SET_WEBDOC_TEMPLATE = 'SET_WEBDOC_TEMPLATE',
  SET_SELECTED_ICD_CODES = 'SET_SELECTED_ICD_CODES',
  SET_SHOW_NOTEPAD = 'SET_SHOW_NOTEPAD',
  SET_NOTEPAD_TEXT = 'SET_NOTEPAD_TEXT',
  SET_NOTE = 'SET_NOTE',
  SAVE_TO_WEBDOC = 'SAVE_TO_WEBDOC',
  UNLOAD = 'UNLOAD',
}

type Payload = {
  [Types.SET_WEBDOC_TEMPLATES]: ReadonlyArray<IWebdocTemplate>
  [Types.SET_WEBDOC_TEMPLATE]: IWebdocTemplate
  [Types.SET_SELECTED_ICD_CODES]: IcdCode[]
  [Types.SET_SHOW_NOTEPAD]: boolean
  [Types.SET_NOTEPAD_TEXT]: string
  [Types.SET_NOTE]: { id: number; note: string }
  [Types.SAVE_TO_WEBDOC]: Record
  [Types.UNLOAD]: undefined
}

export type Action = ActionMap<Payload>
