import styled from 'styled-components'

export const ProgressBar = styled.div<{ $count?: number; $completed?: number }>`
  width: 100%;
  height: 4px;
  background-color: ${({ theme }) => theme.colors.web.light};
  border-radius: ${({ theme }) => theme.radius.rounded};
  overflow: hidden;

  &::after {
    content: '';
    display: block;
    height: 100%;
    width: ${({ $count, $completed }) =>
      $count ? (($completed || 0) / ($count || 1)) * 100 : 0}%;

    background-color: ${({ theme, $count, $completed }) =>
      $count === $completed
        ? theme.colors.web.success
        : theme.colors.web.primary};
  }
`
