import React from 'react'
import * as S from './MeetingRoomNotepad.style'
import { useWebdocNotes } from '@bonliva-traits/webdoc-notes'
import { useMeeting } from '@bonliva-traits/meeting-web'
import MeetingNotepad from '@bonliva-components/web/shared/MeetingNotepad'

const MeetingRoomNotepad = () => {
  const { showNotepad } = useWebdocNotes()
  const { appointment } = useMeeting()

  return (
    <S.MeetingRoomNotepad $showNotepad={showNotepad}>
      {appointment && <MeetingNotepad appointment={appointment} />}
    </S.MeetingRoomNotepad>
  )
}

export default MeetingRoomNotepad
