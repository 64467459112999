import React from 'react'
import * as S from '../InboxModal.style'
import { ITreaterNotification } from '@bonliva-traits/api/types'

type Props = {
  notification: ITreaterNotification
}

const RejectedTreatmentPlan = (props: Props) => {
  return (
    <>
      <S.Header>
        <S.Title>Nekad behandlingsplan</S.Title>

        {props.notification.user && (
          <S.Link
            to={`/patients/${props.notification.courier?.id}/treatment-plans/${props.notification.metadata?.treatmentPlanId}`}
          >
            Gå till behandlingsplan <S.ChevronRight src="ChevronRight" />
          </S.Link>
        )}
      </S.Header>

      <S.Content>
        <S.Grid>
          <S.GridItem key="Name">
            <S.Label>Namn</S.Label>
            <S.Value>
              {props.notification.courier?.name || 'Bonliva Care'}
            </S.Value>
          </S.GridItem>

          <S.GridItem key="Inriktning">
            <S.Label>Inriktning</S.Label>
            <S.Value>
              {props.notification.metadata?.treatmentCategoryName}
            </S.Value>
          </S.GridItem>

          <S.GridItem key="Status">
            <S.Label>Status</S.Label>
            <S.Value>Nekad</S.Value>
          </S.GridItem>

          <S.GridItem key="Meddelande">
            <S.Label>Meddelande från patient</S.Label>
            <S.Value>
              {props.notification.metadata?.treatmentPlanRejectionMessage}
            </S.Value>
          </S.GridItem>
        </S.Grid>
      </S.Content>
    </>
  )
}

export default RejectedTreatmentPlan
