import React from 'react'
import * as S from './Login.style'

import Spinner from '@bonliva-components/web/shared/Spinner'

const Authenticating: React.FC = () => {
  return (
    <S.Center>
      <Spinner />
    </S.Center>
  )
}

export default Authenticating
