import React from 'react'
import * as S from './ReferralPlanStatusChip.style'
import { Icon, IconType } from '@bonliva-traits/web-icons'
import { useTheme } from 'styled-components'

type Props = {
  status: 'active' | 'expired' | 'completed'
}

type StatusIcon = {
  icon: keyof typeof IconType
  backgroundColor: string
  borderColor: string
  borderStyle: string
  title: string
}

const ReferralPlanStatusChip: React.FC<Props> = (props) => {
  const theme = useTheme()

  const statusChipMap: Record<'active' | 'expired' | 'completed', StatusIcon> =
    {
      active: {
        icon: IconType.Check,
        backgroundColor: theme.colors.web.success,
        borderColor: theme.colors.web.success,
        borderStyle: 'solid',
        title: 'Aktiv',
      },
      expired: {
        icon: IconType.InfoFilled,
        backgroundColor: theme.colors.web.light,
        borderColor: theme.colors.web.neutral,
        borderStyle: 'solid',
        title: 'Utgången',
      },
      completed: {
        icon: IconType.Check,
        backgroundColor: theme.colors.web.info,
        borderColor: theme.colors.web.info,
        borderStyle: 'solid',
        title: 'Avslutad',
      },
    }

  const currentStatus = statusChipMap[props.status]

  return (
    <S.StatusContainer
      borderColor={currentStatus.borderColor}
      backgroundColor={currentStatus.backgroundColor}
      borderStyle={currentStatus.borderStyle}
    >
      <S.StatusWrapper color={currentStatus.borderColor}>
        <Icon src={currentStatus.icon} />
        <S.StatusTitle textColor={currentStatus.borderColor}>
          {currentStatus.title}
        </S.StatusTitle>
      </S.StatusWrapper>
    </S.StatusContainer>
  )
}

export default ReferralPlanStatusChip
