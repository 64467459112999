import React, { useMemo } from 'react'
import * as S from './SearchResults.style'
import {
  IExercise,
  IExerciseCategory,
  IExerciseProgram,
  IFavoriteMedia,
} from '@bonliva-traits/api/types'
import MediaCard from '@bonliva-components/web/shared/MediaCard'

type Props = {
  exerciseCategories: IExerciseCategory[]
  favorites: IFavoriteMedia[]
  favoriteFilter: (item: IExercise | IExerciseProgram) => boolean
  searchFilter: (item: IExercise | IExerciseProgram) => boolean
}

const SearchResults: React.FC<Props> = (props) => {
  const combined = props.exerciseCategories.reduce(
    (acc, item) => {
      acc.exercises.push(...item.exercises.data.map((item) => item.attributes))
      acc.programs.push(
        ...item.exercisePrograms.data.map((item) => item.attributes)
      )
      return acc
    },
    {
      programs: [],
      exercises: [],
    } as { programs: IExerciseProgram[]; exercises: IExercise[] }
  )

  const filteredPrograms = useMemo(() => {
    return combined.programs
      .filter(props.favoriteFilter)
      .filter(props.searchFilter)
  }, [combined.programs, props.favoriteFilter, props.searchFilter])

  const filteredExercises = useMemo(() => {
    return combined.exercises
      .filter(props.favoriteFilter)
      .filter(props.searchFilter)
  }, [combined.exercises, props.favoriteFilter, props.searchFilter])

  return (
    <S.SearchResults>
      <S.MediaLibraryLayout>
        {filteredPrograms.length > 0 && (
          <S.MediaLibrarySection>
            <S.MediaLibraryTitle>Program</S.MediaLibraryTitle>
            <S.MediaLibraryUnderline />
            <S.MediaLibraryContent>
              {filteredPrograms.map((media) => {
                const favorite = props.favorites.find(
                  (favorite) => favorite.mediaLibraryId === media.uuid
                )

                return (
                  <MediaCard
                    key={media.uuid}
                    canFavorite
                    media={media}
                    favoriteId={favorite?.id}
                  />
                )
              })}
            </S.MediaLibraryContent>
          </S.MediaLibrarySection>
        )}

        {filteredExercises.length > 0 && (
          <S.MediaLibrarySection>
            <S.MediaLibraryTitle>Övningar</S.MediaLibraryTitle>
            <S.MediaLibraryUnderline />
            <S.MediaLibraryContent>
              {filteredExercises.map((media) => {
                const favorite = props.favorites.find(
                  (favorite) => favorite.mediaLibraryId === media.uuid
                )

                return (
                  <MediaCard
                    key={media.uuid}
                    canFavorite
                    media={media}
                    favoriteId={favorite?.id}
                  />
                )
              })}
            </S.MediaLibraryContent>
          </S.MediaLibrarySection>
        )}
      </S.MediaLibraryLayout>
    </S.SearchResults>
  )
}

export default SearchResults
