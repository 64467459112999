import styled from 'styled-components'
import { ButtonPrimary, ButtonSecondary } from '@bonliva-ui/web'
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalWrapper,
} from '../Modal/Modal.style'

export const DeleteModal = styled(ModalWrapper)``

export const Header = styled(ModalHeader)``

export const Title = styled(ModalTitle)``

export const Content = styled(ModalContent)``

export const Footer = styled(ModalFooter)``

export const Cancel = styled(ButtonSecondary)``

export const Delete = styled(ButtonPrimary)``
