import {
  ButtonSecondary,
  H3,
  BodyRegular,
  BodySmall,
  ButtonPrimary,
} from '@bonliva-ui/web'
import styled from 'styled-components'

export const SummaryContainer = styled.div`
  display: flex;
  flex-direction: column;
`
export const TopBarWrapper = styled.div`
  padding: ${({ theme }) => theme.margin.xxs};
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.web.neutral}40;
`

export const Title = styled(H3)`
  margin-top: 6px;
  line-height: 26px;
`

export const ContentWrapper = styled.div`
  margin: ${({ theme }) => theme.margin.xxs};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const EditButton = styled(ButtonSecondary)`
  height: 40px;
`

export const MarkAsDoneButton = styled(ButtonPrimary)<{ disabled: boolean }>`
  height: 40px;
`

export const ButtonWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.margin['3xs']};
`

export const FirstWrapper = styled.div`
  width: 40%;
  gap: 32px;
  display: flex;
`

export const SummaryWrapper = styled.div`
  width: 75%;
  gap: 32px;
  display: flex;
`
export const SummaryStatusContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const SummaryContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`

export const SummaryAppointmentContent = styled.div`
  display: flex;
  flex-direction: column;
`

export const SummaryProgressContent = styled.div`
  width: 260px;
  display: flex;
  flex-direction: column;
`

export const SummaryHeader = styled(BodyRegular)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.web.darkest};
  margin-bottom: ${({ theme }) => theme.margin['3xs']};
`
export const SummaryBody = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.black};
`

export const ProgressBarWrapper = styled.div`
  margin-top: 11px;
  margin-bottom: 8px;
`

export const ProgressInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`
export const ProgressInfo = styled(BodySmall)``
