import styled from 'styled-components'
import { H3, BodyRegular, TextArea } from '@bonliva-ui/web'

export const BioSlide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const BioSlideTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const BioSlideTitle = styled(H3)``

export const BioSlideBody = styled(BodyRegular)``

export const BioSlideTextField = styled(TextArea)`
  width: 100%;
  max-width: 600px;
  min-height: 150px;
`

export const ErrorMessage = styled(BodyRegular)`
  color: ${({ theme }) => theme.colors.web.error};
`
