import styled from 'styled-components'
import { BodyRegular, ButtonPrimary, H2 } from '@bonliva-ui/web'
import { Icon } from '@bonliva-traits/web-icons'

export const BankIdModal = styled.div`
  padding: ${({ theme }) => theme.margin.xxs};
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding-bottom: ${({ theme }) => theme.margin.xl};
`

export const BonlivaLogo = styled(Icon)`
  height: 26px;
  width: 140px;
  margin: 0 auto;
  margin-bottom: ${({ theme }) => theme.margin.xxs};
  color: ${({ theme }) => theme.colors.web.logo};
`

export const Title = styled(H2)`
  text-align: center;
`

export const Label = styled(BodyRegular)`
  max-width: 65%;
  text-align: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.xxs};
  align-items: center;
`

export const QrWrapper = styled.div`
  margin: ${({ theme }) => theme.margin.xxs} auto;
`

export const Button = styled(ButtonPrimary)`
  height: 56px;
  width: 60%;
  margin: ${({ theme }) => theme.margin.xxs} auto;
`

export const UnderlineLabel = styled(Label)`
  cursor: pointer;
  color: ${({ theme }) => theme.colors.web.primary};
  border-bottom: 1px solid ${({ theme }) => theme.colors.web.primary};
  transition: filter 0.1s ease-in-out;

  &:hover {
    filter: brightness(125%);
  }
`
