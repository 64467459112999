import styled from 'styled-components'
import { Icon } from '@bonliva-traits/web-icons'
import { Link as ReactLink } from 'react-router-dom'
import {
  BodyRegular,
  BodySmall,
  ButtonPrimary,
  ButtonSecondary,
  H3,
  H4,
} from '@bonliva-ui/web'
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalWrapper,
} from '@bonliva-components/web/shared/Modal/Modal.style'

export const AppointmentInfoModal = styled(ModalWrapper)``

export const Header = styled(ModalHeader)``

export const Title = styled(ModalTitle)``

export const ChevronRight = styled(Icon)`
  color: ${({ theme }) => theme.colors.web.primary};
  width: 12px;
  height: 12px;
  transition: 0.2s ease-in-out;

  @-moz-document url-prefix() {
    margin-bottom: 2px;
  }
`

export const Link = styled(ReactLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.web.primary};
  font-weight: 400;
  gap: ${({ theme }) => theme.margin['4xs']};

  ${ChevronRight} {
    transform: translate(0px, 0);
  }

  &:hover {
    ${ChevronRight} {
      transform: translate(4px, 0);
    }
  }
`

export const Content = styled(ModalContent)``

export const Grid = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  grid-gap: ${({ theme }) => theme.margin.xxs};
`

export const GridItem = styled.div`
  padding: ${({ theme }) => theme.margin['3xs']};
  border-bottom: 1px solid ${({ theme }) => theme.colors.web.light};
`

export const Label = styled(BodySmall)`
  text-transform: uppercase;
  color: ${({ theme }) => theme.colors.web.dark};
`

export const Value = styled(BodyRegular)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const FormAnswers = styled.div`
  overflow: scroll;
  min-height: 100px;
  border: 1px solid ${({ theme }) => theme.colors.web.neutral}66;
  border-radius: ${({ theme }) => theme.radius.rounded};
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.web.light};
`

export const FormAnswersHeader = styled.div`
  margin: ${({ theme }) => theme.margin.xxs};
`

export const FormAnswersTitle = styled(H3)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const FormAnswersDescription = styled(H4)`
  color: ${({ theme }) => theme.colors.web.darkest};
  display: flex;
  gap: ${({ theme }) => theme.margin['3xs']};
  font-weight: 400;
  font-size: 18px;
`

export const FormAnswersTypes = styled(BodyRegular)`
  color: ${({ theme }) => theme.colors.web.black};
  font-size: 16px;
`

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.margin.xxs};

  border-top: 1px solid ${({ theme }) => theme.colors.web.neutral}66;
`

export const FormItemLabel = styled(BodyRegular)`
  color: ${({ theme }) => theme.colors.web.black};
  font-size: 16px;
`

export const FormItemQuestion = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darkest};
  font-size: 18px;
`

export const FormItemAnswer = styled(BodyRegular)`
  color: ${({ theme }) => theme.colors.web.darkest};
  font-size: 16px;
`

export const Footer = styled(ModalFooter)``

export const Abort = styled(ButtonSecondary)``

export const Join = styled(ButtonPrimary)``
