import * as React from 'react'
import * as S from './Dashboard.style'
import DashboardLinkCards from './components/DashboardLinkCards'
import DashboardHeader from './components/DashboardHeader'
import DashboardTodaysMeetings from './components/DashboardTodaysMeetings'
import DashboardReminders from './components/DashboardReminders'

const Dashboard: React.FC = () => {
  return (
    <S.Dashboard>
      <DashboardHeader />

      <S.DashboardLayout>
        <DashboardLinkCards />

        <S.DashboardColumn>
          <DashboardReminders />
          <DashboardTodaysMeetings />
        </S.DashboardColumn>
      </S.DashboardLayout>
    </S.Dashboard>
  )
}

export default Dashboard
