import React, {
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from 'react'
import * as S from './Calendar.style'
import CalenderTopBar from './components/CalenderTopBar'
import CalendarHeader from './components/CalendarHeader'
import CalendarSideMenu from './components/CalendarSideMenu'
import CalendarEvent from './components/CalendarEvent'
import FullCalendar from '@fullcalendar/react'
import calendarSv from '@fullcalendar/core/locales/sv'
import timeGridPlugin from '@fullcalendar/timegrid'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import {
  EventClickArg,
  EventInput,
  EventApi,
  EventContentArg,
  DayHeaderContentArg,
  DateSelectArg,
  EventSourceInput,
  CalendarApi,
} from '@fullcalendar/core'

export type CalendarRef = {
  refreshCalendar: () => void
  addEvent: (event: EventInput) => void
  getEventById: (id: string) => EventApi | null | undefined
  getApi(): CalendarApi | undefined
}

type CalendarProps = {
  type?: 'day' | 'week' | 'month'
  date?: string
  initialDate?: Date
  onEventClick?: (arg: EventClickArg) => void
  eventIsDisabled?: (event: EventContentArg) => boolean
  onCreateEventClick?: (arg: DateSelectArg) => void
  onCreateNewMeetingClick?: () => void
  sideMenuContent?: React.ReactNode
  selectable?: boolean
  events?: EventSourceInput
}

const Calendar = forwardRef<CalendarRef, CalendarProps>((props, ref) => {
  const calendarRef = useRef<FullCalendar>(null)

  useImperativeHandle(
    ref,
    () => ({
      refreshCalendar: () => {
        return calendarRef.current?.getApi().refetchEvents()
      },
      addEvent: (event) => {
        return calendarRef.current?.getApi().addEvent(event)
      },
      getEventById: (id) => {
        return calendarRef.current?.getApi().getEventById(id)
      },
      getApi: () => {
        return calendarRef.current?.getApi()
      },
    }),
    [calendarRef.current]
  )

  useEffect(() => {
    if (!props.date) return
    calendarRef.current?.getApi().gotoDate(new Date(props.date))
  }, [props.date, calendarRef.current])

  const dayHeaderContent = (args: DayHeaderContentArg) => {
    return <CalendarHeader {...args} disableOnDayClick={!props.type} />
  }

  const eventContent = (args: EventContentArg) => {
    return <CalendarEvent {...args} disabled={props.eventIsDisabled?.(args)} />
  }

  return (
    <S.Calendar>
      <S.CalendarLayout>
        <CalendarSideMenu content={props.sideMenuContent} />
        <S.CalendarContainer>
          <S.CalendarWrapper>
            <CalenderTopBar
              onCreateNewMeetingClick={props.onCreateNewMeetingClick}
              type={props.type}
              date={props.date}
              calendarRef={calendarRef}
            />
            <FullCalendar
              nowIndicator
              selectable={props.selectable}
              locale={calendarSv}
              allDaySlot={false}
              headerToolbar={false}
              ref={calendarRef}
              initialDate={props.initialDate}
              events={props.events}
              eventClick={props.onEventClick}
              select={props.onCreateEventClick}
              slotLabelFormat={{ hour: 'numeric', minute: '2-digit' }}
              plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
              dayHeaderContent={dayHeaderContent}
              height="100%"
              allDayText="Tid"
              scrollTime={'07:00:00'}
              slotDuration={'00:30:00'}
              eventColor="transparent"
              eventBorderColor="transparent"
              eventBackgroundColor="transparent"
              eventContent={eventContent}
              selectOverlap={(event) =>
                event.display === 'inverse-background' ||
                event.display === 'background'
              }
            />
          </S.CalendarWrapper>
        </S.CalendarContainer>
      </S.CalendarLayout>
    </S.Calendar>
  )
})

export default Calendar
