import { languages as langs } from '@bonliva-traits/constants'
import { ITreater } from '../../types'

export const getLanguages = ({ languages }: ITreater) => {
  if (!languages) return

  const languagesNames = languages.map(
    (code) => langs.find((lang) => lang.code === code)?.name
  )

  return languagesNames.filter((i): i is string => !!i)
}
