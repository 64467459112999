import styled, { css } from 'styled-components'
import { Icon } from '@bonliva-traits/web-icons'
import { BodySmall, ButtonPrimary, H2 } from '@bonliva-ui/web'

export const MediaPhoneDisplay = styled.div``

export const Phone = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 700px;
  aspect-ratio: 9/18;
  background: black;
  border-radius: 48px;

  -webkit-box-shadow: 2px 10px 19px -3px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 2px 10px 19px -3px rgba(0, 0, 0, 0.4);
  box-shadow: 2px 10px 19px -3px rgba(0, 0, 0, 0.4);

  &::after {
    content: '';
    position: absolute;
    top: 150px;
    left: -3px;
    width: 3px;
    height: 45px;
    background: black;
    border-radius: 5px 0 0 5px;
  }

  &::before {
    content: '';
    position: absolute;
    top: 210px;
    left: -3px;
    width: 3px;
    height: 45px;
    background: black;
    border-radius: 5px 0 0 5px;
  }
`

export const PhoneContent = styled.div`
  isolation: isolate;
  height: 670px;
  width: calc(100% - 28px);
  aspect-ratio: 9/18;
  overflow: hidden;

  background: ${({ theme }) => theme.colors.web.lightest};
  border-radius: 32px;

  &::after {
    content: '';
    position: absolute;
    top: 170px;
    right: -3px;
    width: 3px;
    height: 70px;
    background: black;
    border-radius: 0 5px 5px 0;
  }

  &::before {
    content: '';
    position: absolute;
    top: 100px;
    left: -3px;
    width: 3px;
    height: 30px;
    background: black;
    border-radius: 5px 0 0 5px;
  }
`

export const PhoneHeader = styled.div`
  z-index: 100;
  position: absolute;
  top: 15px;
  left: 14px;
  width: calc(100% - 14px * 2);
  height: 68px;

  border-radius: 32px 32px 0 0;

  display: flex;
  align-items: flex-end;
  padding: 8px;

  background: ${({ theme }) => theme.colors.web.white}cc;
  backdrop-filter: blur(18px);
`

export const BackButtonIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.web.primary};
  height: 12px;
  width: 12px;
  margin-bottom: 2px;
  transition: color 0.2s ease-out;
`

export const BackButtonLabel = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.primary};
  font-size: 15px;
  transition: color 0.2s ease-out;
`

export const BackButton = styled.div<{ $showBackButton: boolean }>`
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 4px;

  ${({ theme, $showBackButton }) =>
    !$showBackButton &&
    css`
      ${BackButtonIcon} {
        color: ${theme.colors.web.neutral};
      }

      ${BackButtonLabel} {
        color: ${theme.colors.web.neutral};
      }
    `}
`

export const HeaderTitle = styled(BodySmall)`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  font-weight: 500;
  color: #121212;
  text-align: center;
`

export const ProgressBar = styled.div<{ $steps: number; $step: number }>`
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0px;
  height: 3px;
  background-color: ${({ theme }) => theme.colors.web.light};
  overflow: hidden;

  &::after {
    content: '';
    transition: width 0.3s ease-in-out;
    display: block;
    height: 100%;
    width: ${({ $steps, $step }) => ($step / $steps) * 100}%;
    background-color: ${({ theme }) => theme.colors.web.primary};
    border-radius: 0 2px 2px 0;
  }
`

export const PhoneBody = styled.div<{ $page: number }>`
  display: flex;
  width: 100%;
  height: 100%;

  transform: translateX(${({ $page }) => $page * -100}%);
  transition: transform 0.3s ease-in-out;
`

export const PhoneBodyContent = styled.div`
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  min-width: 100%;
  padding-top: 68px;
  padding-bottom: 100px;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }
`

export const MediaPhoneDisplayVideo = styled.video<{
  height?: number
  width?: number
}>`
  background-color: black !important;
  object-fit: contain;
  height: 200px;
  width: 100%;
`

export const MediaPhoneDisplayImage = styled.img<{
  height?: number
  width?: number
}>`
  object-fit: cover;
  height: ${({ height }) => height}px;
  width: ${({ width }) => width}px;
`

export const MediaPhoneDisplayDetail = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.primary};
  margin: 7px 10px 0px;
  font-weight: 500;
`

export const MediaPhoneDisplayTitle = styled(H2)`
  font-size: 28px;
  color: #121212;
  margin: 2px 10px 5px;
  font-weight: 500;
`

export const MediaPhoneDisplayMarkdown = styled.div`
  margin: 4px 10px;
  font-family: Calibre;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: ${(props) => props.theme.colors.web.darkest};
`

export const PhoneFooter = styled.div`
  z-index: 10;
  position: absolute;
  bottom: 15px;
  left: 14px;
  width: calc(100% - 14px * 2);
  height: 90px;

  border-radius: 0 0 32px 32px;

  display: flex;
  align-items: flex-start;
  padding: 8px;

  border-top: 1px solid ${({ theme }) => theme.colors.web.light};

  background: ${({ theme }) => theme.colors.web.white}cc;
  backdrop-filter: blur(18px);
`

export const PhoneFooterButton = styled(ButtonPrimary)`
  position: relative;
  height: 46px;
  width: 100%;
`

export const PhoneFooterButtonIcon = styled(Icon)`
  position: absolute;

  top: 50%;
  right: 8px;
  transform: translateY(-50%);
  height: 12px;
  width: 12px;
`
