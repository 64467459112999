import React, { useMemo } from 'react'
import * as S from './InfoSlide.style'
import { Images } from '@bonliva-ui/assets/images'

type Props = {
  index: number
}

const InfoSlide: React.FC<Props> = (props) => {
  const copy = useMemo(
    () =>
      [
        {
          image: Images.Onboarding1,
          title: 'Schema och tidsbokning',
          text: 'Du lägger själv in ditt schema och dina bokningsbara tider.',
        },
        {
          image: Images.Onboarding2,
          title: 'Skicka hemuppgifter direkt i appen',
          text: 'Du kan skicka program och övningar direkt till patienten.',
        },
        {
          image: Images.Onboarding3,
          title: 'Träffa patienter direkt i appen',
          text: 'Samtalen med patienten sker via appen och i samtalet kan du skriva in anteckningar.',
        },
        {
          image: Images.Onboarding4,
          title: 'Enkel uppföljning',
          text: 'Du kan följa patientens utveckling och hur det går för dem mellan samtalen.',
        },
      ][props.index],
    [props.index]
  )

  return (
    <S.InfoSlide>
      <S.InfoSlideImage src={copy.image} />

      <span>
        <S.InfoSlideTitle>{copy.title}</S.InfoSlideTitle>
        <S.InfoSlideBody>{copy.text}</S.InfoSlideBody>
      </span>
    </S.InfoSlide>
  )
}

export default InfoSlide
