import { hasMore, pending, success } from '@bonliva-core/store'
import { requestPagination } from '@bonliva-traits/utils'
import { AxiosError, useApi } from '../../index'
import { IFavoriteMedia } from '../../types'
import store from '../store'

export const useFetchMediaFavorites = () => {
  const api = useApi()
  const dispatch = store.useDispatch()

  return async (page = 0, pageSize = 1000) => {
    dispatch(pending())

    try {
      const notifications = await api.get<IFavoriteMedia[]>(
        '/v1/treater/media-library-favorites',
        { params: { ...requestPagination(page, pageSize) } }
      )

      dispatch(hasMore(notifications.headers['x-total-count']))
      dispatch(success(notifications.data))
    } catch (error) {
      const err = error as AxiosError
      if (!err.response) throw err
      dispatch(fail(err.response.data))
    }
  }
}
