import React from 'react'
import AsyncSelect, { AsyncProps } from 'react-select/async'
import { GroupBase } from 'react-select'
import useSelectStyle from './SelectAsync.style'

const SelectAsync = <
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>
>(
  props: AsyncProps<Option, IsMulti, Group>
) => {
  const styles = useSelectStyle<Option, IsMulti, Group>()

  return (
    <AsyncSelect
      {...props}
      noOptionsMessage={() => 'Inga alternativ matchar din sökning'}
      menuPortalTarget={document.getElementById('popup') || document.body}
      styles={styles}
    />
  )
}

export default SelectAsync
