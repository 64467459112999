import React, { useMemo } from 'react'
import * as S from './PlanCard.style'
import { IReferralPlan, ITreatmentPlan } from '@bonliva-traits/api/types'
import ProgressBar from '../ProgressBar'

type Props = {
  onClick: () => void
  data: ITreatmentPlan | IReferralPlan
  status: React.ReactNode
}

const TreatmentPlanCard: React.FC<Props> = (props) => {
  const progressPercent = useMemo(() => {
    const { bookingsCount, completedBookingsCount } = props.data
    if (!bookingsCount || !completedBookingsCount) return 0
    Math.floor((completedBookingsCount / bookingsCount) * 100)
  }, [props.data])

  return (
    <S.PlanCard onClick={props.onClick}>
      <S.CardHeaderWrapper>
        <S.CardHeader>
          {props.data.treatmentCategory?.category || 'Ej angivet'}
        </S.CardHeader>
      </S.CardHeaderWrapper>

      <S.CardBodyWrapper>
        <S.ProgressBarWrapper>
          <ProgressBar
            count={props.data.bookingsCount}
            completed={props.data.completedBookingsCount}
          />
        </S.ProgressBarWrapper>

        <S.ProgressInfoWrapper>
          <S.ProgressInfo>
            {props.data.completedBookingsCount}/{props.data.bookingsCount}{' '}
            Slutförda
          </S.ProgressInfo>
          <S.ProgressInfo>
            {!progressPercent ? 0 : progressPercent}%
          </S.ProgressInfo>
        </S.ProgressInfoWrapper>

        {!!props.status && <S.StatusWrapper>{props.status}</S.StatusWrapper>}
      </S.CardBodyWrapper>
    </S.PlanCard>
  )
}

export default TreatmentPlanCard
