import styled, { css, keyframes } from 'styled-components'
import { BodyXSmall, H2 } from '@bonliva-ui/web'

const FadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

export const Weekday = styled(BodyXSmall)`
  transition: color 0.2s ease-in-out;
  color: ${(props) => props.theme.colors.web.darker};
  font-weight: 400;
  text-transform: uppercase;
  font-size: 12px;
`

export const Date = styled(H2)`
  transition: color 0.2s ease-in-out, background-color 0.2s ease-in-out;
  height: 42px;
  width: 42px;
  line-height: 1.2 !important;

  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => props.theme.colors.web.black};
  border-radius: 9999px;

  @-moz-document url-prefix() {
    padding-top: 4px;
  }
`

export const Wrapper = styled.div<{ $isToday: boolean }>`
  animation: ${FadeIn} 0.2s ease-in-out;
  height: 54px;
  margin-bottom: 8px;

  ${({ $isToday, theme }) =>
    $isToday &&
    css`
      ${Date} {
        background-color: ${theme.colors.web.primary};
        color: ${theme.colors.web.white};
      }

      ${Weekday} {
        color: ${theme.colors.web.primary};
      }
    `}
`

export const CalendarDayHeader = styled(Weekday)``

export const CalendarDayHeaderDate = styled(Date)``

export const CalendarDayHeaderWrapper = styled(Wrapper)``

export const CalendarDayHeaderWrapperInner = styled.div`
  position: absolute;
  top: 2px;
  left: 32px;
`

export const CalendarWeekHeader = styled(Weekday)``

export const CalendarWeekHeaderDate = styled(Date)``

export const CalendarWeekHeaderWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  height: 58px;
  cursor: pointer;

  &:hover {
    ${Date} {
      background-color: ${({ theme }) => theme.colors.web.primaryLight};
      color: ${({ theme }) => theme.colors.web.white};
    }
  }
`

export const CalendarMonthHeader = styled(Weekday)``

export const CalendarMonthHeaderWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  height: fit-content;
`
