import React from 'react'
import * as S from './Spinner.style'
import { useTheme } from 'styled-components'
import { DefaultTheme } from '@bonliva-ui/core/theme'

type Props = {
  width?: number
  height?: number
  color?: keyof DefaultTheme['colors']['web']
}

const Spinner: React.FC<Props> = (props) => {
  const theme = useTheme()

  const color = props.color
    ? theme.colors.web[props.color]
    : theme.colors.web.dark

  return (
    <S.Spinner
      src="Spinner"
      color={color}
      width={props.width || 24}
      height={props.height || 24}
    />
  )
}
export default Spinner
