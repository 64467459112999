import React, { useEffect, useCallback } from 'react'
import * as S from './InfoAndCategoryForm.style'
import { SingleValue } from 'react-select'
import Select from '@bonliva-components/web/shared/Select/Select'
import useApiState from '@bonliva-traits/hooks/useApiState'
import { ITreatmentCategory, ITreatmentPlan } from '@bonliva-traits/api/types'
import { useNavigate, useParams } from 'react-router-dom'
import { TreatmentPlanStatus } from '@bonliva-traits/api/enums'
import { useApi } from '@bonliva-traits/api'

type InfoAndCategoryFormProps = {
  selectedCategory: {
    label: string
    value: string
  } | null
  setSelectedCategory: (
    e: SingleValue<{
      label: string
      value: string
    }>
  ) => void
  treatersNote: string
  setTreatersNote: (e: string) => void
}

const InfoAndCategoryForm = (props: InfoAndCategoryFormProps) => {
  const params = useParams<{ patientId: string; treatmentPlanId: string }>()
  const api = useApi()
  const navigate = useNavigate()

  const treatmentCategories = useApiState<ITreatmentCategory[]>(
    `/v1/treatment-categories`
  )

  const treatmentPlan = useApiState<ITreatmentPlan>(
    `/v1/treaters/treatment-plans/${params.treatmentPlanId}`
  )

  const fetchTreatmentHandler = useCallback(async () => {
    await treatmentCategories.get({})
    await treatmentPlan.get({})
  }, [])

  useEffect(() => {
    fetchTreatmentHandler()
  }, [])

  const handleCategoryChange = useCallback(
    async (
      category: SingleValue<{
        label: string
        value: string
      }>
    ) => {
      try {
        props.setSelectedCategory(category)
        const data = { treatmentCategoryId: category?.value }
        await api.put(
          `/v1/treaters/treatment-plans/${params.treatmentPlanId}`,
          data
        )
      } catch (error) {
        throw new Error('Error updating treatment plan')
      }
    },
    []
  )

  const handleTreatersNoteBlur = useCallback(async () => {
    try {
      const data = { information: props.treatersNote || null }
      await api.put(
        `/v1/treaters/treatment-plans/${params.treatmentPlanId}`,
        data
      )
    } catch (error) {
      throw new Error('Error updating treatment plan')
    }
  }, [props.treatersNote])

  const handleDeteteClick = useCallback(async () => {
    try {
      await api.delete(`/v1/treaters/treatment-plans/${params.treatmentPlanId}`)
      navigate(`/patients/${params.patientId}/treatment-plans`)
    } catch (error) {
      throw new Error('Error deleting treatment plan')
    }
  }, [])

  return (
    <S.Container>
      <S.InfoAndCategoryFormWrapper>
        <S.CategoryWrapper>
          <S.Label>Välj inriktning</S.Label>
          <Select
            placeholder="Ingen"
            options={treatmentCategories?.data?.map((category) => {
              return { label: category.category, value: category.id }
            })}
            value={props.selectedCategory}
            onChange={(e) => handleCategoryChange(e)}
          />
        </S.CategoryWrapper>

        <S.NoteWrapper>
          <S.Label>Information om behandlingsplan</S.Label>
          <S.TextInput
            placeholder="Kort information om behandlingsplanen"
            value={props.treatersNote}
            onChange={(e) => props.setTreatersNote(e.target.value)}
            onBlur={handleTreatersNoteBlur}
          />
        </S.NoteWrapper>
      </S.InfoAndCategoryFormWrapper>
      {treatmentPlan.data?.status === TreatmentPlanStatus.Draft && (
        <S.DeleteButton onClick={handleDeteteClick}>
          Radera behandlingsplan
        </S.DeleteButton>
      )}
    </S.Container>
  )
}

export default InfoAndCategoryForm
