import React from 'react'
import * as S from '../InboxModal.style'
import { ITreaterNotification } from '@bonliva-traits/api/types'
import { format } from 'date-fns'

type Props = {
  notification: ITreaterNotification
}
const UpdatedBooking = (props: Props) => {
  return (
    <>
      <S.Header>
        <S.Title>Uppdaterad bokning</S.Title>

        {props.notification.user && (
          <S.Link to={`/patients/${props.notification.courier?.id}`}>
            Gå till patientprofil <S.ChevronRight src="ChevronRight" />
          </S.Link>
        )}
      </S.Header>
      <S.Content>
        <S.Grid>
          <S.GridItem key="Name">
            <S.Label>Namn</S.Label>
            <S.Value>
              {props.notification.courier?.name || 'Bonliva Care'}
            </S.Value>
          </S.GridItem>

          <S.GridItem key="Inriktning">
            <S.Label>Inriktning</S.Label>
            <S.Value>{props.notification.booking?.treatment?.category}</S.Value>
          </S.GridItem>
          <S.GridItem key="Datum">
            <S.Label>Datum för möte</S.Label>
            <S.Value>
              {props.notification.booking &&
                format(
                  new Date(props.notification.booking.startDate),
                  'yyyy-MM-dd'
                )}
            </S.Value>
          </S.GridItem>

          <S.GridItem key="Tid">
            <S.Label>Tid för möte</S.Label>
            <S.Value>
              {props.notification.booking &&
                format(new Date(props.notification.booking.startDate), 'HH:mm')}
            </S.Value>
          </S.GridItem>
        </S.Grid>
      </S.Content>
    </>
  )
}

export default UpdatedBooking
