import styled from 'styled-components'
import { H3, BodyRegular } from '@bonliva-ui/web'

export const LocationSlide = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const LocationSlideTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LocationSlideLocation = styled(H3)``

export const LocationSlideBody = styled(BodyRegular)``

export const LocationSlideSelectWrapper = styled.div`
  width: 100%;
  max-width: 500px;
`

export const LocationSuggestionWrapper = styled.div`
  background-color: white;
  padding: ${({ theme }) => theme.margin['3xs']};
  position: absolute;
  margin-top: ${({ theme }) => theme.margin['4xs']};
  display: flex;
  flex-direction: column;
  border: 1px solid ${({ theme }) => theme.colors.web.neutralLight};
  border-radius: ${({ theme }) => theme.radius.rounded};
  width: 100%;
  max-width: 500px;
`

export const LocationSuggestion = styled(BodyRegular)`
  cursor: pointer;
  display: flex;
  align-items: center;
  width: 100%;
  padding: ${({ theme }) => theme.margin['3xs']};

  &:hover {
    background-color: ${({ theme }) => theme.colors.web.lightest};
  }
`

export const Suggestion = styled(BodyRegular)``
