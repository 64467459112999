import styled from 'styled-components'
import { Icon } from '@bonliva-traits/web-icons'
import { BodySmall } from '@bonliva-ui/web'

export const Header = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.margin['3xs']};
  flex-wrap: wrap;
`

export const AlertSymbol = styled(Icon)`
  width: 18px;
  height: 18px;
`

export const Title = styled(BodySmall)``

export const Message = styled(BodySmall)`
  font-size: 14px;
`

export const Alert = styled.div<{
  $type: 'success' | 'error' | 'warning' | 'info'
}>`
  display: flex;
  flex-direction: column;
  padding: 8px 16px;
  border-radius: ${({ theme }) => theme.radius.rounded};

  -webkit-box-shadow: 1px 4px 10px -2px rgba(0, 0, 0, 0.1);
  box-shadow: 1px 4px 10px -2px rgba(0, 0, 0, 0.1);

  ${({ $type }) => {
    switch ($type) {
      case 'success':
        return `
          background-color: #dcfce7;
          border: 1px solid #86efac;
          color: #109c43;

          ${Title} {
            color: #109c43;
          }

          ${Message} {
            color: #109c43;
          }

        `
      case 'error':
        return `
          background-color: #fee2e2;
          border: 1px solid #fca5a5;
          color: #ef4444;

          ${Title} {
            color: #ef4444;
          }

          ${Message} {
            color: #ef4444;
          }
        `
      case 'warning':
        return `
          background-color: #fef9c3;
          border: 1px solid #fde047;
          color: #d97706;

          ${Title} {
            color: #d97706;
          }

          ${Message} {
            color: #d97706;
          }
        `
      case 'info':
        return `
          background-color: #dbeafe;
          border: 1px solid #93c5fd;
          color: #2A6DDD;

          ${Title} {
            color: #2A6DDD;
          }

          ${Message} {
            color: #2A6DDD;
          }
        `
    }
  }}
`
