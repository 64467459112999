import styled from 'styled-components'
import { BodySmall, H4 } from '@bonliva-ui/web'

export const MediaExercise = styled.div``

export const ExerciseProgram = styled.div`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation: fadeIn 0.2s ease-in-out;
`

export const ExerciseProgramLayoutWrapper = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`

export const ExerciseProgramLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.margin.xs};
  gap: ${({ theme }) => theme.margin.xs};
  justify-content: space-between;

  & > :last-child {
    margin-left: ${({ theme }) => theme.margin['3xs']};
  }

  @media (min-width: 1200px) {
    flex-direction: row;
    gap: ${({ theme }) => theme.margin.s};
    margin-right: ${({ theme }) => theme.margin.s};

    & > :last-child {
      margin-left: 0;
      transform: translateY(-110px);
    }
  }
`

export const ExerciseProgramContent = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${({ theme }) => theme.margin.xs};
`

export const ExerciseProgramTitle = styled(H4)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const ExerciseProgramDescription = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darkest};
  max-width: 1000px;
`

export const ExerciseProgramDetail = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const AlertWrapper = styled.div`
  max-width: 500px;
  margin-left: -2px;
`

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
`
