import styled from 'styled-components'
import { BodyXSmall } from '@bonliva-ui/web'
import { BookingType } from '@bonliva-traits/api/enums'
import { BookingMeetingType } from '@bonliva-traits/api/enums'

export const CalendarBackgroundEvent = styled.div<{
  hourly: boolean
}>`
  width: 100%;
  height: 100%;
  height: 49px !important;
  background-color: white !important;
  border-top: 1px solid
    ${(props) => (props.hourly ? props.theme.colors.web.light : 'transparent')};
`

export const CalendarAvailableEvent = styled.div<{
  hourly: boolean
}>`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 2px;
  border-top: 1px solid
    ${(props) => (props.hourly ? props.theme.colors.web.light : 'transparent')};
  cursor: pointer;

  &:hover {
    border: 1px dotted blue;
    background: ${(props) => props.theme.colors.web.secondaryLight};
  }
`
export const CalendarEventWrapper = styled.div<{
  hourly: boolean
}>`
  background-color: white;
  width: calc(100% + 5px);
  height: 100%;
  position: absolute;
  top: -1px;
  left: -2px;
  border-top: 1px solid
    ${(props) => (props.hourly ? props.theme.colors.web.light : 'transparent')};
`

export const CalendarEvent = styled.div<{
  disableEventClick: boolean
  isLoading: boolean
  meetingType: BookingMeetingType
  type: BookingType
}>`
  cursor: ${(props) => (props.disableEventClick ? 'default' : 'pointer')};

  height: 100%;
  width: 100%;
  display: flex;
  padding: 0px 5px;

  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
  border-left-width: 4px;
  border-left-style: solid;
  border-left-color: ${(props) => {
    if (props.isLoading) {
      return `none`
    } else if (props.disableEventClick) {
      return 'none'
    } else if (
      props.meetingType === BookingMeetingType.Digital &&
      props.type === BookingType.Initial
    ) {
      return '#9215CD'
    } else if (
      props.meetingType === BookingMeetingType.Digital &&
      props.type === BookingType.FollowUp
    ) {
      return '#BB6E7E'
    } else if (
      props.meetingType === BookingMeetingType.Physical &&
      props.type === BookingType.Initial
    ) {
      return '#122F42'
    } else if (
      props.meetingType === BookingMeetingType.Physical &&
      props.type === BookingType.FollowUp
    ) {
      return '#6994F8'
    }
  }};

  background-color: ${(props) => {
    if (props.isLoading) {
      return props.theme.colors.web.light
    } else if (props.disableEventClick) {
      return props.theme.colors.web.neutral
    } else if (
      props.meetingType === BookingMeetingType.Digital &&
      props.type === BookingType.Initial
    ) {
      return `#9215cd14`
    } else if (
      props.meetingType === BookingMeetingType.Digital &&
      props.type === BookingType.FollowUp
    ) {
      return `#bb6e7e28`
    } else if (
      props.meetingType === BookingMeetingType.Physical &&
      props.type === BookingType.Initial
    ) {
      return `#122f4228`
    } else if (
      props.meetingType === BookingMeetingType.Physical &&
      props.type === BookingType.FollowUp
    ) {
      return `#E7EEFE`
    }
  }};

  animation: ${(props) => (props.isLoading ? 'loading 2s infinite' : 'none')};

  @keyframes loading {
    0% {
      opacity: 0.1;
    }
    50% {
      opacity: 0.4;
    }
    100% {
      opacity: 0.1;
    }
  }
`

export const CalenderEventTitle = styled(BodyXSmall)`
  color: ${(props) => props.theme.colors.web.black};
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 14px;
  line-height: 1.2;
`
