import styled from 'styled-components'
import ReactClock from 'react-clock'
import { addPxPrefix } from '@bonliva-traits/utils'

export const AnalogClock = styled.div<{
  $height?: number | string
  $width?: number | string
}>`
  position: relative;
  align-self: center;
  height: ${({ $height }) => addPxPrefix($height || '100px')};
  width: ${({ $width }) => addPxPrefix($width || '100px')};
`

export const Clock = styled(ReactClock)`
  .react-clock {
    display: block;
    position: relative;

    &,
    & *,
    & *:before,
    & *:after {
      -moz-box-sizing: border-box;
      -webkit-box-sizing: border-box;
      box-sizing: border-box;
    }

    &__face {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;

      border: none;
      border-radius: 50%;
    }

    &__hand {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      right: 50%;

      &__body {
        position: absolute;
        background-color: black;
        transform: translateX(-50%);
      }
    }

    &__mark {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 50%;
      right: 50%;

      &__body {
        position: absolute;
        background-color: black;
        transform: translateX(-50%);
      }

      &__number {
        position: absolute;
        left: -40px;
        width: 80px;
        text-align: center;
      }
    }

    &__second-hand {
      &__body {
      }
    }
  }
  .react-clock__hour-mark:nth-child(3),
  .react-clock__hour-mark:nth-child(6),
  .react-clock__hour-mark:nth-child(9),
  .react-clock__hour-mark:nth-child(12) {
    .react-clock__hour-mark__body {
      display: none;
    }
    .react-clock__mark__number {
      top: -5% !important;
      font-weight: 300;
    }
  }
  .react-clock__hour-mark:nth-child(1),
  .react-clock__hour-mark:nth-child(2),
  .react-clock__hour-mark:nth-child(4),
  .react-clock__hour-mark:nth-child(5),
  .react-clock__hour-mark:nth-child(7),
  .react-clock__hour-mark:nth-child(8),
  .react-clock__hour-mark:nth-child(10),
  .react-clock__hour-mark:nth-child(11) {
    .react-clock__hour-mark__body {
      width: 1px !important;
    }
    .react-clock__mark__number {
      display: none;
    }
  }
  .react-clock__minute-hand__body {
    background-color: ${({ theme }) => theme.colors.web.primary};
  }
`
