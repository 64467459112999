import React from 'react'
import * as S from './PatientInfoCard.style'
import { usePatient } from '@bonliva-routes/web/special-routes/PatientRoute'

const PatientInfoCard: React.FC = () => {
  const patient = usePatient()

  return (
    <S.PatientInfoCard>
      <span>
        <S.Label>Namn</S.Label>
        <S.Body>{patient.data?.name}</S.Body>
      </span>

      <span>
        <S.Label>Personnummer</S.Label>
        <S.Body>{patient.data?.personalNumber}</S.Body>
      </span>

      <span>
        <S.Label>Telefonnummer</S.Label>
        <S.Body>{patient.data?.phone || '-'}</S.Body>
      </span>

      <span>
        <S.Label>Email</S.Label>
        <S.Body>{patient.data?.email || '-'}</S.Body>
      </span>
    </S.PatientInfoCard>
  )
}

export default PatientInfoCard
