import styled from 'styled-components'
import { BodySmall } from '@bonliva-ui/web'
import { BookingMeetingType, BookingType } from '@bonliva-traits/api/enums'
import Select from '@mui/material/Select'

export const ChipWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  & > *:first-child {
    margin-right: ${(props) => props.theme.margin.xxs};
  }
  //margin-bottom: ${(props) => props.theme.margin.xxs};
`

export const ChipSelect = styled(Select)` 
    & > div {
        padding: 0;
        padding-right: 32px
    },
`

export const Chip = styled(BodySmall)<{
  meetingType: BookingMeetingType
  type: BookingType
}>`
  padding: 2px 8px;
  border-radius: ${(props) => props.theme.radius.roundedSm};

  background-color: ${(props) => {
    if (
      props.meetingType === BookingMeetingType.Digital &&
      props.type === BookingType.Initial
    ) {
      return `#9215cd14`
    } else if (
      props.meetingType === BookingMeetingType.Digital &&
      props.type === BookingType.FollowUp
    ) {
      return `#bb6e7e28`
    } else if (
      props.meetingType === BookingMeetingType.Physical &&
      props.type === BookingType.Initial
    ) {
      return `#122f4228`
    } else if (
      props.meetingType === BookingMeetingType.Physical &&
      props.type === BookingType.FollowUp
    ) {
      return `#E7EEFE`
    }
  }};
`

export const ReferralChip = styled(BodySmall)<{ referral: boolean }>`
  padding: 2px 8px;
  border-radius: ${(props) => props.theme.radius.roundedSm};

  background-color: ${(props) => {
    if (props.referral) {
      return `#9fc7ab32`
    } else {
      return `#ffa80032`
    }
  }};
`
