import React, { useEffect } from 'react'
import * as S from './PatientTreatmentPlans.style'
import PatientTopBar from '@bonliva-components/web/shared/PatientTopBar'
import { useNavigate, useParams } from 'react-router-dom'
import { useCreateTreatmentPlan } from './hooks/useCreateTreatmentPlan'
import { TreatmentPlanStatus } from '@bonliva-traits/api/enums'
import PlanAdd from '@bonliva-components/web/shared/PlanAdd'
import PlanCard from '@bonliva-components/web/shared/PlanCard'
import TreatmentPlanStatusChip from '@bonliva-components/web/shared/TreatmentPlanStatusChip'
import { useTreatmentPlans } from '@bonliva-routes/web/special-routes/PatientRoute'

const PatientTreatmentPlans = () => {
  const navigate = useNavigate()
  const params = useParams()
  const createTreatmentPlan = useCreateTreatmentPlan()
  const treatmentPlans = useTreatmentPlans()

  useEffect(() => {
    treatmentPlans.refresh()
  }, [])

  const createNewTreatmentPlanHandler = async () => {
    if (!params.patientId) throw new Error('No patient id')
    const createdTreatmentPlan = await createTreatmentPlan(params.patientId)
    navigate(`${createdTreatmentPlan.id}/create`)
  }

  const navigateToTreatmentPlanHandler = (
    planId: string,
    status: TreatmentPlanStatus
  ) => {
    if (status === TreatmentPlanStatus.Draft) navigate(`${planId}/create`)
    else navigate(planId)
  }

  return (
    <S.PatientTreatmentPlans>
      <S.PatientTreatmentPlansLayout>
        <PatientTopBar />
        <S.TreatmentPlansWrapper>
          <S.TreatmentPlansContainer>
            {treatmentPlans.data?.map((plan) => (
              <PlanCard
                key={plan.id}
                data={plan}
                status={<TreatmentPlanStatusChip status={plan.status} />}
                onClick={() => {
                  navigateToTreatmentPlanHandler(plan.id, plan.status)
                }}
              />
            ))}

            <PlanAdd
              label="Skapa ny behandlingsplan"
              onClick={createNewTreatmentPlanHandler}
            />
          </S.TreatmentPlansContainer>
        </S.TreatmentPlansWrapper>
      </S.PatientTreatmentPlansLayout>
    </S.PatientTreatmentPlans>
  )
}

export default PatientTreatmentPlans
