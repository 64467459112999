import React, { useMemo, useEffect, useState } from 'react'
import * as S from './MediaExercise.style'
import { IExercise, IExercisePart } from '@bonliva-traits/api/types'
import { useParams } from 'react-router-dom'
import { useMediaLibrary } from '@bonliva-traits/api/media-library'
import { useMediaFavorites } from '@bonliva-traits/api/media-favorites'
import MediaPhoneDisplay from './components/MediaPhoneDisplay'
import MediaTopBar from '@bonliva-components/web/shared/MediaTopBar'
import useCmsState from '@bonliva-traits/hooks/useCmsState'
import Spinner from '@bonliva-components/web/shared/Spinner'
import MediaInfoBar from '@bonliva-components/web/shared/MediaInfoBar'
import Alert from '@bonliva-components/web/shared/Alert'

const MediaExercise = () => {
  const params = useParams<{ exerciseId: string }>()
  const mediaLibrary = useMediaLibrary()
  const favorites = useMediaFavorites()
  const [exercise, setExercise] = useState<IExercise>()
  const rawExercise = useCmsState<IExercise>('exercises', [])

  useEffect(() => {
    rawExercise.get({
      filters: { uuid: params.exerciseId },
      populate: ['deep', 7],
    })
  }, [params.exerciseId])

  useEffect(() => {
    if (!rawExercise.data.length) return
    setExercise(rawExercise.data[0])
  }, [rawExercise.data])

  const canFavorite = useMemo(() => {
    const reduced = mediaLibrary.data.some((item) => {
      return item.exercises.data.some(
        (i) => i.attributes.uuid === params.exerciseId
      )
    })

    return reduced
  }, [mediaLibrary.data, params.exerciseId])

  const favoriteId = useMemo(
    () =>
      favorites.data.find((i) => i.mediaLibraryId === params.exerciseId)?.id,
    [favorites.data, params.exerciseId]
  )

  const exerciseParts = useMemo(() => {
    return exercise?.exerciseParts
      ?.filter((i) => i.exercisePart.data)
      .map((i) => i.exercisePart.data?.attributes) as IExercisePart[]
  }, [exercise])

  return (
    <S.MediaExercise>
      {exercise && exerciseParts && (
        <S.ExerciseProgram>
          <MediaTopBar
            detail={`Övning • ${exerciseParts.length} ${
              exerciseParts.length > 1 ? 'delar' : 'del'
            } • ${exercise.timeEstimation} min`}
            title={exercise.title}
            description={exercise.subtitle}
            image={exercise.banner.data?.attributes}
          />

          <S.ExerciseProgramLayoutWrapper>
            <S.ExerciseProgramLayout>
              <S.ExerciseProgramContent>
                {!canFavorite && (
                  <S.AlertWrapper>
                    <Alert
                      type="info"
                      title="Denna övning är bara kopplad till ett program"
                      messages={[
                        'Detta innebär att du inte kan spara eller dela denna övning',
                      ]}
                    />
                  </S.AlertWrapper>
                )}

                <MediaInfoBar
                  canFavorite={canFavorite}
                  media={exercise}
                  favoriteId={favoriteId}
                />

                {exercise.purpose && (
                  <span>
                    <S.ExerciseProgramTitle>Syfte</S.ExerciseProgramTitle>

                    <S.ExerciseProgramDescription>
                      {exercise.purpose}
                    </S.ExerciseProgramDescription>
                  </span>
                )}

                <span>
                  <S.ExerciseProgramTitle>
                    {exercise.title}
                  </S.ExerciseProgramTitle>

                  <S.ExerciseProgramDescription>
                    {exercise.description}
                  </S.ExerciseProgramDescription>
                </span>
              </S.ExerciseProgramContent>

              <MediaPhoneDisplay
                exerciseTitle={exercise.title}
                parts={exerciseParts}
              />
            </S.ExerciseProgramLayout>
          </S.ExerciseProgramLayoutWrapper>
        </S.ExerciseProgram>
      )}

      {!exercise && (
        <S.Loading>
          <Spinner />
        </S.Loading>
      )}
    </S.MediaExercise>
  )
}

export default MediaExercise
