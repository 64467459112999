import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalWrapper,
} from '@bonliva-components/web/shared/Modal/Modal.style'
import { Icon } from '@bonliva-traits/web-icons'
import { ButtonPrimary } from '@bonliva-ui/web'
import styled, { css } from 'styled-components'

export const DeviceSelectorWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.margin.xxs};
  width: 100%;
`

export const DeviceSelectorPopup = styled.div`
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateY(-120%) translateX(-50%);
  background-color: ${({ theme }) => theme.colors.web.white};
  border-radius: 8px;
  box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.2);
  padding: ${({ theme }) => theme.margin.xs};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.xxs};
  width: 300px;
`

export const DeviceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: unset;
  width: 100%;
  min-width: 150px;
  max-width: 450px;
  margin-inline: auto;
  gap: ${({ theme }) => theme.margin['4xs']};
`

export const DeviceSelectorButton = styled.button`
  position: relative;
  height: 46px;
  width: 46px;
  border-radius: 9999px;
  border: none;
  padding: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  ${({ theme }) =>
    css`
      background-color: ${theme.colors.web.white};
      color: ${theme.colors.web.black};

      &:hover {
        background-color: ${theme.colors.web.neutral}66;
      }
    `}

  -webkit-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.2);
`
export const DeviceSelectorIcon = styled(Icon)`
  scale: 2.5;
`

export const DeviceSelectorModal = styled(ModalWrapper)``

export const Header = styled(ModalHeader)``

export const Title = styled(ModalTitle)``

export const Content = styled(ModalContent)`
  width: 100%;
  padding: ${({ theme }) => theme.margin.xs};
`

export const ContentInner = styled.div`
  overflow: scroll;
  min-height: 100px;
  border: 1px solid ${({ theme }) => theme.colors.web.neutral}66;
  border-radius: ${({ theme }) => theme.radius.rounded};
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.web.light};
`

export const Footer = styled(ModalFooter)``

export const Close = styled(ButtonPrimary)``
