import styled from 'styled-components'

export const VolumeMeterContainer = styled.div<{ level: number }>`
  position: absolute;
  bottom: 8px;
  left: 8px;
  width: 4px;
  height: 150px;

  --volume-level: ${(props) => props.level};
`

export const VolumeMeterYellow = styled.div`
  height: 67px;
  border-radius: 4px 4px 0 0;
  width: 4px;
  background-color: rgba(78, 78, 78, 0.8);
  overflow: hidden;
  transform: scale(1, -1);

  :before {
    content: '';
    display: flex;
    width: 4px;
    background-color: yellow;
    transition: height 0.1s ease-in-out;
    height: calc(var(--volume-level) * 2.474022761 * 100%);
  }
`

export const VolumeMeterGreen = styled.div`
  height: 67px;
  width: 4px;
  background-color: rgba(78, 78, 78, 0.8);
  margin-bottom: 2px;
  overflow: hidden;
  transform: scale(1, -1);

  :before {
    content: '';
    display: flex;
    width: 4px;
    background-color: green;
    transition: height 0.2s ease-in-out;
    height: calc((var(--volume-level) - 0.4042) * 1.776830135 * 100%);
  }
`

export const VolumeMeterRed = styled.div`
  height: 12px;
  border-radius: 0 0 4px 4px;
  width: 4px;
  background-color: rgba(78, 78, 78, 0.8);
  margin-bottom: 2px;
  overflow: hidden;
  transform: scale(1, -1);

  :before {
    content: '';
    display: flex;
    width: 4px;
    background-color: red;
    transition: height 0.3s ease-in-out;
    height: calc((var(--volume-level) - 1.67) * 30.303030303 * 100%);
  }
`
