import styled, { css } from 'styled-components'
import { H3 } from '@bonliva-ui/web'
import { Icon } from '@bonliva-traits/web-icons'
import { Link as ReactLink } from 'react-router-dom'

export const MeetingRoomControls = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  gap: ${({ theme }) => theme.margin.xxs};
  height: 54px;
  padding: 0 4px;
`

export const MeetingRoomTitleWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.margin.xxs};
  align-items: center;
  max-width: calc(50% - 16px);
`

export const MeetingRoomTitle = styled(H3)`
  color: ${({ theme }) => theme.colors.web.darkest};
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const MeetingRoomTreatment = styled(H3)`
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.web.dark};
  font-weight: 400;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`

export const MeetingRoomClock = styled(H3)`
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.web.darkest};
  font-weight: 400;
  margin-right: 8px;
  display: flex;
  align-items: center;
  gap: 16px;
`

export const ChevronRight = styled(Icon)`
  color: ${({ theme }) => theme.colors.web.primary};
  width: 12px;
  height: 12px;
  transition: 0.2s ease-in-out;

  @-moz-document url-prefix() {
    margin-bottom: 2px;
  }
`

export const Link = styled(ReactLink)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.web.primary};
  font-weight: 400;
  font-size: 18px;
  gap: ${({ theme }) => theme.margin['4xs']};

  ${ChevronRight} {
    transform: translate(0px, 0);
  }

  &:hover {
    ${ChevronRight} {
      transform: translate(4px, 0);
    }
  }
`

export const MeetingRoomControlsContent = styled.div`
  position: absolute;
  top: 4px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: ${({ theme }) => theme.margin.xxs};
  padding: 0 46px;
  background: linear-gradient(
    -90deg,
    ${({ theme }) => theme.colors.web.light} 90%,
    ${({ theme }) => theme.colors.web.light}00 100%
  );
`

export const MeetingRoomControl = styled.button<{
  $active?: boolean
  $close?: boolean
}>`
  height: 46px;
  width: 46px;
  border-radius: 9999px;
  border: none;
  padding: 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;

  ${({ $active, theme }) =>
    $active
      ? css`
          background-color: ${theme.colors.web.white};
          color: ${theme.colors.web.black};

          &:hover {
            background-color: ${theme.colors.web.neutral}66;
          }
        `
      : css`
          background-color: ${theme.colors.web.primary};
          color: ${theme.colors.web.white};

          &:hover {
            background-color: ${theme.colors.web.primaryDark}cc;
          }
        `}

  ${({ $close, theme }) =>
    $close &&
    css`
      background-color: #ef4444;
      color: ${theme.colors.web.white};

      &:hover {
        background-color: #ef4444aa;
      }
    `}

  -webkit-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.2);
`

export const MeetingRoomIcon = styled(Icon)`
  width: 20px;
  height: 20px;
`
