import React, { useEffect, useCallback } from 'react'
import * as S from './InboxModal.style'
import { ITreaterNotification } from '@bonliva-traits/api/types'
import { useReadNotification } from '@bonliva-traits/api/notifications'
import {
  PaymentProductStatus,
  TreaterNotificationType,
} from '@bonliva-traits/api/enums'
import useApiState from '@bonliva-traits/hooks/useApiState'
import Modal from '@bonliva-components/web/shared/Modal'
import Spinner from '@bonliva-components/web/shared/Spinner'
import CompletedBookingTreatmentForm from './components/CompletedBookingTreatmentFormNotification'
import CompletedTreatmentPlanTreatmentForm from './components/CompletedTreatmentPlanTreatmentFormNotification'
import NewBooking from './components/NewBookingNotification'
import UpdatedBooking from './components/UpdatedBookingNotification'
import CanceledBooking from './components/CanceledBookingNotification'
import RejectedTreatmentPlan from './components/RejectedTreatmentPlanNotification'
import AcceptedTreatmentPlan from './components/AcceptedTreatmentPlanNotification'
import MovedReferralPlan from './components/MovedReferralPlanNotification'
import SuccessModal from '@bonliva-components/web/shared/SuccessModal'
import { useToast } from '@bonliva-traits/web-toasts'

type Props = {
  selected: ITreaterNotification
  setSelected: (empty: undefined) => void
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  onRead: () => void
  openDeleteModal?: (item: ITreaterNotification) => void
}

interface INotificationProps {
  notification: ITreaterNotification
}

type IStatusToComponentMap = Record<
  TreaterNotificationType,
  React.FC<INotificationProps>
>

const StatusToComponent: IStatusToComponentMap = {
  [TreaterNotificationType.MovedReferralPlan]: MovedReferralPlan,
  [TreaterNotificationType.AcceptedTreatmentPlan]: AcceptedTreatmentPlan,
  [TreaterNotificationType.CanceledBooking]: CanceledBooking,
  [TreaterNotificationType.NewBooking]: NewBooking,
  [TreaterNotificationType.UpdatedBooking]: UpdatedBooking,
  [TreaterNotificationType.RejectedTreatmentPlan]: RejectedTreatmentPlan,
  [TreaterNotificationType.CompletedBookingTreatmentForm]:
    CompletedBookingTreatmentForm,
  [TreaterNotificationType.CompletedTreatmentPlanTreatmentForm]:
    CompletedTreatmentPlanTreatmentForm,
}

const InboxModal: React.FC<Props> = (props) => {
  const toast = useToast()
  const readNotification = useReadNotification()

  const notification = useApiState<ITreaterNotification>(`v2/notifications`)

  const refund = useApiState(`v1/treaters/appointments`)

  useEffect(() => {
    notification.get({}, props.selected.id)
  }, [])

  useEffect(() => {
    if (!notification.data) return
    readNotification(notification.data.id).then(() => {
      props.onRead()
    })
  }, [notification.data])

  const onDismissHandler = useCallback(() => {
    props.setIsOpen(false)
    props.setSelected(undefined)
  }, [])

  const refundPatientHandler = useCallback(async () => {
    const data = { isAccepted: true }
    await refund.post({ data }, `${notification.data?.bookingId}/refund`)
    props.setIsOpen(false)
    props.setSelected(undefined)
    toast.addToast({
      toast: (
        <SuccessModal
          title="Återbetalning genomförd"
          successTitle="Återbetalningen är genomförd och pengarna kommer att överföras till patientens bankkonto inom 3-5 arbetsdagar."
        />
      ),
    })
  }, [notification.data])

  return (
    <Modal.Wrapper isOpen={props.isOpen}>
      <Modal.Contents onDismiss={onDismissHandler}>
        {notification.data ? (
          <S.InboxModal>
            <Modal.CloseButton />

            {React.createElement(StatusToComponent[notification.data.type], {
              notification: notification.data,
            })}

            <S.Footer>
              {!!props.openDeleteModal && !!props.selected && (
                <Modal.DismissButton
                  onClick={() => {
                    if (!props.selected) return
                    if (!props.openDeleteModal) return
                    props.openDeleteModal(props.selected)
                  }}
                >
                  <S.Delete>Ta bort meddelande</S.Delete>
                </Modal.DismissButton>
              )}

              {notification.data.metadata?.cancelReason && (
                <S.RefundButton
                  onClick={refundPatientHandler}
                  disabled={refund.isLoading || notification.data.expired}
                >
                  {refund.isLoading ? (
                    <Spinner />
                  ) : notification.data.booking?.paymentProduct?.status &&
                    [
                      (PaymentProductStatus.PendingRefund,
                      PaymentProductStatus.CompletedRefund),
                    ].includes(
                      notification.data.booking?.paymentProduct?.status
                    ) ? (
                    'Återbetalning genomförd'
                  ) : notification.data.expired ? (
                    'Återbetalning avvisad'
                  ) : (
                    'Gör en återbetalning'
                  )}
                </S.RefundButton>
              )}

              <Modal.DismissButton>
                <S.Cancel>Stäng</S.Cancel>
              </Modal.DismissButton>
            </S.Footer>
          </S.InboxModal>
        ) : (
          <S.Center>
            <Spinner />
          </S.Center>
        )}
      </Modal.Contents>
    </Modal.Wrapper>
  )
}

export default InboxModal
