import React, { useCallback } from 'react'
import * as S from './ProfileDropdown.style'
import ProfilePicture from '../../shared/ProfilePicture'
import { useTreaterProfile } from '@bonliva-traits/api/treater-profile'
import useCleanLogout from '@bonliva-auth/helpers/useCleanLogout'

type Props = {
  isOpen: boolean
  onClose: () => void
}

const ProfileDropdown: React.FC<Props> = (props) => {
  const logout = useCleanLogout()
  const profile = useTreaterProfile()

  const logoutHandler = useCallback(() => {
    logout()
  }, [logout])

  return (
    <React.Fragment>
      <S.ProfileDropdownBackground
        onClick={props.onClose}
        $isOpen={props.isOpen}
      />

      {props.isOpen && (
        <S.ProfileDropdown>
          <S.ProfileInfo>
            <ProfilePicture profile={profile.data} width={48} height={48} />
            <S.ProfileName>{profile.data.name}</S.ProfileName>
          </S.ProfileInfo>

          <S.Divider />

          <S.NavBlock>
            <S.LinkText onClick={props.onClose} to="/account">
              Mitt konto
            </S.LinkText>
          </S.NavBlock>

          <S.Divider />

          <S.NavBlock>
            <S.LinkText
              onClick={props.onClose}
              to="https://bonliva-app-production-cms-assets.s3.eu-north-1.amazonaws.com/Bonliva_Care_integritetspolicy_%5B7%5D.pdf"
              target="_blank"
            >
              Integritet
            </S.LinkText>
          </S.NavBlock>

          <S.Divider />

          <S.LogoutButton onClick={logoutHandler}>Logga ut</S.LogoutButton>
        </S.ProfileDropdown>
      )}
    </React.Fragment>
  )
}

export default ProfileDropdown
