import React from 'react'
import * as S from './PatientReferralPlan.style'
import PatientTopBar from '@bonliva-components/web/shared/PatientTopBar'
import PlanSummary from './components/plan-summary'
import { useParams } from 'react-router-dom'
import { useReferralPlan } from './hooks/useReferralPlan'
import { useReferralPlanAppointments } from './hooks/useReferralPlanAppointments'
import { useReferralPlanTreatmentForms } from './hooks/useReferralPlanTreatmentForms'
import { useReferralPlanAssignments } from './hooks/useReferralPlanAssignments'
import PlanAppointments from '@bonliva-components/web/shared/PlanAppointments'
import PlanAssignments from '@bonliva-components/web/shared/PlanAssignments'
import PlanAnalysis from '@bonliva-components/web/shared/PlanAnalysis'
import PlanWellbeingScale from '@bonliva-components/web/shared/PlanWellbeingScale'

const PatientReferralPlan = () => {
  const params = useParams()
  const referralPlanId = params.referralPlanId as string
  const referralPlan = useReferralPlan(referralPlanId)
  const appointments = useReferralPlanAppointments(referralPlanId)
  const assignments = useReferralPlanAssignments(referralPlanId)
  const analysis = useReferralPlanTreatmentForms(referralPlanId)
  const wellbeingScale = useReferralPlanTreatmentForms(referralPlanId)

  return (
    <S.PatientReferralPlan>
      {referralPlan.data && (
        <S.PatientReferralPlanLayout>
          <PatientTopBar />
          <S.ReferralPlanWrapper>
            <PlanSummary
              referralPlan={referralPlan.data}
              refetch={referralPlan.get}
            />
          </S.ReferralPlanWrapper>

          <S.ReferralPlanWrapper>
            <PlanAppointments {...appointments} />
          </S.ReferralPlanWrapper>

          <S.ReferralPlanWrapper>
            <PlanAssignments
              {...assignments}
              referralPlanId={params.referralPlanId}
            />
          </S.ReferralPlanWrapper>

          <S.ReferralPlanWrapper>
            <PlanAnalysis {...analysis} />
          </S.ReferralPlanWrapper>

          <S.ReferralPlanWrapper>
            <PlanWellbeingScale {...wellbeingScale} />
          </S.ReferralPlanWrapper>
        </S.PatientReferralPlanLayout>
      )}
    </S.PatientReferralPlan>
  )
}

export default PatientReferralPlan
