import React from 'react'
import * as S from './Annotation.style'
import { IComponentAnnotation } from '@bonliva-traits/api/types'

export const Annotation: React.FC<IComponentAnnotation> = (props) => {
  return (
    <S.AnnotationWrapper>
      <S.H4>{props.question}</S.H4>

      <S.AnnotationTextBox />
    </S.AnnotationWrapper>
  )
}
