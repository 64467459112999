import React, { useEffect } from 'react'

const QuestionsAndAnswers = () => {
  useEffect(() => {
    const iframe = document.querySelector(
      '#loopedin-iframe'
    ) as HTMLIFrameElement
    if (iframe) iframe.style.height = `${window.innerHeight - 56 - 28}px`
  }, [])

  return (
    <iframe
      id="loopedin-iframe"
      src="https://app.loopedin.io/digital-mottgning/kb"
      width="100%"
    ></iframe>
  )
}

export default QuestionsAndAnswers
