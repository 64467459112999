import { fail } from '@bonliva-core/store'
import { AxiosError, useApi } from '../../index'
import { setUnreadCount } from '../actions'
import store from '../store'

export const useFetchNotificationsUnreadCount = () => {
  const api = useApi()
  const dispatch = store.useDispatch()

  return async () => {
    try {
      const unreadCount = await api.get<number>(`v1/notifications/unread/count`)

      dispatch(setUnreadCount(unreadCount.data))
    } catch (error) {
      const err = error as AxiosError
      if (!err.response) throw err
      dispatch(fail(err.response.data))
    }
  }
}
