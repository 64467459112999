import { ExercisePartStatus } from '../../enums'
import { IExercise, IExerciseProgram } from '../../types'
import { isExercise } from './isExercise'
import { isExerciseProgram } from './isExerciseProgram'

export function getAssignmentCompletionRate(
  data: IExercise | IExerciseProgram
) {
  if (isExercise(data)) {
    const count = 1

    const completed = data.exerciseParts.every(
      (part) =>
        part.exercisePart.data?.attributes.status ===
        ExercisePartStatus.Completed
    )
      ? 1
      : 0

    const isCompleted = completed === count
    return {
      count,
      completed,
      isCompleted,
    }
  }

  if (isExerciseProgram(data)) {
    const count = data.exercises.length

    const completed = data.exercises.reduce((acc, curr) => {
      return (
        acc +
        (curr.exercise.data?.attributes.exerciseParts.every(
          (part) =>
            part.exercisePart.data?.attributes.status ===
            ExercisePartStatus.Completed
        )
          ? 1
          : 0)
      )
    }, 0)

    const isCompleted = completed === count
    return {
      count,
      completed,
      isCompleted,
    }
  }

  return {
    count: 0,
    completed: 0,
    isCompleted: false,
  }
}
