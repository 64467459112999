import { AxiosError, useApi } from '../../index'
import { IFavoriteMedia } from '../../types'
import { createFavorite } from '../actions'
import store from '../store'

export const useCreateMediaFavorite = () => {
  const api = useApi()
  const dispatch = store.useDispatch()

  return async (type: string, mediaId: string, treaterId: string) => {
    try {
      const res = await api.post<IFavoriteMedia>(
        '/v1/treater/media-library-favorites',
        {
          type: type,
          mediaLibraryId: mediaId,
          treaterId: treaterId,
        }
      )

      dispatch(createFavorite(res.data))
    } catch (error) {
      const err = error as AxiosError
      if (!err.response) throw err
      dispatch(fail(err.response.data))
    }
  }
}
