import React from 'react'
import * as S from './Account.style'
import ProfileCard from './components/ProfileCard'
import NotificationsCard from './components/NotificationsCard'
import CompetenceCard from './components/CompetenceCard'

const Account: React.FC = () => {
  return (
    <S.Account>
      <S.Row>
        <ProfileCard />
        <S.Col>
          <CompetenceCard />
          <NotificationsCard />
        </S.Col>
      </S.Row>
    </S.Account>
  )
}

export default Account
