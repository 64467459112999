import styled, { css } from 'styled-components'
import {
  H3,
  BodyRegular,
  TextArea,
  H5,
  BodySmall,
  Input,
} from '@bonliva-ui/web'

export const EmailSlide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const EmailSlideTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

export const EmailSlideTitle = styled(H3)``

export const EmailSlideBody = styled(BodyRegular)``

export const EmailSlideTextField = styled(TextArea)`
  width: 100%;
  max-width: 600px;
  min-height: 150px;
`

export const EmailSlideTextInput = styled(Input)`
  min-width: 100%;
`

export const EmailSlideContent = styled.div<{ $disabled: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  min-height: 200px;
  margin-top: ${({ theme }) => theme.margin.xxs};
  padding: ${({ theme }) => theme.margin.xxs};
  border: 1px solid ${({ theme }) => theme.colors.web.light};
  border-radius: ${({ theme }) => theme.radius.rounded};
  gap: ${({ theme }) => theme.margin.xxs};
  transition: opacity 0.2s ease-in-out;

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

export const EmailSlideFlexWrapper = styled.div`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const EmailSlideUnderline = styled.div`
  border: 0.5px solid ${({ theme }) => theme.colors.web.light}99;
  width: calc(100% + ${({ theme }) => theme.margin.xxs} * 2);
  margin: 0 -${({ theme }) => theme.margin.xxs};
`

export const EmailSlideLabel = styled(H5)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const EmailSlideDescription = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darkest};
`
