import styled from 'styled-components'
import { H3, BodyRegular } from '@bonliva-ui/web'

export const TitleSlide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const TitleSlideTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const TitleSlideTitle = styled(H3)``

export const TitleSlideBody = styled(BodyRegular)``

export const TitleSlideSelectWrapper = styled.div`
  width: 100%;
  max-width: 500px;
`
