import styled from 'styled-components'

export const PatientReferralPlan = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`

export const PatientReferralPlanLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.margin.xxs};
  margin-bottom: -${({ theme }) => theme.margin.xxs};
`

export const ReferralPlanWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.web.white};
  border-radius: ${({ theme }) => theme.radius.rounded};
  margin-bottom: ${({ theme }) => theme.margin.s};
`
