import React, { useCallback } from 'react'
import * as S from './PostMeetingRoom.style'
import { useNavigate, useParams } from 'react-router-dom'
import Spinner from '@bonliva-components/web/shared/Spinner'
import { MeetingStatus, useMeeting } from '@bonliva-traits/meeting-web'
import PostMeetingRoomNotepad from './components/PostMeetingRoomNotepad'

const PostMeetingRoom = () => {
  const navigate = useNavigate()
  const params = useParams()
  const { appointment, startMeeting, status } = useMeeting()

  const startMeetingHandler = useCallback(async () => {
    await startMeeting(params.appointmentId || '')
    navigate(`/meeting-room/${params.appointmentId || ''}/meeting`)
  }, [navigate])

  const navigateToPatientProfileHandler = useCallback(() => {
    navigate(`/patients/${appointment?.patientId}`)
  }, [navigate, appointment])

  const navigateToCalenderHandler = useCallback(() => {
    navigate(`/calendar`)
  }, [navigate, appointment])

  return (
    <S.PostMeetingRoom>
      <S.PostMeetingRoomLayout>
        <S.PostMeetingRoomContent>
          <S.PostMeetingRoomTitle>
            Ditt samtal är avslutat
          </S.PostMeetingRoomTitle>
          <S.PostMeetingRoomInfo>Hoppas det gick bra!</S.PostMeetingRoomInfo>

          <S.PostMeetingRoomButtonsWrapper>
            <S.PostMeetingRoomOutlineButton onClick={navigateToCalenderHandler}>
              Gå till kalendern
            </S.PostMeetingRoomOutlineButton>

            <S.PostMeetingRoomOutlineButton
              onClick={startMeetingHandler}
              disabled={status === MeetingStatus.Connecting}
            >
              {status === MeetingStatus.Connecting ? (
                <Spinner />
              ) : (
                'Återanslut till mötet'
              )}
            </S.PostMeetingRoomOutlineButton>

            <S.PostMeetingRoomButton
              onClick={navigateToPatientProfileHandler}
              disabled={!appointment}
            >
              Visa patientprofil
            </S.PostMeetingRoomButton>
          </S.PostMeetingRoomButtonsWrapper>
        </S.PostMeetingRoomContent>

        <PostMeetingRoomNotepad />
      </S.PostMeetingRoomLayout>
    </S.PostMeetingRoom>
  )
}

export default PostMeetingRoom
