import React, { useState, useEffect } from 'react'
import * as S from './PatientTreatmentPlanCreate.style'
import PatientTopBar from '@bonliva-components/web/shared/PatientTopBar'
import InfoAndCategoryForm from './components/InfoAndCategoryForm'
import CreateBookingsCalendar from './components/CreateBookingsCalendar'
import useApiState from '@bonliva-traits/hooks/useApiState'
import { ITreatmentPlan } from '@bonliva-traits/api/types'
import { useParams } from 'react-router-dom'

const PatientTreatmentPlanCreate = () => {
  const params = useParams()
  const [treatersNote, setTreatersNote] = useState('')
  const [selectedCategory, setSelectedCategory] = useState<{
    label: string
    value: string
  } | null>(null)

  const treatmentPlan = useApiState<ITreatmentPlan>(
    `/v1/treaters/treatment-plans/${params.treatmentPlanId}`
  )

  const fetchTreatmentPlanHandler = async () => {
    const data = await treatmentPlan.get({})

    if (data.treatmentCategory?.category && data.treatmentCategoryId) {
      setSelectedCategory({
        label: data.treatmentCategory?.category,
        value: data.treatmentCategoryId,
      })
    }

    if (data.information) {
      setTreatersNote(data.information)
    }
  }

  useEffect(() => {
    fetchTreatmentPlanHandler()
  }, [])

  return (
    <S.TreatmentPlanCreate>
      <S.TreatmentPlanCreateLayout>
        <PatientTopBar />

        <S.TreatmentPlanCreateContainer>
          <InfoAndCategoryForm
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
            treatersNote={treatersNote}
            setTreatersNote={setTreatersNote}
          />

          {selectedCategory && (
            <CreateBookingsCalendar treatmentId={selectedCategory.value} />
          )}
        </S.TreatmentPlanCreateContainer>
      </S.TreatmentPlanCreateLayout>
    </S.TreatmentPlanCreate>
  )
}

export default PatientTreatmentPlanCreate
