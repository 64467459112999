import { State } from './types'

export const notificationsSelector = (state: State) => state

export const notificationSelector = (id: string) => (state: State) => {
  const notification = state.data.find((n) => n.id === id)

  if (!notification) throw new Error(`Notification with id ${id} not found`)

  return notification
}
