import React, { useState, useCallback } from 'react'
import * as S from './MediaPhoneDisplay.style'
import { IExerciseComponents, IExercisePart } from '@bonliva-traits/api/types'
import { ComponentType } from '@bonliva-traits/api/enums'
import { Video } from './components/Video'
import { Body } from './components/Body'
import { Image } from './components/Image'
import { Title } from './components/Title'
import { Subtitle } from './components/Subtitle'
import { Annotation } from './components/Annotation'
import { Audio } from './components/Audio'
import { Youtube } from './components/Youtube'

type Props = {
  exerciseTitle: string
  parts: IExercisePart[]
}

const MediaPhoneDisplay: React.FC<Props> = (props) => {
  const [page, setPage] = useState(0)

  const showNextPartHandler = useCallback(() => {
    setPage((prev) => {
      if (prev + 1 >= props.parts.length) return prev
      return prev + 1
    })
  }, [props.parts.length])

  const showPrevPartHandler = useCallback(() => {
    setPage((prev) => {
      if (prev - 1 < 0) return prev
      return prev - 1
    })
  }, [])

  const components = (data: IExerciseComponents) => {
    switch (data.__component) {
      case ComponentType.Title:
        return <Title {...data} />
      case ComponentType.Subtitle:
        return <Subtitle {...data} />
      case ComponentType.Body:
        return <Body {...data} />
      case ComponentType.Image:
        return <Image {...data} />
      case ComponentType.Video:
        return <Video {...data} />
      case ComponentType.Audio:
        return <Audio {...data} />
      case ComponentType.Annotation:
        return <Annotation {...data} />
      case ComponentType.Youtube:
        return <Youtube {...data} />
      default:
        const exhaustiveCheck: never = data
        throw new Error(`Unhandled ComponentType case: ${exhaustiveCheck}`)
    }
  }

  return (
    <S.MediaPhoneDisplay>
      <S.Phone>
        <S.PhoneContent>
          <S.PhoneBody $page={page}>
            {props.parts.map((part) => {
              const media = part.media?.data?.attributes.url || ''
              const isMovie = media?.match(/.mp4|.mov/)
              const isPhoto = media?.match(/.jpg|.png|.jpeg/)

              const originalAspectRatio =
                (part.media?.data?.attributes?.width || 0) /
                (part.media?.data?.attributes.height || 1)

              const width = 648 * (9 / 18)
              const height = width / originalAspectRatio

              return (
                <S.PhoneBodyContent key={part.uuid}>
                  {media && isMovie && (
                    <S.MediaPhoneDisplayVideo
                      width={width}
                      height={height}
                      controls
                      controlsList="nodownload noremoteplayback noplaybackrate"
                      disablePictureInPicture
                      src={part.media?.data?.attributes.url + '#t=1'}
                    />
                  )}

                  {media && isPhoto && (
                    <S.MediaPhoneDisplayImage
                      width={width}
                      height={height}
                      src={part.media?.data?.attributes.url}
                    />
                  )}

                  <S.MediaPhoneDisplayDetail>
                    {props.exerciseTitle}
                  </S.MediaPhoneDisplayDetail>

                  {part.content.map((data) => (
                    <span key={data.uuid}>{components(data)}</span>
                  ))}
                </S.PhoneBodyContent>
              )
            })}
          </S.PhoneBody>

          <S.PhoneHeader>
            <S.BackButton
              $showBackButton={page !== 0}
              onClick={showPrevPartHandler}
            >
              <S.BackButtonIcon src="ChevronLeft" />
              <S.BackButtonLabel>Tillbaka</S.BackButtonLabel>
            </S.BackButton>

            <S.HeaderTitle>{props.exerciseTitle}</S.HeaderTitle>

            <S.ProgressBar $steps={props.parts.length} $step={page + 1} />
          </S.PhoneHeader>

          <S.PhoneFooter>
            <S.PhoneFooterButton
              onClick={showNextPartHandler}
              disabled={page + 1 >= props.parts.length}
            >
              {page + 1 >= props.parts.length
                ? 'Slutför'
                : `Nästa ${page + 1} / ${props.parts.length}`}

              <S.PhoneFooterButtonIcon src="ChevronRight" />
            </S.PhoneFooterButton>
          </S.PhoneFooter>
        </S.PhoneContent>
      </S.Phone>
    </S.MediaPhoneDisplay>
  )
}

export default MediaPhoneDisplay
