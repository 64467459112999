import styled from 'styled-components'
import { BodyRegular, H3, TextArea } from '@bonliva-ui/web'

export const Calendar = styled.div``

export const CalendarLayout = styled.div`
  display: flex;
  min-width: 772px;
`

export const CalendarContainer = styled.div`
  width: 100%;
  min-width: 772px;
  background-color: white;
  border-radius: 0 8px 8px 0;
  padding: ${(props) => props.theme.margin.xxs};
`

export const CalendarWrapper = styled.div`
  height: calc(100vh - 152px);
  max-height: 1285px;
  padding-bottom: 24px;

  .fc-timegrid-axis-cushion.fc-scrollgrid-shrink-cushion.fc-scrollgrid-sync-inner {
    font-family: Calibre;
    font-weight: 300;
    font-size: 14px;
    color: ${(props) => props.theme.colors.web.dark};
    margin-right: 12px;
    margin-bottom: 6px;
  }

  th {
    border: none !important;
  }

  .fc-day-today {
    background: transparent !important;
    border: none !important;
  }

  .fc-timegrid-divider {
    margin: none !important;
    display: none !important;
  }

  .fc-daygrid-day-events {
    margin: 0px !important;
    overflow: hidden;
  }

  .fc-event-main {
    margin: 1px !important;
    min-height: 1.25em;
  }

  .fc-scroller {
    height: calc(100% - 56px) !important;
  }

  .fc-timegrid-slot-label {
    border: none !important;
    background-color: white;

    @-moz-document url-prefix() {
      transform: translateX(-1px) !important;
    }
  }

  .fc-timegrid-body {
    background-color: ${(props) => props.theme.colors.web.light} !important;
  }

  .fc-bg-event {
    background: transparent !important;
    opacity: 1 !important;
  }

  .fc-bg-event.available {
    background: transparent !important;
    opacity: 1 !important;
  }

  .fc-timegrid-event {
    box-shadow: none !important;
  }

  .fc-highlight {
    display: none !important;
  }

  .fc-timegrid-slot-lane {
    border-top: 1px solid ${({ theme }) => theme.colors.web.light} !important;
    border-bottom: none !important;
    border-left: none !important;
    border-right: none !important;
  }

  .fc-timegrid-slot-minor {
    border: none !important;
  }

  [data-time='00:00:00'] {
    &:first-child {
      background-color: white;

      .fc-timegrid-slot-label-cushion {
        color: white !important;
      }
    }
  }

  .fc-timegrid-now-indicator-line {
    background-color: ${(props) => props.theme.colors.web.primary} !important;
    height: 1px;
    width: 100%;
    border: none !important;

    &::after {
      content: '';
      position: absolute;
      top: -6px;
      left: -6px;
      background-color: ${(props) => props.theme.colors.web.primary} !important;
      height: 12px;
      width: 12px;
      border: none !important;
      border-radius: 9999px;
    }
  }

  .fc-timegrid-slot {
    height: 25px !important;
  }

  .fc-timegrid-now-indicator-arrow {
    display: none;
  }

  .fc-timegrid-slot.fc-timegrid-slot-label.fc-timegrid-slot-minor {
    border: none !important;
  }

  .fc-timegrid-slot .fc-timegrid-slot-lane {
    background-color: white !important;
  }

  .fc-col-header-cell-cushion {
    font-family: Calibre;
    font-weight: 400;
    font-size: 14px;
    color: ${(props) => props.theme.colors.web.dark};
  }

  .fc-timegrid-slot-label-cushion {
    font-family: Calibre;
    font-weight: 300;
    font-size: 14px;
    color: ${(props) => props.theme.colors.web.dark};
    margin-right: 12px !important;
    transform: translateY(-12px);
  }

  .fc-scrollgrid {
    border-color: transparent !important;
  }

  .fc-scrollgrid td:last-of-type {
    border-right-color: transparent !important;
  }

  .fc-scrollgrid-section.fc-scrollgrid-section-body td[role='presentation'] {
    border-bottom: none !important;

    border-bottom-color: ${({ theme }) =>
      theme.colors.web.neutral}99 !important;
  }

  [role='row']:last-of-type td {
    border-bottom-color: ${({ theme }) =>
      theme.colors.web.neutral}99 !important;
  }

  td {
    border-color: ${({ theme }) => theme.colors.web.neutral}99 !important;
  }

  .fc-daygrid-day-top {
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: Calibre;
    font-weight: 300;
    font-size: 14px;
    color: ${(props) => props.theme.colors.web.dark};
  }
`

export const DeleteModalLabel = styled(BodyRegular)``

export const DeleteModalCommentField = styled(TextArea)`
  resize: vertical;
  margin-bottom: ${({ theme }) => theme.margin.xxs};
`

export const SuccessModalTitle = styled(H3)`
  font-weight: 500;
  text-align: center;
`

export const SuccessModalInfoWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.margin.xxs};
  margin: 0 auto;
`

export const SuccessModalInfo = styled(BodyRegular)`
  display: flex;
  color: ${({ theme }) => theme.colors.web.dark};
  gap: ${({ theme }) => theme.margin['3xs']};
`

export const SuccessModalBold = styled(BodyRegular)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.web.darkest};
`
