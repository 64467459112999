import { BodySmall } from '@bonliva-ui/web'
import styled from 'styled-components'

export const PatientInfoCard = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: ${({ theme }) => theme.margin.xxs};
  gap: ${({ theme }) => theme.margin.xxs};
  background-color: ${({ theme }) => theme.colors.web.white};
  border-radius: ${({ theme }) => theme.radius.rounded};
`

export const Label = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darkest};
  font-weight: 500;
`

export const Body = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darkest};
`
