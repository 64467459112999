import React, { useCallback } from 'react'
import { useNavigate } from 'react-router-dom'
import * as S from './404.style'

const Exception = () => {
  const navigate = useNavigate()

  const navigateToHomeHandler = useCallback(() => {
    navigate('/')
  }, [navigate])

  const openMailHandler = useCallback((e: React.MouseEvent) => {
    window.location.href = 'mailto:info@bonliva.se'
    e.preventDefault()
  }, [])

  return (
    <S.Exception>
      <S.ExceptionLayout>
        <S.Exception404>404</S.Exception404>

        <S.ExceptionTitle>Hoppsan! Nu gick något snett...</S.ExceptionTitle>

        <S.ExceptionDescription>
          Vi lyckades inte hitta sidan du sökte. Det kan bero på att den
          flyttats, inte längre finns. Kontrollera att du stavat rätt i url:en.
          Om du inte hittar det du söker kan du alltid kontakta Bonliva Care så
          hjälper vi dig.
        </S.ExceptionDescription>

        <S.ExceptionButtonWrapper>
          <S.ExceptionButton onClick={navigateToHomeHandler}>
            Tillbaka till startsidan
          </S.ExceptionButton>

          <S.ExceptionButton onClick={openMailHandler}>
            Kontakta Bonliva Care
          </S.ExceptionButton>
        </S.ExceptionButtonWrapper>
      </S.ExceptionLayout>
    </S.Exception>
  )
}

export default Exception
