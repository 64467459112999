import React, { FC } from 'react'
import * as S from '../CreateBookingModal.style'
import Modal from '@bonliva-components/web/shared/Modal'
import { Icon } from '@bonliva-traits/web-icons'
import { addMinutes, format } from 'date-fns'
import { useToast } from '@bonliva-traits/web-toasts'
import { BookingMeetingType } from '@bonliva-traits/api/enums'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  meetingStart: Date | undefined
  patientName: string
  date: Date | undefined
  meetingType: BookingMeetingType
}

export const SuccessModal: FC<Props> = (props) => {
  const toast = useToast()
  return (
    <Modal.Wrapper isOpen={props.isOpen}>
      <Modal.Contents onDismiss={() => props.setIsOpen(false)} maxWidth={600}>
        <S.SuccessWrapper>
          <S.ImageWrapper>
            <Icon src="SuccessFulBooking" width={200} />
          </S.ImageWrapper>
          <S.SuccessTitle>
            {`Du har bokat ett samtal med ${props.patientName}`}
          </S.SuccessTitle>
          <S.FlexCenter>
            <S.Body>
              <S.Bold>Datum:</S.Bold>{' '}
              {format(props.date || new Date(), 'yyyy-MM-dd')}
            </S.Body>
            <S.Body>
              <S.Bold>Tid:</S.Bold>{' '}
              {format(props.meetingStart || new Date(), 'HH:mm')} -
              {format(
                addMinutes(props.meetingStart || new Date(), 45) || new Date(),
                'HH:mm'
              )}
            </S.Body>
            <S.Body>
              <S.Bold>Typ av möte:</S.Bold>{' '}
              {props.meetingType === BookingMeetingType.Digital
                ? 'Digitalt'
                : 'Fysiskt'}
            </S.Body>
          </S.FlexCenter>
          <S.BackToCalendarButton
            onClick={() => {
              toast.removeToast('success')
            }}
          >
            Tillbaka till kalendern
          </S.BackToCalendarButton>
        </S.SuccessWrapper>
      </Modal.Contents>
    </Modal.Wrapper>
  )
}
