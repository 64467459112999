import styled, { css } from 'styled-components'

export const NoWorkingTimesWarning = styled.div<{ $isActive: boolean }>`
  z-index: 100;
  position: fixed;
  width: fit-content;
  height: fit-content;
  bottom: ${({ theme }) => theme.margin.xxs};
  right: ${({ theme }) => theme.margin.xxs};
  pointer-events: all;
  visibility: visible;
  opacity: 1;

  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;

  ${({ $isActive }) =>
    !$isActive &&
    css`
      visibility: none;
      opacity: 0;
      pointer-events: none;
    `}

  svg {
    &:last-child {
      position: absolute;
      top: ${({ theme }) => theme.margin.xxs};
      right: 0;
      width: 18px;
      height: 18px;
      margin-right: ${({ theme }) => theme.margin.xxs};
      color: #d97706;
      cursor: pointer;
    }
  }
`
