import React from 'react'
import Deviations from './components/Deviations'
import Schedules from './components/Schedules'
import * as S from './WorkTimes.style'

const WorkTimes: React.FC = () => {
  return (
    <S.WorkTimes>
      <Schedules />
      <Deviations />
    </S.WorkTimes>
  )
}

export default WorkTimes
