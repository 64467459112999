import React, { useState, useEffect, useCallback } from 'react'
import * as S from './Deviations.style'
import { format } from 'date-fns'
import { IDeviatingTime } from '@bonliva-traits/api/types'
import { DeviationType } from '@bonliva-traits/api/enums'
import useApiState from '@bonliva-traits/hooks/useApiState'
import Table from '@bonliva-components/web/shared/Table'
import Alert from '@bonliva-components/web/shared/Alert'
import DeleteModal from '@bonliva-components/web/shared/DeleteModal'
import SuccessModal from '@bonliva-components/web/shared/SuccessModal'
import DeviationsModal from '../DeviationsModal'
import { useApi } from '@bonliva-traits/api'

const tableColumns = [
  {
    label: 'Dag',
  },
  {
    label: 'Tid',
  },
  {
    label: 'Typ',
  },
  {
    label: 'Tar samtal',
  },
  {
    label: '',
  },
]

const Deviations: React.FC = () => {
  const api = useApi()
  const [showModal, setShowModal] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [selected, setSelected] = useState<IDeviatingTime>()

  const deviatingTimes = useApiState<IDeviatingTime[]>(
    '/v1/treater/deviating-working-times'
  )

  useEffect(() => {
    deviatingTimes.get()
  }, [showModal])

  const openCreateModalHandler = useCallback(() => {
    setSelected(undefined)
    setShowModal(true)
  }, [])

  const onDeletedHandler = useCallback(async () => {
    if (!selected) return
    await api.delete(`/v1/treater/deviating-working-times/${selected.id}`)
    await deviatingTimes.get()
    setShowDeleteModal(false)
    setShowSuccessModal(true)
  }, [selected])

  return (
    <S.Deviations>
      <Table.Wrapper
        header={
          <Table.Header
            title="Avvikande tider"
            showButton
            buttonTitle="Lägg till avvikande tid"
            buttonOnClick={openCreateModalHandler}
          />
        }
        head={<Table.Head columns={tableColumns} />}
        body={
          <Table.Body isLoading={deviatingTimes.isLoading}>
            {deviatingTimes.hasLoaded && !deviatingTimes.data?.length && (
              <S.EmptyInbox>
                <td>
                  <Alert type="info" title="Inga avvikande tider hittades" />
                </td>
              </S.EmptyInbox>
            )}

            {deviatingTimes.data?.map((d) => {
              const onClickHandler = () => {
                setSelected(d)
                setShowModal(true)
              }

              const isActive = new Date(d.deviationDateStart) > new Date()

              const date = isActive
                ? format(new Date(d.deviationDateStart), 'yyyy-MM-dd')
                : 'Period har passerat'

              const formatLocationAvailability = (
                acceptDigital: boolean,
                acceptPhysical: boolean
              ) => {
                if (acceptDigital && acceptPhysical) return 'Fysiskt & Digitalt'
                if (acceptDigital && !acceptPhysical) return 'Digitalt'
                if (!acceptDigital && acceptPhysical) return 'Fysiskt'
              }

              return (
                <S.TableRow key={d.id} onClick={onClickHandler}>
                  <S.FlexSpan>
                    <S.RowText>{date}</S.RowText>
                  </S.FlexSpan>

                  <S.FlexSpan>
                    <S.RowText>
                      {format(new Date(d.deviationDateStart), 'HH:mm')} {' - '}
                      {format(new Date(d.deviationDateEnd), 'HH:mm')}
                    </S.RowText>
                  </S.FlexSpan>

                  <S.FlexSpan>
                    <S.RowText>
                      {
                        {
                          [DeviationType.Unavailable]: 'Ej bokningsbar',
                          [DeviationType.Available]: 'Extra tid',
                        }[d.deviationType]
                      }
                    </S.RowText>
                  </S.FlexSpan>
                  {d.deviationType === DeviationType.Available && (
                    <S.FlexSpan>
                      <S.RowText>
                        {formatLocationAvailability(
                          d.acceptDigitalMeetings,
                          d.acceptPhysicalMeetings
                        )}
                      </S.RowText>
                    </S.FlexSpan>
                  )}
                </S.TableRow>
              )
            })}
          </Table.Body>
        }
      />

      {showModal && (
        <DeviationsModal
          selected={selected}
          setSelected={setSelected}
          isOpen={showModal}
          setIsOpen={setShowModal}
          openDeleteModal={(item) => {
            setSelected(item)
            setShowDeleteModal(true)
          }}
        />
      )}

      {showDeleteModal && (
        <DeleteModal
          items={selected}
          isLoading={deviatingTimes.isLoading}
          hasError={deviatingTimes.hasError}
          isOpen={showDeleteModal}
          setIsOpen={setShowDeleteModal}
          onDelete={onDeletedHandler}
          title={'Ta bort avvikande tid'}
          warningTitle={
            'Är du säker på att du vill ta bort denna avvikande tid?'
          }
          warningMessages={[
            'Du kan inte ångra dig efter att du har tagit bort något.',
          ]}
        />
      )}

      {showSuccessModal && (
        <SuccessModal
          isOpen={showSuccessModal}
          setIsOpen={setShowSuccessModal}
          title="Lyckad borttagning"
          successTitle="Borttagningen har genomförts"
        />
      )}
    </S.Deviations>
  )
}

export default Deviations
