import React, { useState, useCallback } from 'react'
import * as S from './MediaInfoBar.style'
import { IExercise, IExerciseProgram } from '@bonliva-traits/api/types'
import { format } from 'date-fns'
import ShareMediaModal from '../ShareMediaModal'
import SuccessModal from '../SuccessModal'
import { isExercise } from '@bonliva-traits/api/assignments/helpers/isExercise'
import { isExerciseProgram } from '@bonliva-traits/api/assignments/helpers/isExerciseProgram'
import { useTreaterProfile } from '@bonliva-traits/api/treater-profile'
import {
  useCreateMediaFavorite,
  useDeleteMediaFavorite,
} from '@bonliva-traits/api/media-favorites'

type Props = {
  media: IExercise | IExerciseProgram
  canFavorite: boolean
  favoriteId?: string
}

const MediaInfoBar: React.FC<Props> = (props) => {
  const profile = useTreaterProfile()
  const createFavorite = useCreateMediaFavorite()
  const deleteFavorite = useDeleteMediaFavorite()
  const [showShareModal, setShowShareModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const openShareModalHandler = useCallback(() => {
    setShowShareModal(true)
  }, [])

  const setFavoriteHandler = useCallback(() => {
    if (!props.canFavorite || !props.media.uuid) return

    if (isExercise(props.media)) {
      if (props.favoriteId) deleteFavorite(props.favoriteId)
      else createFavorite('exercise', props.media.uuid, profile.data.id)
    }

    if (isExerciseProgram(props.media)) {
      if (props.favoriteId) deleteFavorite(props.favoriteId)
      else createFavorite('program', props.media.uuid, profile.data.id)
    }
  }, [props.favoriteId])

  return (
    <React.Fragment>
      <S.MediaInfoBar>
        <S.MediaInfoBarContent>
          <S.FlexWrapper>
            <S.MediaInfoBarLabel>Skapad av:</S.MediaInfoBarLabel>
            <S.MediaInfoBarValue>{props.media?.creator}</S.MediaInfoBarValue>
          </S.FlexWrapper>

          <S.FlexWrapper>
            <S.MediaInfoBarLabel>Estimerad tid:</S.MediaInfoBarLabel>
            <S.MediaInfoBarValue>
              {props.media.timeEstimation} min
            </S.MediaInfoBarValue>
          </S.FlexWrapper>

          <S.FlexWrapper>
            <S.MediaInfoBarLabel>Publicerad:</S.MediaInfoBarLabel>
            <S.MediaInfoBarValue>
              {format(new Date(props.media.publishedAt), 'yyyy-MM-dd')}
            </S.MediaInfoBarValue>
          </S.FlexWrapper>

          <S.FlexWrapper>
            <S.MediaInfoBarLabel>Redigerad:</S.MediaInfoBarLabel>
            <S.MediaInfoBarValue>
              {props.media.updatedAt
                ? format(new Date(props.media.updatedAt), 'yyyy-MM-dd')
                : '-'}
            </S.MediaInfoBarValue>
          </S.FlexWrapper>
        </S.MediaInfoBarContent>

        <S.FlexWrapper>
          {props.canFavorite && (
            <React.Fragment>
              <S.IconButton
                src={props.favoriteId ? 'HeartFilled' : 'Heart'}
                onClick={setFavoriteHandler}
              />

              <S.IconButton onClick={openShareModalHandler} src="Share" />
            </React.Fragment>
          )}
        </S.FlexWrapper>
      </S.MediaInfoBar>

      {showShareModal && (
        <ShareMediaModal
          media={props.media}
          isOpen={showShareModal}
          setIsOpen={setShowShareModal}
          setSuccessModalOpen={setShowSuccessModal}
        />
      )}

      {showSuccessModal && (
        <SuccessModal
          isOpen={showSuccessModal}
          setIsOpen={setShowSuccessModal}
          title="Delning genomförd"
          successTitle={`${
            isExercise(props.media)
              ? 'Övningen'
              : isExerciseProgram(props.media)
              ? 'Programmet'
              : 'Media'
          } har delats`}
        />
      )}
    </React.Fragment>
  )
}

export default MediaInfoBar
