import React, { useState, useEffect } from 'react'
import ReactDom from 'react-dom'
import * as S from './Modal.style'

type ModalContext = {
  isOpen: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const ModalContext = React.createContext<ModalContext>({
  isOpen: false,
  setIsOpen: () => null,
})

type ModalWrapper = {
  isOpen?: boolean
}

const ModalWrapper: React.FC<React.PropsWithChildren<ModalWrapper>> = (
  props
) => {
  const [isOpen, setIsOpen] = useState(props.isOpen || false)

  useEffect(() => {
    if (props.isOpen === undefined) return
    setIsOpen(props.isOpen)
  }, [props.isOpen])

  return (
    <ModalContext.Provider value={{ isOpen, setIsOpen }}>
      {props.children}
    </ModalContext.Provider>
  )
}

type ModalButton = {
  onClick?: () => void
}

const ModalDismissButton: React.FC<React.PropsWithChildren<ModalButton>> = ({
  children,
  onClick,
}) => {
  const { setIsOpen } = React.useContext(ModalContext)

  if (!React.isValidElement(children))
    throw new Error('ModalDismissButton child is not a valid element')

  const props = {
    onClick: () => {
      setIsOpen(false)
      if (onClick) onClick()
    },
  }

  return React.cloneElement(children, props)
}

const ModalOpenButton: React.FC<React.PropsWithChildren<ModalButton>> = ({
  children,
  onClick,
}) => {
  const { setIsOpen } = React.useContext(ModalContext)

  if (!React.isValidElement(children))
    throw new Error('ModalOpenButton child is not a valid element')

  const props = {
    onClick: () => {
      setIsOpen(true)
      if (onClick) onClick()
    },
  }

  return React.cloneElement(children, props)
}

const ModalCloseButton: React.FC = () => {
  const { setIsOpen } = React.useContext(ModalContext)

  return <S.ModalCloseButton onClick={() => setIsOpen(false)} src="Close" />
}

type ModalContents = {
  foregroundStyle?: React.CSSProperties
  step?: number
  steps?: number
  backgroundDismissDisabled?: boolean
  onDismiss?: () => void
  onShow?: () => void
  height?: string | number
  width?: string | number
  maxHeight?: string | number
  maxWidth?: string | number
}

const ModalContents: React.FC<React.PropsWithChildren<ModalContents>> = (
  props
) => {
  const { isOpen, setIsOpen } = React.useContext(ModalContext)
  const foregroundStyle = props.foregroundStyle
  useEffect(() => {
    if (isOpen && props.onShow) props.onShow()
    if (!isOpen && props.onDismiss) props.onDismiss()
  }, [isOpen, props.onShow])

  if (!isOpen) return null

  return ReactDom.createPortal(
    <S.Modal>
      <S.background
        onClick={() => !props.backgroundDismissDisabled && setIsOpen(false)}
      />
      <S.Foreground
        $height={props.height}
        $width={props.width}
        $maxHeight={props.maxHeight}
        $maxWidth={props.maxWidth}
        style={foregroundStyle}
      >
        {props.children}

        {props.steps && (
          <S.Pagination>
            {Array.from({ length: props.steps }).map((_, i) => (
              <S.PaginationDot key={i} $active={i === props.step} />
            ))}
          </S.Pagination>
        )}
      </S.Foreground>
    </S.Modal>,
    document.getElementById('modal') || document.body
  )
}

const Modal = {
  Wrapper: ModalWrapper,
  OpenButton: ModalOpenButton,
  DismissButton: ModalDismissButton,
  CloseButton: ModalCloseButton,
  Contents: ModalContents,
}

export default Modal
