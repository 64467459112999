import React, { useCallback, useEffect, useState, useMemo } from 'react'
import useInterval from '@bonliva-traits/hooks/useInterval'
import { Outlet, useOutletContext, useParams } from 'react-router-dom'
import { requestPagination } from '@bonliva-traits/utils'
import { ResponseError } from '@bonliva-core/store'
import { useDebouncedCallback } from 'use-debounce'
import { Dispatch, SetStateAction } from 'react'
import { format, sub } from 'date-fns'
import {
  IAppointment,
  IAssignment,
  INotification,
  IPatient,
  IReferralPlan,
  ITreatmentPlan,
} from '@bonliva-traits/api/types'
import useApiState from '@bonliva-traits/hooks/useApiState'
import useWindowSize from '@bonliva-traits/hooks/useWindowSize'
import { RawAxiosResponseHeaders, AxiosResponseHeaders } from 'axios'

type Headers = RawAxiosResponseHeaders | AxiosResponseHeaders

type IPatientContext = {
  data: IPatient | undefined
  error: ResponseError<IPatient> | undefined
  hasError: boolean
  hasLoaded: boolean
  isLoading: boolean
  headers: Headers
}

const usePatientContextData = (patientId: string): IPatientContext => {
  const patient = useApiState<IPatient>('/v1/treaters/patients')

  const fetchPatientHandler = useCallback(() => {
    patient.get({}, patientId)
  }, [])

  useEffect(() => {
    fetchPatientHandler()
  }, [fetchPatientHandler])

  return patient
}

type IActiveAppointmentContext = {
  data: IAppointment | undefined
  error: ResponseError<IAppointment[]> | undefined
  hasError: boolean
  hasLoaded: boolean
  isLoading: boolean
}

const useActiveAppointmentContextData = (
  patientId: string
): IActiveAppointmentContext => {
  const appointments = useApiState<IAppointment[]>(
    `/v2/treaters/patients/${patientId}/appointments`
  )

  useInterval(
    () => {
      const params = {
        _end: 1,
        _sort: 'booking.start_date',
        _order: 'DESC',
        endDate: `gt:${new Date().toISOString()}`,
        startDate: `lt:${new Date().toISOString()}`,
      }

      appointments.get({ params })
    },
    60000,
    []
  )

  return {
    data: appointments.data?.[0],
    error: appointments.error,
    hasError: appointments.hasError,
    hasLoaded: appointments.hasLoaded,
    isLoading: appointments.isLoading,
  }
}

type IUpcomingAppointmentsContext = {
  data: IAppointment[] | undefined
  error: ResponseError<IAppointment[]> | undefined
  hasError: boolean
  hasLoaded: boolean
  isLoading: boolean
  headers: Headers
  numberOfAppointments: string
}

const useUpcomingAppointmentsContextData = (
  patientId: string
): IUpcomingAppointmentsContext => {
  const upcomingAppointments = useApiState<IAppointment[]>(
    '/v2/treaters/patients'
  )

  const fetchUpcomingAppointmentsHandler = useCallback(() => {
    const params = {
      endDate: `gt:${format(
        sub(new Date(), { hours: 1 }),
        'yyyy-MM-dd HH:mm:ss+00:00'
      )}`,
      _sort: 'booking.start_date',
      _order: 'ASC',
    }

    upcomingAppointments.get({ params }, `${patientId}/appointments`)
  }, [])

  useInterval(fetchUpcomingAppointmentsHandler, 60000, [patientId])

  const numberOfAppointments = useMemo(
    () => upcomingAppointments.headers['x-total-count'],
    [upcomingAppointments.data]
  )

  return {
    data: upcomingAppointments.data,
    error: upcomingAppointments.error,
    hasError: upcomingAppointments.hasError,
    headers: upcomingAppointments.headers,
    hasLoaded: upcomingAppointments.hasLoaded,
    isLoading: upcomingAppointments.isLoading,
    numberOfAppointments,
  }
}

type IAppointmentsContext = {
  headers: Headers
  data: IAppointment[] | undefined
  error: ResponseError<IAppointment[]> | undefined
  hasError: boolean
  hasLoaded: boolean
  isLoading: boolean
  pageSize: number
  numberOfPages: number
  page: number
  isAscending: boolean
  sortBy: string
  setPage: Dispatch<SetStateAction<number>>
  setIsAscending: Dispatch<SetStateAction<boolean>>
  setSortBy: Dispatch<SetStateAction<string>>
}

const useAppointmentsContextData = (
  patientId: string
): IAppointmentsContext => {
  const [pageSize] = useState(10)
  const [page, setPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isAscending, setIsAscending] = useState(false)
  const [sortBy, setSortBy] = useState('booking.start_date')

  const appointments = useApiState<IAppointment[]>('/v2/treaters/patients')

  useEffect(() => {
    setIsLoading(true)
    fetchAppointmentsHandler()
  }, [sortBy, isAscending, page])

  const fetchAppointmentsHandler = useDebouncedCallback(async () => {
    const order = isAscending ? 'ASC' : 'DESC'

    const params = {
      ...requestPagination(page, pageSize),
      ['_sort']: sortBy,
      ['_order']: order,
    }

    await appointments.get({ params }, `${patientId}/appointments`)

    setIsLoading(false)
  }, 400)

  const numberOfPages = useMemo(() => {
    if (!appointments.headers?.['x-total-count']) return 0
    const total = parseInt(appointments.headers['x-total-count'])
    return Math.ceil(total / pageSize)
  }, [appointments.data])

  return {
    pageSize,
    page,
    setPage,
    isAscending,
    setIsAscending,
    sortBy,
    setSortBy,
    isLoading,
    numberOfPages,
    data: appointments.data,
    error: appointments.error,
    hasError: appointments.hasError,
    headers: appointments.headers,
    hasLoaded: appointments.hasLoaded,
  }
}

type INotificationsContext = {
  headers: Headers
  data: INotification[] | undefined
  error: ResponseError<INotification[]> | undefined
  refresh: () => void
  hasError: boolean
  hasLoaded: boolean
  isLoading: boolean
  pageSize: number
  numberOfPages: number
  page: number
  isAscending: boolean
  sortBy: string
  searchValue: string
  setSearchValue: Dispatch<SetStateAction<string>>
  setPage: Dispatch<SetStateAction<number>>
  setIsAscending: Dispatch<SetStateAction<boolean>>
  setSortBy: Dispatch<SetStateAction<string>>
}

const useNotificationsContextData = (
  patientId: string
): INotificationsContext => {
  const [pageSize] = useState(10)
  const [page, setPage] = useState(0)
  const [isLoading, setIsLoading] = useState(false)
  const [isAscending, setIsAscending] = useState(false)
  const [sortBy, setSortBy] = useState('n.created_at')
  const [searchValue, setSearchValue] = useState('')

  const notifications = useApiState<INotification[]>('/v2/notifications/sent')

  useEffect(() => {
    setIsLoading(true)
    fetchNotificationsHandler()
  }, [sortBy, isAscending, page, searchValue])

  const fetchNotificationsHandler = useDebouncedCallback(async () => {
    const order = isAscending ? 'ASC' : 'DESC'

    const params = {
      ...requestPagination(page, pageSize),
      ['_sort']: sortBy,
      ['_order']: order,
      ['userId']: patientId,
      searchQuery: searchValue,
    }
    await notifications.get({ params })

    setIsLoading(false)
  }, 400)

  const refreshNotificationsHandler = useCallback(() => {
    setPage(0)
    notifications.unload()
    fetchNotificationsHandler()
  }, [fetchNotificationsHandler])

  const numberOfPages = useMemo(() => {
    if (!notifications.headers?.['x-total-count']) return 0
    const total = parseInt(notifications.headers['x-total-count'])
    return Math.ceil(total / pageSize)
  }, [notifications.data])

  return {
    pageSize,
    page,
    setPage,
    isAscending,
    setIsAscending,
    sortBy,
    setSortBy,
    isLoading,
    numberOfPages,
    searchValue,
    setSearchValue,
    refresh: refreshNotificationsHandler,
    data: notifications.data,
    error: notifications.error,
    hasError: notifications.hasError,
    headers: notifications.headers,
    hasLoaded: notifications.hasLoaded,
  }
}

type IAssignmentsContext = {
  headers: Headers
  data: IAssignment[] | undefined
  error: ResponseError<IAssignment[]> | undefined
  refresh: () => void
  hasError: boolean
  hasLoaded: boolean
  isLoading: boolean
  pageSize: number
  page: number
  numberOfPages: number
  setPage: Dispatch<SetStateAction<number>>
}

const useAssignmentsContextData = (patientId: string): IAssignmentsContext => {
  const windowSize = useWindowSize()
  const [pageSize, setPageSize] = useState(4)
  const [page, setPage] = useState(0)

  const assignments = useApiState<IAssignment[]>('/v1/treaters/assignments')

  const fetchAssignmentsHandler = useCallback(async () => {
    const params = {
      ...requestPagination(page, pageSize),
      patientId: patientId,
    }

    assignments.get({ params })
  }, [page, pageSize])

  const refreshAssignmentsHandler = useCallback(() => {
    setPage(0)
    assignments.unload()
    fetchAssignmentsHandler()
  }, [fetchAssignmentsHandler])

  useEffect(() => {
    fetchAssignmentsHandler()
  }, [fetchAssignmentsHandler])

  useEffect(() => {
    if (windowSize.width < 1884 && pageSize === 6) {
      assignments.unload()
      setPageSize(4)
    }

    if (windowSize.width > 1883 && pageSize === 4) {
      assignments.unload()
      setPageSize(6)
    }
  }, [windowSize])

  const numberOfPages = useMemo(() => {
    if (!assignments.headers?.['x-total-count']) return 0
    const total = parseInt(assignments.headers['x-total-count'])
    return Math.ceil(total / pageSize)
  }, [assignments.data, assignments.headers, pageSize])

  return {
    pageSize,
    page,
    setPage,
    numberOfPages,
    data: assignments.data,
    error: assignments.error,
    hasError: assignments.hasError,
    headers: assignments.headers,
    hasLoaded: assignments.hasLoaded,
    isLoading: assignments.isLoading,
    refresh: refreshAssignmentsHandler,
  }
}

type ITreatmentPlanContext = {
  headers: Headers
  data: ITreatmentPlan[] | undefined
  error: ResponseError<ITreatmentPlan[]> | undefined
  hasError: boolean
  hasLoaded: boolean
  isLoading: boolean
  refresh: () => void
}

const useTreatmentPlanContextData = (
  patientId: string
): ITreatmentPlanContext => {
  const treatmentPlans = useApiState<ITreatmentPlan[]>(
    `/v1/treaters/treatment-plans`
  )

  useInterval(
    () => {
      fetchTreatmentPlansHandler()
    },
    60000,
    [patientId]
  )

  const sortedData = useMemo(() => {
    if (!treatmentPlans.data) return undefined
    return treatmentPlans.data.sort((a, b) => {
      if (a.status === b.status) return a.createdAt < b.createdAt ? -1 : 1
      return a.status < b.status ? -1 : 1
    })
  }, [treatmentPlans.data])

  const fetchTreatmentPlansHandler = () => {
    treatmentPlans.get({
      params: { patientId: patientId },
    })
  }

  return {
    data: sortedData,
    error: treatmentPlans.error,
    hasError: treatmentPlans.hasError,
    headers: treatmentPlans.headers,
    hasLoaded: treatmentPlans.hasLoaded,
    isLoading: treatmentPlans.isLoading,
    refresh: fetchTreatmentPlansHandler,
  }
}

type IReferralPlanContextData = {
  headers: Headers
  data: IReferralPlan[] | undefined
  error: ResponseError<IReferralPlan[]> | undefined
  hasError: boolean
  hasLoaded: boolean
  isLoading: boolean
  refresh: () => void
}

const IReferralPlanContextData = (
  patientId: string
): IReferralPlanContextData => {
  const referralPlans = useApiState<IReferralPlan[]>(
    `/v1/treaters/referral-plans`
  )

  useInterval(
    () => {
      fetchReferralPlansHandler()
    },
    60000,
    [patientId]
  )

  const sortedData = useMemo(() => {
    if (!referralPlans.data) return undefined
    return referralPlans.data.sort((a, b) => {
      return a.createdAt < b.createdAt ? -1 : 1
    })
  }, [referralPlans.data])

  const fetchReferralPlansHandler = () => {
    referralPlans.get({
      params: { patientId: patientId },
    })
  }

  return {
    data: sortedData,
    error: referralPlans.error,
    hasError: referralPlans.hasError,
    headers: referralPlans.headers,
    hasLoaded: referralPlans.hasLoaded,
    isLoading: referralPlans.isLoading,
    refresh: fetchReferralPlansHandler,
  }
}

const PatientRoute: React.FC = () => {
  const { patientId } = useParams<{ patientId: string }>()

  if (!patientId) return null

  const patient = usePatientContextData(patientId)
  const assignments = useAssignmentsContextData(patientId)
  const activeAppointment = useActiveAppointmentContextData(patientId)
  const appointments = useAppointmentsContextData(patientId)
  const notifications = useNotificationsContextData(patientId)
  const upcomingAppointments = useUpcomingAppointmentsContextData(patientId)
  const treatmentPlans = useTreatmentPlanContextData(patientId)
  const referralPlans = IReferralPlanContextData(patientId)

  const context = {
    patient,
    assignments,
    appointments,
    notifications,
    upcomingAppointments,
    treatmentPlans,
    referralPlans,
    activeAppointment,
  }

  return <Outlet context={context} />
}

type IOutletContext = {
  patient: IPatientContext
  appointments: IAppointmentsContext
  notifications: INotificationsContext
  upcomingAppointments: IUpcomingAppointmentsContext
  assignments: IAssignmentsContext
  treatmentPlans: ITreatmentPlanContext
  activeAppointment: IActiveAppointmentContext
  referralPlans: IReferralPlanContextData
}

export function usePatient() {
  return useOutletContext<IOutletContext>().patient
}

export function useActiveAppointment() {
  return useOutletContext<IOutletContext>().activeAppointment
}

export function useAppointments() {
  return useOutletContext<IOutletContext>().appointments
}

export function useNotifications() {
  return useOutletContext<IOutletContext>().notifications
}

export function useUpcomingAppointments() {
  return useOutletContext<IOutletContext>().upcomingAppointments
}

export function useAssignments() {
  return useOutletContext<IOutletContext>().assignments
}

export function useTreatmentPlans() {
  return useOutletContext<IOutletContext>().treatmentPlans
}

export function useReferralPlans() {
  return useOutletContext<IOutletContext>().referralPlans
}

export default PatientRoute
