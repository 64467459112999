import React from 'react'
import * as S from './NotificationsCard.style'
import { useTreaterProfile } from '@bonliva-traits/api/treater-profile'
import { useEmailPreferences } from '@bonliva-traits/hooks/useEmailPreferences'
import { EmailPreferenceType } from '@bonliva-traits/api/enums'
import Switch from '@bonliva-components/web/shared/Switch'
import Alert from '@bonliva-components/web/shared/Alert'

const NotificationsCard = () => {
  const profile = useTreaterProfile()
  const [emailPreferences, togglePreferenceHandler, preferencesHasLoaded] =
    useEmailPreferences()

  return (
    <S.NotificationsCard>
      <S.Header>
        <S.Title>E-post notifikationer</S.Title>
      </S.Header>

      {!profile.data?.email && (
        <S.NoMailAlert>
          <Alert type="info" title="Du har inte angivit någon mail" />
        </S.NoMailAlert>
      )}

      <S.Content $disabled={!profile.data.email || !preferencesHasLoaded}>
        {Object.entries(emailPreferences).map(([type, data], index, arr) => (
          <React.Fragment key={type}>
            <S.FlexWrapper>
              <span>
                <S.Label>{data.label}</S.Label>
                <S.Description>{data.description}</S.Description>
              </span>

              <Switch
                label={data.label}
                isChecked={data.isChecked}
                onChange={(bool) =>
                  togglePreferenceHandler(type as EmailPreferenceType, bool)
                }
              />
            </S.FlexWrapper>

            {index < arr.length - 1 && <S.Underline />}
          </React.Fragment>
        ))}
      </S.Content>
    </S.NotificationsCard>
  )
}

export default NotificationsCard
