import Onboarding from '@bonliva-web/onboarding'
import React, { useState, useEffect } from 'react'
import { useTreaterProfile } from '@bonliva-traits/api/treater-profile'
import { Outlet } from 'react-router-dom'

const OnboardingRoute = () => {
  const profile = useTreaterProfile()
  const [showOnboarding, setShowOnboarding] = useState(false)

  useEffect(() => {
    if (profile.data.completedOnboardingAt) return

    setShowOnboarding(true)
  }, [profile.data.completedOnboardingAt])

  return (
    <React.Fragment>
      <Onboarding isOpen={showOnboarding} setIsOpen={setShowOnboarding} />
      <Outlet />
    </React.Fragment>
  )
}

export default OnboardingRoute
