import React, { useState, useCallback } from 'react'
import * as S from './FeedbackFormModal.style'
import Modal from '@bonliva-components/web/shared/Modal'
import useApiState from '@bonliva-traits/hooks/useApiState'
import Spinner from '@bonliva-components/web/shared/Spinner'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  setShowSuccessModal: (showSuccessModal: boolean) => void
}

const FeedbackFormModal: React.FC<Props> = (props) => {
  const [subject, setSubject] = useState('')
  const [description, setDescription] = useState('')

  const feedback = useApiState('v1/feedback/media-library')

  const submitHandler = useCallback(async () => {
    try {
      await feedback.post({
        data: { subject: `Feedback: ${subject}`, description },
      })
      props.setShowSuccessModal(true)
      props.setIsOpen(false)
    } catch (e) {
      throw new Error('Failed to send feedback')
    }
  }, [subject, description])

  const canSubmit = subject.length > 0 && description.length > 0

  return (
    <Modal.Wrapper isOpen={props.isOpen}>
      <Modal.Contents onDismiss={() => props.setIsOpen(false)}>
        <S.FeedbackFormModal>
          <Modal.CloseButton />

          <S.Header>
            <S.Title>Förbättra Mediebiblioteket</S.Title>
          </S.Header>

          <S.Content>
            <span>
              <S.Label>Titel</S.Label>
              <S.TitleInput
                placeholder="Vad är ditt ärende?"
                value={subject}
                onChange={(e) => setSubject(e.target.value)}
              />
            </span>

            <span>
              <S.Label>Beskrivning</S.Label>
              <S.DescriptionInput
                placeholder="Vill du lämna feedback om hur vi kan förbättra Mediebiblioteket?"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </span>
          </S.Content>

          <S.Footer>
            <Modal.DismissButton>
              <S.Cancel>Avbryt</S.Cancel>
            </Modal.DismissButton>

            <S.Send
              disabled={!canSubmit || feedback.isLoading}
              onClick={submitHandler}
            >
              {feedback.isLoading ? <Spinner /> : 'Skicka'}
            </S.Send>
          </S.Footer>
        </S.FeedbackFormModal>
      </Modal.Contents>
    </Modal.Wrapper>
  )
}

export default FeedbackFormModal
