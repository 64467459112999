import React from 'react'
import * as S from './Video.style'
import { IComponentVideo } from '@bonliva-traits/api/types'

export const Video: React.FC<IComponentVideo> = (props) => {
  const width = 648 * (9 / 18)

  return (
    <S.MediaPhoneDisplayVideo
      width={width}
      controls
      controlsList="nodownload noremoteplayback noplaybackrate"
      disablePictureInPicture
      src={props.video.data?.attributes.url + '#t=1'}
    />
  )
}
