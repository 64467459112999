import styled from 'styled-components'
import {
  BodyRegular,
  BodySmall,
  ButtonPrimary,
  ButtonSecondary,
  H3,
} from '@bonliva-ui/web'
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalWrapper,
} from '../Modal/Modal.style'
import { Icon } from '@bonliva-traits/web-icons'

export const ShareMediaModal = styled(ModalWrapper)``

export const Header = styled(ModalHeader)``

export const Title = styled(ModalTitle)``

export const Content = styled(ModalContent)``

export const ShareMediaCard = styled.div`
  width: 100%;
  min-height: 160px;
  position: relative;
  border-radius: ${({ theme }) => theme.radius.rounded};
  border: 1px solid ${({ theme }) => theme.colors.web.light};
  overflow: hidden;
`

export const ShareMediaCardThumbnail = styled.img`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.radius.rounded};
`

export const ShareMediaCardThumbnailOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.2s ease;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
`

export const ShareMediaCardContent = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: ${({ theme }) => theme.margin.xxs};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`

export const ShareMediaCardDetail = styled(BodyRegular)`
  color: ${({ theme }) => theme.colors.web.primary};
  font-weight: 400;
  display: flex;
  gap: ${({ theme }) => theme.margin['3xs']};
`

export const ShareMediaCardTitle = styled(H3)`
  color: ${({ theme }) => theme.colors.web.white};
  font-weight: 500;
`

export const ShareMediaCardDescription = styled(BodyRegular)`
  color: ${({ theme }) => theme.colors.web.white};
`

export const ShareMediaSearchBar = styled.div`
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 36px;
  border-radius: ${({ theme }) => theme.radius.rounded};
`

export const ShareMediaSearchBarIcon = styled(Icon)`
  position: absolute;
  z-index: 1;
  color: ${({ theme }) => theme.colors.web.darkest};

  top: 50%;
  left: 10px;
  transform: translateY(-50%);

  height: 50%;
`

export const ShareMediaSearchBarClearIcon = styled(Icon)<{
  $hasValue: boolean
}>`
  opacity: 1;
  position: absolute;
  top: 50%;
  right: 10px;
  color: ${({ theme }) => theme.colors.web.darkest};
  transition: color 0.1s ease-in-out, opacity 0.1s ease-in-out;
  transform: translateY(-50%);
  height: 30%;
  z-index: 1;

  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.web.primary};
  }

  ${({ $hasValue }) => !$hasValue && 'opacity: 0;'}
`

export const ShareMediaSearchBarInput = styled.input`
  position: absolute;
  height: 100%;
  min-height: ${({ theme }) => theme.margin.m};
  width: 100%;
  border: none;
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: ${({ theme }) => theme.margin['3xs']};
  padding-left: ${({ theme }) => theme.margin.m};
  padding-right: ${({ theme }) => theme.margin.m};
  background-color: ${({ theme }) => theme.colors.web.white};
  color: ${({ theme }) => theme.colors.web.darkest};
  border: 1px solid ${({ theme }) => theme.colors.web.neutral}cc;
  font-weight: 300;
`

export const ShareMediaPatientsList = styled.div`
  padding: ${({ theme }) => theme.margin['3xs']};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin['3xs']};
  background-color: ${({ theme }) => theme.colors.web.light}33;
  border: 1px solid ${({ theme }) => theme.colors.web.neutral}66;
  border-radius: ${({ theme }) => theme.radius.rounded};

  overflow: scroll;
  max-height: 400px;
`

export const PatientsListLoading = styled.div``

export const ShareMediaPatient = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.margin['3xs']};
  padding: ${({ theme }) => theme.margin['3xs']};
  border-radius: ${({ theme }) => theme.radius.rounded};
  border: 1px solid ${({ theme }) => theme.colors.web.neutral}cc;
  cursor: pointer;
  transition: background-color 0.1s ease-in-out;
  background: ${({ theme }) => theme.colors.web.white};

  &:hover {
    background-color: ${({ theme }) => theme.colors.web.light}33;
  }
`

export const ShareMediaPatientName = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const ShareMediaPatientCheckbox = styled.div`
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.web.white};
  border: 1px solid ${({ theme }) => theme.colors.web.primary};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const ShareMediaPatientCheck = styled(Icon)`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.web.primary};
  color: ${({ theme }) => theme.colors.web.white};
  padding: 3px;
`

export const NoPatientsFound = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darker};
  font-weight: 400;
  text-align: center;
`

export const Footer = styled(ModalFooter)``

export const OutlineButton = styled(ButtonSecondary)``

export const Button = styled(ButtonPrimary)``
