import React from 'react'
import * as S from './Alert.style'
import { IconType } from '@bonliva-traits/web-icons'

type Props = {
  type: 'error' | 'warning' | 'info' | 'success'
  title: string
  messages?: string[]
}

const Alert: React.FC<Props> = (props) => {
  const icon = React.useMemo(
    () => ({
      error: IconType.Alert,
      warning: IconType.Alert,
      info: IconType.Info,
      success: IconType.Info,
    }),
    [props.type]
  )

  return (
    <S.Alert $type={props.type}>
      <S.Header>
        <S.AlertSymbol src={icon[props.type]} />
        <S.Title>{props.title}</S.Title>
      </S.Header>
      {props.messages?.map((m, i) => (
        <S.Message key={i}>{m}</S.Message>
      ))}
    </S.Alert>
  )
}

export default Alert
