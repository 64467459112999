import styled from 'styled-components'
import { Icon } from '@bonliva-traits/web-icons'
import { BodySmall, H2 } from '@bonliva-ui/web'

export const MediaTopBar = styled.div`
  position: relative;
  display: flex;
  transform: translateY(0) !important;
`

export const MediaTopBarThumbnail = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`

export const MediaTopBarThumbnailOverlay = styled.img`
  position: absolute;
  top: 0;
  left: -1px;
  width: calc(100% + 2px);
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
`

export const MediaTopBarLayout = styled.div`
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.margin.l} 24px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin['4xs']};
`

export const MediaTopBarTitle = styled(H2)`
  color: ${({ theme }) => theme.colors.web.white};
`

export const MediaTopBarDescription = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.white};
`

export const MediaTopBarDetail = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.primary};
  font-weight: 500;
`

export const MediaTopBarSearchBarWrapper = styled.div`
  margin-top: ${({ theme }) => theme.margin.xxs};
  position: relative;
  width: 100%;
  max-width: 400px;
  height: 36px;
  border-radius: ${({ theme }) => theme.radius.rounded};
`

export const MediaTopBarSearchBarIcon = styled(Icon)`
  position: absolute;
  z-index: 1;
  color: ${({ theme }) => theme.colors.web.darkest};

  top: 50%;
  left: 10px;
  transform: translateY(-50%);

  height: 50%;
`

export const MediaTopBarSearchBarClearIcon = styled(Icon)<{
  $hasValue: boolean
}>`
  opacity: 1;
  position: absolute;
  top: 50%;
  right: 10px;
  color: ${({ theme }) => theme.colors.web.darkest};
  transition: color 0.1s ease-in-out, opacity 0.1s ease-in-out;
  transform: translateY(-50%);
  height: 30%;
  z-index: 1;

  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.web.primary};
  }

  ${({ $hasValue }) => !$hasValue && 'opacity: 0;'}
`

export const MediaTopBarSearchBar = styled.input`
  position: absolute;
  height: 100%;
  width: 100%;
  border: none;
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: ${({ theme }) => theme.margin['3xs']};
  padding-left: ${({ theme }) => theme.margin.m};
  padding-right: ${({ theme }) => theme.margin.m};
  background-color: ${({ theme }) => theme.colors.web.white};
  color: ${({ theme }) => theme.colors.web.darkest};
  border: 1px solid ${({ theme }) => theme.colors.web.light};
  font-weight: 300;
`
