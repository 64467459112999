import React, { useCallback, useEffect } from 'react'
import * as S from './ProfilePictureSlide.style'
import useApiState from '@bonliva-traits/hooks/useApiState'
import Spinner from '@bonliva-components/web/shared/Spinner'
import { IFile } from '@bonliva-traits/api/types'
import {
  useTreaterProfile,
  useUpdateTreaterProfile,
} from '@bonliva-traits/api/treater-profile'

type Props = {
  setDisabled: (disabled: boolean) => void
}

const ProfilePictureSlide: React.FC<Props> = (props) => {
  const profile = useTreaterProfile()
  const updateProfile = useUpdateTreaterProfile()

  const avatar = useApiState<IFile>('v1/uploads', profile.data.avatar)

  const handleChangeAvatar = useCallback(async () => {
    if (!hiddenFileInputAvatar.current?.files) return
    const formData = new FormData()
    formData.append('file', hiddenFileInputAvatar.current.files[0])
    const res = await avatar.post({ data: formData })

    if (!res) return

    const body = { avatarId: res.id }
    await updateProfile(body)
  }, [])

  useEffect(() => {
    props.setDisabled(!profile.data.avatar)
  }, [profile.data])

  const hiddenFileInputAvatar = React.useRef<HTMLInputElement>(null)

  return (
    <S.ProfilePictureSlide>
      <S.ProfilePictureSlideTextWrapper>
        <S.ProfilePictureSlideTitle>
          Lägg till en profilbild.
        </S.ProfilePictureSlideTitle>
        <S.ProfilePictureSlideBody>
          Profilbilden kommer att synas för patienterna.
        </S.ProfilePictureSlideBody>
      </S.ProfilePictureSlideTextWrapper>

      <S.ProfilePictureSlideImageWrapper>
        {avatar.data?.url ? (
          <S.ProfilePictureSlideImage src={avatar.data?.url} />
        ) : (
          <S.ProfilePictureSlideNoImage>
            <S.ProfilePictureSlideNoImageDefaultAvatar src="DefaultAvatar" />
          </S.ProfilePictureSlideNoImage>
        )}

        {avatar.isLoading && (
          <S.ProfilePictureSlideLoading>
            <Spinner />
          </S.ProfilePictureSlideLoading>
        )}
      </S.ProfilePictureSlideImageWrapper>

      <S.ProfilePictureSlideButton
        onClick={() => hiddenFileInputAvatar.current?.click()}
      >
        Ladda upp profilbild
      </S.ProfilePictureSlideButton>

      <input
        type="file"
        accept="image/*"
        ref={hiddenFileInputAvatar}
        onChange={handleChangeAvatar}
        style={{ display: 'none' }}
      />
    </S.ProfilePictureSlide>
  )
}

export default ProfilePictureSlide
