import useApi from '@bonliva-traits/hooks/useApiState'
import { ITreatmentForm } from '@bonliva-traits/api/types'
import useInterval from '@bonliva-traits/hooks/useInterval'

export const useReferralPlanTreatmentForms = (referralPlanId?: string) => {
  const treatmentForms = useApi<ITreatmentForm[]>(`v1/treaters/referral-plans`)

  useInterval(
    () => {
      if (!referralPlanId) return
      treatmentForms.get(
        {
          params: {
            _sort: 'tf.completedAt',
            _order: 'ASC',
          },
        },
        `${referralPlanId}/treatment-forms`
      )
    },
    60000,
    [referralPlanId]
  )

  return {
    treatmentForms: treatmentForms.data,
  }
}
