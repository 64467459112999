import React, { useCallback, useState, useEffect } from 'react'
import * as S from './LanguageSlide.style'
import * as constant from '@bonliva-traits/constants'
import { ILanguage } from '@bonliva-traits/api/types'
import {
  useTreaterProfile,
  useUpdateTreaterProfile,
} from '@bonliva-traits/api/treater-profile'
import Select from '@bonliva-components/web/shared/Select'

type Props = {
  setDisabled: (disabled: boolean) => void
}

const LanguageSlide: React.FC<Props> = (props) => {
  const profile = useTreaterProfile()
  const updateProfile = useUpdateTreaterProfile()
  const [languages, setLanguages] = useState<ILanguage[]>([])

  const updateTreaterHandler = useCallback(() => {
    updateProfile({ languages: languages.map(({ code }) => code) })
  }, [languages])

  const formattExistingLanguagesHandler = useCallback(() => {
    const filteredLanguages = constant.languages.filter((l) =>
      profile.data.languages?.includes(l.code)
    )

    setLanguages(filteredLanguages)
  }, [profile.data])

  useEffect(() => {
    formattExistingLanguagesHandler()
  }, [])

  useEffect(() => {
    props.setDisabled(languages.length === 0)
  }, [languages])

  return (
    <S.LanguageSlide>
      <S.LanguageSlideTextWrapper>
        <S.LanguageSlideTitle>
          Lägg till de språk du behärskar och kan användas under behandling.
        </S.LanguageSlideTitle>
        <S.LanguageSlideBody>
          Språken du väljer kommer att synas för patienterna.
        </S.LanguageSlideBody>
      </S.LanguageSlideTextWrapper>

      <S.LanguageSlideSelectWrapper>
        <Select
          isMulti
          placeholder="Välj språk"
          options={constant.languages.sort((a, b) =>
            a.name.localeCompare(b.name)
          )}
          value={languages}
          getOptionValue={({ code }) => code}
          getOptionLabel={({ name }) => name}
          onChange={(l) => setLanguages(l as ILanguage[])}
          onBlur={updateTreaterHandler}
        />
      </S.LanguageSlideSelectWrapper>
    </S.LanguageSlide>
  )
}

export default LanguageSlide
