import styled from 'styled-components'
import {
  BodyRegular,
  ButtonPrimary,
  ButtonSecondary,
  H5,
  TextArea,
} from '@bonliva-ui/web'
import {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalTitle,
  ModalWrapper,
} from '../Modal/Modal.style'
import { Icon } from '@bonliva-traits/web-icons'

export const MessageModal = styled(ModalWrapper)``

export const Header = styled(ModalHeader)``

export const Title = styled(ModalTitle)``

export const Content = styled(ModalContent)``

export const Label = styled(BodyRegular)`
  margin-bottom: ${({ theme }) => theme.margin['3xs']};
`

export const CommentField = styled(TextArea)`
  resize: vertical;
  width: 100%;
`

export const AttachHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.margin['3xs']};
  margin: ${({ theme }) => theme.margin['3xs']} 0;
`

export const AttachHeaderIcon = styled(Icon)`
  height: 16px;
  width: 16px;
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const AttachHeaderLabel = styled(H5)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.margin['3xs']};
  margin-bottom: ${({ theme }) => theme.margin['4xs']};
`

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: ${({ theme }) => theme.margin['3xs']};
`

export const Input = styled.input`
  height: 46px;
  display: flex;
  width: 100%;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.web.neutral};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: ${({ theme }) => theme.margin['3xs']};
  margin-bottom: ${({ theme }) => theme.margin['3xs']};
  font-family: 'Calibre';
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;

  &:focus {
    outline: none;
    border: 1px solid ${({ theme }) => theme.colors.web.primary};
  }
`

export const RemoveIcon = styled(Icon)`
  transition: color 0.1s ease-in-out;
  color: ${({ theme }) => theme.colors.web.secondaryDark};
  height: 14px;
  width: 14px;
  cursor: pointer;

  &:hover {
    color: ${({ theme }) => theme.colors.web.primary};
  }
`

export const ListItem = styled.a`
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: ${({ theme }) => theme.margin['3xs']};
  font-family: Calibre;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.web.darker};

  width: fit-content;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.web.secondaryLight};
  color: ${({ theme }) => theme.colors.web.secondary};
  padding: 2px ${({ theme }) => theme.margin['3xs']};
  border-radius: ${({ theme }) => theme.radius.rounded};
  border: 0.5px solid ${({ theme }) => theme.colors.web.secondary}66;
  cursor: pointer;
`

export const InputForm = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.margin.xxs};
`

export const InputWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.margin.xxs};
  margin-bottom: ${({ theme }) => theme.margin['4xs']};
`

export const AddButton = styled(ButtonPrimary)`
  margin-right: auto;
`

export const FileWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin['3xs']};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.web.light}cc;
  padding: 8px;
  border-radius: ${({ theme }) => theme.radius.rounded};
`

export const Footer = styled(ModalFooter)``

export const OutlineButton = styled(ButtonSecondary)``

export const Button = styled(ButtonPrimary)``
