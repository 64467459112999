import { Icon } from '@bonliva-traits/web-icons'
import React from 'react'
import * as S from './TreatmentPlanAdd.style'

type Props = {
  onClick: () => void
  label: string
}

const TreatmentPlanAdd: React.FC<Props> = (props) => {
  return (
    <S.TreatmentPlanAddCard onClick={props.onClick}>
      <Icon src="Plus" style={{ marginBottom: '7px' }} />

      <S.TreatmentPlanAddHeader>{props.label}</S.TreatmentPlanAddHeader>
    </S.TreatmentPlanAddCard>
  )
}

export default TreatmentPlanAdd
