import React, { useMemo, useState, useCallback, useEffect } from 'react'
import * as S from './DeviationsModal.style'
import { format, addMinutes, subMinutes, startOfDay } from 'date-fns'
import Modal from '@bonliva-components/web/shared/Modal'
import Alert from '@bonliva-components/web/shared/Alert'
import DatePicker from '@bonliva-components/web/shared/DatePicker'
import Select from '@bonliva-components/web/shared/Select'
import Spinner from '@bonliva-components/web/shared/Spinner'
import useApiState from '@bonliva-traits/hooks/useApiState'
import { IDeviatingTime } from '@bonliva-traits/api/types'
import { DeviationType } from '@bonliva-traits/api/enums'
import { useFetchTreaterProfile } from '@bonliva-traits/api/treater-profile'
import RadioButton from '@bonliva-components/web/shared/RadioButton/RadioButton'

type ITime = {
  value: Date
  label: string
}

type Props = {
  selected?: IDeviatingTime
  setSelected: (empty: undefined) => void
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  openDeleteModal?: (item: IDeviatingTime) => void
}

const DeviationsModal: React.FC<Props> = (props) => {
  const fetchProfile = useFetchTreaterProfile()
  const [date, setDate] = useState<Date>()
  const [timeStart, setTimeStart] = useState<Date>()
  const [timeEnd, setTimeEnd] = useState<Date>()
  const [type, setType] = useState(DeviationType.Unavailable)
  const [digitalCheck, setDigitalCheck] = useState<boolean>()
  const [physicalCheck, setPhysicalCheck] = useState<boolean>()

  const isFilledOutOffWork =
    !!date && !!timeStart && !!timeEnd && type === DeviationType.Unavailable

  const isFilledOutWorking =
    !!date &&
    !!timeStart &&
    !!timeEnd &&
    type === DeviationType.Available &&
    (!!digitalCheck || !!physicalCheck)

  const isFilledOut = isFilledOutOffWork || isFilledOutWorking

  const deviationTimes = useApiState<IDeviatingTime>(
    '/v1/treater/deviating-working-times'
  )

  useEffect(() => {
    if (!props.selected) return
    setDate(new Date(props.selected?.deviationDateStart))
    setTimeStart(new Date(props.selected?.deviationDateStart))
    setTimeEnd(new Date(props.selected?.deviationDateEnd))
    setType(props.selected?.deviationType)
    setDigitalCheck(props.selected.acceptDigitalMeetings)
    setPhysicalCheck(props.selected.acceptPhysicalMeetings)
  }, [props.selected])

  const onDismissHandler = useCallback(() => {
    props.setIsOpen(false)
  }, [])

  const formatSelectedDateHandler = useCallback((date: Date | undefined) => {
    if (!date) return

    return {
      value: date,
      label: format(date, 'HH:mm'),
    }
  }, [])

  const filterTimesHandler = useCallback(
    (
      times: ITime[],
      { startTime, endTime }: { startTime?: Date; endTime?: Date }
    ) => {
      if (!startTime && !endTime) return times

      const filteredTimes = times.filter((time) => {
        if (startTime && endTime) {
          return time.value >= startTime && time.value <= endTime
        }

        if (startTime) {
          return time.value >= startTime
        }

        if (endTime) {
          return time.value <= endTime
        }

        return false
      })

      return filteredTimes
    },
    []
  )

  const createWokringTimesHandler = useCallback(async () => {
    if (!isFilledOut) return

    const formattedDate = format(date, 'yyyy-MM-dd ')
    const formattedTimeStart = format(timeStart, 'HH:mm')
    const formattedTimeEnd = format(timeEnd, 'HH:mm')

    const data = {
      deviationDateStart: new Date(formattedDate + formattedTimeStart),
      deviationDateEnd: new Date(formattedDate + formattedTimeEnd),
      deviationType: type,
      acceptDigitalMeetings: digitalCheck || false,
      acceptPhysicalMeetings: physicalCheck || false,
    }

    if (!props.selected) await deviationTimes.post({ data })
    else await deviationTimes.put({ data }, props.selected.id)
    fetchProfile()
    props.setIsOpen(false)
  }, [date, timeStart, timeEnd, isFilledOut, type, digitalCheck, physicalCheck])

  // every 60 min from 00:10 to 23:00
  const times = useMemo(() => {
    const date = props.selected
      ? new Date(props.selected?.deviationDateStart)
      : new Date()

    const start = addMinutes(startOfDay(date), 60)

    const times = Array.from({ length: 24 }, (_, i) =>
      addMinutes(start, i * 60)
    ).map((date) => ({
      value: date,
      label: format(date, 'HH:mm'),
    }))

    return times
  }, [props.selected])

  const timeStartTimes = useMemo(() => {
    const endTime = timeEnd && subMinutes(timeEnd, 60)
    return filterTimesHandler(times, { endTime })
  }, [timeEnd])

  const timeEndTimes = useMemo(() => {
    const startTime = timeStart && addMinutes(timeStart, 60)
    return filterTimesHandler(times, { startTime })
  }, [timeStart])

  const title = useMemo(() => {
    if (props.selected) return 'Redigera avvikande tid'
    else return 'Lägg till avvikande tid'
  }, [isFilledOut])

  const alertMessages = useMemo(() => {
    const messages = [
      'Här lägger du in tider som infaller utöver ditt vanliga schema.',
      'Du kan också lägga in ledig tid eller tid då du inte kan ta emot patienter.',
    ]
    if (!isFilledOut)
      messages.push('Du måste fylla i alla fält för att kunna spara.')

    return messages
  }, [isFilledOut])

  return (
    <Modal.Wrapper isOpen={props.isOpen}>
      <Modal.Contents onDismiss={onDismissHandler}>
        <S.DeviationsModal>
          <Modal.CloseButton />

          <S.Header>
            <S.Title>{title}</S.Title>
          </S.Header>

          <S.Content>
            <Alert
              type="info"
              title="Avvikande tider"
              messages={alertMessages}
            />

            <S.FlexWrapper>
              <S.SelectTimeWrapper>
                <S.Label>Datum</S.Label>
                <DatePicker
                  onChange={setDate}
                  value={date}
                  placeholder="Välj datum"
                />
              </S.SelectTimeWrapper>
            </S.FlexWrapper>

            <S.FlexWrapper>
              <S.SelectTimeWrapper>
                <S.Label>Arbetstid</S.Label>
                <Select
                  placeholder="Från"
                  options={timeStartTimes}
                  value={formatSelectedDateHandler(timeStart)}
                  onChange={(e) => setTimeStart(e?.value)}
                />
              </S.SelectTimeWrapper>

              <S.SelectTimeWrapper>
                <Select
                  placeholder="Till"
                  options={timeEndTimes}
                  value={formatSelectedDateHandler(timeEnd)}
                  onChange={(e) => setTimeEnd(e?.value)}
                />
              </S.SelectTimeWrapper>
            </S.FlexWrapper>

            <S.Label>Vilken typ av tid vill du lägga till?</S.Label>

            <S.FlexWrapper>
              <RadioButton
                label="Ej bokningsbar"
                onClick={() => setType(DeviationType.Unavailable)}
                isSelected={type === DeviationType.Unavailable}
              />
            </S.FlexWrapper>

            <S.FlexWrapper>
              <RadioButton
                label="Extra tid"
                onClick={() => setType(DeviationType.Available)}
                isSelected={type === DeviationType.Available}
              />
            </S.FlexWrapper>
            {type === DeviationType.Available && (
              <>
                <S.Label>Jag kan ta samtal</S.Label>
                <S.FlexWrapper>
                  <S.FlexClickWrapper
                    onClick={() => setDigitalCheck((prev) => !prev)}
                  >
                    <S.Checkbox>
                      {digitalCheck && <S.Check src="Check" />}
                    </S.Checkbox>
                    <S.CheckboxLabel>Digitalt</S.CheckboxLabel>
                  </S.FlexClickWrapper>

                  <S.FlexClickWrapper
                    onClick={() => setPhysicalCheck((prev) => !prev)}
                  >
                    <S.Checkbox>
                      {physicalCheck && <S.Check src="Check" />}
                    </S.Checkbox>
                    <S.CheckboxLabel>Fysiskt</S.CheckboxLabel>
                  </S.FlexClickWrapper>
                </S.FlexWrapper>
              </>
            )}
          </S.Content>

          <S.Footer>
            {!!props.openDeleteModal && !!props.selected && (
              <Modal.DismissButton
                onClick={() => {
                  if (!props.selected) return
                  if (!props.openDeleteModal) return
                  props.openDeleteModal(props.selected)
                }}
              >
                <S.Delete>Ta bort avvikande tid</S.Delete>
              </Modal.DismissButton>
            )}

            <Modal.DismissButton>
              <S.Cancel>Avbryt</S.Cancel>
            </Modal.DismissButton>

            <S.Create
              disabled={!isFilledOut || deviationTimes.isLoading}
              onClick={createWokringTimesHandler}
            >
              {deviationTimes.isLoading ? <Spinner /> : 'Spara'}
            </S.Create>
          </S.Footer>
        </S.DeviationsModal>
      </Modal.Contents>
    </Modal.Wrapper>
  )
}

export default DeviationsModal
