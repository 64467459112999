import React, { useState, useEffect, useRef } from 'react'
import * as S from './Audio.style'
import { IComponentAudio } from '@bonliva-traits/api/types'
import { Icon } from '@bonliva-traits/web-icons'

export const AudioPlayer: React.FC<IComponentAudio> = (props) => {
  const animationRef = useRef(0)
  const [audio] = useState(
    props.audio.data ? new Audio(props.audio.data.attributes.url) : undefined
  )
  const [hasError, setHasError] = useState(false)
  const [isPlaying, setIsPlaying] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const [percentage, setPercentage] = useState(0)

  useEffect(() => {
    audio?.addEventListener('error', () => {
      setHasError(true)
    })

    audio?.addEventListener('loadedmetadata', () => {
      setDuration(audio.duration)
    })

    audio?.addEventListener('ended', () => {
      setIsPlaying(false)
      setCurrentTime(0)
    })
  }, [])

  const update = () => {
    audio?.addEventListener('timeupdate', () => {
      const time = audio.currentTime
      const percent = time / duration
      setPercentage(percent)
      setCurrentTime(time)
    })
  }

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!audio) return

    audio.currentTime = duration * Number(e.target.value)

    setPercentage(Number(e.target.value))
  }

  const togglePlayPause = () => {
    if (!isPlaying) {
      setIsPlaying(true)
      audio?.play()
      animationRef.current = requestAnimationFrame(update)
    }

    if (isPlaying) {
      setIsPlaying(false)
      audio?.pause()
      cancelAnimationFrame(animationRef.current)
    }
  }

  const SecondsToMMSS = (d: number) => {
    const minutes = Math.floor(d / 60)
    const seconds = Math.floor(d - minutes * 60)
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
  }

  return (
    <S.AudioWrapper>
      <S.Card>
        {hasError ? (
          <React.Fragment>
            <S.ErrorTitle>Något gick snett</S.ErrorTitle>

            <S.ErrorSmall>
              Tyvärr, det gick inte att spela upp ljudet.
            </S.ErrorSmall>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <S.Title>{props.audio.data?.attributes.name}</S.Title>

            {props.audio.data?.attributes.caption && (
              <S.Small color="darker">
                {props.audio.data.attributes.caption}
              </S.Small>
            )}
          </React.Fragment>
        )}

        <S.Range
          type="range"
          min={0}
          max={1}
          step="any"
          value={percentage}
          onChange={onChange}
        />

        <S.AudioControls>
          <S.CounterLeft>{SecondsToMMSS(currentTime)}</S.CounterLeft>

          <Icon src={isPlaying ? 'Pause' : 'Play'} onClick={togglePlayPause} />

          <S.CounterRight>
            -{SecondsToMMSS(duration - currentTime)}
          </S.CounterRight>
        </S.AudioControls>
      </S.Card>
    </S.AudioWrapper>
  )
}
