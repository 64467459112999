import React, { useEffect } from 'react'
import * as S from './PatientReferralPlans.style'
import PatientTopBar from '@bonliva-components/web/shared/PatientTopBar'
import PlanCard from '@bonliva-components/web/shared/PlanCard'
import ReferralPlanStatusChip from '@bonliva-components/web/shared/ReferralPlanStatusChip'
import { useNavigate } from 'react-router-dom'
import { useReferralPlans } from '@bonliva-routes/web/special-routes/PatientRoute'
import { referralPlanStatus } from '@bonliva-traits/api/referral-plans'

const PatientReferralPlans = () => {
  const navigate = useNavigate()
  const referralPlans = useReferralPlans()

  useEffect(() => {
    referralPlans.refresh()
  }, [])

  const handleNavigateToReferralPlan = (planId: string) => {
    navigate(planId)
  }

  return (
    <S.PatientReferralPlans>
      <S.PatientReferralPlansLayout>
        <PatientTopBar />
        <S.ReferralPlansWrapper>
          <S.ReferralPlansContainer>
            {referralPlans.data && (referralPlans.data?.length || 0) === 0 && (
              <S.NoRemindersMessage>Inga remisser än.</S.NoRemindersMessage>
            )}
            {referralPlans.data?.map((plan) => (
              <PlanCard
                key={plan.id}
                data={plan}
                status={
                  <ReferralPlanStatusChip status={referralPlanStatus(plan)} />
                }
                onClick={() => {
                  handleNavigateToReferralPlan(plan.id)
                }}
              />
            ))}
          </S.ReferralPlansContainer>
        </S.ReferralPlansWrapper>
      </S.PatientReferralPlansLayout>
    </S.PatientReferralPlans>
  )
}

export default PatientReferralPlans
