import { useApi } from '@bonliva-traits/api'
import { IcdCode } from '@bonliva-traits/api/types'

export const useIcdCodes = () => {
  const api = useApi()

  const getIcdCodesHandler = async (searchQuery: string) => {
    try {
      const result = await api.get<IcdCode[]>(`/v1/treaters/icd-codes`, {
        params: {
          searchQuery,
        },
      })
      return result.data
    } catch (error) {
      throw new Error('Could not get ICD codes')
    }
  }

  return {
    getIcdCodesHandler,
  }
}
