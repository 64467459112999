import styled from 'styled-components'

export const Account = styled.div`
  max-width: 1920px;
  min-width: 772px;
  margin: 0 auto;
`

export const Row = styled.div`
  margin: ${({ theme }) => theme.margin.xxs};
  height: 100%;
  display: flex;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const Col = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.xxs};
`
