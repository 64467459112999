import React, { useState, useEffect } from 'react'
import * as S from './NoWorkingTimesWarning.style'
import Alert from '@bonliva-components/web/shared/Alert'
import { Icon } from '@bonliva-traits/web-icons'

type Props = {
  showNoTimesWarning: boolean
}

const NoWorkingTimesWarning: React.FC<Props> = (props) => {
  const [active, setActive] = useState(false)

  useEffect(() => {
    setActive(props.showNoTimesWarning)
  }, [props.showNoTimesWarning])

  return (
    <S.NoWorkingTimesWarning $isActive={active}>
      <Alert
        type="warning"
        title="Du har inga aktiva arbetstider"
        messages={[
          'Du måste ha minst en aktiv arbetstid för att kunna bli bokad.',
          'Du kan skapa en ny arbetstid genom gå till arbetstider.',
        ]}
      />

      <Icon src="Close" onClick={() => setActive(false)} />
    </S.NoWorkingTimesWarning>
  )
}

export default NoWorkingTimesWarning
