import styled from 'styled-components'
import { BodyRegular, BodySmall, H3, H4 } from '@bonliva-ui/web'

export const PatientAppointments = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`

export const PatientAppointmentsLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.margin.xxs};
  margin-bottom: -${({ theme }) => theme.margin.xxs};
`

export const PatientAppointmentTableWrapper = styled.div`
  margin: ${({ theme }) => theme.margin.xxs};
`

export const RowText = styled(BodySmall)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const EmptyInbox = styled.tr`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 120px;
  padding: 0 ${({ theme }) => theme.margin.xxs};

  border-bottom: 0.5px solid ${({ theme }) => theme.colors.web.light};
`

export const TableRow = styled.tr`
  position: relative;
  padding: ${({ theme }) => theme.margin.xxs};

  display: grid;
  grid-template-columns: repeat(4, calc(25% - 16px));
  gap: ${({ theme }) => theme.margin.xxs};
  grid-template-rows: 1fr;
  height: 58px;
  background-color: ${({ theme }) => theme.colors.web.white}33;
  border-top: 0.5px solid ${({ theme }) => theme.colors.web.light};

  &:last-child {
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.web.light};
  }
`

export const FlexSpan = styled.td`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.margin.xxs};
  overflow: hidden;
`

export const TreatmentFormWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.web.white};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: ${({ theme }) => theme.margin.xxs};
  margin: ${({ theme }) => theme.margin.xxs};
`

export const FormAnswers = styled.div`
  overflow: scroll;
  min-height: 100px;
  border: 1px solid ${({ theme }) => theme.colors.web.neutral}66;
  border-radius: ${({ theme }) => theme.radius.rounded};
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.web.light};
`

export const FormAnswersHeader = styled.div`
  margin: ${({ theme }) => theme.margin.xxs};
`

export const FormAnswersTitle = styled(H3)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const FormAnswersDescription = styled(H4)`
  color: ${({ theme }) => theme.colors.web.darkest};
  display: flex;
  gap: ${({ theme }) => theme.margin['3xs']};
  font-weight: 400;
  font-size: 18px;
`

export const FormAnswersTypes = styled(BodyRegular)`
  color: ${({ theme }) => theme.colors.web.black};
  font-size: 16px;
`

export const FormItem = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${({ theme }) => theme.margin.xxs};

  border-top: 1px solid ${({ theme }) => theme.colors.web.neutral}66;
`

export const FormItemLabel = styled(BodyRegular)`
  color: ${({ theme }) => theme.colors.web.black};
  font-size: 16px;
`

export const FormItemQuestion = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darkest};
  font-size: 18px;
`

export const FormItemAnswer = styled(BodyRegular)`
  color: ${({ theme }) => theme.colors.web.darkest};
  font-size: 16px;
`
