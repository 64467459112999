import React, { useState, useCallback } from 'react'
import * as S from './Login.style'
import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '@bonliva-auth/context'
import { UserType } from '@bonliva-auth/api'
import { Status } from '@bonliva-auth/context/context'
import { Images } from '@bonliva-ui/assets/images'
import BankIdModal from './components/BankIdModal'
import TermsModal from './components/TermsModal'
import {
  useFetchTreaterProfile,
  useTreaterProfile,
} from '@bonliva-traits/api/treater-profile'

const Login: React.FC = () => {
  const auth = useAuth()
  const profile = useTreaterProfile()
  const fetchProfile = useFetchTreaterProfile()
  const navigate = useNavigate()
  const location = useLocation()

  const [showBankIdModal, setShowBankIdModal] = useState(false)
  const [showTermsModal, setShowTermsModal] = useState(false)
  const [openOnThisDevice, setOpenOnThisDevice] = useState(false)

  const initLoginHandler = useCallback(
    async (thisDevice: boolean) => {
      await auth.login(UserType.Treater)
      setShowBankIdModal(true)
      setOpenOnThisDevice(thisDevice)
    },
    [auth]
  )

  const completeLoginHandler = useCallback(() => {
    const previousPath = (location.state as { redirectTo?: string })?.redirectTo
    navigate(previousPath || '/')
  }, [])

  React.useEffect(() => {
    if (auth.status !== Status.Authenticated) return
    setShowBankIdModal(false)
    fetchProfile()
  }, [auth.status])

  React.useEffect(() => {
    if (!profile.data.id) return

    const isTermsAccepted = profile.data.privacyPolicyAccepted

    if (isTermsAccepted) completeLoginHandler()
    else setShowTermsModal(true)
  }, [profile])

  return (
    <S.Login>
      <S.BackgroundImageWrapper>
        <S.BackgroundImage src={Images.Login} />
        <S.BonlivaLogo src="Bonliva" />
        <S.BonlivaSlogan src="BonlivaSlogan" />
      </S.BackgroundImageWrapper>

      <S.Content>
        <S.Title>Välkommen till Bonliva Care</S.Title>
        <S.Body>Välj inloggningssätt:</S.Body>

        <S.Button onClick={() => initLoginHandler(false)}>
          Använd Mobilt BankID
          <S.BankId src="BankId" />
        </S.Button>

        <S.OutlineButton onClick={() => initLoginHandler(true)}>
          BankID på den här enheten
          <S.BankId src="BankId" />
        </S.OutlineButton>
      </S.Content>

      <BankIdModal
        isOpen={showBankIdModal}
        setIsOpen={setShowBankIdModal}
        openOnThisDevice={openOnThisDevice}
        setOpenOnThisDevice={setOpenOnThisDevice}
      />

      <TermsModal
        isOpen={showTermsModal}
        setIsOpen={setShowTermsModal}
        treater={profile.data}
      />
    </S.Login>
  )
}

export default Login
