import React, { useRef, useState } from 'react'
import { IMeetingSubscriber, useMeeting } from './context'
import {
  MeetingSubscriberInnerMiniature,
  PatientPlaceholder,
  PatientPlaceholderCircleWrapperMiniature as PatientPlaceholderName,
} from '../MeetingSubscriber.style'
import { animated, useSpring } from '@react-spring/web'
import { useDrag } from '@use-gesture/react'
import { useNavigate } from 'react-router-dom'

enum Corner {
  TopLeft = 'top-left',
  TopRight = 'top-right',
  BottomLeft = 'bottom-left',
  BottomRight = 'bottom-right',
}

const corners = [
  { x: 0, y: 0, corner: Corner.TopLeft },
  { x: 0, y: window.innerHeight, corner: Corner.BottomLeft },
  { x: window.innerWidth, y: 0, corner: Corner.TopRight },
  { x: window.innerWidth, y: window.innerHeight, corner: Corner.BottomRight },
]

const getClosestCorner = (x: number, y: number) => {
  const distances = corners.map((corner) => {
    const ax = Math.pow(corner.x - x, 2)
    const ay = Math.pow(corner.y - y, 2)
    return Math.sqrt(ax + ay)
  })

  const min = Math.min(...distances)
  const index = distances.indexOf(min)
  return corners[index]
}

type Props = {
  subscriber: IMeetingSubscriber
}

const MeetingSubscriberMiniature: React.FC<Props> = ({ subscriber }) => {
  const navigate = useNavigate()
  const { appointment } = useMeeting()
  const ref = useRef<HTMLDivElement>(null)

  const [props, set] = useSpring(() => ({ x: 16, y: 16 }))
  const [offset, setOffset] = useState({ x: 16, y: 16 })

  const bind = useDrag((event) => {
    if (event.tap) {
      navigate(`/meeting-room/${appointment?.id}/meeting`)
    }
    if (event.down) {
      set({ x: offset.x + event.movement[0], y: offset.y + event.movement[1] })
    } else {
      const corner = getClosestCorner(
        offset.x + event.movement[0] + 75,
        offset.y + event.movement[1] + 100
      )

      const pos = {
        [Corner.TopLeft]: { x: corner.x + 16, y: corner.y + 16 },
        [Corner.TopRight]: { x: corner.x - 166, y: corner.y + 16 },
        [Corner.BottomLeft]: { x: corner.x + 15, y: corner.y - 216 },
        [Corner.BottomRight]: { x: corner.x - 166, y: corner.y - 216 },
      }[corner.corner]

      setOffset(pos)
      set(pos)
    }
  })

  const containerRef = useRef<HTMLDivElement>(null)
  const [videoElement, setVideoElement] = React.useState<HTMLElement>()

  React.useEffect(() => {
    if (!containerRef.current) return

    if (videoElement) {
      try {
        containerRef.current?.removeChild(videoElement)
      } catch (_) {}
    }

    if (subscriber.videoElement) {
      containerRef.current?.appendChild(subscriber.videoElement)
      setVideoElement(subscriber.videoElement)
    }
  }, [containerRef, subscriber.videoElement])

  return (
    <animated.div
      ref={ref}
      {...bind()}
      style={{
        cursor: 'grab',
        position: 'fixed',
        zIndex: 1000,
        left: 0,
        top: 0,
        x: props.x,
        y: props.y,
      }}
    >
      <MeetingSubscriberInnerMiniature ref={containerRef} />
      {!subscriber.hasVideo && (
        <PatientPlaceholder>
          <PatientPlaceholderName>
            {appointment?.patient?.name}
          </PatientPlaceholderName>
        </PatientPlaceholder>
      )}
    </animated.div>
  )
}

export const MeetingSubscribersMiniature: React.FC = () => {
  const { subscriber } = useMeeting()
  if (!subscriber) return null

  return <MeetingSubscriberMiniature subscriber={subscriber} />
}
