import React, { useMemo, useState, useEffect } from 'react'
import * as S from './MediaProgram.style'
import { useParams } from 'react-router-dom'
import { useMediaLibrary } from '@bonliva-traits/api/media-library'
import { useMediaFavorites } from '@bonliva-traits/api/media-favorites'
import { IExerciseProgram } from '@bonliva-traits/api/types'
import MediaTopBar from '@bonliva-components/web/shared/MediaTopBar'
import useCmsState from '@bonliva-traits/hooks/useCmsState'
import Spinner from '@bonliva-components/web/shared/Spinner'
import MediaInfoBar from '@bonliva-components/web/shared/MediaInfoBar'
import MediaCard from '@bonliva-components/web/shared/MediaCard'

const MediaProgram = () => {
  const params = useParams<{ programId: string }>()
  const mediaLibrary = useMediaLibrary()
  const favorites = useMediaFavorites()
  const [program, setProgram] = useState<IExerciseProgram>()
  const rawProgram = useCmsState<IExerciseProgram>('exercise-programs', [])

  useEffect(() => {
    rawProgram.get({
      filters: { uuid: params.programId },
      populate: ['deep', 7],
    })
  }, [params.programId])

  useEffect(() => {
    if (!rawProgram.data.length) return
    setProgram(rawProgram.data[0])
  }, [rawProgram.data])

  const canFavorite = useMemo(() => {
    const reduced = mediaLibrary.data.some((item) => {
      return item.exercisePrograms.data.some(
        (i) => i.attributes.uuid === params.programId
      )
    })

    return reduced
  }, [mediaLibrary.data, params.programId])

  const favoriteId = useMemo(
    () => favorites.data.find((i) => i.mediaLibraryId === params.programId)?.id,
    [favorites.data, params.programId]
  )

  const exercises = useMemo(() => {
    return program?.exercises?.map((i) => i.exercise.data?.attributes)
  }, [program])

  return (
    <React.Fragment>
      {program && exercises && (
        <S.MediaProgram>
          <MediaTopBar
            detail={`Program • ${exercises.length} ${
              exercises.length > 1 ? 'övningar' : 'övning'
            } • ${program.timeEstimation} min`}
            title={program.title}
            description={program.subtitle}
            image={program.banner.data?.attributes}
          />

          <S.MediaProgramLayout>
            <S.MediaProgramContent>
              <MediaInfoBar
                media={program}
                canFavorite={canFavorite}
                favoriteId={favoriteId}
              />

              {program.purpose && (
                <span>
                  <S.MediaProgramTitle>Syfte</S.MediaProgramTitle>

                  <S.MediaProgramDescription>
                    {program.purpose}
                  </S.MediaProgramDescription>
                </span>
              )}

              <span>
                <S.MediaProgramTitle>{program.title}</S.MediaProgramTitle>

                <S.MediaProgramDescription>
                  {program.description}
                </S.MediaProgramDescription>
              </span>

              <S.MediaProgramSection>
                <S.MediaProgramSectionTitle>
                  Övningar
                </S.MediaProgramSectionTitle>
                <S.MediaProgramSectionUnderline />
                <S.MediaProgramSectionContent>
                  {exercises.map((media) => {
                    return media ? (
                      <MediaCard
                        key={media?.uuid}
                        media={media}
                        canFavorite={false}
                      />
                    ) : null
                  })}
                </S.MediaProgramSectionContent>
              </S.MediaProgramSection>
            </S.MediaProgramContent>
          </S.MediaProgramLayout>
        </S.MediaProgram>
      )}

      {!program && (
        <S.Loading>
          <Spinner />
        </S.Loading>
      )}
    </React.Fragment>
  )
}

export default MediaProgram
