import React, { useCallback, useState, useEffect } from 'react'
import * as S from './BioSlide.style'
import {
  useTreaterProfile,
  useUpdateTreaterProfile,
} from '@bonliva-traits/api/treater-profile'

type Props = {
  setDisabled: (disabled: boolean) => void
}

const BioSlide: React.FC<Props> = (props) => {
  const profile = useTreaterProfile()
  const updateProfile = useUpdateTreaterProfile()
  const [bio, setBio] = useState(profile.data.bio || '')
  const [tooLongError, setTooLongError] = useState(false)

  const updateTreaterHandler = useCallback(() => {
    updateProfile({ bio })
  }, [bio])

  useEffect(() => {
    props.setDisabled(!bio || bio.length > 1000)
  }, [bio])

  useEffect(() => {
    if (bio.length > 1000) {
      setTooLongError(true)
    } else {
      setTooLongError(false)
    }
  }, [bio])

  return (
    <S.BioSlide>
      <S.BioSlideTextWrapper>
        <S.BioSlideTitle>Lägg till biografi.</S.BioSlideTitle>
        <S.BioSlideBody>
          Biografin kommer att synas för patienterna. Du kan komplettera din
          biografi senare.
        </S.BioSlideBody>
      </S.BioSlideTextWrapper>

      <S.BioSlideTextField
        placeholder="Skriv en kort beskrivning om vad patienten kan förvänta sig av dig som psykolog."
        value={bio}
        onChange={(e) => setBio(e.target.value)}
        onBlur={updateTreaterHandler}
      />
      {tooLongError && (
        <S.ErrorMessage>
          Din biografi får inte överstiga 1000 tecken
        </S.ErrorMessage>
      )}
    </S.BioSlide>
  )
}

export default BioSlide
