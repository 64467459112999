import { addPxPrefix } from '@bonliva-traits/utils'
import { Icon } from '@bonliva-traits/web-icons'
import styled from 'styled-components'

type IDimensions = {
  $height?: string | number
  $width?: string | number
}

export const ProfilePicture = styled.img<IDimensions>`
  width: ${({ $width }) => addPxPrefix($width || '46px')};
  height: ${({ $height }) => addPxPrefix($height || '46px')};
  object-fit: cover;
  border-radius: 4px;
  aspect-ratio: 1 / 1;
`

export const NoProfilePicture = styled.div<IDimensions>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${({ $width }) => addPxPrefix($width || '46px')};
  height: ${({ $height }) => addPxPrefix($height || '46px')};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.web.secondaryLight};
  aspect-ratio: 1 / 1;
`

export const DefaultAvatar = styled(Icon)`
  width: 70%;
  height: 70%;
  color: ${({ theme }) => theme.colors.web.secondaryDark};
`
