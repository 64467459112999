import React from 'react'
import * as S from './PatientTopBar.style'
import {
  useActiveAppointment,
  usePatient,
} from '@bonliva-routes/web/special-routes/PatientRoute'
import { useLocation } from 'react-router-dom'
import { StartMeetingButton } from '../StartMeetingButton/StartMeetingButton'

const PatientTopBar: React.FC = () => {
  const patient = usePatient()
  const location = useLocation()
  const appointment = useActiveAppointment()

  return (
    <S.PatientTopBar>
      <S.PatientTopBarLayout>
        <S.Title>{patient.data?.name}</S.Title>

        <S.FlexWrapper>
          {!!appointment.data && (
            <StartMeetingButton appointment={appointment.data} />
          )}
        </S.FlexWrapper>
      </S.PatientTopBarLayout>

      <S.PatientTopBarNav>
        <S.PatientTopBarNavLink
          to={`/patients/${patient.data?.id}`}
          $isActive={location.pathname === `/patients/${patient.data?.id}`}
        >
          Profil
        </S.PatientTopBarNavLink>

        <S.PatientTopBarNavLink
          to={`/patients/${patient.data?.id}/appointments`}
          $isActive={location.pathname.includes('/appointments')}
        >
          Samtal
        </S.PatientTopBarNavLink>

        <S.PatientTopBarNavLink
          to={`/patients/${patient.data?.id}/messages`}
          $isActive={location.pathname.includes('/messages')}
        >
          Meddelanden
        </S.PatientTopBarNavLink>

        <S.PatientTopBarNavLink
          to={`/patients/${patient.data?.id}/treatment-plans`}
          $isActive={location.pathname.includes('/treatment-plans')}
        >
          Behandlingsplan
        </S.PatientTopBarNavLink>

        <S.PatientTopBarNavLink
          to={`/patients/${patient.data?.id}/referral-plans`}
          $isActive={location.pathname.includes('/referral-plans')}
        >
          Remiss
        </S.PatientTopBarNavLink>
      </S.PatientTopBarNav>
    </S.PatientTopBar>
  )
}

export default PatientTopBar
