import React from 'react'
import * as S from './RadioButton.style'

type Props = {
  label: string
  isSelected: boolean
  onClick: () => void
  disabled?: boolean
}

export const RadioButton: React.FC<Props> = (props) => {
  return (
    <S.RadioWrapper
      onClick={() => {
        if (!props.disabled) {
          props.onClick()
        }
      }}
      disabled={props.disabled || false}
    >
      <S.RadioButton isSelected={props.isSelected} />
      <S.RadioLabel>{props.label}</S.RadioLabel>
    </S.RadioWrapper>
  )
}

export default RadioButton
