import { ActionMap } from '@bonliva-core/store'

export type State = {
  toasts: { id: string; toast: React.ReactNode }[]
  addToast: (toast: { id?: string; toast: React.ReactNode }) => void
  removeToast: (id: string) => void
  updateToast: (toast: { id: string; toast: React.ReactNode }) => void
}

export enum Types {
  ADD_TOAST = 'ADD_TOAST',
  REMOVE_TOAST = 'REMOVE_TOAST',
  UPDATE_TOAST = 'UPDATE_TOAST',
}

type Payload = {
  [Types.ADD_TOAST]: { toast: React.ReactNode; id?: string }
  [Types.REMOVE_TOAST]: string
  [Types.UPDATE_TOAST]: { id: string; toast: React.ReactNode }
}

export type Action = ActionMap<Payload>
