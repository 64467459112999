import styled from 'styled-components'
import { H1 } from '@bonliva-ui/web'

export const DashboardHeader = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto auto;
  grid-gap: ${({ theme }) => theme.margin.xs};

  align-items: center;

  padding: ${({ theme }) => theme.margin.xxs};
  background-color: ${({ theme }) => theme.colors.web.white};
  border-radius: ${({ theme }) => theme.radius.rounded};
  margin: ${({ theme }) => theme.margin.xxs};

  -webkit-box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.1);

  & > *:nth-child(3) {
    display: none;
  }

  & > *:nth-child(4) {
    display: none;
  }

  @media (min-width: 768px) {
    grid-template-columns: 130px calc(80% - 140px);
    grid-template-rows: auto 60px;
    grid-gap: ${({ theme }) => theme.margin.xs};

    & > *:nth-child(4) {
      display: block;
    }
  }

  @media (min-width: 1200px) {
    grid-template-columns: auto 1fr auto auto;
    grid-template-rows: auto;
    grid-row-gap: ${({ theme }) => theme.margin.s};
    grid-column-gap: ${({ theme }) => theme.margin.xs};

    & > *:nth-child(3) {
      display: block;
      margin-left: ${({ theme }) => theme.margin.xs};
    }
  }
`

export const DashboardHeaderTitle = styled(H1)`
  font-weight: 500;
  font-size: 34px;

  @media (min-width: 768px) {
    font-size: 48px;
  }
`
