import React from 'react'
import * as S from './ProfilePicture.style'
import { ITreater } from '@bonliva-traits/api/types'

type Props = {
  profile: ITreater
  height?: number | string
  width?: number | string
}

const ProfilePicture: React.FC<Props> = (props) => {
  const profileUrl = props.profile.avatar?.url

  return profileUrl ? (
    <S.ProfilePicture
      src={profileUrl}
      alt="Profil bild"
      $height={props.height}
      $width={props.width}
    />
  ) : (
    <S.NoProfilePicture $height={props.height} $width={props.width}>
      <S.DefaultAvatar src="Person" />
    </S.NoProfilePicture>
  )
}

export default ProfilePicture
