import React from 'react'
import { useAuth } from '@bonliva-auth/context'
import { Status } from '@bonliva-auth/context/context'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { ToastProvider } from '@bonliva-traits/web-toasts'

const ProtectedRoute: React.FC = () => {
  const { status } = useAuth()
  const { pathname } = useLocation()

  if (status !== Status.Authenticated) {
    return <Navigate to="/login" replace state={{ redirectTo: pathname }} />
  }

  return (
    <ToastProvider>
      <Outlet />
    </ToastProvider>
  )
}

export default ProtectedRoute
