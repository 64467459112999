import styled from 'styled-components'
import { Icon } from '@bonliva-traits/web-icons'
import { ButtonPrimary, TextArea, H3, H4, BodyRegular } from '@bonliva-ui/web'

export const MeetingNotepad = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
`

export const MeetingNotepadHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${({ theme }) => theme.margin.xxs};
`

export const MeetingNotepadTitle = styled(H3)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const MeetingNotepadClose = styled(Icon)`
  cursor: pointer;
  min-height: 20px;
  min-width: 20px;
  max-width: 20px;
  max-height: 20px;
  color: ${({ theme }) => theme.colors.web.darker};
`

export const MeetingNotepadContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`

export const MeetingNotepadNotesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: scroll;
  margin: ${({ theme }) => theme.margin.xxs} 0 0 0;
`

export const MeetingNotepadInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: ${({ theme }) => theme.margin['3xs']};
`

export const MeetingNotepadInput = styled(TextArea)`
  resize: none;
  border: 1px solid ${({ theme }) => theme.colors.web.light};
  height: 200px;
  margin-right: ${({ theme }) => theme.margin['3xs']};
`

export const MeetingNotepadFooter = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
`

export const MeetingNotepadButton = styled(ButtonPrimary)`
  width: 135px;
`

export const MeetingNotepadDropdownTitle = styled(H4)`
  margin-bottom: ${({ theme }) => theme.margin['3xs']};
`

export const MeetingNotepadDropdownWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.margin['3xs']};
`

export const SelectAsyncWrapper = styled.div`
  margin-bottom: ${({ theme }) => theme.margin['3xs']};
  margin-right: ${({ theme }) => theme.margin['3xs']};
`

export const Disclaimer = styled(BodyRegular)`
  font-style: italic;
`
