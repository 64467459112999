import styled from 'styled-components'
import { BodySmall } from '@bonliva-ui/web'

export const ReferralReminders = styled.div`
  background-color: ${({ theme }) => theme.colors.web.white};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: ${({ theme }) => theme.margin.xxs};
  flex: 1;
`

export const NoRemindersMessage = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darker};
  font-weight: 400;
  text-align: center;
`

export const PatientReferralPlans = styled.div`
  max-width: 1920px;
  margin: 0 auto 30px auto;
`

export const PatientReferralPlansLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.margin.xxs};
  margin-bottom: -${({ theme }) => theme.margin.xxs};
`

export const ReferralPlansWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.web.white};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: ${({ theme }) => theme.margin.m};
`

export const ReferralPlansContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
`
