import styled, { css } from 'styled-components'

export const PlanWellbeingScale = styled.div`
  display: inline-flex;
  padding: ${({ theme }) => theme.margin.s};
`

export const FlexCol = styled.div`
  display: flex;
  flex-direction: column;
`

export const FlexRow = styled.div`
  display: flex;
`

export const TableHeaderLeftWrapper = styled.div`
  border-radius: 4px 0 0 0;

  height: 65px;
  background: ${({ theme }) => theme.colors.web.primaryLight};
  border-right: 1px solid #f3a393;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 497px;
`

export const TableLeftFooter = styled.div`
  border-radius: 0 0 0 4px;
  height: 60px;
  background: #ffb8aa80;
  padding-right: ${({ theme }) => theme.margin.xxs};
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 497px;
  font-family: 'Calibre';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 21px;
`

export const TableHeaderRightWrapper = styled.div<{ isLast: boolean }>`
  display: flex;
  background: ${({ theme }) => theme.colors.web.primaryLight};
  border-right: 1px solid #f3a393;

  ${({ isLast }) =>
    isLast &&
    css`
      border-right: none;
      border-radius: 0 4px 0 0;
    `}
`
export const TableHeaderDate = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ theme }) => theme.margin.xxs};
  height: 65px;
  /* min-width: 123px; */
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
`

export const IconWrapper = styled.button<{
  disabled: boolean
  show: boolean
}>`
  display: ${({ show }) => (show ? 'flex' : 'none')};
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 40px;
  height: 40px;
  background: white;
  color: ${({ theme, disabled }) =>
    disabled ? theme.colors.web.neutral : theme.colors.web.primary};
  margin-right: ${({ theme }) => theme.margin.xxs};
  border: none;
  cursor: ${({ disabled }) => (disabled ? 'unset' : 'pointer')};
`

export const QuestionWrapper = styled.span`
  width: 497px;
  height: 60px;
  padding: ${({ theme }) => theme.margin['3xs']};
  text-align: left;
  border-bottom: 1px solid ${({ theme }) => theme.colors.web.primaryDark}50;
  background: #ffb8aa80;
  font-family: 'Calibre';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
`
export const AnswerWrapper = styled.span<{ oddRow: boolean }>`
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${({ oddRow }) => (oddRow ? '#FFF8F7' : 'white')};
  font-family: 'Calibre';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  border-right: 1px solid #bfbfbf59;
  border-bottom: 1px solid transparent;
`

export const AnswerScoreWrapper = styled.span<{ isLast: boolean }>`
  height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #ffdcd480;
  font-family: 'Calibre';
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 31px;
  border-right: 1px solid #ffb8aa50;
  ${({ isLast }) =>
    isLast &&
    css`
      border-radius: 0 0 4px 0;
    `};
`
