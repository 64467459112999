import React, { useCallback, useState, useEffect } from 'react'
import * as S from './CompetenceSlide.style'
import * as constant from '@bonliva-traits/constants'
import {
  useTreaterProfile,
  useUpdateTreaterProfile,
} from '@bonliva-traits/api/treater-profile'
import useApiState from '@bonliva-traits/hooks/useApiState'
import {
  ISpecialistCompetence,
  ISpecialistCompetenceTreater,
  ITreater,
} from '@bonliva-traits/api/types'
import { Icon } from '@bonliva-traits/web-icons'
import Select from '@bonliva-components/web/shared/Select'

type Props = {
  setDisabled: (disabled: boolean) => void
}

const CompetenceSlide: React.FC<Props> = (props) => {
  const profile = useTreaterProfile()
  const updateProfile = useUpdateTreaterProfile()

  const [competences, setCompetences] = useState(
    profile.data.specialistCompetences || []
  )

  const specialistCompetences = useApiState<ISpecialistCompetence[]>(
    '/v1/treater/specialist-competences'
  )

  useEffect(() => {
    specialistCompetences.get()
  }, [])

  useEffect(() => {
    props.setDisabled(!competences.length)
  }, [competences])

  useEffect(() => {
    if (!competences.length) return
    updateProfile({
      specialistCompetences: competences,
      completedOnboardingAt: new Date(),
    })
  }, [competences])

  const setCompetencesHandler = useCallback(
    (specialistCompetenceTreater: ISpecialistCompetenceTreater) => {
      setCompetences((prev) => {
        if (
          specialistCompetenceTreater.clinicalExperience ||
          specialistCompetenceTreater.education ||
          specialistCompetenceTreater.courses.length > 0
        ) {
          const curr = prev.slice()

          const index = curr.findIndex(
            (c) =>
              c.specialistCompetenceId ===
              specialistCompetenceTreater.specialistCompetenceId
          )

          if (index > -1) {
            curr[index] = specialistCompetenceTreater
          } else {
            curr.push(specialistCompetenceTreater)
          }

          return curr
        } else {
          return prev.filter(
            (c) =>
              c.specialistCompetenceId !==
              specialistCompetenceTreater.specialistCompetenceId
          )
        }
      })
    },
    []
  )

  return (
    <S.CompetenceSlide>
      <S.CompetenceSlideTextWrapper>
        <S.CompetenceSlideTitle>Lägg till kompetenser.</S.CompetenceSlideTitle>
        <S.CompetenceSlideBody>
          Kompetenserna kommer att synas för patienterna.
        </S.CompetenceSlideBody>
      </S.CompetenceSlideTextWrapper>

      {specialistCompetences.data?.map((c, index, arr) => (
        <Competence
          key={c.id}
          index={index}
          length={arr.length}
          competence={c}
          treater={profile.data}
          onChange={setCompetencesHandler}
        />
      ))}
    </S.CompetenceSlide>
  )
}

type ICompetenceProps = {
  index: number
  length: number
  treater: ITreater
  competence: ISpecialistCompetence
  onChange: (specialistCompetenceTreater: ISpecialistCompetenceTreater) => void
}

const Competence: React.FC<ICompetenceProps> = (props) => {
  const [course, setCourse] = useState('')
  const [courses, setCourses] = useState<string[]>([])
  const [hasEducation, setHasEducation] = useState(false)
  const [hasClinicalExperience, setHasClinicalExperience] = useState(false)
  const [clinicalExperienceYears, setClinicalExperienceYears] = useState(0)

  const addCourseHandler = useCallback(() => {
    if (!course) return
    setCourses((prev) => {
      if (prev.includes(course)) return prev
      return [...prev, course]
    })

    setCourse('')
  }, [course])

  const removeCourseHandler = useCallback((course: string) => {
    setCourses((prev) => {
      const index = prev.findIndex((c) => c === course)
      if (index > -1) {
        const newCourses = [...prev]
        newCourses.splice(index, 1)
        return newCourses
      } else {
        return prev
      }
    })
  }, [])

  useEffect(() => {
    props.onChange({
      specialistCompetenceId: props.competence.id,
      education: hasEducation,
      clinicalExperience: hasClinicalExperience,
      clinicalExperienceYears: clinicalExperienceYears,
      courses: courses,
    })
  }, [courses, clinicalExperienceYears, hasClinicalExperience, hasEducation])

  useEffect(() => {
    const hasCompetence = props.treater?.specialistCompetences?.find(
      (c) => c.specialistCompetenceId === props.competence.id
    )

    if (hasCompetence) {
      setCourses(hasCompetence.courses)
      setHasEducation(hasCompetence.education)
      setHasClinicalExperience(hasCompetence.clinicalExperience)
      setClinicalExperienceYears(hasCompetence?.clinicalExperienceYears || 0)
    }
  }, [props.competence])

  return (
    <S.Competence>
      <S.CompetenceHeader>
        <S.CompetenceDetail>
          Fråga {props.index + 1}/{props.length}
        </S.CompetenceDetail>
        <S.CompetenceTitle>{props.competence.name}</S.CompetenceTitle>
      </S.CompetenceHeader>

      <S.CompetenceContent>
        <span>
          <S.FlexWrapper>
            <S.Checkbox onClick={() => setHasEducation((prev) => !prev)}>
              {hasEducation && <S.Check src="Check" />}
            </S.Checkbox>

            <S.CheckboxLabel>{`Jag har specialistutbildning`}</S.CheckboxLabel>
          </S.FlexWrapper>
        </span>

        <span>
          <S.FlexWrapper>
            <S.Checkbox
              onClick={() => setHasClinicalExperience((prev) => !prev)}
            >
              {hasClinicalExperience && <S.Check src="Check" />}
            </S.Checkbox>

            <S.CheckboxLabel>{`Jag har klinisk erfarenhet`}</S.CheckboxLabel>
          </S.FlexWrapper>
        </span>

        <span>
          <S.CompetenceLabel>
            Hur många år av klinisk erfarenhet?
          </S.CompetenceLabel>
          <Select
            isDisabled={!hasClinicalExperience}
            placeholder="Ange antal år av erfarenhet"
            value={{
              value: clinicalExperienceYears,
              label: `${clinicalExperienceYears} år`,
            }}
            options={constant.experiences}
            onChange={(e) => e && setClinicalExperienceYears(e.value)}
          />
        </span>

        <span>
          <S.CompetenceLabel>
            Har du genomgått någon kurs eller utbildning?
          </S.CompetenceLabel>

          <S.FlexWrapper>
            <S.Input
              placeholder="Ange namn på kurs eller utbildning"
              value={course}
              onChange={(e) => setCourse(e.target.value)}
            />
            <S.Button
              onClick={addCourseHandler}
              disabled={
                !course ||
                courses.some((c) => c.toLowerCase() === course.toLowerCase())
              }
            >
              Lägg till kurs
            </S.Button>
          </S.FlexWrapper>

          <S.CourseList>
            {courses.map((c, index) => (
              <S.Course key={index}>
                {c}
                <S.CourseRemoveButton onClick={() => removeCourseHandler(c)}>
                  <Icon src="Close" />
                </S.CourseRemoveButton>
              </S.Course>
            ))}
          </S.CourseList>
        </span>
      </S.CompetenceContent>
    </S.Competence>
  )
}

export default CompetenceSlide
