import { BodyRegular } from '@bonliva-ui/web'
import styled from 'styled-components'

export const SectionedResults = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`

export const NoFavorites = styled(BodyRegular)`
  margin-left: ${({ theme }) => theme.margin.s};
`

export * from '../../MediaLibrary.style'
