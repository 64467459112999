import styled from 'styled-components'

export const TreatmentPlanCreate = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`

export const TreatmentPlanCreateLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.margin.xxs};
`

export const TreatmentPlanCreateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.xxs};
`
