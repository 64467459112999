import React from 'react'
import * as S from './SectionedResults.style'
import {
  IExercise,
  IExerciseCategory,
  IExerciseProgram,
  IFavoriteMedia,
} from '@bonliva-traits/api/types'
import MediaCard from '@bonliva-components/web/shared/MediaCard'

type Props = {
  exerciseCategories: IExerciseCategory[]
  favorites: IFavoriteMedia[]
  favoriteFilter: (item: IExercise | IExerciseProgram) => boolean
  isFavorite: boolean
}

const SectionedResults: React.FC<Props> = (props) => {
  return (
    <S.SectionedResults>
      <S.MediaLibraryLayout>
        {props.isFavorite && !props.favorites.length ? (
          <S.NoFavorites>Det finns inga favoriter att visa</S.NoFavorites>
        ) : (
          <>
            {props.exerciseCategories.map((exerciseCategory) => {
              const combined = [
                ...exerciseCategory.exercisePrograms.data.map(
                  (exerciseCategory) => exerciseCategory.attributes
                ),
                ...exerciseCategory.exercises.data.map(
                  (exerciseCategory) => exerciseCategory.attributes
                ),
              ]

              return (
                <S.MediaLibrarySection key={exerciseCategory.uuid}>
                  <S.MediaLibraryTitle>
                    {exerciseCategory.title}
                  </S.MediaLibraryTitle>
                  <S.MediaLibraryUnderline />
                  <S.MediaLibraryContent>
                    {combined.filter(props.favoriteFilter).map((media) => {
                      const favorite = props.favorites.find(
                        (favorite) => favorite.mediaLibraryId === media.uuid
                      )

                      return (
                        <MediaCard
                          key={media.uuid}
                          canFavorite
                          media={media}
                          favoriteId={favorite?.id}
                        />
                      )
                    })}
                  </S.MediaLibraryContent>
                </S.MediaLibrarySection>
              )
            })}
          </>
        )}
      </S.MediaLibraryLayout>
    </S.SectionedResults>
  )
}

export default SectionedResults
