import { IAppointment } from '@bonliva-traits/api/types'
import React from 'react'

export enum MeetingStatus {
  Connecting = 'connecting',
  Connected = 'connected',
  Disconnected = 'disconnected',
  Failed = 'failed',
}

export enum ScreenShareStatus {
  NotSupported = 'not-supported',
  NotSharing = 'not-sharing',
  Starting = 'starting',
  Sharing = 'sharing',
}

import {
  OTError,
  Publisher,
  Subscriber,
  Session,
  Stream,
  EventMap,
} from '@opentok/client'

export type IMeetingSubscriber = {
  streamId: string
  subscriber: Subscriber
  hasVideo: boolean
  videoElement?: HTMLElement
}

type IMeetingContext = {
  startMeeting: (appointmentId: string) => Promise<void>
  stopMeeting: (appointmentId: string) => void
  setPublishAudio: (value: boolean) => void
  setPublishVideo: (value: boolean) => void
  startScreenShare: () => void
  stopScreenShare: () => void
  setAudioInputDevice: (device: MediaDeviceInfo) => void
  setAudioOutputDevice: (device: MediaDeviceInfo) => void
  setVideoDevice: (device: MediaDeviceInfo) => void
  getDevices: () => void
  status: MeetingStatus
  screenSharingStatus: ScreenShareStatus
  stream?: Stream
  subscriber?: IMeetingSubscriber
  session?: Session
  publisher?: Publisher
  screenSharePublisher?: Publisher
  error?: OTError
  appointment?: IAppointment
  publishAudio: boolean
  publishVideo: boolean
  availableAudioInputDevices: MediaDeviceInfo[]
  availableAudioOutputDevices: MediaDeviceInfo[]
  availableVideoDevices: MediaDeviceInfo[]
  audioInputDevice: MediaDeviceInfo | undefined
  audioOutputDevice: MediaDeviceInfo | undefined
  videoDevice: MediaDeviceInfo | undefined
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <MeetingProvider>.')
}

const initialState: IMeetingContext = {
  startMeeting: stub,
  stopMeeting: stub,
  setPublishAudio: stub,
  setPublishVideo: stub,
  startScreenShare: stub,
  stopScreenShare: stub,
  setAudioInputDevice: stub,
  setAudioOutputDevice: stub,
  setVideoDevice: stub,
  getDevices: stub,
  status: MeetingStatus.Disconnected,
  screenSharingStatus: ScreenShareStatus.NotSupported,
  publishAudio: true,
  publishVideo: true,
  availableAudioInputDevices: [],
  availableAudioOutputDevices: [],
  availableVideoDevices: [],
  audioInputDevice: undefined,
  audioOutputDevice: undefined,
  videoDevice: undefined,
}

export const MeetingContext = React.createContext(initialState)

export const useMeeting = () => React.useContext(MeetingContext)
