import React, { useEffect } from 'react'
import * as S from './PostMeetingRoomNotepad.style'
import MeetingNotepad from '@bonliva-components/web/shared/MeetingNotepad'
import { useWebdocNotes } from '@bonliva-traits/webdoc-notes'
import { useMeeting } from '@bonliva-traits/meeting-web'

const PostMeetingRoomNotepad = () => {
  const { setShowNotepad } = useWebdocNotes()
  const { appointment } = useMeeting()

  useEffect(() => {
    setShowNotepad(true)
  }, [])

  return (
    <S.PostMeetingRoomNotepad>
      {appointment && <MeetingNotepad appointment={appointment} />}
    </S.PostMeetingRoomNotepad>
  )
}

export default PostMeetingRoomNotepad
