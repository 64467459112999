import { success, pending } from '@bonliva-core/store'
import { AxiosError, useApi } from '../../index'
import { BookingStatus, UserType } from '../../enums'
import { IAppointment } from '../../types'
import { relationsToQuery } from '@bonliva-traits/utils'
import store from '../store'

type IParams = {
  fromDate?: string
  toDate?: string
  status?: BookingStatus[]
  relations?: string[]
}

export const useFetchAppointments = (userType: UserType) => {
  const api = useApi()
  const dispatch = store.useDispatch()
  const type = userType === UserType.Patient ? 'patients' : 'treaters'

  return async (params?: IParams) => {
    dispatch(pending())
    const relations = params?.relations
    delete params?.relations

    try {
      const appointments = await api.get<IAppointment[]>(
        `/v1/${type}/appointments`,
        {
          params: {
            ...params,
            ...relationsToQuery(relations || []),
          },
        }
      )

      dispatch(success(appointments.data))
      return appointments.data
    } catch (err) {
      const e = err as AxiosError
      if (!e.response) throw err
      dispatch(fail(e.response.data))
    }
  }
}
