import React from 'react'
import * as S from './DeleteModal.style'
import Modal from '../Modal'
import Alert from '../Alert'
import Spinner from '../Spinner'

type Props<T> = {
  items: T
  isLoading: boolean
  hasError?: boolean
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  disableOnDelete?: boolean
  onDelete: () => void
  onDeleteTitle?: string
  title: string
  errorTitle?: string
  errorMessages?: string[]
  warningTitle: string
  warningMessages?: string[]
  content?: React.ReactNode
}

function DeleteModal<T>(props: Props<T>) {
  return (
    <Modal.Wrapper isOpen={props.isOpen}>
      <Modal.Contents onDismiss={() => props.setIsOpen(false)}>
        <S.DeleteModal>
          <Modal.CloseButton />

          <S.Header>
            <S.Title>{props.title}</S.Title>
          </S.Header>

          <S.Content>
            {props.hasError && (
              <Alert
                type="error"
                title={props.errorTitle || 'Något gick fel.'}
                messages={props.errorMessages || ['Vänligen försök igen.']}
              />
            )}

            <Alert
              type="warning"
              title={props.warningTitle}
              messages={props.warningMessages}
            />

            {props.content}
          </S.Content>

          <S.Footer>
            <Modal.DismissButton>
              <S.Cancel>Avbryt</S.Cancel>
            </Modal.DismissButton>

            <S.Delete
              disabled={props.isLoading || props.disableOnDelete}
              onClick={props.onDelete}
            >
              {props.isLoading ? <Spinner /> : props.onDeleteTitle || 'Ta bort'}
            </S.Delete>
          </S.Footer>
        </S.DeleteModal>
      </Modal.Contents>
    </Modal.Wrapper>
  )
}

export default DeleteModal
