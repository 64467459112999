import React, { useMemo } from 'react'
import * as S from './PlanSummary.style'
import { IReferralPlan } from '@bonliva-traits/api/types'
import { useNavigate, useParams } from 'react-router-dom'
import ProgressBar from '@bonliva-components/web/shared/ProgressBar'
import ReferralPlanStatusChip from '@bonliva-components/web/shared/ReferralPlanStatusChip'

import { useApi } from '@bonliva-traits/api'
import { referralPlanStatus } from '@bonliva-traits/api/referral-plans'

type PlanSummaryProps = {
  referralPlan: IReferralPlan
  refetch: () => void
}

const PlanSummary = (props: PlanSummaryProps) => {
  const api = useApi()
  const { patientId, referralPlanId } = useParams()
  const navigate = useNavigate()

  const progressInPercent = useMemo(() => {
    const { completedBookingsCount, bookingsCount } = props.referralPlan
    if (!bookingsCount || !completedBookingsCount) return 0
    return Math.floor((completedBookingsCount / bookingsCount) * 100)
  }, [props.referralPlan])

  const navigateToEditHandler = () => {
    navigate(`/patients/${patientId}/referral-plans/${referralPlanId}/edit`)
  }

  const markAsDoneHandler = async () => {
    try {
      await api.put(`/v1/treaters/referral-plans/${referralPlanId}/complete`)
      props.refetch()
    } catch (e) {
      throw new Error("Couldn't send complete referral plan request")
    }
  }

  return (
    <S.SummaryContainer>
      <S.TopBarWrapper>
        <S.Title>Översikt</S.Title>

        <S.ButtonWrapper>
          <S.MarkAsDoneButton
            disabled={!!props.referralPlan.completedAt}
            onClick={markAsDoneHandler}
          >
            {!props.referralPlan?.completedAt ? 'Markera som klar' : 'Avklarad'}
          </S.MarkAsDoneButton>

          <S.EditButton onClick={navigateToEditHandler}>Redigera</S.EditButton>
        </S.ButtonWrapper>
      </S.TopBarWrapper>

      <S.ContentWrapper>
        <S.SummaryWrapper>
          <S.SummaryStatusContent>
            <S.SummaryHeader>Status</S.SummaryHeader>
            <S.SummaryBody>
              <ReferralPlanStatusChip
                status={referralPlanStatus(props.referralPlan)}
              />
            </S.SummaryBody>
          </S.SummaryStatusContent>

          <S.SummaryStatusContent>
            <S.SummaryHeader>Berättigade samtal</S.SummaryHeader>
            <S.SummaryBody>
              {props.referralPlan.numberOfAppointments}
            </S.SummaryBody>
          </S.SummaryStatusContent>
        </S.SummaryWrapper>

        <S.FirstWrapper>
          <S.SummaryAppointmentContent>
            <S.SummaryHeader>Ärende</S.SummaryHeader>
            <S.SummaryBody>
              {props.referralPlan?.treatmentCategory?.category}
            </S.SummaryBody>
          </S.SummaryAppointmentContent>

          <S.SummaryProgressContent>
            <S.SummaryHeader>Samtal</S.SummaryHeader>
            <S.SummaryBody>
              <S.ProgressBarWrapper>
                <ProgressBar
                  count={props.referralPlan.bookingsCount}
                  completed={props.referralPlan.completedBookingsCount}
                />
              </S.ProgressBarWrapper>
              <S.ProgressInfoWrapper>
                <S.ProgressInfo>
                  {props.referralPlan.completedBookingsCount}/
                  {props.referralPlan.bookingsCount} Slutförda
                </S.ProgressInfo>
                <S.ProgressInfo>{progressInPercent}%</S.ProgressInfo>
              </S.ProgressInfoWrapper>
            </S.SummaryBody>
          </S.SummaryProgressContent>
        </S.FirstWrapper>
      </S.ContentWrapper>
    </S.SummaryContainer>
  )
}

export default PlanSummary
