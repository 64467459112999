import React from 'react'
import * as S from './AnalogClock.style'
import useInterval from '@bonliva-traits/hooks/useInterval'

type Props = {
  height?: number | string
  width?: number | string
}

const AnalogClock: React.FC<Props> = (props) => {
  const [value, setValue] = React.useState(new Date())

  useInterval(() => setValue(new Date()), 1000)

  return (
    <S.AnalogClock $height={props.height} $width={props.width}>
      <S.Clock
        value={value}
        renderNumbers
        renderMinuteMarks={false}
        renderSecondHand={false}
      />
    </S.AnalogClock>
  )
}

export default AnalogClock
