import React, { useEffect } from 'react'
import * as S from './PatientReferralPlanEdit.style'
import PatientTopBar from '@bonliva-components/web/shared/PatientTopBar'
import CreateBookingsCalendar from './components/CreateBookingsCalendar'
import { useParams } from 'react-router-dom'
import useApiState from '@bonliva-traits/hooks/useApiState'
import { IReferralPlan } from '@bonliva-traits/api/types'

const ReferralPlanCreate = () => {
  const params = useParams()

  const referralPlan = useApiState<IReferralPlan>(
    `/v1/treaters/referral-plans/${params.referralPlanId}`
  )

  useEffect(() => {
    referralPlan.get()
  }, [])

  return (
    <S.ReferralPlanCreate>
      <S.ReferralPlanCreateLayout>
        <PatientTopBar />

        <S.ReferralPlanCreateContainer>
          {referralPlan.data && (
            <CreateBookingsCalendar
              treatmentId={referralPlan.data.treatmentCategoryId}
              numberOfAppointmentsLeft={
                referralPlan.data.numberOfAppointmentsLeft
              }
            />
          )}
        </S.ReferralPlanCreateContainer>
      </S.ReferralPlanCreateLayout>
    </S.ReferralPlanCreate>
  )
}

export default ReferralPlanCreate
