import styled from 'styled-components'
import { BodySmall, H3, H4 } from '@bonliva-ui/web'

export const MediaProgram = styled.div`
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation: fadeIn 0.2s ease-in-out;
`

export const MediaProgramLayout = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`

export const MediaProgramContent = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.margin.xs};
  gap: ${({ theme }) => theme.margin.xs};
`

export const MediaProgramTitle = styled(H4)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const MediaProgramDescription = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darkest};
  max-width: 1000px;
`

export const MediaProgramDetail = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const MediaProgramSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin['4xs']};
`

export const MediaProgramSectionTitle = styled(H3)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const MediaProgramSectionUnderline = styled.div`
  display: flex;
  width: 100%;
  height: 0.5px;
  background-color: ${({ theme }) => theme.colors.web.neutral};
  margin-bottom: ${({ theme }) => theme.margin['3xs']};
`

export const MediaProgramSectionContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: ${({ theme }) => theme.margin.xxs};
`

export const Loading = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 100px);
`
