import React from 'react'
import * as S from './TreatmentPlanStatusChip.style'
import { Icon, IconType } from '@bonliva-traits/web-icons'
import { useTheme } from 'styled-components'
import { TreatmentPlanStatus } from '@bonliva-traits/api/enums'

type StatusChipProps = {
  status: TreatmentPlanStatus
  rejectionMessage?: string
}

type StatusIcon = {
  icon: keyof typeof IconType
  backgroundColor: string
  borderColor: string
  borderStyle: string
  title: string
}

const TreatmentPlanStatusChip = (props: StatusChipProps) => {
  const theme = useTheme()

  const statusChipMap: Record<TreatmentPlanStatus, StatusIcon> = {
    [TreatmentPlanStatus.Draft]: {
      icon: IconType.Draft,
      backgroundColor: theme.colors.web.black,
      borderColor: theme.colors.web.black,
      borderStyle: 'dashed',
      title: 'Utkast',
    },
    [TreatmentPlanStatus.Pending]: {
      icon: IconType.InfoFilled,
      backgroundColor: theme.colors.web.info,
      borderColor: theme.colors.web.info,
      borderStyle: 'solid',
      title: 'Inväntar svar',
    },
    [TreatmentPlanStatus.Rejected]: {
      icon: IconType.AlertDenied,
      backgroundColor: theme.colors.web.rejected,
      borderColor: theme.colors.web.rejected,
      borderStyle: 'solid',
      title: 'Nekad',
    },
    [TreatmentPlanStatus.Accepted]: {
      icon: IconType.Check,
      backgroundColor: theme.colors.web.success,
      borderColor: theme.colors.web.success,
      borderStyle: 'solid',
      title: 'Accepterad',
    },
    [TreatmentPlanStatus.Completed]: {
      icon: IconType.Check,
      backgroundColor: theme.colors.web.info,
      borderColor: theme.colors.web.info,
      borderStyle: 'solid',
      title: 'Avslutad',
    },
  }

  const currentStatus = statusChipMap[props.status]

  return (
    <S.StatusContainer
      borderColor={currentStatus.borderColor}
      backgroundColor={currentStatus.backgroundColor}
      borderStyle={currentStatus.borderStyle}
    >
      <S.StatusWrapper color={currentStatus.borderColor}>
        <Icon src={currentStatus.icon} />
        <S.StatusTitle textColor={currentStatus.borderColor}>
          {currentStatus.title}
        </S.StatusTitle>
      </S.StatusWrapper>
      {props.rejectionMessage &&
        props.status === TreatmentPlanStatus.Rejected && (
          <S.RejectedMessage>
            <S.SummaryBody>Meddelande från patient: </S.SummaryBody>
            <S.SummaryBody>{props.rejectionMessage}</S.SummaryBody>
          </S.RejectedMessage>
        )}
    </S.StatusContainer>
  )
}

export default TreatmentPlanStatusChip
