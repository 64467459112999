import { useState, useMemo, useCallback } from 'react'
import useApi from '@bonliva-traits/hooks/useApiState'
import { IAssignment } from '@bonliva-traits/api/types'
import { requestPagination } from '@bonliva-traits/utils'
import useInterval from '@bonliva-traits/hooks/useInterval'

export const useTreatmentPlanAssignments = (treatmentPlanId?: string) => {
  const [pageSize] = useState(11)
  const [currentPage, setCurrentPage] = useState(0)

  const assignments = useApi<IAssignment[]>('/v1/treaters/treatment-plans')

  const fetchAssignmentsHandler = async () => {
    const params = {
      ...requestPagination(currentPage, pageSize),
    }

    await assignments.get({ params }, `${treatmentPlanId}/assignments`)
  }

  const refreshAssignmentsHandler = useCallback(() => {
    setCurrentPage(0)
    assignments.unload()
    fetchAssignmentsHandler()
  }, [fetchAssignmentsHandler])

  useInterval(fetchAssignmentsHandler, 60000, [currentPage])

  const numberOfPages = useMemo(() => {
    if (!assignments.headers?.['x-total-count']) return 0
    const total = parseInt(assignments.headers['x-total-count'])
    return Math.ceil(total / pageSize)
  }, [assignments.data])

  return {
    currentPage,
    setCurrentPage,
    numberOfPages,
    assignments: assignments.data,
    refreshAssignmentsHandler,
  }
}
