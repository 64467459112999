import React, { useCallback, useState, useEffect } from 'react'
import * as S from './TitleSlide.style'
import * as constant from '@bonliva-traits/constants'
import {
  useTreaterProfile,
  useUpdateTreaterProfile,
} from '@bonliva-traits/api/treater-profile'
import Select from '@bonliva-components/web/shared/Select'
import { getLastHundredYears } from '@bonliva-traits/utils/getLastHundredYears'
import { getYear } from 'date-fns'

type Props = {
  setDisabled: (disabled: boolean) => void
}

const TitleSlide: React.FC<Props> = (props) => {
  const yearValues = getLastHundredYears()
  const profile = useTreaterProfile()
  const updateProfile = useUpdateTreaterProfile()
  const [title, setTitle] = useState(constant.titles[0].value)
  const [licensedAt, setLicensedAt] = useState(profile.data.licensedAt)

  const updateTreaterHandler = useCallback(() => {
    updateProfile({ title, licensedAt })
  }, [title, licensedAt])

  useEffect(() => {
    props.setDisabled(!title || !licensedAt)
  }, [title, licensedAt])

  return (
    <S.TitleSlide>
      <S.TitleSlideTextWrapper>
        <S.TitleSlideTitle>
          Lägg till yrkestitel och år för din legitimering.
        </S.TitleSlideTitle>
        <S.TitleSlideBody>
          Yrkestiteln och din erfarenhet kommer att synas för patienterna.
        </S.TitleSlideBody>
      </S.TitleSlideTextWrapper>

      <S.TitleSlideSelectWrapper>
        <Select
          isDisabled
          value={{ value: title, label: title }}
          options={constant.titles}
          onChange={(t) => t && setTitle(t.value)}
        />
      </S.TitleSlideSelectWrapper>

      <S.TitleSlideSelectWrapper>
        <Select
          placeholder="Ange år för legitimering"
          value={
            !!licensedAt && {
              value: licensedAt,
              label: getYear(new Date(licensedAt)).toString(),
            }
          }
          options={yearValues}
          onChange={(e) => e && setLicensedAt(e.value)}
          onBlur={updateTreaterHandler}
        />
      </S.TitleSlideSelectWrapper>
    </S.TitleSlide>
  )
}

export default TitleSlide
