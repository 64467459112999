import { useEffect } from 'react'
import { ITreatmentPlan } from '@bonliva-traits/api/types'
import useApiState from '@bonliva-traits/hooks/useApiState'

export const useTreatmentPlan = (treatmentPlanId: string) => {
  const treatmentPlan = useApiState<ITreatmentPlan>(
    `v1/treaters/treatment-plans/${treatmentPlanId}`
  )

  useEffect(() => {
    treatmentPlan.get()
  }, [])

  return treatmentPlan
}
