import React from 'react'
import * as S from './DigitalClock.style'
import { format } from 'date-fns'
import { sv } from 'date-fns/locale'
import useInterval from '@bonliva-traits/hooks/useInterval'
import { uppercaseFirst } from '@bonliva-traits/utils'

const DigitalClock: React.FC = () => {
  const [value, setValue] = React.useState(new Date())

  useInterval(() => {
    setValue(new Date())
  }, 1000)

  return (
    <S.DigitalClock>
      <S.TimeText>{format(value, 'HH:mm')}</S.TimeText>

      <S.DateText>
        {uppercaseFirst(format(value, 'EEEE, d MMM', { locale: sv }))}
      </S.DateText>
    </S.DigitalClock>
  )
}

export default DigitalClock
