import React, { useEffect, useCallback } from 'react'
import * as S from './PatientMessage.style'
import { useParams } from 'react-router-dom'
import { INotification } from '@bonliva-traits/api/types'
import { format } from 'date-fns'
import useApiState from '@bonliva-traits/hooks/useApiState'
import PatientTopBar from '@bonliva-components/web/shared/PatientTopBar'
import Table from '@bonliva-components/web/shared/Table'

const tableColumns = [
  {
    label: 'Skickat',
  },
  {
    label: 'Tid',
  },
  {
    label: 'Läst',
  },
]

const PatientMessage: React.FC = () => {
  const params = useParams<{ messageId: string }>()

  const notification = useApiState<INotification>(
    `/v2/notifications/${params.messageId}`
  )

  const fetchNotificationHandler = useCallback(() => {
    notification.get({})
  }, [])

  useEffect(() => {
    fetchNotificationHandler()
  }, [])

  return (
    <S.PatientMessages>
      <S.PatientMessagesLayout>
        <PatientTopBar />
      </S.PatientMessagesLayout>

      {notification.data && (
        <S.PatientMessageTableWrapper>
          <Table.Wrapper
            header={
              <Table.Header
                title={`Meddelande - ${format(
                  new Date(notification.data.createdAt),
                  'yyyy-MM-dd'
                )}`}
              />
            }
            head={<Table.Head columns={tableColumns} />}
            body={
              <Table.Body isLoading={notification.isLoading}>
                <S.TableRow>
                  <S.FlexSpan>
                    <S.RowText>
                      {format(
                        new Date(notification.data.createdAt),
                        'yyyy-MM-dd'
                      )}
                    </S.RowText>
                  </S.FlexSpan>

                  <S.FlexSpan>
                    <S.RowText>
                      {format(new Date(notification.data?.createdAt), 'HH:mm')}
                    </S.RowText>
                  </S.FlexSpan>

                  <S.FlexSpan>
                    <S.RowText>
                      {notification.data?.unread ? 'Nej' : 'Ja'}
                    </S.RowText>
                  </S.FlexSpan>
                </S.TableRow>
              </Table.Body>
            }
          />
        </S.PatientMessageTableWrapper>
      )}

      <S.MessageWrapper>
        <S.MessageContainer>
          <S.MessageHeader>
            <S.MessageTitle>Meddelande</S.MessageTitle>
          </S.MessageHeader>

          <S.Message>{notification.data?.metadata?.message}</S.Message>

          {!!notification.data?.metadata?.links?.length && (
            <>
              <S.AttachHeader>
                <S.AttachHeaderIcon src="LinkAttach" />
                <S.AttachHeaderLabel>Bifogade länkar</S.AttachHeaderLabel>
              </S.AttachHeader>

              <S.FlexWrap>
                {notification.data.metadata.links.map(({ label, url }) => (
                  <S.ListWrapper key={url}>
                    <S.ListItem href={url} target="_blank">
                      {label} - {url}
                    </S.ListItem>
                  </S.ListWrapper>
                ))}
              </S.FlexWrap>
            </>
          )}

          {!!notification.data?.files?.length && (
            <>
              <S.AttachHeader>
                <S.AttachHeaderIcon src="fileAttach" />
                <S.AttachHeaderLabel>Bifogade filer</S.AttachHeaderLabel>
              </S.AttachHeader>

              <S.FlexWrap>
                {notification.data.files.map(({ name, url, id }) => (
                  <S.ListWrapper key={id}>
                    <S.ListItem href={url} target="_blank">
                      {name.substring(13)}
                    </S.ListItem>
                  </S.ListWrapper>
                ))}
              </S.FlexWrap>
            </>
          )}
        </S.MessageContainer>
      </S.MessageWrapper>
    </S.PatientMessages>
  )
}

export default PatientMessage
