import React from 'react'
import { IMeetingSubscriber, useMeeting } from './context'
import {
  MeetingSubscriberInner,
  PatientPlaceholder,
  PatientPlaceholderCircleWrapper as PatientPlaceholderName,
  Container,
} from '../MeetingSubscriber.style'

type Props = {
  subscriber: IMeetingSubscriber
}

const MeetingSubscriber: React.FC<Props> = (props) => {
  const { appointment } = useMeeting()
  const containerRef = React.useRef<HTMLDivElement>(null)
  const [videoElement, setVideoElement] = React.useState<HTMLElement>()

  React.useEffect(() => {
    if (!containerRef.current) return

    if (videoElement) {
      try {
        containerRef.current?.removeChild(videoElement)
      } catch (_) {}
    }

    if (props.subscriber.videoElement) {
      containerRef.current?.appendChild(props.subscriber.videoElement)
      setVideoElement(props.subscriber.videoElement)
    }
  }, [containerRef, props.subscriber.videoElement])

  return (
    <Container>
      <MeetingSubscriberInner
        hide={!props.subscriber.hasVideo}
        ref={containerRef}
      />
      {!props.subscriber.hasVideo && (
        <PatientPlaceholder>
          <PatientPlaceholderName>
            {appointment?.patient?.name}
          </PatientPlaceholderName>
        </PatientPlaceholder>
      )}
    </Container>
  )
}

export const MeetingSubscribers: React.FC = () => {
  const { subscriber } = useMeeting()

  if (!subscriber) return null

  return (
    <>
      <MeetingSubscriber subscriber={subscriber} />
    </>
  )
}
