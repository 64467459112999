import styled from 'styled-components'
import { BodyRegular, H3, TextArea } from '@bonliva-ui/web'

export const Calendar = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`

export const CalendarLayout = styled.div`
  margin: ${({ theme }) => theme.margin.xxs};
`

export const DeleteModalLabel = styled(BodyRegular)``

export const DeleteModalCommentField = styled(TextArea)`
  resize: vertical;
  margin-bottom: ${({ theme }) => theme.margin.xxs};
`

export const SuccessModalTitle = styled(H3)`
  font-weight: 500;
  text-align: center;
`

export const SuccessModalInfoWrapper = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.margin.xxs};
  margin: 0 auto;
`

export const SuccessModalInfo = styled(BodyRegular)`
  display: flex;
  color: ${({ theme }) => theme.colors.web.dark};
  gap: ${({ theme }) => theme.margin['3xs']};
`

export const SuccessModalBold = styled(BodyRegular)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.web.darkest};
`
