import styled from 'styled-components'
import { H3, BodyRegular, ButtonPrimary } from '@bonliva-ui/web'
import { Icon } from '@bonliva-traits/web-icons'

export const ProfilePictureSlide = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const ProfilePictureSlideTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const ProfilePictureSlideTitle = styled(H3)``

export const ProfilePictureSlideBody = styled(BodyRegular)``

export const ProfilePictureSlideLoading = styled.div`
  border-radius: 4px;
  z-index: 10;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: ${({ theme }) => theme.colors.web.secondary};

  svg {
    background-color: ${({ theme }) => theme.colors.web.white}cc;
    border-radius: 9999px;
    padding: ${({ theme }) => theme.margin['3xs']};
    backdrop-filter: blur(12px);

    min-width: 36px;
    min-height: 36px;
  }

  background-color: ${({ theme }) => theme.colors.web.secondaryDark}cc;
  backdrop-filter: blur(2px);

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  animation: fadeIn 0.2s ease-in-out;
`

export const ProfilePictureSlideImageWrapper = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
  min-width: 200px;
  min-height: 200px;
  border-radius: 4px;
  overflow: hidden;
`

export const ProfilePictureSlideImage = styled.img`
  width: 100%;
  height: 100%;

  object-fit: cover;
`

export const ProfilePictureSlideNoImage = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.colors.web.secondaryLight};
  aspect-ratio: 1 / 1;
`

export const ProfilePictureSlideNoImageDefaultAvatar = styled(Icon)`
  width: 70%;
  height: 70%;
  color: ${({ theme }) => theme.colors.web.secondaryDark};
`

export const ProfilePictureSlideButton = styled(ButtonPrimary)``
