import { Icon } from '@bonliva-traits/web-icons'
import { BodySmall } from '@bonliva-ui/web'
import styled from 'styled-components'

export const CreatedBookingSlot = styled.div`
  position: relative;
  height: fit-content;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: ${({ theme }) => theme.radius.rounded};
  border: 1px dotted ${({ theme }) => theme.colors.web.secondary};
  background-color: ${({ theme }) => theme.colors.web.secondaryLight};
  padding: ${({ theme }) => theme.margin['3xs']};
`

export const RemoveButton = styled(Icon)`
  cursor: pointer;
  position: absolute;
  color: ${({ theme }) => theme.colors.web.darkest};
  height: 20px;
  width: 20px;
  top: 4px;
  right: 4px;

  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.colors.web.primary};
  }
`

export const Time = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const Date = styled(BodySmall)`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.web.black};
  margin-bottom: ${({ theme }) => theme.margin['3xs']};
`

export const MeetingTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: ${({ theme }) => theme.margin.xxs};
`
