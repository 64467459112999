import styled from 'styled-components'
import { Icon } from '@bonliva-traits/web-icons'
import {
  BodyRegular,
  H1,
  ButtonPrimary,
  ButtonSecondary,
} from '@bonliva-ui/web'

export const Center = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
`

export const Login = styled.div`
  display: flex;
`

export const BackgroundImageWrapper = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin['3xs']};
`

export const BackgroundImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
`

export const BonlivaLogo = styled(Icon)`
  position: absolute;
  width: 200px;
  top: 24px;
  left: 24px;
  color: ${({ theme }) => theme.colors.web.white};
`

export const BonlivaSlogan = styled(Icon)`
  position: absolute;
  width: 151px;
  top: 58px;
  left: 49px;
  color: ${({ theme }) => theme.colors.web.white};
`

export const Content = styled.div`
  height: 100vh;
  min-width: 55vw;
  padding: ${({ theme }) => theme.margin.s};
  background-color: ${({ theme }) => theme.colors.web.white}cc;

  border-left: 1px solid ${({ theme }) => theme.colors.web.light};

  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const Button = styled(ButtonPrimary)`
  height: 56px;
  max-width: 356px;
  gap: ${({ theme }) => theme.margin['3xs']};
  margin-bottom: ${({ theme }) => theme.margin.xxs};
`

export const OutlineButton = styled(ButtonSecondary)`
  height: 56px;
  max-width: 356px;
  gap: ${({ theme }) => theme.margin['3xs']};
`

export const BankId = styled(Icon)`
  width: 22px;
  height: 22px;
`

export const Title = styled(H1)`
  font-size: 48px;
  margin-bottom: ${({ theme }) => theme.margin.s};
`

export const Body = styled(BodyRegular)`
  margin: ${({ theme }) => theme.margin['3xs']} 0;
`
