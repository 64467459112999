import * as React from 'react'
import * as S from './DatePicker.style'
import 'react-datepicker/dist/react-datepicker.css'
import ReactDatePicker, { registerLocale } from 'react-datepicker'
import sv from 'date-fns/locale/sv'
import { Icon } from '@bonliva-traits/web-icons'

registerLocale('sv', sv)

type Props = {
  onChange: (date: Date) => void
  value: Date | undefined
  minDate?: Date
  maxDate?: Date
  placeholder?: string
}

const DatePicker: React.FC<Props> = (props) => {
  return (
    <S.DatePicker $hasInput={!!props.value}>
      <ReactDatePicker
        showWeekNumbers
        minDate={props.minDate || new Date()}
        maxDate={props.maxDate}
        placeholderText={props.placeholder}
        locale="sv"
        dateFormat="dd-MM-yyyy"
        className="date-picker"
        selected={props.value}
        onChange={props.onChange}
        calendarStartDay={1}
        renderCustomHeader={({ monthDate, decreaseMonth, increaseMonth }) => (
          <div className="customHeader">
            <span className="react-datepicker__current-month">
              {monthDate.toLocaleString('sv-SV', {
                month: 'short',
                year: 'numeric',
              })}
            </span>

            <div className="customButtonContainer">
              <button
                onClick={decreaseMonth}
                aria-label="Previous Month"
                className={
                  'react-datepicker__navigation react-datepicker__navigation--previous'
                }
              >
                <Icon
                  src="ChevronLeft"
                  width="7px"
                  height="13px"
                  color="#495358"
                />
              </button>

              <button
                onClick={increaseMonth}
                aria-label="Next Month"
                className={
                  'react-datepicker__navigation react-datepicker__navigation--next'
                }
              >
                <Icon
                  src="ChevronRight"
                  width="7px"
                  height="13px"
                  color="#495358"
                />
              </button>
            </div>
          </div>
        )}
      />
    </S.DatePicker>
  )
}

export default DatePicker
