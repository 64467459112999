import React, { ReactNode } from 'react'
import Spinner from '../Spinner'
import * as S from './Table.style'

type Props = {
  header?: ReactNode
  head: ReactNode
  body: ReactNode
  footer?: ReactNode
  isLoading?: boolean
}

const TableWrapper: React.FC<Props> = (props) => {
  return (
    <S.TableWrapper>
      {props.header}
      <S.Table>
        {props.head}
        {props.body}
        {props.footer}
      </S.Table>
    </S.TableWrapper>
  )
}

type IHeaderProps = {
  title?: string
  unreadCount?: number
  showSearchBar?: boolean
  searchBarPlaceholder?: string
  searchBarValue?: string
  setSearchValue?: (value: string) => void
  showButton?: boolean
  buttonTitle?: string
  buttonOnClick?: () => void
  showDeleteButton?: boolean
  deleteButtonOnClick?: () => void
  deleteButtonTitle?: string
}

const TableHeader: React.FC<IHeaderProps> = (props) => {
  return (
    <S.TableHeader>
      <S.TableHeaderTitle>
        {props.title}

        {!!props.unreadCount && (
          <S.TableHeaderUnreadCount>
            ({props.unreadCount}) olästa meddelanden
          </S.TableHeaderUnreadCount>
        )}
      </S.TableHeaderTitle>

      <S.SideContent>
        {props.showButton && (
          <S.TableHeaderButton onClick={props.buttonOnClick}>
            {props.buttonTitle}
          </S.TableHeaderButton>
        )}

        {props.showDeleteButton && (
          <S.TableHeaderDeleteButton onClick={props.deleteButtonOnClick}>
            {props.deleteButtonTitle}
          </S.TableHeaderDeleteButton>
        )}

        {props.showSearchBar && (
          <S.TableHeaderSearch>
            <S.TableHeaderSearchIcon src="Search" />
            <S.TableHeaderSearchInput
              placeholder={props.searchBarPlaceholder}
              value={props.searchBarValue}
              onChange={(e) => props.setSearchValue?.(e.target.value)}
            />
          </S.TableHeaderSearch>
        )}
      </S.SideContent>
    </S.TableHeader>
  )
}

type IColumn = {
  label: string
  sortable?: string
}

type IHeadProps = {
  columns: IColumn[]
  sortBy?: string
  setSortBy?: (value: string) => void
  isAscending?: boolean
  setIsAscending?: (value: boolean) => void
}

const TableHead: React.FC<IHeadProps> = (props) => {
  return (
    <S.TableHead $numberOfColumns={props.columns.length}>
      {props.columns.map((column) => {
        const onSortHandler = () => {
          if (!column.sortable) return
          const shouldAscending =
            props.sortBy === column.sortable ? !props.isAscending : true
          props.setSortBy?.(column.sortable)
          props.setIsAscending?.(shouldAscending)
        }

        const isSelected = props.sortBy === column.sortable

        return (
          <tr key={column.label}>
            <S.TableHeadCell onClick={onSortHandler}>
              {column.label}

              {column.sortable && (
                <S.TableHeadCellSortIcon
                  src="ChevronUp"
                  $isSelected={isSelected}
                  $isAscending={(isSelected && props.isAscending) || false}
                />
              )}
            </S.TableHeadCell>
          </tr>
        )
      })}
    </S.TableHead>
  )
}

type IBodyProps = {
  children: ReactNode
  isLoading?: boolean
}

const TableBody: React.FC<IBodyProps> = (props) => {
  return (
    <S.TableBody>
      {props.isLoading && (
        <S.Loading>
          <th>
            <Spinner color="darkest" />
          </th>
        </S.Loading>
      )}
      {props.children}
    </S.TableBody>
  )
}

type IFooterProps = {
  page: number
  setPage: (value: number) => void
  numberOfPages: number
}

const TableFooter: React.FC<IFooterProps> = (props) => {
  return (
    <S.TableFooter>
      <tr>
        <S.TableFooterPagination>
          <S.TableFooterPaginationButton
            onClick={() => props.setPage(props.page - 1)}
            disabled={props.page <= 0}
          >
            <S.TableFooterPaginationButtonIcon src="ChevronLeft" />
          </S.TableFooterPaginationButton>

          <S.TableFooterPaginationPage>
            {props.page + 1} / {props.numberOfPages || 1}
          </S.TableFooterPaginationPage>

          <S.TableFooterPaginationButton
            onClick={() => props.setPage(props.page + 1)}
            disabled={props.page >= props.numberOfPages - 1}
          >
            <S.TableFooterPaginationButtonIcon src="ChevronRight" />
          </S.TableFooterPaginationButton>
        </S.TableFooterPagination>
      </tr>
    </S.TableFooter>
  )
}

const Table = {
  Wrapper: TableWrapper,
  Header: TableHeader,
  Head: TableHead,
  Body: TableBody,
  Footer: TableFooter,
}

export default Table
