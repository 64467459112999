import React from 'react'
import * as S from './PatientAppointments.style'
import { useNavigate } from 'react-router-dom'
import { useAppointments } from '@bonliva-routes/web/special-routes/PatientRoute'
import { format } from 'date-fns'
import PatientTopBar from '@bonliva-components/web/shared/PatientTopBar'
import Table from '@bonliva-components/web/shared/Table'
import Alert from '@bonliva-components/web/shared/Alert'

const tableColumns = [
  {
    label: 'Datum',
    sortable: 'booking.start_date',
  },
  {
    label: 'Tid',
  },
  {
    label: 'Orsak',
    sortable: 'treatment.category',
  },
  {
    label: 'Hälsoformulär',
  },
  {
    label: 'Behandlingsplan',
  },
]

const PatientAppointments: React.FC = () => {
  const appointments = useAppointments()
  const navigate = useNavigate()

  return (
    <S.PatientAppointments>
      <S.PatientAppointmentsLayout>
        <PatientTopBar />
      </S.PatientAppointmentsLayout>

      <S.PatientAppointmentsTableWrapper>
        <Table.Wrapper
          header={<Table.Header title="Samtal" />}
          head={
            <Table.Head
              columns={tableColumns}
              sortBy={appointments.sortBy}
              setSortBy={appointments.setSortBy}
              isAscending={appointments.isAscending}
              setIsAscending={appointments.setIsAscending}
            />
          }
          body={
            <Table.Body isLoading={appointments.isLoading}>
              {appointments.hasLoaded && !appointments.data?.length && (
                <S.EmptyInbox>
                  <Alert type="info" title="Inga samtal hittades" />
                </S.EmptyInbox>
              )}

              {appointments.data?.map((a) => {
                const onClickHandler = () => {
                  navigate(`${a.id}`)
                }

                return (
                  <S.TableRow key={a.id} onClick={onClickHandler}>
                    <S.FlexSpan>
                      <S.RowText>
                        {format(new Date(a.startDate), 'yyyy-MM-dd')}
                      </S.RowText>
                    </S.FlexSpan>

                    <S.FlexSpan>
                      <S.RowText>
                        {format(new Date(a.startDate), 'HH:mm')}
                        {' - '}
                        {format(new Date(a.endDate), 'HH:mm')}
                      </S.RowText>
                    </S.FlexSpan>

                    <S.FlexSpan>
                      <S.RowText>{a.treatment?.category}</S.RowText>
                    </S.FlexSpan>

                    <S.FlexSpan>
                      <S.RowText>{a.hasTreatmentForm ? 'Ja' : 'Nej'}</S.RowText>
                    </S.FlexSpan>
                    <S.FlexSpan>
                      <S.RowText>{a.treatmentPlanId ? 'Ja' : 'Nej'}</S.RowText>
                    </S.FlexSpan>
                  </S.TableRow>
                )
              })}
            </Table.Body>
          }
          footer={
            <Table.Footer
              page={appointments.page}
              setPage={appointments.setPage}
              numberOfPages={appointments.numberOfPages}
            />
          }
        />
      </S.PatientAppointmentsTableWrapper>
    </S.PatientAppointments>
  )
}

export default PatientAppointments
