import styled from 'styled-components'
export { H4 } from '@bonliva-ui/web'

export const AnnotationWrapper = styled.div`
  padding: 9px;
`

export const AnnotationTextBox = styled.div`
  margin-top: 5px;
  border: 1px solid ${(props) => props.theme.colors.web.neutralLight};
  border-radius: 8px;
  height: 100px;
  padding: 9px;

  &::after {
    content: 'Här skriver patienten sin kommentar';
    color: ${(props) => props.theme.colors.web.dark};
    font-family: Calibre;
    font-weight: 300;
    font-size: 14px;
    line-height: 16.66px;
    display: block;
  }
`
