import styled from 'styled-components'
import { H3 } from '@bonliva-ui/web'

export const Accordion = styled.div``

export const AccordionHeader = styled.div<{ open: boolean }>`
  cursor: pointer;
  height: 64px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.margin.xxs};
  color: ${({ theme }) => theme.colors.web.black};
  font-weight: 500;
  font-size: 22px;
  border-bottom: ${({ open, theme }) =>
    open ? `1px solid ${theme.colors.web.neutral}80` : 'none'};
`

export const TitleWrapper = styled.div``

export const Title = styled(H3)`
  margin-top: 4px;
  line-height: 26px;
`

export const SubTitle = styled.span`
  color: ${({ theme }) => theme.colors.web.darker};
`

export const IconWrapper = styled.div<{ isOpen: boolean }>`
  transform: ${({ isOpen }) => (isOpen ? 'rotate(-180deg)' : 'rotate(0deg)')};
  transition: transform 0.3s ease;

  svg {
    height: 16px;
    width: 16px;
    color: ${({ theme }) => theme.colors.web.primary};
  }
`

export const AccordionBody = styled.div``
