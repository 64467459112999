import styled from 'styled-components'

export const Dashboard = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`

export const DashboardLayout = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: calc(100% - ${({ theme }) => theme.margin.xxs} * 2);
  margin: ${({ theme }) => theme.margin.xxs};
  gap: ${({ theme }) => theme.margin.xxs};

  @media (min-width: 1200px) {
    align-items: flex-start;
    flex-direction: row;
  }
`

export const DashboardColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.xxs};
`
