import styled from 'styled-components'
import { BodySmall, BodyXSmall } from '@bonliva-ui/web'
import { Icon } from '@bonliva-traits/web-icons'

export const MediaCardThumbnail = styled.img`
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: ${({ theme }) => theme.radius.rounded};
`

export const MediaCardThumbnailOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.2s ease;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
`
export const MediaCard = styled.div`
  width: 100%;
  min-height: 160px;
  position: relative;
  border-radius: ${({ theme }) => theme.radius.rounded};
  border: 1px solid ${({ theme }) => theme.colors.web.light};
  overflow: hidden;

  cursor: pointer;

  &:hover {
    ${MediaCardThumbnailOverlay} {
      opacity: 0.7;
    }
  }
`

export const MediaCardContent = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  padding: ${({ theme }) => theme.margin.xxs};
`

export const MediaCardTitle = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.white};
  font-weight: 500;
`

export const MediaCardDetail = styled(BodyXSmall)`
  color: ${({ theme }) => theme.colors.web.white};
  font-weight: 400;
  display: flex;
  text-transform: uppercase;
  gap: ${({ theme }) => theme.margin['3xs']};
`

export const FavoriteIcon = styled(Icon)`
  height: 28px;
  width: 28px;
  position: absolute;
  top: ${({ theme }) => theme.margin.xxs};
  right: ${({ theme }) => theme.margin.xl};
  background-color: ${({ theme }) => theme.colors.web.primary};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: 6px;
  color: ${({ theme }) => theme.colors.web.white};

  -webkit-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  &:hover {
    filter: brightness(125%);
  }
`

export const ShareIcon = styled(Icon)`
  height: 28px;
  width: 28px;
  position: absolute;
  top: ${({ theme }) => theme.margin.xxs};
  right: ${({ theme }) => theme.margin.xxs};
  background-color: ${({ theme }) => theme.colors.web.primary};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: 6px;
  color: ${({ theme }) => theme.colors.web.white};

  -webkit-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 30px -15px rgba(0, 0, 0, 0.2);
  cursor: pointer;

  &:hover {
    filter: brightness(125%);
  }
`
