import styled, { css } from 'styled-components'
import { BodySmall, ButtonPrimary, H3, H5 } from '@bonliva-ui/web'

export const NotificationsCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.web.white};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: ${({ theme }) => theme.margin.xxs};
  margin-bottom: 0;
`

export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  min-height: 42px;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const Title = styled(H3)`
  color: ${({ theme }) => theme.colors.web.darkest};
  margin-bottom: 0;
  line-height: 16px;
`

export const Button = styled(ButtonPrimary)``

export const NoMailAlert = styled.div`
  margin-top: ${({ theme }) => theme.margin.xxs};
`

export const Content = styled.div<{ $disabled: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  height: 100%;
  min-height: 200px;
  margin: -${({ theme }) => theme.margin['4xs']};
  margin-top: ${({ theme }) => theme.margin.xxs};
  padding: ${({ theme }) => theme.margin.xxs};
  border: 1px solid ${({ theme }) => theme.colors.web.light};
  border-radius: ${({ theme }) => theme.radius.rounded};
  gap: ${({ theme }) => theme.margin.xxs};
  transition: opacity 0.2s ease-in-out;

  ${({ $disabled }) =>
    $disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}
`

export const FlexWrapper = styled.div`
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const Underline = styled.div`
  border: 0.5px solid ${({ theme }) => theme.colors.web.light}99;
  width: calc(100% + ${({ theme }) => theme.margin.xxs} * 2);
  margin: 0 -${({ theme }) => theme.margin.xxs};
`

export const Label = styled(H5)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const Description = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darkest};
`
