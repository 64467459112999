import React, { useMemo } from 'react'
import * as S from './DashboardLinkCards.style'
import { Images } from '@bonliva-ui/assets/images'
import { IconType } from '@bonliva-traits/web-icons'

const DashboardLinkCards: React.FC = () => {
  const cards = useMemo(
    () => [
      {
        label: 'Mediebibliotek',
        path: '/media-library',
        image: Images.Dash1,
        icon: IconType.MediaLibrary,
      },
      {
        label: 'Kalender',
        path: '/calendar',
        image: Images.Dash2,
        icon: IconType.Calender,
      },
      {
        label: 'Inkorg',
        path: '/inbox',
        image: Images.Dash3,
        icon: IconType.Inbox,
      },
    ],
    []
  )

  return (
    <S.DashboardLinkCards>
      {cards.map((card, index) => (
        <S.Link key={index} to={card.path}>
          <S.DashboardLinkCard>
            <S.DashboardLinkCardImage src={card.image} />
            <S.DashboardLinkCardGradient />
            <S.DashboardLinkCardIcon src={card.icon} />
            <S.DashboardLinkCardLabel>{card.label}</S.DashboardLinkCardLabel>
          </S.DashboardLinkCard>
        </S.Link>
      ))}
    </S.DashboardLinkCards>
  )
}

export default DashboardLinkCards
