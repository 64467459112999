import { addPxPrefix } from '@bonliva-traits/utils'
import { Icon } from '@bonliva-traits/web-icons'
import { H3 } from '@bonliva-ui/web'
import styled, { keyframes, css } from 'styled-components'

const FadeIn = keyframes`
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`

export const Modal = styled.div`
  animation: ${FadeIn} 0.1s ease-in-out;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const background = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  cursor: pointer;
`

export const Foreground = styled.div<{
  $height?: string | number
  $width?: string | number
  $maxHeight?: string | number
  $maxWidth?: string | number
}>`
  position: relative;
  z-index: 100;
  width: ${({ $width }) => addPxPrefix($width || '90vw')};
  height: ${({ $height }) => addPxPrefix($height || 'auto')};
  max-width: ${({ $maxWidth }) => addPxPrefix($maxWidth || '900px')};
  max-height: ${({ $maxHeight }) => addPxPrefix($maxHeight || '80vh')};
  min-height: 200px;
  background-color: ${({ theme }) => theme.colors.web.white};
  border-radius: ${({ theme }) => theme.radius.rounded};
  -webkit-box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.2);
`

export const Pagination = styled.div`
  bottom: -32px;
  left: 0;
  display: flex;
  justify-content: center;
  gap: 10px;
  height: 10px;
  width: 100%;
  position: absolute;
`

export const PaginationDot = styled.div<{ $active: boolean }>`
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.web.light};
  border: 1px solid ${({ theme }) => theme.colors.web.white};
  transition: background-color 0.2s ease-in-out, border 0.2s ease-in-out,
    transform 0.1s ease-in-out;

  ${({ $active }) =>
    $active &&
    css`
      background-color: ${({ theme }) => theme.colors.web.primary};
      border: 0.5px solid ${({ theme }) => theme.colors.web.primaryLight};
      transform: scale(130%);
    `}
`

export const ModalCloseButton = styled(Icon)`
  cursor: pointer;
  min-height: 22px;
  min-width: 22px;
  max-width: 22px;
  max-height: 22px;
  margin-left: auto;
  color: ${({ theme }) => theme.colors.web.darker};
`

export const ModalWrapper = styled.div`
  padding: ${({ theme }) => theme.margin.xxs};
  width: inherit;
  height: inherit;
  max-height: inherit;
  max-width: inherit;
  min-height: inherit;
  min-width: inherit;
  display: flex;
  flex-direction: column;
`

export const ModalHeader = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  column-gap: ${({ theme }) => theme.margin.xxs};
  row-gap: ${({ theme }) => theme.margin['4xs']};
  padding-bottom: ${({ theme }) => theme.margin.xxs};
`

export const ModalTitle = styled(H3)`
  font-weight: 500;
`

export const ModalContent = styled.div`
  overflow: scroll;
  display: flex;
  flex-direction: column;
  flex-shrink: 1;

  gap: ${({ theme }) => theme.margin.xxs};
  margin: -${({ theme }) => theme.margin['4xs']};
  padding: ${({ theme }) => theme.margin.xxs};
  margin-bottom: auto;
  border: 1px solid ${({ theme }) => theme.colors.web.light};
  border-radius: ${({ theme }) => theme.radius.rounded};
`

export const ModalFooter = styled.div`
  flex-shrink: 0;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: ${({ theme }) => theme.margin.xxs};
  margin-top: ${({ theme }) => theme.margin.xxs};
`
