import React, { useCallback } from 'react'
import * as S from './TermsModal.style'
import Modal from '@bonliva-components/web/shared/Modal'
import { ITreater } from '@bonliva-traits/api/types'
import {
  useFetchTreaterProfile,
  useUpdateTreaterProfile,
} from '@bonliva-traits/api/treater-profile'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  treater: ITreater
}

const TermsModal: React.FC<Props> = (props) => {
  const fetchProfile = useFetchTreaterProfile()
  const updateProfile = useUpdateTreaterProfile()
  const [isPolicyAccepted, setIsPolicyAccepted] = React.useState(false)

  const acceptTermsHandler = useCallback(async () => {
    await updateProfile({
      privacyPolicyAccepted: isPolicyAccepted,
    })

    fetchProfile()
  }, [isPolicyAccepted])

  const onDismissHandler = useCallback(() => {
    setIsPolicyAccepted(false)
    props.setIsOpen(false)
  }, [])

  return (
    <Modal.Wrapper isOpen={props.isOpen}>
      <Modal.Contents maxWidth="600px" onDismiss={onDismissHandler}>
        <S.TermsModal>
          <Modal.CloseButton />
          <S.BonlivaLogo src="Bonliva" />
          <S.Content>
            <S.Title>Välkommen {props.treater.name}!</S.Title>
            <S.Label>
              Genom att klicka i nedan godkänner du våra villkor.
            </S.Label>

            <S.CheckContainer>
              <S.FlexWrapper>
                <S.CheckBox
                  onClick={() => setIsPolicyAccepted((prev) => !prev)}
                >
                  {isPolicyAccepted && <S.Check src="Check" />}
                </S.CheckBox>
                <S.CheckBoxLabel>
                  Jag godkänner
                  <S.Link
                    href="https://bonliva-app-production-cms-assets.s3.eu-north-1.amazonaws.com/Bonliva_Care_integritetspolicy_%5B7%5D.pdf"
                    target="_blank"
                  >
                    Integritet
                  </S.Link>
                </S.CheckBoxLabel>
              </S.FlexWrapper>

              <S.Button
                disabled={!isPolicyAccepted}
                onClick={acceptTermsHandler}
              >
                Gå vidare
              </S.Button>
            </S.CheckContainer>
          </S.Content>
        </S.TermsModal>
      </Modal.Contents>
    </Modal.Wrapper>
  )
}

export default TermsModal
