import { IFavoriteMedia } from '../types'
import { Types } from './types'

export const createFavorite = (favorite: IFavoriteMedia) =>
  ({
    type: Types.CREATE_FAVORITE,
    payload: favorite,
  } as const)

export const deleteFavorite = (mediaFavoriteId: string) =>
  ({
    type: Types.DELETE_FAVORITE,
    payload: mediaFavoriteId,
  } as const)
