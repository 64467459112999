import { ButtonPrimary, H3 } from '@bonliva-ui/web'
import styled from 'styled-components'

export const MediaLibrary = styled.div``

export const MediaLibraryLayout = styled.div`
  margin: ${({ theme }) => theme.margin.xs};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const MediaLibraryContent = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: ${({ theme }) => theme.margin.xxs};
`

export const MediaLibrarySection = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin['4xs']};
`

export const MediaLibraryTitle = styled(H3)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const MediaLibraryUnderline = styled.div`
  display: flex;
  width: 100%;
  height: 0.5px;
  background-color: ${({ theme }) => theme.colors.web.neutral};
  margin-bottom: ${({ theme }) => theme.margin['3xs']};
`

export const MediaLibraryLoading = styled.div`
  height: calc(100vh / 1.5);
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const MediaLibraryFeedbackButton = styled(ButtonPrimary)`
  position: absolute;
  top: 16px;
  right: 16px;
`
