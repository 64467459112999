import styled from 'styled-components'

export const ReferralPlanCreate = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`

export const ReferralPlanCreateLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.margin.xxs};
`

export const ReferralPlanCreateContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.xxs};
`
