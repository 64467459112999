import { AxiosError, useApi } from '../../index'
import { deleteFavorite } from '../actions'
import store from '../store'

export const useDeleteMediaFavorite = () => {
  const api = useApi()
  const dispatch = store.useDispatch()

  return async (id: string) => {
    try {
      await api.delete(`/v1/treater/media-library-favorites/${id}`)

      dispatch(deleteFavorite(id))
    } catch (error) {
      const err = error as AxiosError
      if (!err.response) throw err
      dispatch(fail(err.response.data))
    }
  }
}
