import { BodySmall } from '@bonliva-ui/web'
import styled from 'styled-components'

export const PlanAnalysis = styled.div`
  padding: ${({ theme }) => theme.margin.xxs};
  position: relative;
  width: 100%;
  height: 100%;
  max-height: 683px;
`

export const Body = styled(BodySmall)``
