import { ButtonPrimary, H3 } from '@bonliva-ui/web'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const PatientTopBar = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  border-radius: ${({ theme }) => theme.radius.rounded};
  margin-bottom: ${({ theme }) => theme.margin.xxs};
  padding: ${({ theme }) => theme.margin.xxs};
  background-color: ${({ theme }) => theme.colors.web.white};
  overflow: hidden;
`

export const PatientTopBarLayout = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: ${({ theme }) => theme.margin.xxs};
  row-gap: ${({ theme }) => theme.margin['3xs']};
  border-radius: ${({ theme }) => theme.radius.rounded};
  margin-bottom: ${({ theme }) => theme.margin['3xs']};
`

export const PatientTopBarNav = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.margin.m};
  padding: 0 ${({ theme }) => theme.margin['3xs']};
`

export const PatientTopBarNavLink = styled(Link)<{ $isActive: boolean }>`
  color: ${({ theme }) => theme.colors.web.darkest};
  font-weight: 500;
  text-decoration: none;
  font-size: 18px;
  transition: color 0.1s ease-in-out;
  padding: ${({ theme }) => theme.margin['3xs']};
  margin: -${({ theme }) => theme.margin['3xs']};
  margin-bottom: -${({ theme }) => theme.margin.xxs};

  ${({ $isActive, theme }) =>
    $isActive && `border-bottom: 2px solid ${theme.colors.web.primary};`}

  &:hover {
    color: ${({ theme }) => theme.colors.web.primary};
  }
`

export const FlexWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  column-gap: ${({ theme }) => theme.margin.xxs};
  row-gap: ${({ theme }) => theme.margin['3xs']};
`

export const Title = styled(H3)`
  margin-top: 4px;
  margin-left: 4px;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.colors.web.darkest};
  font-weight: 500;
  line-height: 22px;
`

export const Button = styled(ButtonPrimary)``
