import React, { useMemo } from 'react'
import ProgressBar from '@bonliva-components/web/shared/ProgressBar'
import * as S from './PlanSummary.style'
import { ITreatmentPlan } from '@bonliva-traits/api/types'
import { useNavigate, useParams } from 'react-router-dom'
import TreatmentPlanStatusChip from '@bonliva-components/web/shared/TreatmentPlanStatusChip'
import { useApi } from '@bonliva-traits/api'

type PlanSummaryProps = {
  treatmentPlan: ITreatmentPlan
  refetch: () => void
}

const PlanSummary = (props: PlanSummaryProps) => {
  const api = useApi()
  const { patientId, treatmentPlanId } = useParams()
  const navigate = useNavigate()

  const progressInPercent = useMemo(() => {
    const { completedBookingsCount, bookingsCount } = props.treatmentPlan
    if (!bookingsCount || !completedBookingsCount) return 0
    return Math.floor((completedBookingsCount / bookingsCount) * 100)
  }, [props.treatmentPlan])

  const navigateToEditHandler = () => {
    navigate(`/patients/${patientId}/treatment-plans/${treatmentPlanId}/create`)
  }

  const markAsDoneHandler = async () => {
    try {
      await api.put(`/v1/treaters/treatment-plans/${treatmentPlanId}/complete`)
      props.refetch()
    } catch (e) {
      throw new Error("Couldn't send complete treatment plan request")
    }
  }

  return (
    <S.SummaryContainer>
      <S.TopBarWrapper>
        <S.Title>Översikt</S.Title>

        <S.ButtonWrapper>
          <S.MarkAsDoneButton
            disabled={!!props.treatmentPlan.completedAt}
            onClick={markAsDoneHandler}
          >
            {props.treatmentPlan.completedAt ? 'Avklarad' : 'Markera som klar'}
          </S.MarkAsDoneButton>
          <S.EditButton onClick={navigateToEditHandler}>Redigera</S.EditButton>
        </S.ButtonWrapper>
      </S.TopBarWrapper>

      <S.ContentWrapper>
        <S.SummaryWrapper>
          <S.SummaryStatusContent>
            <S.SummaryHeader>Status</S.SummaryHeader>

            <S.SummaryBody>
              <TreatmentPlanStatusChip
                rejectionMessage={props.treatmentPlan?.rejectionMessage}
                status={props.treatmentPlan.status}
              />
            </S.SummaryBody>
          </S.SummaryStatusContent>
        </S.SummaryWrapper>

        <S.FirstWrapper>
          <S.SummaryAppointmentContent>
            <S.SummaryHeader>Ärende</S.SummaryHeader>

            <S.SummaryBody>
              {props.treatmentPlan?.treatmentCategory?.category}
            </S.SummaryBody>
          </S.SummaryAppointmentContent>

          <S.SummaryProgressContent>
            <S.SummaryHeader>Samtal</S.SummaryHeader>
            <S.SummaryBody>
              <S.ProgressBarWrapper>
                <ProgressBar
                  count={props.treatmentPlan.bookingsCount}
                  completed={props.treatmentPlan.completedBookingsCount}
                />
              </S.ProgressBarWrapper>
              <S.ProgressInfoWrapper>
                <S.ProgressInfo>
                  {props.treatmentPlan.completedBookingsCount}/
                  {props.treatmentPlan.bookingsCount} Slutförda
                </S.ProgressInfo>
                <S.ProgressInfo>{progressInPercent}%</S.ProgressInfo>
              </S.ProgressInfoWrapper>
            </S.SummaryBody>
          </S.SummaryProgressContent>
        </S.FirstWrapper>

        <S.SummaryWrapper>
          {props.treatmentPlan.information && (
            <S.SummaryContent>
              <S.SummaryHeader>Info</S.SummaryHeader>
              <S.SummaryBody>{props.treatmentPlan.information}</S.SummaryBody>
            </S.SummaryContent>
          )}
        </S.SummaryWrapper>
      </S.ContentWrapper>
    </S.SummaryContainer>
  )
}

export default PlanSummary
