import React, { useState, useMemo, useCallback } from 'react'
import * as S from './MediaLibrary.style'
import { useLocation } from 'react-router-dom'
import { IExercise, IExerciseProgram } from '@bonliva-traits/api/types'
import MediaTopBar from '@bonliva-components/web/shared/MediaTopBar'
import { useMediaLibrary } from '@bonliva-traits/api/media-library'
import { useMediaFavorites } from '@bonliva-traits/api/media-favorites'
import SectionedResults from './components/SectionedResults'
import SearchResults from './components/SearchResults'
import Spinner from '@bonliva-components/web/shared/Spinner'
import FeedbackFormModal from './components/FeedbackFormModal'
import SuccessModal from '@bonliva-components/web/shared/SuccessModal'

const MediaLibrary = () => {
  const location = useLocation()
  const mediaLibrary = useMediaLibrary()
  const favorites = useMediaFavorites()
  const [showFeedbackModal, setShowFeedbackModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)
  const [searchBarValue, setSearchBarValue] = useState('')

  const isFavorite = useMemo(
    () => location.pathname.includes('favorites'),
    [location.pathname]
  )

  const filterFavoriteMedia = useCallback(
    (item: IExercise | IExerciseProgram) => {
      if (!isFavorite) return true

      const hasFavorite = favorites.data.some(
        (favorite) => favorite.mediaLibraryId === item.uuid
      )

      return hasFavorite
    },
    [favorites.data, isFavorite]
  )

  const filterSearchBarValue = useCallback(
    (item: IExercise | IExerciseProgram) => {
      if (searchBarValue.length === 0) return true

      const searchValue = searchBarValue.toLowerCase()

      return (
        item.title?.toLowerCase().includes(searchValue) ||
        item.subtitle?.toLowerCase().includes(searchValue) ||
        item.description?.toLowerCase().includes(searchValue)
      )
    },
    [searchBarValue]
  )

  return (
    <S.MediaLibrary>
      <MediaTopBar
        showSearchBar
        searchBarValue={searchBarValue}
        setSearchBarValue={setSearchBarValue}
        searchBarPlaceholder="Sök bland program och övningar"
        detail={`Mediebibliotek ${isFavorite ? '• Favoriter' : ''}`}
        title={
          isFavorite
            ? 'Dina favorit övningar och program'
            : 'Mediebibliotek för övningar och program'
        }
        description={
          isFavorite
            ? 'Sök bland dina favorit program och övningar'
            : 'Sök bland våra program och övningar.'
        }
      />

      {searchBarValue.length > 0 ? (
        <SearchResults
          exerciseCategories={mediaLibrary.data}
          favorites={favorites.data}
          favoriteFilter={filterFavoriteMedia}
          searchFilter={filterSearchBarValue}
        />
      ) : (
        <SectionedResults
          exerciseCategories={mediaLibrary.data}
          favorites={favorites.data}
          isFavorite={isFavorite}
          favoriteFilter={filterFavoriteMedia}
        />
      )}

      {!mediaLibrary.hasLoaded && mediaLibrary.isLoading && (
        <S.MediaLibraryLoading>
          <Spinner color="neutral" />
        </S.MediaLibraryLoading>
      )}

      <S.MediaLibraryFeedbackButton onClick={() => setShowFeedbackModal(true)}>
        Feedback
      </S.MediaLibraryFeedbackButton>

      {showFeedbackModal && (
        <FeedbackFormModal
          isOpen={showFeedbackModal}
          setIsOpen={setShowFeedbackModal}
          setShowSuccessModal={setShowSuccessModal}
        />
      )}

      {showSuccessModal && (
        <SuccessModal
          isOpen={showSuccessModal}
          setIsOpen={setShowSuccessModal}
          title="Tack för din feedback!"
          successTitle="Vi har tagit emot din feedback och kommer att titta på den så fort vi kan."
        />
      )}
    </S.MediaLibrary>
  )
}

export default MediaLibrary
