import styled, { css } from 'styled-components'
import { Icon } from '@bonliva-traits/web-icons'
import { BodySmall, H3 } from '@bonliva-ui/web'

export const DashboardReminders = styled.div`
  background-color: ${({ theme }) => theme.colors.web.white};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: ${({ theme }) => theme.margin.xxs};
  flex: 1;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: ${({ theme }) => theme.margin.xxs};
  row-gap: ${({ theme }) => theme.margin['4xs']};
  margin-bottom: ${({ theme }) => theme.margin.xxs};
  padding-bottom: ${({ theme }) => theme.margin['3xs']};
  border-bottom: 1px solid ${({ theme }) => theme.colors.web.light};
`

export const Title = styled(H3)`
  font-weight: 500;
`

export const TitleNumber = styled.span`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.web.darker};
`

export const DashboardRemindersNavigation = styled.div`
  display: flex;
  align-items: center;
  gap: ${(props) => props.theme.margin.xxs};
`

export const DashboardRemindersNavigationButton = styled(Icon)<{
  disabled?: boolean
}>`
  min-height: 28px;
  min-width: 28px;
  height: 28px;
  width: 28px;
  color: ${(props) => props.theme.colors.web.secondary};
  background-color: ${(props) => props.theme.colors.web.secondaryLight};
  border-radius: 9999px;
  padding: 8px;
  transition: filter 0.1s ease-in-out;
  border: 0.5px solid ${(props) => props.theme.colors.web.secondary}33;
  cursor: pointer;

  ${(props) =>
    props.disabled
      ? css`
          background-color: ${props.theme.colors.web.light};
          color: ${props.theme.colors.web.dark};
          border: 1px solid ${props.theme.colors.web.neutral};
        `
      : css`
          &:hover {
            filter: brightness(95%);
          }
        `}
`

export const NoRemindersMessage = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darker};
  font-weight: 400;
  text-align: center;
`

export const LoadingWrapper = styled.div`
  margin-top: ${({ theme }) => theme.margin['4xs']};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`
