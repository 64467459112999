import React, { useState, useRef, useEffect } from 'react'
import * as S from './CreateBookingsCalendar.style'
import Calendar, { CalendarRef } from '@bonliva-components/web/shared/Calendar'
import { useReferralPlanBookings } from '../../hooks/useReferralPlanBookings'
import { useNavigate, useParams } from 'react-router-dom'
import { usePatient } from '@bonliva-routes/web/special-routes/PatientRoute'
import { format, sub, add, isWithinInterval } from 'date-fns'
import { sv } from 'date-fns/locale'
import { EventContentArg, DateSelectArg } from '@fullcalendar/core'
import { IAppointment } from '@bonliva-traits/api/types'
import { BookingMeetingType, BookingStatus } from '@bonliva-traits/api/enums'
import { useApi } from '@bonliva-traits/api'
import { useToast } from '@bonliva-traits/web-toasts'
import { useCalendarEvents } from '../../hooks/useCalendarEvents'
import { isAxiosError } from 'axios'
import ErrorModal from '@bonliva-components/web/shared/ErrorModal/ErrorModal'
import PlanTimeSlot from '@bonliva-components/web/shared/PlanTimeSlot/PlanTimeSlot'

type EventInput = {
  id: string
  title?: string
  start: string
  end: string
  extendedProps: {
    isLoading: boolean
    appointment?: IAppointment
  }
  status: BookingStatus
}

type Props = {
  treatmentId?: string
  numberOfAppointmentsLeft?: number
}

const CreateBookingsCalendar: React.FC<Props> = (props) => {
  const api = useApi()
  const toast = useToast()
  const calendarRef = useRef<CalendarRef>(null)
  const params = useParams<{ referralPlanId: string; date?: string }>()
  const navigate = useNavigate()
  const patient = usePatient()
  const calenderEvents = useCalendarEvents(params.referralPlanId || '')
  const [newEvents, setNewEvents] = useState<EventInput[]>([])
  const [prevEvents, setPrevEvents] = useState<EventInput[]>([])
  const [numberOfAppointmentsLeft, setNumberOfAppointmentsLeft] = useState(
    props.numberOfAppointmentsLeft || 0
  )

  const referralPlanUrl = `/v1/treaters/referral-plans/${params.referralPlanId}`

  const appointments = useReferralPlanBookings(params.referralPlanId)

  const onCreateEventClick = async (i: DateSelectArg) => {
    const startHour = i.start
    const startHourStr = startHour.toISOString()
    setNumberOfAppointmentsLeft((prev) => prev - 1)

    if (startHour < add(new Date(), { weeks: 1 })) return

    const eventInput: EventInput = {
      id: format(i.start, 'dd-HH-mm') + 'loading',
      title: patient.data?.name,
      start: startHour.toISOString(),
      end: add(startHour, { minutes: 45 }).toISOString(),
      extendedProps: { isLoading: true },
      status: BookingStatus.Reserved,
    }

    const preAvailableSlot = calendarRef.current?.getEventById(
      format(sub(i.start, { minutes: 30 }), 'dd-HH-mm') + 'available'
    )

    const availableSlot = calendarRef.current?.getEventById(
      format(i.start, 'dd-HH-mm') + 'available'
    )

    const postAvailableSlot = calendarRef.current?.getEventById(
      format(add(i.start, { minutes: 30 }), 'dd-HH-mm') + 'available'
    )

    if (!availableSlot) return

    try {
      preAvailableSlot?.remove()
      availableSlot?.remove()
      postAvailableSlot?.remove()

      calendarRef.current?.addEvent(eventInput)

      const appointment = await api.post<IAppointment>(
        `${referralPlanUrl}/bookings/reserve`,
        {
          startDate: startHourStr,
          endDate: add(new Date(startHourStr), { minutes: 45 }).toISOString(),
          treatmentId: props.treatmentId,
          patientId: patient.data?.id,
        }
      )

      calendarRef.current
        ?.getEventById(format(i.start, 'dd-HH-mm') + 'loading')
        ?.remove()

      eventInput.id = appointment.data.id
      eventInput.extendedProps.isLoading = false
      eventInput.extendedProps.appointment = appointment.data

      calendarRef.current?.addEvent(eventInput)
      setNewEvents((prev) => [...prev, eventInput])
    } catch (e) {
      calendarRef.current?.getEventById(eventInput.id)?.remove()

      if (preAvailableSlot) {
        calendarRef.current?.addEvent({
          id: preAvailableSlot.id,
          start: preAvailableSlot.start || new Date(),
          end: preAvailableSlot.end || new Date(),
          display: 'background',
          extendedProps: { isAvailable: true },
        })
      }

      if (availableSlot) {
        calendarRef.current?.addEvent({
          id: availableSlot.id,
          start: availableSlot.start || new Date(),
          end: availableSlot.end || new Date(),
          display: 'background',
          extendedProps: { isAvailable: true },
        })
      }

      if (postAvailableSlot) {
        calendarRef.current?.addEvent({
          id: postAvailableSlot.id,
          start: postAvailableSlot.start || new Date(),
          end: postAvailableSlot.end || new Date(),
          display: 'background',
          extendedProps: { isAvailable: true },
        })
      }

      if (isAxiosError(e)) {
        if (e.response?.data.status === 409) {
          toast.addToast({
            id: 'error',
            toast: (
              <ErrorModal
                isOpen={true}
                title="Något gick fel vid bokningen"
                errorTitle="Tiden är redan bokad. Vänligen välj en annan tid."
              />
            ),
          })
        }
      } else {
        toast.addToast({
          id: 'general-error',
          toast: (
            <ErrorModal
              isOpen={true}
              title="Något gick fel vid bokningen"
              errorTitle="Något gick fel vid bokningen, vänligen försök igen vid ett senare tillfälle."
            />
          ),
        })
      }
    }
  }

  const eventIsDisabled = (event: EventContentArg) => {
    const sameReferralPlanId =
      event.event.extendedProps.appointment?.referralPlanId !==
      params.referralPlanId

    const disabled = event.isPast || sameReferralPlanId

    return disabled
  }

  const handleDeleteClick = async (id: string) => {
    const event = calendarRef.current?.getEventById(id)
    if (event) {
      event.remove()

      const isEventOneHourBefore = calendarRef.current
        ?.getApi()
        ?.getEvents()
        .some((e) => {
          const appointment = e._def.extendedProps.appointment
          if (!appointment) return false
          return isWithinInterval(new Date(appointment.startDate), {
            start: sub(new Date(event.start || new Date()), { hours: 1 }),
            end: new Date(event.start || new Date()),
          })
        })

      const isEventOneHourAfter = calendarRef.current
        ?.getApi()
        ?.getEvents()
        .some((e) => {
          const appointment = e._def.extendedProps.appointment
          if (!appointment) return false
          return isWithinInterval(new Date(appointment.startDate), {
            start: new Date(event.start || new Date()),
            end: add(new Date(event.start || new Date()), { hours: 1 }),
          })
        })

      if (!isEventOneHourBefore) {
        calendarRef.current?.addEvent({
          id:
            format(
              sub(event.start || new Date(), { minutes: 30 }),
              'dd-HH-mm'
            ) + 'available',
          start: sub(event.start || new Date(), { minutes: 30 }),
          end: event.start || new Date(),
          display: 'background',
          extendedProps: { isAvailable: true },
        })
      }

      calendarRef.current?.addEvent({
        id: format(event.start || new Date(), 'dd-HH-mm') + 'available',
        start: event.start || new Date(),
        end: add(event.start || new Date(), { minutes: 30 }),
        display: 'background',
        extendedProps: { isAvailable: true },
      })

      if (!isEventOneHourAfter) {
        calendarRef.current?.addEvent({
          id:
            format(
              add(event.start || new Date(), { minutes: 30 }),
              'dd-HH-mm'
            ) + 'available',
          start: add(event.start || new Date(), { minutes: 30 }),
          end: add(event.start || new Date(), { minutes: 60 }),
          display: 'background',
          extendedProps: { isAvailable: true },
        })
      }
    }

    setNewEvents((prev) => prev.filter((e) => e.id !== id))
    await api.put(`${referralPlanUrl}/bookings/${id}/cancel`)
    setNumberOfAppointmentsLeft((prev) => prev + 1)
  }

  const handleMeetingTypeClick = async (
    bookmeetingType: BookingMeetingType,
    event: EventInput
  ) => {
    const calendarEvent = calendarRef.current?.getEventById(event.id)
    if (calendarEvent) {
      calendarEvent.remove()

      const appointment = await api.put<IAppointment>(
        `${referralPlanUrl}/bookings/${event.id}/update`,
        {
          meetingType: bookmeetingType,
        }
      )

      const eventInput: EventInput = {
        ...event,
        extendedProps: {
          appointment: appointment.data,
          isLoading: false,
        },
      }

      calendarRef.current?.addEvent(eventInput)
    }
  }

  const handleSaveClick = async () => {
    navigate(
      `/patients/${patient?.data?.id}/referral-plans/${params.referralPlanId}`
    )
  }

  const handlePublishClick = async () => {
    await api.post(`${referralPlanUrl}/publish`)
    navigate(
      `/patients/${patient?.data?.id}/referral-plans/${params.referralPlanId}`
    )
  }

  const fetchReferralPlanHandler = () => {
    if (!appointments.data) return

    const paidAndCompleted = appointments.data.filter((b) =>
      [BookingStatus.Paid, BookingStatus.Completed].includes(b.status)
    )

    const reservedAndCreated = appointments.data.filter((b) =>
      [BookingStatus.Reserved, BookingStatus.Created].includes(b.status)
    )

    const mapAppointmentToEvent = (b: IAppointment) => ({
      title: patient.data?.name,
      id: b.id,
      start: b.startDate,
      end: b.endDate,
      extendedProps: {
        isLoading: false,
        appointment: b,
      },
      status: b.status,
    })

    const reservedBookings = reservedAndCreated.map(mapAppointmentToEvent)
    const completedBookings = paidAndCompleted.map(mapAppointmentToEvent)

    setNewEvents(reservedBookings)
    setPrevEvents(completedBookings)
  }

  useEffect(() => {
    fetchReferralPlanHandler()
  }, [appointments.data])

  useEffect(() => {
    setNumberOfAppointmentsLeft(
      (props.numberOfAppointmentsLeft || 0) - newEvents.length
    )
  }, [])

  useEffect(() => {
    newEvents.forEach((e) => calendarRef.current?.getEventById(e.id)?.remove())
  }, [params.date])

  return (
    <S.CreateBookingsCalendar>
      <S.CreateBookingsHeader>
        <S.CreateBookingsHeaderTitle>
          Lägg till tider
        </S.CreateBookingsHeaderTitle>

        <S.CreateBookingsHeaderButtonsWrapper>
          <S.AbortButton onClick={handleSaveClick}>Spara</S.AbortButton>

          <S.SaveButton onClick={handlePublishClick}>Skicka</S.SaveButton>
        </S.CreateBookingsHeaderButtonsWrapper>
      </S.CreateBookingsHeader>

      <Calendar
        selectable
        ref={calendarRef}
        date={params.date}
        events={calenderEvents}
        initialDate={add(new Date(), { weeks: 1 })}
        eventIsDisabled={eventIsDisabled}
        onCreateEventClick={
          numberOfAppointmentsLeft > 0 ? onCreateEventClick : undefined
        }
        sideMenuContent={
          <S.CreatedBookings>
            <S.CreatedBookingsTitle>Tillagda tider</S.CreatedBookingsTitle>
            {newEvents.length ? (
              <S.CreatedBookingsWrapper>
                {newEvents
                  .sort(
                    (a, b) =>
                      new Date(a.start).getTime() - new Date(b.start).getTime()
                  )
                  .map((e) => {
                    return (
                      <PlanTimeSlot
                        key={e.id}
                        event={e}
                        handleDeleteClick={handleDeleteClick}
                        handleMeetingTypeClick={handleMeetingTypeClick}
                      />
                    )
                  })}
              </S.CreatedBookingsWrapper>
            ) : (
              <S.Body>Du har inte lagt till några tider än</S.Body>
            )}

            {!!prevEvents.length && (
              <React.Fragment>
                <S.CreatedBookingsTitle>
                  Betalade eller avslutade tider
                </S.CreatedBookingsTitle>

                <S.CreatedBookingsWrapper>
                  {prevEvents.map((e, i) => {
                    const startTime = format(new Date(e.start), 'HH:mm', {
                      locale: sv,
                    })
                    const endTime = format(new Date(e.end), 'HH:mm', {
                      locale: sv,
                    })

                    return (
                      <S.CreatedBookingSlot key={i}>
                        <S.Time>
                          {startTime} - {endTime}
                        </S.Time>

                        <S.Date>
                          {format(new Date(e.start), 'dd MMMM yyyy', {
                            locale: sv,
                          })}
                        </S.Date>
                        <S.MeetingType>
                          {e.extendedProps.appointment?.meetingType ===
                          BookingMeetingType.Digital
                            ? 'Digitalt möte'
                            : 'Fysiskt möte'}
                        </S.MeetingType>
                      </S.CreatedBookingSlot>
                    )
                  })}
                </S.CreatedBookingsWrapper>
              </React.Fragment>
            )}
          </S.CreatedBookings>
        }
      />
    </S.CreateBookingsCalendar>
  )
}

export default CreateBookingsCalendar
