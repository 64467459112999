import styled from 'styled-components'
import { BodySmall, H4, H5 } from '@bonliva-ui/web'
import { Icon } from '@bonliva-traits/web-icons'

export const PatientMessages = styled.div`
  max-width: 1920px;
  margin: 0 auto;
`

export const PatientMessagesLayout = styled.div`
  display: flex;
  flex-direction: column;
  margin: ${({ theme }) => theme.margin.xxs};
  margin-bottom: -${({ theme }) => theme.margin.xxs};
`

export const PatientMessageTableWrapper = styled.div`
  margin: ${({ theme }) => theme.margin.xxs};
`

export const RowText = styled(BodySmall)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const TableRow = styled.tr`
  position: relative;
  padding: ${({ theme }) => theme.margin.xxs};

  display: grid;
  grid-template-columns: repeat(3, calc(${100 / 3}% - 16px));
  gap: ${({ theme }) => theme.margin.xxs};
  grid-template-rows: 1fr;
  height: 58px;
  background-color: ${({ theme }) => theme.colors.web.white}33;
  border-top: 0.5px solid ${({ theme }) => theme.colors.web.light};

  &:last-child {
    border-bottom: 0.5px solid ${({ theme }) => theme.colors.web.light};
  }
`

export const FlexSpan = styled.td`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.margin.xxs};
  overflow: hidden;
`

export const MessageWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.web.white};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: ${({ theme }) => theme.margin.xxs};
  margin: ${({ theme }) => theme.margin.xxs};
`

export const MessageHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.margin['3xs']};
  margin: ${({ theme }) => theme.margin['3xs']} 0;
`

export const MessageTitle = styled(H4)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const MessageContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const Message = styled(BodySmall)`
  color: ${({ theme }) => theme.colors.web.darkest};
  margin-bottom: ${({ theme }) => theme.margin['3xs']};
`

export const AttachHeader = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.margin['3xs']};
  margin: ${({ theme }) => theme.margin['3xs']} 0;
`

export const AttachHeaderIcon = styled(Icon)`
  height: 16px;
  width: 16px;
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const AttachHeaderLabel = styled(H5)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const FlexWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.margin['3xs']};
`

export const ListWrapper = styled.div`
  display: flex;
  margin-bottom: ${({ theme }) => theme.margin['3xs']};

  :last-child {
    margin-bottom: 0;
  }
`

export const ListItem = styled.a`
  text-decoration: none;
  display: flex;
  font-family: Calibre;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  color: ${(props) => props.theme.colors.web.darker};

  width: fit-content;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.web.secondaryLight};
  color: ${({ theme }) => theme.colors.web.secondary};
  padding: 2px ${({ theme }) => theme.margin['3xs']};
  border-radius: ${({ theme }) => theme.radius.rounded};
  border: 0.5px solid ${({ theme }) => theme.colors.web.secondary}66;
`
