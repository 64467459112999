import React from 'react'
import * as S from './ProgressBar.style'

interface ProgressBarProps {
  count?: number
  completed?: number
}

const ProgressBar = ({ count, completed }: ProgressBarProps) => {
  return <S.ProgressBar $count={count} $completed={completed} />
}

export default ProgressBar
