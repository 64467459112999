import { BodyBig, Link, ButtonPrimary } from '@bonliva-ui/web'
import styled from 'styled-components'

export const ProfileDropdownBackground = styled.div<{ $isOpen: boolean }>`
  visibility: ${({ $isOpen }) => ($isOpen ? 'visible' : 'hidden')};
  z-index: 100;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: ${({ $isOpen }) => ($isOpen ? '#00000033' : 'transparent')};
  transition: visibility 0.2s ease-in-out, background-color 0.2s ease-in-out;
  cursor: pointer;
`

export const ProfileDropdown = styled.div`
  z-index: 100;
  min-width: 280px;
  position: absolute;
  top: calc(56px + ${({ theme }) => theme.margin.xxs});
  right: ${({ theme }) => theme.margin.xxs};
  background-color: ${({ theme }) => theme.colors.web.white};

  padding: ${({ theme }) => theme.margin.xxs};
  border-radius: ${({ theme }) => theme.radius.rounded};

  -webkit-box-shadow: 0px 15px 35px -10px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 15px 35px -10px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 15px 35px -10px rgba(0, 0, 0, 0.3);
`

export const ProfileInfo = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const ProfileName = styled(BodyBig)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const Divider = styled.div`
  margin: ${({ theme }) => theme.margin.xxs} 0;
  width: 100%;
  height: 1px;
  background-color: ${({ theme }) => theme.colors.web.light};
`

export const NavBlock = styled.nav`
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const LinkText = styled(Link)`
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const LogoutButton = styled(ButtonPrimary)`
  width: 100%;
`
