import React, { useState, useCallback } from 'react'
import * as S from './Onboarding.style'
import { useTreaterProfile } from '@bonliva-traits/api/treater-profile'
import Modal from '@bonliva-components/web/shared/Modal'
import InfoSlide from './components/InfoSlide'
import ProfilePictureSlide from './components/ProfilePictureSlide'
import LanguageSlide from './components/LanguageSlide'
import TitleSlide from './components/TitleSlide'
import BioSlide from './components/BioSlide'
import CompetenceSlide from './components/CompetenceSlide'
import EmailSlide from './components/EmailSlide'
import LocationSlide from './components/LocationSlide/LocationSlide'

type Props = {
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
}

const Onboarding: React.FC<Props> = (props) => {
  const profile = useTreaterProfile()
  const [page, setPage] = useState(0)
  const [disabled, setDisabled] = useState(false)

  const prevPageHandler = useCallback(() => {
    setPage((prev) => {
      if (prev === 0) return 0
      return prev - 1
    })
  }, [])

  const nextPageHandler = useCallback(() => {
    setPage((prev) => {
      if (prev < 10) {
        return prev + 1
      }

      const script = document.createElement('script')
      script.setAttribute(
        'driftly-api',
        'ScUpHPoTyvfS5OtSJgVSdOyObMCcasB7c4Og1f5Wxz5oD'
      )
      script.src =
        'https://storage.googleapis.com/driftly-cdn/driftly-loader.umd.js'
      document.head.appendChild(script)

      props.setIsOpen(false)
      return prev
    })
  }, [])

  return (
    <Modal.Wrapper isOpen={props.isOpen}>
      <Modal.Contents
        backgroundDismissDisabled
        step={page}
        steps={11}
        height="80vh"
        maxHeight="800px"
      >
        <S.Onboarding>
          <S.Header>
            <S.BackArrowLabel onClick={prevPageHandler} $disabled={page === 0}>
              <S.BackArrowIcon src="ChevronLeft" />
              Tillbaka
            </S.BackArrowLabel>

            <S.Title>Välkommen {profile.data?.name}!</S.Title>
          </S.Header>

          <S.Content $center={[4, 5, 6, 7, 8].includes(page)}>
            {page <= 3 && <InfoSlide index={page} />}
            {page === 4 && <ProfilePictureSlide setDisabled={setDisabled} />}
            {page === 5 && <TitleSlide setDisabled={setDisabled} />}
            {page === 6 && <LocationSlide />}
            {page === 7 && <LanguageSlide setDisabled={setDisabled} />}
            {page === 8 && <BioSlide setDisabled={setDisabled} />}
            {page === 9 && <EmailSlide setDisabled={setDisabled} />}
            {page === 10 && <CompetenceSlide setDisabled={setDisabled} />}
          </S.Content>

          <S.Footer>
            <S.NextButton
              onClick={nextPageHandler}
              disabled={page > 3 && disabled}
            >
              {page < 10 ? 'Nästa' : 'Slutför'}
            </S.NextButton>
          </S.Footer>
        </S.Onboarding>
      </Modal.Contents>
    </Modal.Wrapper>
  )
}

export default Onboarding
