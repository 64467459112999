import React, { useState, useEffect } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { useFetchNotificationsUnreadCount } from '@bonliva-traits/api/notifications'
import {
  useFetchTreaterProfile,
  useTreaterProfile,
} from '@bonliva-traits/api/treater-profile'
import { useFetchMediaFavorites } from '@bonliva-traits/api/media-favorites'
import { useFetchMediaLibrary } from '@bonliva-traits/api/media-library'
import useInterval from '@bonliva-traits/hooks/useInterval'
import OnboardingRoute from './special-routes/OnboardingRoute'
import ProtectedRoute from './special-routes/ProtectedRoute'
import LayoutRoute from './special-routes/LayoutRoute'
import PopupRoute from './special-routes/PopupRoute'
import PatientRoute from './special-routes/PatientRoute'
import PreMeetingRoom from '@bonliva-web/pre-meeting-room'
import MeetingRoom from '@bonliva-web/meeting-room'
import PostMeetingRoom from '@bonliva-web/post-meeting-room'
import Calendar from '@bonliva-web/calendar'
import Patient from '@bonliva-web/patient'
import PatientTable from '@bonliva-web/patient-table'
import PatientAppointments from '@bonliva-web/patient-appointments'
import PatientAppointment from '@bonliva-web/patient-appointment'
import PatientMessages from '@bonliva-web/patient-messages'
import PatientMessage from '@bonliva-web/patient-message'
import Login from '@bonliva-auth/web'
import Dashboard from '@bonliva-web/dashboard'
import Inbox from '@bonliva-web/inbox'
import WorkTimes from '@bonliva-web/work-times'
import Account from '@bonliva-web/account'
import Exception from '@bonliva-web/404'
import MediaLibrary from '@bonliva-web/media-library'
import MediaProgram from '@bonliva-web/media-program'
import MediaExercise from '@bonliva-web/media-exercise'
import PatientTreatmentPlans from '@bonliva-web/patient-treatment-plans'
import PatientTreatmentPlan from '@bonliva-web/patient-treatment-plan'
import PatientTreatmentPlanCreate from '@bonliva-web/patient-treatment-plan-create'
import PatientReferralPlan from '@bonliva-web/patient-referral-plan'
import PatientReferralPlans from '@bonliva-web/patient-referral-plans'
import PatientReferralPlanEdit from '@bonliva-web/patient-referral-plan-edit'
import QuestionsAndAnswers from '@bonliva-web/questions-and-answers'
import Authenticating from '@bonliva-auth/web/Authenticating'
import { useAuth } from '@bonliva-auth/context'
import { Status } from '@bonliva-auth/context/context'

const Routing: React.FC = () => {
  const auth = useAuth()
  const profile = useTreaterProfile()
  const fetchProfile = useFetchTreaterProfile()
  const fetchNotificationsUnreadCount = useFetchNotificationsUnreadCount()
  const fetchFavorites = useFetchMediaFavorites()
  const fetchMediaLibrary = useFetchMediaLibrary()
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [isLoading, setIsLoading] = useState(true)

  useEffect(() => {
    ;(async () => {
      setTimeout(async () => {
        if (auth.status === Status.Authenticated) {
          setIsLoading(false)
          const treater = await fetchProfile()

          const hasNoInfo =
            !treater?.bio ||
            !treater?.languages?.length ||
            !treater?.licensedAt ||
            !treater?.specialistCompetences?.length

          if (treater?.privacyPolicyAccepted && !hasNoInfo) {
            const script = document.createElement('script')
            script.setAttribute(
              'driftly-api',
              'ScUpHPoTyvfS5OtSJgVSdOyObMCcasB7c4Og1f5Wxz5oD'
            )
            script.src =
              'https://storage.googleapis.com/driftly-cdn/driftly-loader.umd.js'
            document.head.appendChild(script)
          }
        }

        if (auth.status === Status.NotAuthenticated) {
          setIsLoading(false)
        }
      }, 500)
    })()
  }, [auth.status])

  useEffect(() => {
    const hasRequiredData =
      !!profile.data.id && !!profile.data.privacyPolicyAccepted

    setIsLoggedIn(hasRequiredData)
  }, [profile])

  useEffect(() => {
    if (!isLoggedIn) return
    fetchFavorites()
    fetchMediaLibrary()
  }, [isLoggedIn])

  useInterval(
    () => {
      if (!isLoggedIn) return
      fetchNotificationsUnreadCount()
    },
    isLoggedIn ? 60000 : null,
    [isLoggedIn]
  )

  if (isLoading || (auth.status === Status.Authenticated && !profile.data.id))
    return <Authenticating />

  return (
    <Routes>
      <Route path="login" element={<Login />} />
      <Route path="redirect" element={<Navigate to="login" />} />

      <Route element={<ProtectedRoute />}>
        <Route element={<OnboardingRoute />}>
          <Route element={<PopupRoute isLoggedIn={isLoggedIn} />}>
            <Route element={<LayoutRoute />}>
              <Route path="/" element={<Dashboard />} />

              <Route path="account" element={<Account />} />

              <Route path="inbox" element={<Inbox />} />

              <Route path="/calendar">
                <Route index element={<Navigate to="week" replace />} />
                <Route path=":type" element={<Calendar />} />
                <Route path=":type/:date" element={<Calendar />} />
              </Route>

              <Route path="work-times" element={<WorkTimes />} />

              <Route path="patients" element={<PatientTable />} />

              <Route path="meeting-room/:appointmentId">
                <Route index element={<Navigate to="pre-meeting" replace />} />
                <Route path="pre-meeting" element={<PreMeetingRoom />} />
                <Route path="meeting" element={<MeetingRoom />} />
                <Route path="post-meeting" element={<PostMeetingRoom />} />
              </Route>

              <Route path="media-library" element={<MediaLibrary />} />

              <Route
                path="media-library/favorites"
                element={<MediaLibrary />}
              />

              <Route
                path="media-library/program/:programId"
                element={<MediaProgram />}
              />

              <Route
                path="media-library/exercise/:exerciseId"
                element={<MediaExercise />}
              />

              <Route path="patients/:patientId" element={<PatientRoute />}>
                <Route index element={<Patient />} />

                <Route path="appointments">
                  <Route index element={<PatientAppointments />} />
                  <Route
                    path=":appointmentId"
                    element={<PatientAppointment />}
                  />
                </Route>

                <Route path="messages">
                  <Route index element={<PatientMessages />} />
                  <Route path=":messageId" element={<PatientMessage />} />
                </Route>

                <Route path="treatment-plans">
                  <Route index element={<PatientTreatmentPlans />} />

                  <Route
                    path=":treatmentPlanId"
                    element={<PatientTreatmentPlan />}
                  />

                  <Route path=":treatmentPlanId/create">
                    <Route index element={<Navigate to="week" replace />} />

                    <Route
                      path=":type/:date?"
                      element={<PatientTreatmentPlanCreate />}
                    />
                  </Route>
                </Route>

                <Route path="referral-plans">
                  <Route index element={<PatientReferralPlans />} />

                  <Route
                    path=":referralPlanId"
                    element={<PatientReferralPlan />}
                  />

                  <Route path=":referralPlanId/edit">
                    <Route index element={<Navigate to="week" replace />} />

                    <Route
                      path=":type/:date?"
                      element={<PatientReferralPlanEdit />}
                    />
                  </Route>
                </Route>
              </Route>

              <Route
                path="questions-and-answers"
                element={<QuestionsAndAnswers />}
              />

              <Route path="*" element={<Exception />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  )
}

export default Routing
