import React, { useState } from 'react'
import * as S from './Youtube.style'
import { IComponentYoutube } from '@bonliva-traits/api/types'

export const Youtube: React.FC<IComponentYoutube> = (props) => {
  const [videoId] = useState(props.url?.split('v=')[1])

  return (
    <S.YoutubePlayer
      title="Youtube player"
      sandbox="allow-same-origin allow-forms allow-popups allow-scripts allow-presentation"
      src={`https://youtube.com/embed/${videoId}?autoplay=0`}
    ></S.YoutubePlayer>
  )
}
