import {
  BodyRegular,
  BodySmall,
  ButtonPrimary,
  ButtonSecondary,
} from '@bonliva-ui/web'
import styled from 'styled-components'

export const LateCanceledBookingContainer = styled.div`
  width: 100%;
  min-height: 150px;
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: ${({ theme }) => theme.margin.xxs};
  border: 1px solid ${({ theme }) => theme.colors.web.light};
`

export const ListItem = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.margin.xxs};
  flex-wrap: wrap;
  margin-bottom: 16px;
`

export const ListItemPatientName = styled(BodyRegular)`
  color: ${({ theme }) => theme.colors.web.darkest};
  font-weight: 500;
`

export const ListItemAppointmentCause = styled(BodySmall)``

export const ListItemAppointmentTime = styled(BodyRegular)``

export const Body = styled(BodySmall)``

export const ButtonWrapper = styled.div`
  margin-top: ${({ theme }) => theme.margin.xxs};
  display: flex;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const Button = styled(ButtonPrimary)``

export const OutlineButton = styled(ButtonSecondary)``
