import React, { useState, useCallback, useEffect } from 'react'
import * as S from './ShareMediaModal.style'
import Modal from '../Modal'
import Spinner from '../Spinner'
import useApiState from '@bonliva-traits/hooks/useApiState'
import {
  IExercise,
  IExerciseProgram,
  IPatient,
} from '@bonliva-traits/api/types'
import { isExercise } from '@bonliva-traits/api/assignments/helpers/isExercise'
import { isExerciseProgram } from '@bonliva-traits/api/assignments/helpers/isExerciseProgram'

type Props = {
  media: IExerciseProgram | IExercise
  isOpen: boolean
  setIsOpen: (isOpen: boolean) => void
  setSuccessModalOpen: (isOpen: boolean) => void
}

const ShareMediaModal: React.FC<Props> = (props) => {
  const [searchBarValue, setSearchBarValue] = useState('')
  const [selectedPatients, setSelectedPatients] = useState<IPatient[]>([])

  const patients = useApiState<IPatient[]>('/v1/treaters/patients')
  const assignment = useApiState('/v1/treaters/assignments')

  useEffect(() => {
    patients.get()
  }, [])

  const patientSearchFilter = useCallback(
    (p: IPatient) => {
      if (searchBarValue === '') return p
      return p.name.toLowerCase().includes(searchBarValue.toLowerCase())
    },
    [searchBarValue]
  )

  const shareMediaHandler = useCallback(async () => {
    const patientIds = selectedPatients.map((patient) => patient.id)

    try {
      await Promise.all(
        patientIds.map(async (patientId) => {
          if (isExercise(props.media)) {
            await assignment.post({
              data: {
                patientId,
                assignments: [{ type: 'exercise', id: props.media.uuid }],
              },
            })
          }

          if (isExerciseProgram(props.media)) {
            await assignment.post({
              data: {
                patientId,
                assignments: [{ type: 'program', id: props.media.uuid }],
              },
            })
          }
        })
      )

      props.setSuccessModalOpen(true)
      props.setIsOpen(false)
    } catch (error) {
      throw new Error("Couldn't share media")
    }
  }, [props.media, selectedPatients])

  return (
    <Modal.Wrapper isOpen={props.isOpen}>
      <Modal.Contents onDismiss={() => props.setIsOpen(false)}>
        <S.ShareMediaModal>
          <Modal.CloseButton />

          <S.Header>
            <S.Title>
              Dela{' '}
              {isExercise(props.media)
                ? 'övningen'
                : isExerciseProgram(props.media)
                ? 'programmet'
                : 'median'}
              {' - '}
              {props.media.title}
            </S.Title>
          </S.Header>

          <S.Content>
            <S.ShareMediaCard>
              <S.ShareMediaCardThumbnail
                src={props.media.thumbnail.data?.attributes.url}
                alt={props.media.thumbnail.data?.attributes.alternativeText}
              />

              <S.ShareMediaCardThumbnailOverlay />

              <S.ShareMediaCardContent>
                <S.ShareMediaCardDetail>
                  {props.media.timeEstimation} min
                </S.ShareMediaCardDetail>

                <S.ShareMediaCardTitle>
                  {props.media.title}
                </S.ShareMediaCardTitle>

                <S.ShareMediaCardDescription>
                  {props.media.subtitle}
                </S.ShareMediaCardDescription>
              </S.ShareMediaCardContent>
            </S.ShareMediaCard>

            <S.ShareMediaSearchBar>
              <S.ShareMediaSearchBarIcon src="Search" />

              <S.ShareMediaSearchBarClearIcon
                $hasValue={!!searchBarValue}
                src="Close"
                onClick={() => setSearchBarValue('')}
              />

              <S.ShareMediaSearchBarInput
                placeholder="Sök efter patient"
                value={searchBarValue}
                onChange={(e) => setSearchBarValue(e.target.value)}
              />
            </S.ShareMediaSearchBar>

            <S.ShareMediaPatientsList>
              {!patients.hasLoaded && patients.isLoading ? (
                <S.PatientsListLoading>
                  <Spinner />
                </S.PatientsListLoading>
              ) : (
                <React.Fragment>
                  {patients.data
                    ?.filter(patientSearchFilter)
                    ?.map((patient) => {
                      const isChecked = selectedPatients.some(
                        (s) => s.id === patient.id
                      )

                      const patientCheckboxHandler = () => {
                        if (isChecked) {
                          setSelectedPatients(
                            selectedPatients.filter((s) => s.id !== patient.id)
                          )
                        } else {
                          setSelectedPatients([...selectedPatients, patient])
                        }
                      }

                      return (
                        <S.ShareMediaPatient
                          key={patient.id}
                          onClick={patientCheckboxHandler}
                        >
                          <S.ShareMediaPatientName>
                            {patient.name}
                          </S.ShareMediaPatientName>

                          <S.ShareMediaPatientCheckbox>
                            {isChecked && (
                              <S.ShareMediaPatientCheck src="Check" />
                            )}
                          </S.ShareMediaPatientCheckbox>
                        </S.ShareMediaPatient>
                      )
                    })}

                  {patients.data?.filter(patientSearchFilter).length === 0 && (
                    <S.NoPatientsFound>
                      Ingen patient hittades med sökningen{' '}
                      {`"${searchBarValue}"`}
                    </S.NoPatientsFound>
                  )}
                </React.Fragment>
              )}
            </S.ShareMediaPatientsList>
          </S.Content>

          <S.Footer>
            <Modal.DismissButton>
              <S.OutlineButton>Stäng</S.OutlineButton>
            </Modal.DismissButton>

            <S.Button
              onClick={shareMediaHandler}
              disabled={!selectedPatients.length}
            >
              {assignment.isLoading ? <Spinner /> : 'Dela'}
            </S.Button>
          </S.Footer>
        </S.ShareMediaModal>
      </Modal.Contents>
    </Modal.Wrapper>
  )
}

export default ShareMediaModal
