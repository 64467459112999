import styled from 'styled-components'
import { Icon } from '@bonliva-traits/web-icons'
import { H3 } from '@bonliva-ui/web'
import { Link as ReactLink } from 'react-router-dom'

export const DashboardLinkCards = styled.div`
  width: 100%;

  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: auto;
  grid-gap: ${({ theme }) => theme.margin.xxs};

  & > * {
    height: 200px;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto;

    & > *:not(:last-child) {
      height: 260px;
    }

    & > :last-child {
      height: 260px;

      grid-area: 2 / 1 / 3 / 3;
    }
  }

  @media (min-width: 1200px) {
    max-width: 50%;
  }
`

export const Link = styled(ReactLink)`
  text-decoration: none;
`

export const DashboardLinkCard = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  border-radius: ${({ theme }) => theme.radius.rounded};
  overflow: hidden;
  -webkit-box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.1);
  box-shadow: 0px 5px 10px -5px rgba(0, 0, 0, 0.1);
`

export const DashboardLinkCardImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

export const DashboardLinkCardGradient = styled.div`
  position: absolute;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  transition: opacity 0.2s ease;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );

  &:hover {
    opacity: 0.5;
  }
`

export const DashboardLinkCardIcon = styled(Icon)`
  position: absolute;
  top: ${({ theme }) => theme.margin.xxs};
  left: ${({ theme }) => theme.margin.xxs};

  color: ${({ theme }) => theme.colors.web.white};
  background-color: ${({ theme }) => theme.colors.web.primary};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: 10px;
  height: 42px;
  width: 42px;

  -webkit-box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3);
`

export const DashboardLinkCardLabel = styled(H3)`
  position: absolute;
  bottom: ${({ theme }) => theme.margin.xxs};
  left: ${({ theme }) => theme.margin.xxs};

  color: ${({ theme }) => theme.colors.web.white};
  -webkit-text-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3);
  -moz-text-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3);
  text-shadow: 0px 5px 15px -5px rgba(0, 0, 0, 0.3);
`
