import React, { useCallback, useState } from 'react'
import * as S from './MediaCard.style'
import { IExercise, IExerciseProgram } from '@bonliva-traits/api/types'
import { isExercise } from '@bonliva-traits/api/assignments/helpers/isExercise'
import { isExerciseProgram } from '@bonliva-traits/api/assignments/helpers/isExerciseProgram'
import { useNavigate } from 'react-router-dom'
import { useTreaterProfile } from '@bonliva-traits/api/treater-profile'
import {
  useCreateMediaFavorite,
  useDeleteMediaFavorite,
} from '@bonliva-traits/api/media-favorites'
import ShareMediaModal from '../ShareMediaModal'
import SuccessModal from '../SuccessModal'

type Props = {
  media: IExerciseProgram | IExercise
  favoriteId?: string
  canFavorite: boolean
}

const MediaCard: React.FC<Props> = (props) => {
  const navigate = useNavigate()
  const profile = useTreaterProfile()
  const createFavorite = useCreateMediaFavorite()
  const deleteFavorite = useDeleteMediaFavorite()
  const [showShareModal, setShowShareModal] = useState(false)
  const [showSuccessModal, setShowSuccessModal] = useState(false)

  const setFavoriteHandler = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation()

      if (isExercise(props.media)) {
        if (props.favoriteId) deleteFavorite(props.favoriteId)
        else createFavorite('exercise', props.media.uuid, profile.data.id)
      }

      if (isExerciseProgram(props.media)) {
        if (props.favoriteId) deleteFavorite(props.favoriteId)
        else createFavorite('program', props.media.uuid, profile.data.id)
      }
    },
    [props.favoriteId]
  )

  const openShareModalHandler = useCallback((e: React.MouseEvent) => {
    e.stopPropagation()
    setShowShareModal(true)
  }, [])

  const navigateToMediaHandler = useCallback(() => {
    if (isExercise(props.media)) {
      navigate(`/media-library/exercise/${props.media.uuid}`)
    }

    if (isExerciseProgram(props.media)) {
      navigate(`/media-library/program/${props.media.uuid}`)
    }
  }, [props.media])

  return (
    <React.Fragment>
      <S.MediaCard onClick={navigateToMediaHandler}>
        <S.MediaCardThumbnail
          src={props.media.thumbnail.data?.attributes.url}
          alt={props.media.thumbnail.data?.attributes.alternativeText}
        />

        <S.MediaCardThumbnailOverlay />

        <S.MediaCardContent>
          {isExercise(props.media) && (
            <React.Fragment>
              <S.MediaCardDetail>
                <span>Övning</span>
                {' • '}
                <span>
                  {props.media.exerciseParts.length}{' '}
                  {props.media.exerciseParts.length > 1 ? 'delar' : 'del'}
                </span>
                {' • '}
                <span>{props.media.timeEstimation} min</span>
              </S.MediaCardDetail>

              <S.MediaCardTitle>{props.media.title}</S.MediaCardTitle>
            </React.Fragment>
          )}

          {isExerciseProgram(props.media) && (
            <React.Fragment>
              <S.MediaCardDetail>
                <span>Program</span>
                {' • '}
                <span>
                  {props.media.exercises.length}{' '}
                  {props.media.exercises.length > 1 ? 'övningar' : 'övning'}
                </span>
                {' • '}
                <span>{props.media.timeEstimation} min</span>
              </S.MediaCardDetail>

              <S.MediaCardTitle>{props.media.title}</S.MediaCardTitle>
            </React.Fragment>
          )}

          {props.canFavorite && (
            <React.Fragment>
              <S.FavoriteIcon
                src={props.favoriteId ? 'HeartFilled' : 'Heart'}
                onClick={setFavoriteHandler}
              />

              <S.ShareIcon onClick={openShareModalHandler} src="Share" />
            </React.Fragment>
          )}
        </S.MediaCardContent>
      </S.MediaCard>

      {showShareModal && (
        <ShareMediaModal
          media={props.media}
          isOpen={showShareModal}
          setIsOpen={setShowShareModal}
          setSuccessModalOpen={setShowSuccessModal}
        />
      )}

      {showSuccessModal && (
        <SuccessModal
          isOpen={showSuccessModal}
          setIsOpen={setShowSuccessModal}
          title="Delning genomförd"
          successTitle={`${
            isExercise(props.media)
              ? 'Övningen'
              : isExerciseProgram(props.media)
              ? 'Programmet'
              : 'Media'
          } har delats`}
        />
      )}
    </React.Fragment>
  )
}

export default MediaCard
