import { useApi } from '@bonliva-traits/api'
import { ITreatmentPlan } from '@bonliva-traits/api/types'

export const useCreateTreatmentPlan = () => {
  const api = useApi()

  const createTreatmentPlan = async (patientId: string) => {
    try {
      const data = { patientId }
      const response = await api.post<ITreatmentPlan>(
        `v1/treaters/treatment-plans/create`,
        data
      )
      return response.data
    } catch (error) {
      throw new Error('Error creating treatment')
    }
  }
  return createTreatmentPlan
}
