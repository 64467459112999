import useInterval from '@bonliva-traits/hooks/useInterval'
import { useState, useCallback, useEffect } from 'react'

const convertToHumanReadable = (a: number) => {
  return 1000 > a
    ? a / 2000
    : 2000 > a
    ? (a - 1000) / 2000 + 0.5
    : 3000 > a
    ? (a - 2000) / 2000 + 1
    : 4000 > a
    ? (a - 3000) / 2000 + 1.5
    : 6000 > a
    ? (a - 4000) / 4000 + 2
    : 10000 > a
    ? (a - 6000) / 8000 + 2.5
    : 15000 > a
    ? (a - 10000) / 10000 + 3
    : 19000 > a
    ? (a - 15000) / 8000 + 3.5
    : 22000 > a
    ? (a - 19000) / 6000 + 4
    : 32767 >= a
    ? (a - 22000) / 21534 + 4.5
    : 5
}

const useVolume = () => {
  const [analyser, setAnalyser] = useState<AnalyserNode>()
  const [volume, setVolume] = useState<number>()
  const [active, setActive] = useState(false)

  const startCheckVolume = useCallback(() => {
    setActive(true)
  }, [])

  const stopCheckVolume = useCallback(() => {
    setVolume(0)
    setActive(false)
  }, [])

  const createAnalyserHandler = useCallback(async () => {
    try {
      const audio = { echoCancellation: true }
      const audioStream = await navigator.mediaDevices.getUserMedia({ audio })
      const audioContext = new AudioContext()
      const audioSource = audioContext.createMediaStreamSource(audioStream)
      const analyser = audioContext.createAnalyser()
      analyser.fftSize = 2048
      analyser.smoothingTimeConstant = 0.3
      audioSource.connect(analyser)

      setAnalyser(analyser)

      return () => {
        audioSource.disconnect()
        audioContext.close()
        audioStream.getAudioTracks().forEach((track) => track.stop())
      }
    } catch (e) {
      throw e
    }
  }, [])

  const fetchVolumeHandler = useCallback(() => {
    if (!analyser) return
    const a = new Uint8Array(analyser.fftSize)
    analyser.getByteTimeDomainData(a)
    const d = 256 * a.reduce((b, c) => Math.max(b, Math.abs(c - 128)), 0)

    const averageVolume = convertToHumanReadable(d) / 5

    setVolume(averageVolume)
  }, [analyser])

  useEffect(() => {
    const cleanup = createAnalyserHandler()

    return () => {
      cleanup.then((c) => c())
    }
  }, [])

  useInterval(fetchVolumeHandler, active ? 100 : null)

  return [volume, startCheckVolume, stopCheckVolume] as const
}

export default useVolume
