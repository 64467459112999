import styled from 'styled-components'
import { BodySmall, ButtonPrimary, H3 } from '@bonliva-ui/web'

export const CompetenceCard = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.web.white};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: ${({ theme }) => theme.margin.xxs};
  margin-bottom: 0;
`

export const Header = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.margin.xxs};
`

export const Title = styled(H3)`
  color: ${({ theme }) => theme.colors.web.darkest};
  margin-bottom: 0;
  line-height: 16px;
`

export const Button = styled(ButtonPrimary)``

export const Content = styled.div`
  display: flex;
  align-items: flex-start;
  height: 100%;
  margin: -${({ theme }) => theme.margin['4xs']};
  margin-top: ${({ theme }) => theme.margin.xxs};
  padding: ${({ theme }) => theme.margin.xxs};
  border: 1px solid ${({ theme }) => theme.colors.web.light};
  border-radius: ${({ theme }) => theme.radius.rounded};
`

export const CompetenceList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.margin['3xs']};
`

export const Competence = styled(BodySmall)`
  width: fit-content;
  height: fit-content;
  background-color: ${({ theme }) => theme.colors.web.secondaryLight};
  color: ${({ theme }) => theme.colors.web.secondary};
  padding: 2px ${({ theme }) => theme.margin['3xs']};
  border-radius: ${({ theme }) => theme.radius.rounded};
  border: 0.5px solid ${({ theme }) => theme.colors.web.secondary}66;
`
