import React, { useState, useEffect } from 'react'
import * as S from './PlanWellbeingScale.style'
import { Icon } from '@bonliva-traits/web-icons'
import { format } from 'date-fns'
import { ITreatmentForm } from '@bonliva-traits/api/types'
import Accordion from '../Accordion'

type Props = {
  treatmentForms?: ITreatmentForm[]
}

const PlanWellbeingScale: React.FC<Props> = (props) => {
  const [currentIndex, setCurrentIndex] = useState(0)

  const filteredCompletedForms = props.treatmentForms?.filter(
    (form) => !!form.completedAt
  )

  const prevPageHandler = () => {
    setCurrentIndex((prev) => (prev === 4 ? prev : prev - 1))
  }

  const nextPageHandler = () => {
    setCurrentIndex((prev) =>
      prev === props.treatmentForms?.length || 1 - 1 ? prev : prev + 1
    )
  }

  useEffect(() => {
    setCurrentIndex((filteredCompletedForms?.length || 1) - 1)
  }, [props.treatmentForms])

  return filteredCompletedForms?.length ? (
    <Accordion title="Sammanställning - välmåendeskalan">
      <S.PlanWellbeingScale>
        <S.FlexCol>
          <S.TableHeaderLeftWrapper>
            <S.IconWrapper
              onClick={prevPageHandler}
              disabled={currentIndex === 4}
              show={filteredCompletedForms.length > 5}
            >
              <Icon src="ChevronLeft" />
            </S.IconWrapper>

            <S.IconWrapper
              onClick={nextPageHandler}
              disabled={currentIndex === filteredCompletedForms.length - 1}
              show={filteredCompletedForms.length > 5}
            >
              <Icon src="ChevronRight" />
            </S.IconWrapper>
          </S.TableHeaderLeftWrapper>
          <S.FlexCol>
            {filteredCompletedForms[0]?.treatmentQuestionAnswers?.map(
              ({ treatmentQuestion, id }) => (
                <S.QuestionWrapper key={id}>
                  {treatmentQuestion?.question}
                </S.QuestionWrapper>
              )
            )}
          </S.FlexCol>
          <S.TableLeftFooter>Score: </S.TableLeftFooter>
        </S.FlexCol>

        <S.FlexRow>
          {(filteredCompletedForms.length > 5
            ? filteredCompletedForms?.slice(currentIndex - 4, currentIndex + 1)
            : filteredCompletedForms
          ).map((treatmentForm, index, arr) => (
            <S.FlexCol key={index}>
              <S.TableHeaderRightWrapper isLast={index === arr.length - 1}>
                <S.TableHeaderDate>
                  {treatmentForm?.completedAt &&
                    format(new Date(treatmentForm?.completedAt), 'yy-MM-dd')}
                </S.TableHeaderDate>
              </S.TableHeaderRightWrapper>
              <S.FlexCol>
                {treatmentForm.treatmentQuestionAnswers?.map(
                  ({ answer }, index) => (
                    <S.AnswerWrapper
                      key={index}
                      oddRow={index % 2 === 0 ? false : true}
                    >
                      {answer}
                    </S.AnswerWrapper>
                  )
                )}
              </S.FlexCol>
              <S.AnswerScoreWrapper isLast={index === arr.length - 1}>
                {treatmentForm.treatmentQuestionAnswers?.reduce(
                  (acc, { answer }) => acc + answer,
                  0
                )}
              </S.AnswerScoreWrapper>
            </S.FlexCol>
          ))}
        </S.FlexRow>
      </S.PlanWellbeingScale>
    </Accordion>
  ) : null
}

export default PlanWellbeingScale
