import styled from 'styled-components'

export const Switch = styled.div`
  display: inline-block;

  span {
    position: relative;
    width: 48px;
    height: 26px;
    float: left;
  }
`

export const SwitchLabel = styled.label`
  display: none;
`

export const SwitchSlider = styled.button`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  border: 0;
  outline: none;
  background-color: #ccc;
  transition: 0.2s;
  border-radius: 34px;
  cursor: pointer;

  &:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 10%;
    top: 50%;
    transform: translate(-10%, -50%);
    background-color: white;
    transition: 0.2s;
    border-radius: 50%;
  }
`

export const SwitchInput = styled.input`
  display: none;
  &:checked + ${SwitchSlider} {
    background-color: ${({ theme }) => theme.colors.web.success};
  }
  &:checked + ${SwitchSlider}:before {
    transform: translate(22px, -50%);
  }
  &:focus + ${SwitchSlider} {
    box-shadow: 0 0 1px ${({ theme }) => theme.colors.web.success};
  }
`
