import { useEffect } from 'react'
import { IReferralPlan } from '@bonliva-traits/api/types'
import useApiState from '@bonliva-traits/hooks/useApiState'

export const useReferralPlan = (referralPlanId: string) => {
  const referralPlan = useApiState<IReferralPlan>(
    `v1/treaters/referral-plans/${referralPlanId}`
  )

  useEffect(() => {
    referralPlan.get()
  }, [])

  return referralPlan
}
