import React from 'react'
import * as S from './PlanAppointments.style'
import { useNavigate, useParams } from 'react-router-dom'
import { format } from 'date-fns'
import { IAppointment } from '@bonliva-traits/api/types'
import Accordion from '../Accordion'
import Table from '../Table'
import Alert from '../Alert'
import { BookingMeetingType } from '@bonliva-traits/api/enums'

const tableColumns = [
  {
    label: 'Datum',
  },
  {
    label: 'Tid',
  },
  {
    label: 'Mötestyp',
  },
  {
    label: 'Hälsoformulär',
  },
]

type Props = {
  appointments?: IAppointment[]
  currentPage: number
  numberOfPages: number
  setCurrentPage: (page: number) => void
}

const PlanAppointments: React.FC<Props> = (props) => {
  const navigate = useNavigate()
  const params = useParams()

  return (
    <S.PatientAppointmentsContainer>
      <Accordion title="Samtal">
        <S.PatientAppointmentsLayout>
          <Table.Wrapper
            head={<Table.Head columns={tableColumns} />}
            body={
              <Table.Body>
                {!props.appointments?.length && (
                  <S.EmptyInbox>
                    <Alert type="info" title="Inga samtal hittades" />
                  </S.EmptyInbox>
                )}

                {props.appointments?.map((a) => {
                  const onClickHandler = () => {
                    navigate(
                      `/patients/${params.patientId}/appointments/${a.id}`
                    )
                  }

                  return (
                    <S.TableRow key={a.id} onClick={onClickHandler}>
                      <S.FlexSpan>
                        <S.RowText>
                          {format(new Date(a.startDate), 'yyyy-MM-dd')}
                        </S.RowText>
                      </S.FlexSpan>

                      <S.FlexSpan>
                        <S.RowText>
                          {format(new Date(a.startDate), 'HH:mm')}
                          {' - '}
                          {format(new Date(a.endDate), 'HH:mm')}
                        </S.RowText>
                      </S.FlexSpan>
                      <S.FlexSpan>
                        <S.RowText>
                          {a.meetingType === BookingMeetingType.Digital
                            ? 'Digitalt'
                            : 'Fysiskt'}
                        </S.RowText>
                      </S.FlexSpan>
                      <S.FlexSpan>
                        <S.RowText>{a.treatmentForm ? 'Ja' : 'Nej'}</S.RowText>
                      </S.FlexSpan>
                    </S.TableRow>
                  )
                })}
              </Table.Body>
            }
            footer={
              <Table.Footer
                page={props.currentPage}
                setPage={props.setCurrentPage}
                numberOfPages={props.numberOfPages}
              />
            }
          />
        </S.PatientAppointmentsLayout>
      </Accordion>
    </S.PatientAppointmentsContainer>
  )
}

export default PlanAppointments
