import React, { useState, useCallback } from 'react'
import { useIcdCodes } from '@bonliva-traits/hooks/useIcdCodes'
import * as S from './MeetingNotepad.style'
import { useWebdocNotes } from '@bonliva-traits/webdoc-notes'
import Select from '../Select'
import SelectAsync from '../SelectAsync'
import SuccessModal from '../SuccessModal'
import Spinner from '../Spinner'
import { IAppointment } from '@bonliva-traits/api/types'

type Props = {
  appointment: IAppointment
}

const MeetingNotepad: React.FC<Props> = (props) => {
  const { getIcdCodesHandler } = useIcdCodes()
  const [loading, setLoading] = useState(false)
  const [openSuccessModal, setOpenSuccessModal] = useState(false)
  const {
    webdocTemplates,
    webdocTemplate,
    notes,
    selectedIcdCodes,
    setSelectedIcdCodes,
    setNote,
    setShowNotepad,
    setWebdocTemplate,
    saveToWebdoc,
  } = useWebdocNotes()

  const closeNotepadHandler = useCallback(() => {
    setShowNotepad(false)
  }, [])

  const saveClickHandler = async () => {
    setLoading(true)
    if (!webdocTemplate || !notes || !props.appointment) return
    try {
      const record = {
        bookingId: props.appointment.id,
        recordTemplateId: webdocTemplate?.id,
        record: {
          keywords: notes?.map((note) => ({
            id: note.id,
            value: note.value,
          })),
          diagnosisCodes: selectedIcdCodes.map((icdCode) => icdCode.code),
        },
      }
      await saveToWebdoc(record)
      setOpenSuccessModal(true)
      setLoading(false)
    } catch (error) {
      throw new Error("Couldn't save to webdoc")
    }
  }

  const promiseOptions = async (inputValue: string) => {
    const data = await getIcdCodesHandler(inputValue)
    return data?.map((icdCode) => ({
      value: icdCode,
      label: `${icdCode.code}`,
    }))
  }

  const successModalContent = (
    <span>
      Glöm inte bort att logga in i Webdoc och signera dina sparande
      anteckningar.
    </span>
  )

  return (
    <S.MeetingNotepad>
      <SuccessModal
        isOpen={openSuccessModal}
        setIsOpen={setOpenSuccessModal}
        title="Sparat till Webdoc"
        successTitle="Dina journal anteckningar är nu sparade till webdoc."
        content={successModalContent}
      />

      <S.MeetingNotepadHeader>
        <S.MeetingNotepadTitle>Journalanteckningar</S.MeetingNotepadTitle>

        <S.MeetingNotepadClose src="Close" onClick={closeNotepadHandler} />
      </S.MeetingNotepadHeader>

      {props.appointment?.treater?.webdocId ? (
        <S.MeetingNotepadContent>
          <S.MeetingNotepadDropdownTitle>
            Välj journalmall
          </S.MeetingNotepadDropdownTitle>

          <S.MeetingNotepadDropdownWrapper>
            <Select
              placeholder="Journalmall"
              value={
                webdocTemplate
                  ? {
                      value: webdocTemplate,
                      label: webdocTemplate.title,
                    }
                  : undefined
              }
              options={webdocTemplates?.map((template) => ({
                value: template,
                label: `${template.title}`,
              }))}
              onChange={(e) => e?.value && setWebdocTemplate(e.value)}
            />
          </S.MeetingNotepadDropdownWrapper>
          <S.MeetingNotepadNotesWrapper>
            {notes?.map((keyword, index) => (
              <S.MeetingNotepadInputWrapper key={keyword.id}>
                {index === 0 && (
                  <S.SelectAsyncWrapper>
                    <S.MeetingNotepadDropdownTitle>
                      ICD-koder
                    </S.MeetingNotepadDropdownTitle>

                    <SelectAsync
                      loadOptions={promiseOptions}
                      isMulti
                      formatOptionLabel={(option) =>
                        `${option.value.code} - ${option.value.title}`
                      }
                      onChange={(e) =>
                        setSelectedIcdCodes(
                          [...e.values()].map((value) => value.value)
                        )
                      }
                    />
                  </S.SelectAsyncWrapper>
                )}
                <S.MeetingNotepadDropdownTitle>
                  {keyword.warnIfEmpty ? keyword.title + ' *' : keyword.title}
                </S.MeetingNotepadDropdownTitle>
                <S.MeetingNotepadInput
                  placeholder="Skriv här..."
                  value={keyword.value}
                  onChange={(e) => setNote(keyword.id, e.target.value)}
                />
              </S.MeetingNotepadInputWrapper>
            ))}
          </S.MeetingNotepadNotesWrapper>
        </S.MeetingNotepadContent>
      ) : (
        <S.MeetingNotepadInput placeholder="Skriv här..." />
      )}

      <S.MeetingNotepadFooter>
        <S.MeetingNotepadButton
          onClick={saveClickHandler}
          disabled={
            !notes ||
            notes?.some((note) => note.warnIfEmpty && !note.value) ||
            notes?.every((note) => !note.value) ||
            !props.appointment?.treater?.webdocId
          }
        >
          {loading ? <Spinner color="white" /> : 'Spara till Webdoc'}
        </S.MeetingNotepadButton>
        {!props.appointment?.treater?.webdocId && (
          <S.Disclaimer>
            Du är inte ansluten till Webdoc och kan därför inte spara dina
            anteckningar.
          </S.Disclaimer>
        )}
      </S.MeetingNotepadFooter>
    </S.MeetingNotepad>
  )
}

export default MeetingNotepad
