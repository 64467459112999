import styled, { css } from 'styled-components'
import { Icon } from '@bonliva-traits/web-icons'
import { BodySmall } from '@bonliva-ui/web'

export const WorkTimes = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  padding: ${({ theme }) => theme.margin.xxs};
`

export const Deviations = styled.div`
  max-width: 1920px;
  margin: 0 auto;
  margin: ${({ theme }) => theme.margin.xxs} 0;
`

export const RowText = styled(BodySmall)`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.web.darkest};
`

export const EmptyInbox = styled.tr`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 120px;
  padding: 0 ${({ theme }) => theme.margin.xxs};
`

export const TableRow = styled.tr<{ $isActive?: boolean }>`
  position: relative;
  padding: ${({ theme }) => theme.margin.xxs};

  display: grid;
  grid-template-columns: repeat(5, calc(20% - 16px));
  gap: ${({ theme }) => theme.margin.xxs};
  grid-template-rows: 1fr;
  height: 64px;
  border-top: 0.5px solid ${({ theme }) => theme.colors.web.light};

  ${({ $isActive }) =>
    $isActive !== false
      ? css`
          background-color: ${({ theme }) => theme.colors.web.white}33;
          &:hover {
            background-color: ${({ theme }) => theme.colors.web.light}33;
            cursor: pointer;
          }
        `
      : css`
          background-color: ${({ theme }) => theme.colors.web.light}66;
          &:hover {
            background-color: ${({ theme }) => theme.colors.web.light}99;
            cursor: pointer;
          }
        `}
`

export const FlexSpan = styled.td`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: ${({ theme }) => theme.margin.xxs};
  overflow: hidden;
`

export const BonlivaIcon = styled(Icon)`
  max-height: 36px;
  min-height: 36px;
  min-width: 36px;
  max-width: 36px;
  background-color: ${({ theme }) => theme.colors.web.primaryLight}99;
  color: ${({ theme }) => theme.colors.web.primary};
  border-radius: ${({ theme }) => theme.radius.rounded};
  padding: 6px;

  display: none;

  @media (min-width: 982px) {
    display: block;
  }
`
