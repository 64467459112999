import styled, { css } from 'styled-components'

export const MeetingRoomNotepad = styled.div<{ $showNotepad: boolean }>`
  background-color: white;
  position: absolute;
  height: 100%;
  top: 0;
  max-width: 400px;
  min-width: 400px;
  border-radius: ${({ theme }) => theme.radius.rounded};
  transition: right 0.3s ease-in-out, opacity 0.3s ease-in-out;
  padding: ${({ theme }) => theme.margin.xxs};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.margin['3xs']};

  ${({ $showNotepad }) =>
    $showNotepad
      ? css`
          right: 0;
          opacity: 1;
        `
      : css`
          right: -500px;
          opacity: 0;
        `}
`
