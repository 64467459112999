import React, { useCallback, useState } from 'react'
import * as S from './ProfileCard.style'
import ProfilePicture from '@bonliva-components/web/shared/ProfilePicture'
import { useTreaterProfile } from '@bonliva-traits/api/treater-profile'
import { getLanguages } from '@bonliva-traits/api/treaters'
import { Icon } from '@bonliva-traits/web-icons'
import EditProfileModal from '../EditProfileModal'
import { getYear } from 'date-fns'

const ProfileCard: React.FC = () => {
  const profile = useTreaterProfile()
  const languages = getLanguages(profile.data)
  const [showEditModal, setShowEditModal] = useState(false)

  const openEditModalHandler = useCallback(() => {
    setShowEditModal(true)
  }, [])

  return (
    <S.ProfileCard>
      <S.FlexWrapper>
        <ProfilePicture profile={profile.data} height={112} width={112} />

        <S.Button onClick={openEditModalHandler}>Redigera profil</S.Button>
      </S.FlexWrapper>

      <S.Content>
        <S.Title>{profile.data?.name}</S.Title>

        <S.Underline />

        <span>
          <S.Label>Email</S.Label>
          <S.Body>{profile.data?.email || '-'}</S.Body>
        </span>

        <S.Underline />

        <span>
          <S.Label>Yrkestitel</S.Label>
          <S.Body>{profile.data.title}</S.Body>
        </span>

        <S.Underline />

        <span>
          <S.Label>Timpris</S.Label>
          <S.Body>{profile.data.hourlyRate / 100} Kr</S.Body>
        </span>

        <S.Underline />

        <span>
          <S.Label>Legitimerad sedan</S.Label>
          {profile.data.licensedAt !== undefined && (
            <S.Body>{getYear(new Date(profile.data.licensedAt))}</S.Body>
          )}
        </span>

        <S.Underline />

        <span>
          <S.Label>Språk</S.Label>
          <S.Body>
            {languages ? languages.join(', ') : 'Inga språk valda'}
          </S.Body>
        </span>

        <S.Underline />

        <span>
          <S.Label>Biografi</S.Label>
          <S.Body>{profile.data.bio}</S.Body>
        </span>

        <S.Underline />

        <span>
          <S.Label>Mottagningsadress</S.Label>
          <S.Body>{profile.data.physicalLocation?.adress}</S.Body>
          {profile.data.physicalLocation?.extraInfo && (
            <S.Body>{profile.data.physicalLocation?.extraInfo}</S.Body>
          )}
        </span>

        <S.Underline />

        <span>
          <S.Label>Presentationsvideo</S.Label>

          <S.VideoWrapper>
            {profile.data.presentation?.url ? (
              <S.Video src={profile.data.presentation.url} controls />
            ) : (
              <S.NoVideo onClick={openEditModalHandler}>
                <Icon src="NoVideo" color="white" />
              </S.NoVideo>
            )}
          </S.VideoWrapper>
        </span>
      </S.Content>

      {showEditModal && (
        <EditProfileModal isOpen={showEditModal} setIsOpen={setShowEditModal} />
      )}
    </S.ProfileCard>
  )
}

export default ProfileCard
