import React, { useState } from 'react'
import * as S from './NoMedicalRecord.style'
import SuccessModal from '@bonliva-components/web/shared/SuccessModal'
import ErrorModal from '@bonliva-components/web/shared/ErrorModal'
import WebDocModal from './components/WebDocModal'
import { IBooking } from '@bonliva-traits/api/types'
import { useToast } from '@bonliva-traits/web-toasts'
import { useApi } from '@bonliva-traits/api'
import { format } from 'date-fns'

type Props = {
  booking: IBooking
  refreshReminders: () => void
}

const NoMedicalRecord: React.FC<Props> = (props) => {
  const api = useApi()
  const toast = useToast()
  const [isWebDocModalOpen, setIsWebDocModalOpen] = useState(false)

  const savedInWebdocClickHandler = async () => {
    try {
      await api.post(`v1/treaters/webdoc/mark-medical-record-as-saved`, {
        bookingId: props.booking.id,
      })

      toast.addToast({
        toast: (
          <SuccessModal
            title="Status uppdaterad"
            successTitle="Statusen har uppdaterats."
          />
        ),
      })

      props.refreshReminders()
    } catch (error) {
      toast.addToast({
        toast: (
          <ErrorModal
            title="Något gick fel"
            errorTitle="Något gick fel när statusen skulle uppdateras."
          />
        ),
      })
    }
  }

  return (
    <S.NoMedicalRecordContainer>
      <S.ListItem>
        <div>
          <S.ListItemPatientName>
            {props.booking.patient?.name}
          </S.ListItemPatientName>

          <S.ListItemAppointmentCause>
            {props.booking.treatment?.category}
            {' - '}
            {format(new Date(props.booking.startDate), 'yyyy-MM-dd')}
            {' : '}
            {format(new Date(props.booking.startDate), 'HH:mm')} -{' '}
            {format(new Date(props.booking.endDate), 'HH:mm')}
          </S.ListItemAppointmentCause>
        </div>
      </S.ListItem>

      <S.Body>
        Du skrev aldrig några anteckningar på ditt senaste möte. Vill du lägga
        in det nu?
      </S.Body>

      <S.ButtonWrapper>
        <S.OutlineButton onClick={savedInWebdocClickHandler}>
          Jag har antecknat i Webdoc
        </S.OutlineButton>

        {!props.booking.treater?.careProvider?.isExternal && (
          <S.Button onClick={() => setIsWebDocModalOpen(true)}>
            Lägg till anteckning
          </S.Button>
        )}
      </S.ButtonWrapper>

      <WebDocModal
        isOpen={isWebDocModalOpen}
        setIsOpen={setIsWebDocModalOpen}
        booking={props.booking}
        refreshReminders={props.refreshReminders}
      />
    </S.NoMedicalRecordContainer>
  )
}

export default NoMedicalRecord
