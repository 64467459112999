import { useEffect, useState, useCallback } from 'react'
import { IAppointment } from '@bonliva-traits/api/types'
import useApi from '@bonliva-traits/hooks/useApiState'
import { BookingStatus } from '@bonliva-traits/api/enums'

export const useTreatmentPlanBookings = (treatmentPlanId?: string) => {
  const [data, setData] = useState<IAppointment[]>([])
  const appointments = useApi<IAppointment[]>(`v1/treaters/treatment-plans`)

  useEffect(() => {
    if (!treatmentPlanId) return
    getTreatmentPlanBookings()
  }, [treatmentPlanId])

  const getTreatmentPlanBookings = useCallback(async () => {
    try {
      const params = {
        status: [
          BookingStatus.Reserved,
          BookingStatus.Created,
          BookingStatus.Paid,
          BookingStatus.Completed,
        ],
        ['_sort']: 'b.start_date',
      }

      const result = await appointments.get(
        { params },
        `${treatmentPlanId}/appointments`
      )
      setData(result)
    } catch (error) {
      throw new Error("Couldn't get treatment plan bookings")
    }
  }, [])

  return { data }
}
