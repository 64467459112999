import { INotification } from '../types'
import { Types } from './types'

export const setNotification = (notification: INotification) =>
  ({
    type: Types.SET_NOTIFICATION,
    payload: notification,
  } as const)

export const removeNotification = (id: string) =>
  ({
    type: Types.REMOVE_NOTIFICATION,
    payload: id,
  } as const)

export const setUnreadCount = (unreadCount: number) =>
  ({
    type: Types.SET_UNREAD_COUNT,
    payload: unreadCount,
  } as const)
