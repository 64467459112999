import { BodySmall, Link } from '@bonliva-ui/web'
import styled from 'styled-components'

export const Footer = styled.footer`
  padding: 0 ${({ theme }) => theme.margin.xs};
  padding-bottom: ${({ theme }) => theme.margin['4xs']};
  margin-left: auto;
  width: calc(100% - 240px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
`

export const CopyRight = styled(BodySmall)`
  margin-right: ${({ theme }) => theme.margin.xxs};
  color: ${({ theme }) => theme.colors.web.darker};
`

export const FooterLinks = styled.nav`
  display: flex;
  column-gap: ${({ theme }) => theme.margin.xxs};
  flex-wrap: wrap;
`
export const FooterLink = styled(Link)`
  font-size: 16px;
  color: ${({ theme }) => theme.colors.web.darker};
`
