import { fail, pending } from '@bonliva-core/store'
import { AxiosError, useApi } from '../../index'
import { INotification } from '../../types'
import { setNotification } from '../actions'

import store from '../store'

export const useReadNotification = () => {
  const api = useApi()
  const dispatch = store.useDispatch()

  return async (notificationId: string) => {
    dispatch(pending())

    try {
      const notification = await api.put<INotification>(
        `/v2/notifications/${notificationId}/read`
      )

      dispatch(setNotification(notification.data))
    } catch (error) {
      const err = error as AxiosError
      if (!err.response) throw err
      dispatch(fail(err.response.data))
    }
  }
}
