import React from 'react'
import * as S from './VolumeMeter.style'

const VolumeMeter: React.FC<{ level: number }> = (props) => {
  return (
    <S.VolumeMeterContainer level={props.level}>
      <S.VolumeMeterRed />
      <S.VolumeMeterGreen />
      <S.VolumeMeterYellow />
    </S.VolumeMeterContainer>
  )
}

export default VolumeMeter
